import { $ } from "js/vendor";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { PositionType } from "common/constants";

export function createResizer(props, selectionLayer) {
    let {
        view,
        element,
        minSize = 0,
        maxSize = 1280,
        position = PositionType.RIGHT,
        scaleFromCenter = false,
        dividerSize = 0,
        offset = 0,
        onResize,
        handleType = "simple",
        roundValue = true,
        showSelectionBox = false
    } = props;

    const canvas = element.canvas;
    const canvasScale = canvas.getScale();
    let startSelectionBounds;

    let $resizer = view.$widgets.addEl($.div("ui_widget"));

    let $handle;
    switch (handleType) {
        case "tray":
            $handle = $resizer.addEl($.div("simple-resize-handle tray"));
            break;
        case "simple":
        default:
            $handle = $resizer.addEl($.div("simple-resize-handle"));
    }

    let axis;
    switch (position) {
        case PositionType.LEFT:
        case PositionType.RIGHT:
            $handle.addClass("horizontal");
            axis = "x";
            if (dividerSize > 0) {
                let $divider = $handle.addEl($.div("divider"));
                $divider.height(dividerSize).top(-dividerSize / 2);
            }
            break;
        case PositionType.TOP:
        case PositionType.BOTTOM:
        case PositionType.BOTTOM_RIGHT:
            $handle.addClass("vertical");
            axis = "y";
            if (dividerSize > 0) {
                let $divider = $handle.addEl($.div("divider"));
                $divider.width(dividerSize).left(-dividerSize / 2);
            }
            break;
    }

    if (scaleFromCenter) {
        maxSize /= 2;
        minSize /= 2;
        offset = 0;
    }

    let positionResizeHandle = () => {
        let x = (element.selectionBounds.left - view.element.selectionBounds.left) * canvasScale;
        let y = (element.selectionBounds.top - view.element.selectionBounds.top) * canvasScale;

        switch (position) {
            case PositionType.RIGHT:
                $resizer.left(x + element.selectionBounds.width * canvasScale + offset);
                $resizer.top(y + element.selectionBounds.height / 2 * canvasScale);
                break;
            case PositionType.LEFT:
                $resizer.left(x - offset);
                $resizer.top(y + element.selectionBounds.height / 2 * canvasScale);
                break;
            case PositionType.BOTTOM:
                $resizer.left(x + element.selectionBounds.width / 2 * canvasScale);
                $resizer.top(y + element.selectionBounds.height * canvasScale + offset);
                break;
            case PositionType.TOP:
                $resizer.left(x + element.selectionBounds.width / 2 * canvasScale);
                $resizer.top(y - offset);
                break;
            case PositionType.BOTTOM_RIGHT:
                $resizer.left(x + element.selectionBounds.width * canvasScale);
                $resizer.top(y + element.selectionBounds.height * canvasScale + offset);
                break;
        }
    };
    positionResizeHandle();

    let padding = 0;
    switch (position) {
        case PositionType.LEFT:
        case PositionType.RIGHT:
            if (typeof element.selectionPadding == "object") {
                padding = element.selectionPadding.left + element.selectionPadding.right;
            } else {
                padding = element.selectionPadding;
            }
            break;
        case PositionType.TOP:
        case PositionType.BOTTOM:
            if (typeof element.selectionPadding == "object") {
                padding = element.selectionPadding.top + element.selectionPadding.bottom;
            } else {
                padding = element.selectionPadding;
            }
            break;
    }

    minSize += padding;
    maxSize += padding;

    let elementBounds = element.canvasBounds.clone();
    let constraints = new geom.Rect(0, 0, element.canvas.CANVAS_WIDTH, element.canvas.CANVAS_HEIGHT);

    switch (position) {
        case PositionType.RIGHT:
            constraints.left = (scaleFromCenter ? elementBounds.centerH : elementBounds.left) + minSize;
            constraints.right = (scaleFromCenter ? elementBounds.centerH : elementBounds.left) + maxSize;
            break;
        case PositionType.LEFT:
            constraints.left = (scaleFromCenter ? elementBounds.centerH : elementBounds.right) - maxSize;
            constraints.right = (scaleFromCenter ? elementBounds.centerH : elementBounds.right) - minSize;
            break;
        case PositionType.BOTTOM:
        case PositionType.BOTTOM_RIGHT:
            constraints.top = (scaleFromCenter ? elementBounds.centerV : elementBounds.top) + minSize;
            constraints.bottom = (scaleFromCenter ? elementBounds.centerV : elementBounds.top) + maxSize;
            break;
        case PositionType.TOP:
            constraints.top = (scaleFromCenter ? elementBounds.centerV : elementBounds.bottom) - maxSize;
            constraints.bottom = (scaleFromCenter ? elementBounds.centerV : elementBounds.bottom) - minSize;
            break;
    }

    let $selectionBox;

    $resizer.makeDraggable({
        axis, constrainDrag: constraints,
        cursor: axis == "x" ? "ew-resize" : "ns-resize",
        start: event => {
            let $widgets = $resizer;
            startSelectionBounds = element.selectionBounds;
            if (showSelectionBox) {
                $selectionBox = view.$widgets.addEl($.div("selection-box"));
                $widgets = $resizer.add($selectionBox);
            }
            selectionLayer.hideWidgets($widgets);
        },
        drag: (event, dragPosition) => {
            if (element.canvas.layouter.isGenerating) {
                return;
            }

            window.requestAnimationFrame(async () => {
                let value;
                switch (position) {
                    case PositionType.RIGHT:
                        if (scaleFromCenter) {
                            value = Math.abs(dragPosition.canvasPosition.x - elementBounds.centerH - padding) * 2;
                        } else {
                            value = dragPosition.canvasPosition.x - offset - elementBounds.left - padding;
                        }
                        break;
                    case PositionType.LEFT:
                        if (scaleFromCenter) {
                            value = Math.abs(dragPosition.canvasPosition.x - elementBounds.centerH - padding) * 2;
                        } else {
                            value = elementBounds.right - dragPosition.canvasPosition.x - offset - padding;
                        }
                        break;
                    case PositionType.BOTTOM:
                    case PositionType.BOTTOM_RIGHT:
                        if (scaleFromCenter) {
                            value = Math.abs(dragPosition.canvasPosition.y - elementBounds.centerV - padding) * 2;
                        } else {
                            value = dragPosition.canvasPosition.y - elementBounds.top - offset - padding;
                        }
                        break;
                    case PositionType.TOP:
                        if (scaleFromCenter) {
                            value = Math.abs(dragPosition.canvasPosition.y - elementBounds.centerV - padding) * 2;
                        } else {
                            value = elementBounds.bottom - dragPosition.canvasPosition.y - offset - padding;
                        }
                        break;
                }

                if (roundValue) {
                    value = Math.round(value);
                }

                await onResize(value, positionResizeHandle);

                if (showSelectionBox) {
                    $selectionBox.setBounds(element.selectionBounds.offset(-startSelectionBounds.left, -startSelectionBounds.top).multiply(canvasScale));
                }
            });
        },
        stop: event => {
            event.stopPropagation();
            $selectionBox && $selectionBox.remove();
            selectionLayer.showWidgets();
            element.canvas.updateCanvasModel(false).then(() => {
                positionResizeHandle();
            });
        }
    });
}
