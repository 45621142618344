import {
    AuthoringBlockType,
    BackgroundStyleType, BlockStructureType,
    ContentBlockType,
    HorizontalAlignType,
    PositionType,
    TextStyleType,
    AssetType,
    ListStyleType
} from "common/constants";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";

import { BaseElement } from "../BaseElement";
import { TextElement, GetBlockModelsFromMigratedHtml } from "./TextElement";

export class TextFrame extends BaseElement {
    get _canSelect() {
        return false;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return this.options.canRefreshElement ?? true;
    }

    get isOverImage() {
        return super.getBackgroundColor(this) == BackgroundStyleType.IMAGE;
    }

    get text() {
        return this.textFrameBox.text;
    }

    get canDelete() {
        return this.options.canDelete ?? true;
    }

    get canDragPosition() {
        return this.options.canDragPosition ?? true;
    }

    get canDragResize() {
        return this.options.canDragResize ?? true;
    }

    get hasUserPosition() {
        return this.canDragPosition && (this.userPositionX != null && this.userPositionY != null);
    }

    get userWidth() {
        return this.model.userWidth;
    }

    get userPositionX() {
        return this.model.userPositionX;
    }

    get userPositionY() {
        return this.model.userPositionY;
    }

    get textPosition() {
        if (this.hasUserPosition) {
            return PositionType.USER;
        } else if (this.options.textPosition) {
            return this.options.textPosition;
        } else {
            return this.model.textPosition ?? PositionType.CENTER;
        }
    }

    _build() {
        this.textFrameBox = this.addElement("textFrameBox", () => TextFrameBox, this.options.textOptions || {});
    }

    _calcProps(props, options) {
        let { size } = props;

        let innerPadding = 0;
        if (!this.hasUserPosition) {
            // determine the inner padding of the textFrame when using textPosition to position the box
            // this can be different depending on whether the TextFrameBox has a backdrop or now
            innerPadding = this.textFrameBox.hasBackdrop ? this.styles.innerPaddingWithBackdrop : this.styles.innerPaddingWithNoBackdrop;
        }

        // if the user has defined a width, we will use that to fit the text within - otherwise we will use autoWidth to calc the textWidth
        let autoWidth = !this.userWidth;

        // this the special caption style when setting to top/bottom and will fill the width of the frame
        if ([PositionType.TOP, PositionType.BOTTOM].includes(this.textPosition) && this.textFrameBox.hasBackdrop) {
            innerPadding = 0;
            autoWidth = false;
        }

        let innerBounds = new geom.Rect(0, 0, size).deflate(innerPadding);
        let availableTextBoxSize = innerBounds.size;

        if (this.userWidth) {
            // constrain available width to the userWidth
            availableTextBoxSize.width = Math.min(availableTextBoxSize.width, this.userWidth);
        } else {
            switch (this.textPosition) {
                case PositionType.CENTER:
                    break;
                case PositionType.TOP:
                case PositionType.BOTTOM:
                    // adjust to cover anti-aliased edge
                    availableTextBoxSize.width += 1;
                    break;
                default:
                    if (!this.options.fillFrameWidth) {
                        // unless fillFrameWidth = true, auto size the textBox to half the width
                        availableTextBoxSize.width = availableTextBoxSize.width / 2;
                    }
                    break;
            }
        }

        let textFrameBoxProps = this.textFrameBox.calcProps(availableTextBoxSize, {
            forceTextScale: options.forceTextScale,
            textAlign: options.textAlign,
            autoWidth
        });

        if (this.hasUserPosition) {
            // user positioned, limiting to the available bounds
            const userPositionX = Math.clamp(this.userPositionX * size.width, innerBounds.left, innerBounds.width - textFrameBoxProps.size.width) / size.width;
            const userPositionY = Math.clamp(this.userPositionY * size.height, innerBounds.top, innerBounds.height - textFrameBoxProps.size.height) / size.height;
            if (this.userPositionX !== userPositionX || this.userPositionY !== userPositionY) {
                this.model.userPositionX = userPositionX;
                this.model.userPositionY = userPositionY;
            }
            textFrameBoxProps.bounds = new geom.Rect(userPositionX * size.width, userPositionY * size.height, textFrameBoxProps.size);
        } else {
            // auto positioned using textPosition
            textFrameBoxProps.bounds = new geom.Rect(0, 0, textFrameBoxProps.size).positionInContainer(innerBounds, this.textPosition);

            // adjust to cover anti-aliased edge
            if (this.textPosition == PositionType.BOTTOM) {
                textFrameBoxProps.bounds.top += 1;
            } else if (this.textPosition == PositionType.TOP) {
                textFrameBoxProps.bounds.top -= 1;
            }
        }

        return { size, scale: textFrameBoxProps.scale };
    }

    _exportToSharedModel() {
        return this.textFrameBox.text._exportToSharedModel();
    }

    _importFromSharedModel(model) {
        return this.textFrameBox.text._importFromSharedModel(model);
    }
}

export class TextFrameBox extends BaseElement {
    static get schema() {
        return {
            backdropPadding: 30
        };
    }

    get _canSelect() {
        return true;
    }

    get requireParentSelection() {
        return false;
    }

    get selectionPadding() {
        return this.hasBackdrop ? 0 : 40;
    }

    get canStyleText() {
        return this.parentElement.isOverImage;
    }

    get hasBackdrop() {
        return this.canStyleText && this.textStyle.contains("box");
    }

    get textStyle() {
        if (this.canStyleText) {
            return this.model.textStyle || "none";
        } else {
            return "none";
        }
    }

    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.FREEFORM,
            autoHeight: true,
            scaleTextToFit: true,
            canAddBlocks: true,
            canReorderBlocks: true,
            canDeleteLastBlock: this.options.canDeleteLastBlock ?? false,
            requireParentSelection: this.parentElement.canDragPosition,
            passThroughSelection: false,
            allowAlignment: true,
            syncFontSizeWithSiblings: this.options.syncFontSizeWithSiblings ?? false,
            allowedBlockTypes: this.options.allowedBlockTypes ?? [
                TextStyleType.HEADLINE,
                TextStyleType.HEADING,
                TextStyleType.TITLE,
                TextStyleType.BODY,
                TextStyleType.BULLET_LIST,
                TextStyleType.CAPTION,
                TextStyleType.LABEL,
                AuthoringBlockType.MEDIA,
                AuthoringBlockType.DIVIDER,
                AuthoringBlockType.CODE,
                AuthoringBlockType.EQUATION,
            ],
            defaultBlockTextStyle: TextStyleType.HEADING,
            createSubBulletOnEnter: true,
            blockDefaults: {
                [TextStyleType.HEADLINE]: {
                    evenBreak: true
                },
                [TextStyleType.HEADING]: {
                    evenBreak: true
                }
            },
            ...this.options
        });
    }

    getHTMLFilter() {
        if (this.textStyle == "white_text_with_shadow") {
            return "drop-shadow(2px 4px 6px rgba(0,0,0,.25))";
        }
    }

    _calcProps(props, options) {
        let { size, children } = props;

        if (this.hasBackdrop && this.canvas.layouter.isGenerating) {
            let decorationProps = {
                type: "frame",
                shape: "rect"
            };

            switch (this.textStyle) {
                case "white_box":
                    decorationProps.fillColor = "white";
                    break;
                case "transparent_light_box":
                    decorationProps.fillColor = "white";
                    decorationProps.fillOpacity = 0.5;
                    decorationProps.ignoreTransparency = true;
                    break;
                case "transparent_dark_box":
                    decorationProps.fillColor = "black";
                    decorationProps.fillOpacity = 0.3;
                    decorationProps.ignoreTransparency = true;
                    break;
            }

            this.text.createDecoration(decorationProps);
        }

        let padding = 0;
        if (this.hasBackdrop) {
            // when autoWidth, set the style padding to account for backdrop padding
            padding = this.model.backdropPadding ?? 30;
        }
        this.text.loadedStyles.paddingLeft = this.text.loadedStyles.paddingRight = this.text.loadedStyles.paddingTop = this.text.loadedStyles.paddingBottom = padding;

        let textProps = this.text.calcProps(size, {
            ...options,
            autoWidth: options.autoWidth,
        });

        let calculatedSize;

        textProps.bounds = new geom.Rect(0, 0, textProps.size);
        calculatedSize = textProps.size;

        return {
            size: calculatedSize,
            scale: textProps.textScale
        };
    }

    _getBackgroundColor(forElement) {
        let backgroundColor = super._getBackgroundColor(forElement);
        if (!this.textStyle || backgroundColor != BackgroundStyleType.IMAGE) {
            return backgroundColor;
        }

        switch (this.textStyle) {
            case "dark_text":
            case "transparent_light_box":
                return this.canvas.getTheme().palette.getColor("white");
            case "white_text_with_shadow":
            case "transparent_dark_box":
            case "white_text":
            default:
                return this.canvas.getTheme().palette.getColor("black");
        }
    }

    static migrateToV10(element) {
        if (element.model.textPosition && !element.model.textAlign) {
            if (element.model.textPosition.contains("left")) {
                element.model.textAlign = HorizontalAlignType.LEFT;
            } else if (element.model.textPosition.contains("right")) {
                element.model.textAlign = HorizontalAlignType.RIGHT;
            } else {
                element.model.textAlign = HorizontalAlignType.CENTER;
            }
        }

        if (element.model.blocks) {
            const blocks = element.model.blocks;
            delete element.model.blocks;

            let blockFontScales = {};

            const migratedBlocks = [];
            for (const block of blocks) {
                if (block.type === ContentBlockType.BULLET_POINT) {
                    let fontScale = 1;
                    for (let [key, value] of Object.entries(element.getRootElement().model.userFontScale ?? {})) {
                        if (key.contains("ContentBlockFrame/ContentBlockCollection/ContentBlockItem/ContentBlockBulletPoint/TextGroup/TextGroupTitle")) {
                            fontScale = value;
                        }
                    }
                    fontScale *= 1.2; // adjust for root style differences

                    blockFontScales[TextStyleType.BULLET_LIST] = [fontScale];

                    migratedBlocks.push(...GetBlockModelsFromMigratedHtml(block.title, element, TextStyleType.TITLE)
                        .map(block => ({
                            ...block,
                            textStyle: TextStyleType.BULLET_LIST,
                            listStyle: element.model.listStyle ?? "bullets",
                            indent: 0
                        }))
                    );

                    if (block.body) {
                        const bodyBlocks = GetBlockModelsFromMigratedHtml(block.body, element, TextStyleType.BODY, ["bullet_list", "numbered_list"].includes(block.text_format));
                        if (bodyBlocks.filter(block => block.html).length > 0) {
                            let listStyle;
                            switch (block.text_format) {
                                case "bullet_list":
                                    listStyle = ListStyleType.BULLET;
                                    break;
                                case "numbered_list":
                                    listStyle = ListStyleType.NUMBERED;
                                    break;
                                default:
                                    listStyle = ListStyleType.TEXT;
                            }

                            let fontScale = 1;
                            for (let [key, value] of Object.entries(element.getRootElement().model.userFontScale ?? {})) {
                                if (key.contains("ContentBlockFrame/ContentBlockCollection/ContentBlockItem/ContentBlockBulletPoint/TextGroup/TextGroupBody")) {
                                    fontScale = value;
                                }
                            }
                            fontScale *= 1.2; // adjust for root style differences
                            blockFontScales[TextStyleType.BULLET_LIST][1] = fontScale;

                            migratedBlocks.push(...bodyBlocks
                                .map(block => ({
                                    ...block,
                                    textStyle: TextStyleType.BULLET_LIST,
                                    listStyle,
                                    indent: 1,
                                }))
                            );
                        }
                    }

                    continue;
                }

                const blocksHtml = GetBlockModelsFromMigratedHtml(block.content, element).map(({ html }) => html);
                blocksHtml.forEach(html => {
                    const migratedBlock = _.cloneDeep(block);

                    migratedBlock.html = html;
                    switch (migratedBlock.type) {
                        case "icon":
                            migratedBlock.type = AuthoringBlockType.MEDIA;
                            migratedBlock.elementModel = {
                                elementType: "MediaBlock",
                                ..._.omit(block, ["id", "type"])
                            };
                            // delete everything else
                            Object.keys(block).forEach(key => {
                                if (!key.equalsAnyOf("id", "type", "blockHeight", "elementModel")) {
                                    delete block[key];
                                }
                            });

                            migratedBlock.autoWidth = true;

                            if (migratedBlock.elementModel.contentSize && migratedBlock.elementModel.content_type !== AssetType.ICON) {
                                element.canvas.layouter.runPostLoad(() => {
                                    const mediaBlockElement = element.text.blockElements[migratedBlock.id];
                                    if (!mediaBlockElement) {
                                        return;
                                    }

                                    const mediaElement = mediaBlockElement.media;
                                    const asset = mediaElement.content?.asset;
                                    if (!asset) {
                                        return;
                                    }

                                    if (["unframedRect", "none"].includes(mediaElement.frameType) && mediaElement.assetType !== AssetType.ICON) {
                                        migratedBlock.blockHeight = (migratedBlock.elementModel.contentSize - 20) / asset.get("w") * asset.get("h");
                                    } else {
                                        migratedBlock.blockHeight = migratedBlock.elementModel.contentSize;
                                    }
                                    delete migratedBlock.elementModel.contentSize;
                                });
                            } else {
                                migratedBlock.blockHeight = migratedBlock.elementModel.contentSize;
                                delete migratedBlock.elementModel.contentSize;
                            }

                            break;
                        case ContentBlockType.DIVIDER:
                            migratedBlock.type = AuthoringBlockType.DIVIDER;
                            migratedBlock.dividerWidth = "short";
                            break;
                        default:
                            migratedBlock.textStyle = migratedBlock.type;
                            migratedBlock.type = AuthoringBlockType.TEXT;
                            migratedBlock.hyphenation = false;
                            migratedBlock.ligatures = true;
                    }
                    delete migratedBlock.content;
                    migratedBlocks.push(migratedBlock);
                });
            }

            element.model.text = {
                blocks: migratedBlocks,
                blockFontScales
            };
        }
    }

    _migrate_10() {
        return TextFrameBox.migrateToV10(this);
    }
}

