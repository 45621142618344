import { AnchorType } from "js/core/utilities/geom";
import { app } from "js/namespaces";
import { $ } from "js/vendor";

/**
 * Returns a dataSource object used for binding an element to a difference
 * between two points of the chart
 */
export const getPointsDiffDataSource = (elementId, seriesId, pointAIndex, pointBIndex) => ({
    elementId,
    seriesId,
    pointAIndex,
    pointBIndex,
    isDiff: true
});

export const getChartAnnotationXOffset = point => {
    return (point.series.userOptions.type === "column" || point.series.userOptions.type === "bar") ? point.barX + point.pointWidth / 2 : point.plotX;
};

export function handleDragChangeInValueAnchor(editor, point, annotations, connector, chartElement, isStart) {
    const series = point.series;
    const seriesId = series.userOptions.id;

    const screenBounds = chartElement.getScreenBounds();
    // const annotations = connector.parentElement;

    let isBarSeries = series.type == "bar";

    if (isBarSeries) {
        connector.startAnchor = AnchorType.RIGHT;
        connector.endAnchor = AnchorType.RIGHT;
    }

    let offsetX = editor.$el.offset().left;
    let offsetY = editor.$el.offset().top;

    const plotBox = chartElement.chart.plotBox;
    let pointOffset = getChartAnnotationXOffset(point);

    let startPos;
    if (isBarSeries) {
        startPos = offsetY + (pointOffset + plotBox.y) * editor.canvasScale;
    } else {
        startPos = offsetX + (pointOffset + plotBox.x) * editor.canvasScale;
    }
    let axisSnapPoints = [];
    for (let dataIndex = 0; dataIndex < series.data.length; dataIndex++) {
        pointOffset = getChartAnnotationXOffset(series.data[dataIndex]);
        let pointPos;
        if (isBarSeries) {
            pointPos = offsetY + (pointOffset + plotBox.y) * editor.canvasScale;
        } else {
            pointPos = offsetX + (pointOffset + plotBox.x) * editor.canvasScale;
        }
        axisSnapPoints.push(pointPos);
    }

    if (isBarSeries) {
        axisSnapPoints = axisSnapPoints.reverse();
    }

    startPos = axisSnapPoints[point.index];
    app.isDraggingItem = true;

    $("#selection_layer").hide();
    const $body = $("body");
    // const $selectionNotice = $body.addEl($.div("drag_selection_notice"));
    // $selectionNotice.append($.div("notice", "Click to finish"));
    // $selectionNotice.setBounds(new geom.Rect(plotBox.x, plotBox.y, plotBox.width, plotBox.height).multiply(editor.canvasScale).offset(offsetX, offsetY));

    $body.on("mousemove.drag", event => {
        event.stopPropagation();

        window.requestAnimationFrame(timestamp => {
            const mouseX = event.clientX;
            const mouseY = event.clientY;

            // find closest xAxisSnapPoint
            const endPos = axisSnapPoints.reduce(function(prev, curr) {
                if (isBarSeries) {
                    return Math.abs(curr - mouseY) < Math.abs(prev - mouseY) ? curr : prev;
                } else {
                    return Math.abs(curr - mouseX) < Math.abs(prev - mouseX) ? curr : prev;
                }
            });

            let endPointIndex;
            let startPointIndex;

            if (isStart) {
                endPointIndex = connector.targetSnapOptions.pointIndex;
                startPointIndex = axisSnapPoints.indexOf(endPos);
            } else {
                endPointIndex = axisSnapPoints.indexOf(endPos);
                startPointIndex = connector.sourceSnapOptions.pointIndex;
            }

            if (startPointIndex === endPointIndex) {
                if (startPointIndex < axisSnapPoints.length - 1) {
                    endPointIndex = startPointIndex + 1;
                } else {
                    endPointIndex = startPointIndex - 1;
                }
            }

            if (isStart && endPointIndex < startPointIndex) {
                const temp = endPointIndex;
                endPointIndex = startPointIndex;
                startPointIndex = temp;

                isStart = false;
            } else if (!isStart && endPointIndex < startPointIndex) {
                const temp = endPointIndex;
                endPointIndex = startPointIndex;
                startPointIndex = temp;

                isStart = true;
            }

            connector.sourceSnapOptions.pointIndex = startPointIndex;
            connector.targetSnapOptions.pointIndex = endPointIndex;
            if (isBarSeries) {
                connector.adjustments = {
                    a1: mouseX
                };
            } else {
                connector.adjustments = {
                    a1: mouseY - screenBounds.top
                };
            }
            connector.labels[0].dataSource = getPointsDiffDataSource(chartElement.uniquePath, seriesId, startPointIndex, endPointIndex);

            annotations.refreshElement();
        });
    });

    $body.one("mouseup.drag", event => {
        event.stopPropagation();
        $body.off("mousemove.drag");
        $body.off("keyup.showChange");

        app.isDraggingItem = false;
        // $selectionNotice.remove();

        $("#selection_layer").show();

        chartElement.canvas.updateCanvasModel();
    });
}
