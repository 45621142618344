import React from "reactn";
import { _ } from "js/vendor";
import getLogger, { LogGroup } from "js/core/logger";
import { timeoutRace, loadImage } from "js/core/utilities/promiseHelper";
import { ASSET_BREAKPOINTS, ASSET_FILETYPE, ASSET_LOAD_TIMEOUT, AssetType } from "common/constants";
import { getCSSTransform } from "js/core/utilities/geom";
import { getElementTransition, SVGGroup, ELEMENT_TRANSITION_DURATION } from "js/core/utilities/svgHelpers";
import { renderAdjustmentFilter, renderColorFilter } from "js/core/utilities/svgFilters";
import { ds } from "js/core/models/dataService";
import { isOfflinePlayer, isRenderer } from "js/config";

import { MediaElement } from "./MediaElement";

const logger = getLogger(LogGroup.ELEMENTS);

class Picture extends MediaElement {
    async _load() {
        if (
            this.asset &&
            this.hasMedia &&
            this.assetId == this.asset.id &&
            this.optimalMediaSizeForAssetId === this.assetId &&
            this.optimalMediaSizeForAssetModifiedAt === this.asset.get("modifiedAt")
        ) {
            return;
        }

        if (this.asset) {
            this.modifiedAt = this.asset.get("modifiedAt");
        }

        if (this.assetId) {
            try {
                const asset = await ds.assets.getAssetById(this.assetId, AssetType.IMAGE);

                // perform resizing, as required - for video elements this
                // function does not exist
                if (asset.resizeIfNeeded) {
                    asset.resizeIfNeeded();
                }

                this.assetUrl = await asset.getURL(this.initialSizeToLoadMediaAt);
                this.alt = asset.getAltText();
                this.asset = asset;

                this.isOptimalSizeLoaded = false;
                this.hasMedia = true;
            } catch (err) {
                logger.error(err, "[Picture] failed to load asset", { slideId: this.canvas.dataModel?.id, assetId: this.assetId });
                this.hasMedia = false;
            }
        } else {
            this.asset = null;
            this.hasMedia = false;
        }
    }

    filterVersion = Date.now();

    get isOptimalSizeLoaded() {
        return (
            !!this._isOptimalSizeLoaded &&
            this.optimalMediaSizeCalculatedForSize &&
            this.sizeForMediaCalculation &&
            this.optimalMediaSizeCalculatedForSize.equals(this.sizeForMediaCalculation) &&
            this.optimalMediaSizeForAssetId === this.assetId &&
            this.optimalMediaSizeForAssetModifiedAt === this.asset.get("modifiedAt")
        );
    }

    set isOptimalSizeLoaded(value) {
        this._isOptimalSizeLoaded = value;
    }

    get sizeForMediaCalculation() {
        return this.calculatedProps.paddedBounds?.size ?? this.innerBounds?.size ?? null;
    }

    // get the optimal size type that should be loaded at
    //   the current canvas scale and element bounds
    calculateOptimalAssetSizeType(size) {
        if (
            this.asset.get("fileType") === ASSET_FILETYPE.GIF ||
            this.asset.get("fileType") === ASSET_FILETYPE.SVG
        ) {
            return "original";
        }

        const area = this.asset.get("h") * this.asset.get("w");
        // In the app, always use a deterministic size, maxing out at the 'xlarge' size
        if (isOfflinePlayer) {
            const size = area > (1600 * 1600) ? "xlarge" : "original";
            return this.asset.has(size) ? size : "original";
        }

        const scale = size
            ? this.getScale(size)
            : (this.model.get("scale") || 1);
        const scaledArea = area * Math.pow(scale * this.canvas.getMaxScale(), 2);
        // * window.devicePixelRatio; mitch: 12/30 - i'm disabling highDPI
        //   support to see if it's a better performance/quality tradeoff
        if (scaledArea >= area) {
            return "xlarge";
        }
        for (const breakpoint of ASSET_BREAKPOINTS) {
            if (scaledArea > breakpoint.area) {
                return breakpoint.size;
            }
        }
    }

    loadOptimalSize() {
        this.optimalMediaSizeCalculatedForSize = this.sizeForMediaCalculation.clone();
        this.optimalMediaSize = this.calculateOptimalAssetSizeType(this.optimalMediaSizeCalculatedForSize);
        this.optimalMediaSizeForAssetId = this.assetId;
        this.optimalMediaSizeForAssetModifiedAt = this.asset.get("modifiedAt");

        this.asset.getURL(this.optimalMediaSize).then(url => {
            if (!this.canvas.layouter) {
                return;
            }

            this.assetUrl = url;
            if (this.canvas.layouter.isGenerating) {
                this.canvas.layouter.runPostRender(() => this.refreshElement());
            } else {
                this.refreshElement();
            }
        });
    }

    renderChildren(transition) {
        if (this.asset) {
            // If the optimal size image hasn't been loaded, load it and then refresh to render
            if (!this.isOptimalSizeLoaded && !isRenderer && !this.canvas.isExport) {
                // Setting isOptimalSizeLoaded to true immediately to avoid multiple loadOptimalSize() runs
                this.isOptimalSizeLoaded = true;
                this.canvas.layouter.runPostRender(() => {
                    if (transition) {
                        // We want to load the optimal size only after the transition is finished to avoid
                        // breaking the animation when the optimal sized image is loaded and rendered
                        _.delay(() => this.loadOptimalSize(), ELEMENT_TRANSITION_DURATION);
                    } else {
                        this.loadOptimalSize();
                    }
                });
            }

            return this.renderImage(this.calculatedProps, transition);
        } else {
            return (
                <SVGGroup key={this.id}>
                    <g id="imageNode"></g>
                </SVGGroup>
            );
        }
    }

    updateFilterVersion(value = Date.now()) {
        this.filterVersion = value;
    }

    get filterId() {
        return `filter_${this.uniqueId}_${this.filterVersion.toString("16")}`;
    }

    get imageFilterId() {
        switch (this.model.filter) {
            case "brannan":
            case "earlybird":
            case "inkwell":
            case "lofi":
            case "mayfair":
            case "nashville":
                return this.model.filter;
            case "theme":
            case "accent1":
            case "accent2":
            case "accent3":
            case "accent4":
            case "accent5":
            case "accent6":
            case "accent7":
            case "accent8":
            case "accent9":
            case "accent10":
            case "background_accent":
                return `${this.filterId}-color-filter`;
            case "none":
            default:
                return null;
        }
    }

    get adjustmentFilterId() {
        let {
            filterBrightness,
            filterBlur,
            filterContrast,
        } = this.calculatedProps;
        if (filterBrightness || filterBlur || filterContrast) {
            return `${this.filterId}-adj-filter`;
        }
        return null;
    }

    renderImage(props, transition) {
        let {
            transformProps,
            mediaOpacity,
        } = props;

        let imageFilterId;
        let colorFilter;
        if (props.filter && props.filter != "None" && props.filter != "none") {
            if (this.colorFilters.includes(props.filter)) {
                colorFilter = renderColorFilter(this, props);
                imageFilterId = `url(#${this.filterId}-color-filter)`;
            } else {
                imageFilterId = `url(#${props.filter})`;
            }
        }

        let adjustmentFilter;
        if (props.filterBrightness || props.filterBlur || props.filterContrast) {
            adjustmentFilter = renderAdjustmentFilter(this, props);
        }

        // create a unique ID to help apply styling
        const hasImageFilter = !!imageFilterId;
        const { filterId } = this;

        return (
            <React.Fragment key={this.id}>
                {/* Safari struggles respecting the attribute change, but a unique style each time works well */}
                {hasImageFilter && (
                    <style type="text/css">{`
                        #${filterId} {
                            filter: ${imageFilterId};
                        }
                    `}</style>
                )}
                <SVGGroup overflowHidden>
                    <g
                        id="imageNode"
                        filter={adjustmentFilter ? `url(#${this.filterId}-adj-filter)` : null}
                        style={{
                            transform: getCSSTransform(transformProps),
                            transition: getElementTransition(transition)
                        }}
                    >
                        {adjustmentFilter}
                        {colorFilter}
                        <image
                            id={filterId}
                            alt={this.alt}
                            href={this.assetUrl}
                            width={this.mediaSize.width}
                            height={this.mediaSize.height}
                            style={{
                                imageOrientation: "from-image",
                                opacity: mediaOpacity,
                            }}
                            onLoad={this.getImageOnLoadPromiseResolver(this.assetUrl)}
                        />
                    </g>
                </SVGGroup>
            </React.Fragment>
        );
    }

    loadImageFromURL(url, size) {
        const asset = this.asset;
        return timeoutRace(ASSET_LOAD_TIMEOUT, loadImage(url)
            .catch(() => {
                logger.warn("[Picture] could not load asset, will try again without cache", { slideId: this.canvas.dataModel?.id, assetId: this.assetId });
                return asset.getURL(size, true).then(url => loadImage(url));
            }));
    }
}

class Logo extends Picture {
    get isTransparent() {
        return this.asset.get("hasAlpha");
    }

    get backgroundColor() {
        if (this.asset.get("hasAlpha")) {
            return "none";
        } else {
            return "white";
        }
    }

    get constrainAspectRatio() {
        return "fit";
    }

    minScale(size) {
        return this.calcFitMedia(size, 0.5).scale;
    }

    maxScale(size) {
        return this.calcFitMedia(size, 1).scale;
    }

    getDefaultMediaTransform(size) {
        return this.calcFitMedia(size, this.options.defaultScale || 0.9);
    }
}

export { Picture, Logo };
