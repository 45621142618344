import React from "react";

import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { ConnectorType, NodeType } from "common/constants";
import { AnchorType } from "js/core/utilities/geom";
import { SVGGroup } from "js/core/utilities/svgHelpers";
import { Ellipse } from "js/core/utilities/ellipse";
import { isRenderer } from "js/config";
import { ConnectorPath, Shape } from "js/core/utilities/shapes";

import ConnectorItemLabels from "./ConnectorItemLabels";
import ConnectorPulseAnimator from "./animators/ConnectorPulseAnimator";
import ConnectorDashAnimator from "./animators/ConnectorDashAnimator";

import { TransitionableSVGPath } from "../../base/SVGElement";
import { CollectionItemElement } from "../../base/CollectionElement";

export const connectorBoldValues = {
    strokeWidth: 30,
    arrowOffset: 10,
    arrowLength: 6,
    arrowWidth: 9,
    circleRadius: 15,
};

export default class ConnectorItem extends CollectionItemElement {
    static get schema() {
        return {
            lineStyle: "solid",
            lineWeight: 2,
            startDecoration: "none",
            endDecoration: "none",
            connectorType: ConnectorType.STRAIGHT,
            startAnchor: AnchorType.FREE,
            endAnchor: AnchorType.FREE
        };
    }

    get canSelect() {
        return this.options.canSelect ?? true;
    }

    getClipboardData() {
        return {
            dataType: "element",
            elementType: this.type,
            parentElementType: this.parentElement.type,
            slideId: this.canvas.dataModel.id,
            model: _.cloneDeep(this.model)
        };
    }

    get selectionPadding() {
        return 0;
    }

    get lineStyle() {
        return this.model.lineStyle || "none";
    }

    get lineWeight() {
        return this.model.lineWeight || 2;
    }

    get color() {
        return this.model.color;
    }

    get startDecoration() {
        return this.model.startDecoration || "none";
    }

    get endDecoration() {
        return this.model.endDecoration || "none";
    }

    get connectorType() {
        return this.model.connectorType || ConnectorType.STRAIGHT;
    }

    get startAnchor() {
        return this.model.startAnchor || AnchorType.FREE;
    }

    get endAnchor() {
        return this.model.endAnchor || AnchorType.FREE;
    }

    get startAnchorOffset() {
        return new geom.Point(this.model.startAnchorOffsetX ?? 0, this.model.startAnchorOffsetY ?? 0);
    }

    get endAnchorOffset() {
        return new geom.Point(this.model.endAnchorOffsetX ?? 0, this.model.endAnchorOffsetY ?? 0);
    }

    get startTarget() {
        if (this.model.source) {
            return this.parentElement.getTargetElement(this.model.source);
        }
    }

    get endTarget() {
        if (this.model.target) {
            return this.parentElement.getTargetElement(this.model.target);
        }
    }

    get sourceSnapOptions() {
        if (this.model.source) {
            return this.model.sourceSnapOptions;
        }
    }

    get targetSnapOptions() {
        if (this.model.target) {
            return this.model.targetSnapOptions;
        }
    }

    get ignoreAnimator() {
        return this.canvas.isExport || isRenderer || this.isDeleted;
    }

    get pathNode() {
        return this.pathRef.current?.pathRef.current;
    }

    get startDecorationNode() {
        return this.startDecorationRef.current?.pathRef.current;
    }

    get endDecorationNode() {
        return this.endDecorationRef.current?.pathRef.current;
    }

    get convertToAuthoringAsSVG() {
        return this.options.convertToAuthoringAsSVG ?? false;
    }

    constructor(props) {
        super(props);

        this.svgRef = React.createRef();
        this.pathRef = React.createRef();
        this.startDecorationRef = React.createRef();
        this.endDecorationRef = React.createRef();
    }

    _build() {
        if (!this.model.adjustments) {
            this.model.adjustments = {};
        }

        this.labels = this.addElement("labels", () => ConnectorItemLabels, {
            getLabelOptions: this.options.getLabelOptions
        });
        this.labels.layer = 1;
    }

    _getUserDefinedFillColor() {
        // if there is a source node, return it's userDefinedColor so that the connector matches
        if (this.startTarget) {
            return this.startTarget.getUserDefinedFillColor();
        } else {
            return super._getUserDefinedFillColor();
        }
    }

    findAnchorPoint(sourceElement, endPoint, anchorPointType, connectorType) {
        if (anchorPointType == AnchorType.FREE) {
            if (connectorType == ConnectorType.STRAIGHT) {
                return AnchorType.CENTER;
            } else {
                return _.minBy([AnchorType.CENTER, AnchorType.TOP, AnchorType.BOTTOM, AnchorType.LEFT, AnchorType.RIGHT], anchorType => {
                    if (sourceElement.availableAnchorPoints.includes(anchorType)) {
                        return sourceElement.getAnchorPoint(this, anchorType).distance(endPoint);
                    } else {
                        return 999999;
                    }
                });
            }
        } else {
            return anchorPointType;
        }
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    _calcProps(props, options) {
        let { size } = props;

        const drawPercentage = this.isAnimating ? (this.animationState.growProgress ?? 1) : 1;
        const reverseDirection = this.isAnimating && !!this.animationState.reverseDirection;

        let sourceElement, targetElement;
        let startBounds, endBounds;

        // get the source and target elements by their id
        if (this.model.source) {
            sourceElement = this.startTarget;
            if (!sourceElement) {
                throw new Error("No source element for connector was found");
            }
        }
        if (this.model.target) {
            targetElement = this.endTarget;
            if (!targetElement) {
                throw new Error("No target element for connector was found");
            }
        }

        let startAnchor = this.startAnchor;
        let endAnchor = this.endAnchor;
        let start, end;

        if (this.connectorType === ConnectorType.ARC) {
            if (!sourceElement || !targetElement) {
                throw new Error("Source and target elements are necessary for arc connector");
            }

            start = sourceElement.anchorBounds.center;
            startAnchor = AnchorType.CENTER;

            end = targetElement.anchorBounds.center;
            endAnchor = AnchorType.CENTER;

            // Inflate the bounds by anchorPadding to move the lines from the actual node's bounds
            startBounds = sourceElement.anchorBounds.inflate(sourceElement.anchorPadding);
            endBounds = targetElement.anchorBounds.inflate(targetElement.anchorPadding);
        } else {
            if (sourceElement) {
                start = sourceElement.anchorBounds.center;
            } else {
                start = new geom.Point(this.model.sourcePoint.x, this.model.sourcePoint.y);
            }
            if (targetElement) {
                end = targetElement.anchorBounds.center;
            } else {
                end = new geom.Point(this.model.targetPoint.x, this.model.targetPoint.y);
            }

            // calculate the start anchor point type
            if (sourceElement) {
                startAnchor = sourceElement.getAnchorPointType(this, end, this.startAnchor, this.connectorType);
                start = sourceElement.getAnchorPoint(this, startAnchor, end, this.connectorType, true);
                start = start.offset(this.startAnchorOffset);
                startBounds = sourceElement.getAnchorBounds ? sourceElement.getAnchorBounds(this, this.endAnchor, true) : sourceElement.anchorBounds;
            } else {
                startAnchor = AnchorType.CENTER;
                startBounds = new geom.Rect(start, 1, 1);
            }

            if (targetElement) {
                endAnchor = targetElement.getAnchorPointType(this, start, this.endAnchor, this.connectorType);
                end = targetElement.getAnchorPoint(this, endAnchor, start, this.connectorType, false);
                end = end.offset(this.endAnchorOffset);
                endBounds = targetElement.getAnchorBounds ? targetElement.getAnchorBounds(this, this.endAnchor, false) : targetElement.anchorBounds;
            } else {
                endAnchor = AnchorType.CENTER;
                endBounds = new geom.Rect(end, 1, 1);
            }
        }

        props.startAnchor = startAnchor;
        props.start = start;
        props.endAnchor = endAnchor;
        props.end = end;

        this.calculatedStartAnchor = startAnchor;
        this.calculatedEndAnchor = endAnchor;

        const SP = 15;

        let path = new ConnectorPath();
        path.setAdjustments(this.model.adjustments || {});

        //region connector layout
        switch (this.connectorType || "straight") {
            case ConnectorType.ANGLE:
                path.addPoint(start);

                let angleDirection = startAnchor;
                if (angleDirection == AnchorType.CENTER || angleDirection == AnchorType.FREE) {
                    if (Math.abs(start.x - end.x) < 50) {
                        path.addPoint(end);
                        break; // don't draw angle when too vertical
                    }
                    angleDirection = (end.x > start.x) ? AnchorType.RIGHT : AnchorType.LEFT;
                }
                switch (angleDirection) {
                    case AnchorType.RIGHT:
                        path.addPoint(start.x + (end.x - start.x) / 2, start.y);
                        break;
                    case AnchorType.BOTTOM:
                        path.addPoint(start.x, start.y + (end.y - start.y) / 2);
                        break;
                    case AnchorType.TOP:
                        path.addPoint(start.x, start.y - (start.y - end.y) / 2);
                        break;
                    case AnchorType.LEFT:
                        path.addPoint(start.x - (start.x - end.x) / 2, start.y);
                        break;
                }
                path.addPoint(end);

                break;
            case ConnectorType.STEP:
                const LocationType = {
                    ABOVE: "above",
                    BELOW: "below",
                    LEFT: "left",
                    RIGHT: "right",
                    ABOVE_LEFT: "above_left",
                    ABOVE_RIGHT: "above_right",
                    BELOW_LEFT: "below_left",
                    BELOW_RIGHT: "below_right"
                };

                let getLocation = function() {
                    if (endBounds.right < startBounds.left - SP) {
                        if (endBounds.bottom <= startBounds.top - SP) {
                            return LocationType.ABOVE_LEFT;
                        }
                        if (endBounds.top >= startBounds.bottom + SP) {
                            return LocationType.BELOW_LEFT;
                        }
                        return LocationType.LEFT;
                    } else if (endBounds.left > startBounds.right + SP) {
                        if (endBounds.bottom <= startBounds.top - SP) {
                            return LocationType.ABOVE_RIGHT;
                        }
                        if (endBounds.top >= startBounds.bottom + SP) {
                            return LocationType.BELOW_RIGHT;
                        }
                        return LocationType.RIGHT;
                    } else {
                        if (endBounds.bottom < start.y - SP) {
                            return LocationType.ABOVE;
                        } else {
                            return LocationType.BELOW;
                        }
                    }
                };

                let centerLine = function(start, end) {
                    return start + (end - start) / 2;
                };

                let location = getLocation();

                if (this.lastLocation && this.lastLocation != location) {
                    this.model.adjustments = {};
                    path.setAdjustments({});
                }
                this.lastLocation = location;

                path.addPoint(start);

                switch (startAnchor) {
                    case AnchorType.TOP:
                        switch (endAnchor) {
                            case AnchorType.BOTTOM:
                                if (endBounds.bottom < start.y - SP) {
                                    if (end.x != start.x) {
                                        path.vert(centerLine(start.y, end.y));
                                        path.horiz(end.x);
                                    }
                                } else {
                                    path.vert(start.y - SP);
                                    if (endBounds.right <= startBounds.left - SP) {
                                        path.horiz(centerLine(startBounds.left, endBounds.right));
                                    } else if (endBounds.left >= startBounds.right + SP) {
                                        path.horiz(centerLine(startBounds.right, endBounds.left));
                                    } else {
                                        path.horiz(Math.max(startBounds.right, endBounds.right) + SP);
                                    }
                                    path.vert(endBounds.bottom + SP * 2);
                                    path.horiz(end.x);
                                }
                                break;

                            case AnchorType.RIGHT:
                                if (endBounds.right > start.x - SP) {
                                    if (endBounds.bottom < start.y - SP) {
                                        path.vert(start.y - SP);
                                        path.horiz(endBounds.right + SP * 2);
                                        path.vert(end.y);
                                    } else {
                                        path.vert(Math.min(startBounds.top, endBounds.top) - SP);
                                        path.horiz(Math.max(startBounds.right + SP, endBounds.right + SP * 2));
                                        path.vert(end.y);
                                    }
                                } else {
                                    if (end.y < start.y - SP) {
                                        path.vert(end.y);
                                    } else if (endBounds.right > startBounds.left - SP) {
                                        path.vert(start.y - SP);
                                        path.horiz(startBounds.right + SP);
                                        path.vert(end.y);
                                    } else {
                                        path.vert(start.y - SP);
                                        path.horiz(centerLine(startBounds.left, endBounds.right));
                                        path.vert(end.y);
                                    }
                                }
                                break;

                            case AnchorType.LEFT:
                                if (endBounds.left <= start.x + SP) {
                                    if (endBounds.bottom < start.y - SP) {
                                        path.vert(centerLine(start.y, endBounds.bottom));
                                        path.horiz(endBounds.left - SP * 2);
                                        path.vert(end.y);
                                    } else {
                                        path.vert(Math.min(startBounds.top, endBounds.top) - SP);
                                        path.horiz(Math.min(startBounds.left - SP, endBounds.left - SP * 2));
                                        path.vert(end.y);
                                    }
                                } else {
                                    if (end.y < start.y - SP) {
                                        path.vert(end.y);
                                    } else if (endBounds.left < startBounds.right + SP) {
                                        path.vert(start.y - SP);
                                        path.horiz(startBounds.left - SP);
                                        path.vert(end.y);
                                    } else {
                                        path.vert(start.y - SP);
                                        path.horiz(centerLine(startBounds.right, endBounds.left));
                                        path.vert(end.y);
                                    }
                                }
                                break;

                            case AnchorType.TOP:
                                if (endBounds.right <= start.x - SP || endBounds.left >= start.x + SP) {
                                    path.vert(Math.min(startBounds.top - SP, endBounds.top - SP * 2));
                                    path.horiz(end.x);
                                } else {
                                    path.vert(start.y - SP);
                                    if (endBounds.bottom <= start.y - SP) {
                                        if (end.x > start.x) {
                                            path.horiz(endBounds.left - SP);
                                        } else {
                                            path.horiz(endBounds.right + SP);
                                        }
                                    } else {
                                        if (end.x > start.x) {
                                            path.horiz(Math.max(startBounds.right + SP, end.x));
                                        } else {
                                            path.horiz(Math.min(startBounds.left - SP, end.x));
                                        }
                                    }
                                    path.vert(endBounds.top - SP * 2);
                                    path.horiz(end.x);
                                }
                                break;
                        }

                        break;

                    case AnchorType.LEFT:

                        switch (endAnchor) {
                            case AnchorType.TOP:
                                if ((end.x <= startBounds.right && endBounds.top > start.y + SP) || (end.x > startBounds.right && endBounds.top > startBounds.bottom + SP)) {
                                    if (end.x <= start.x - SP) {
                                        path.horiz(end.x);
                                    } else {
                                        path.horiz(start.x - SP);
                                        path.vert(centerLine(startBounds.bottom, end.y));
                                        path.horiz(end.x);
                                    }
                                } else {
                                    if (endBounds.right < start.x - SP) {
                                        path.horiz(start.x - SP);
                                        path.vert(endBounds.top - SP * 2);
                                        path.horiz(end.x);
                                    } else {
                                        path.horiz(Math.min(startBounds.left - SP, endBounds.left - SP));
                                        path.vert(Math.min(endBounds.top - SP * 2, startBounds.top - SP));
                                        path.horiz(end.x);
                                    }
                                }

                                break;
                            case AnchorType.BOTTOM:

                                if ((end.x <= startBounds.right && endBounds.bottom < start.y - SP) || (end.x > startBounds.right && endBounds.bottom < startBounds.top - SP)) {
                                    if (end.x <= start.x - SP) {
                                        path.horiz(end.x);
                                    } else {
                                        path.horiz(start.x - SP);
                                        path.vert(centerLine(startBounds.top, end.y));
                                        path.horiz(end.x);
                                    }
                                } else {
                                    if (endBounds.right < start.x - SP) {
                                        path.horiz(start.x - SP);
                                        path.vert(endBounds.bottom + SP * 2);
                                        path.horiz(end.x);
                                    } else {
                                        path.horiz(Math.min(startBounds.left - SP, endBounds.left - SP));
                                        path.vert(Math.max(endBounds.bottom + SP * 2, startBounds.bottom + SP));
                                        path.horiz(end.x);
                                    }
                                }
                                break;
                            case AnchorType.LEFT:

                                if (endBounds.bottom < start.y - SP || endBounds.top > start.y + SP) {
                                    path.horiz(Math.min(endBounds.left - SP * 2, startBounds.left - SP));
                                    path.vert(end.y);
                                } else {
                                    path.horiz(start.x - SP);
                                    if (end.x < start.x) {
                                        if (end.y < start.y) {
                                            path.vert(endBounds.bottom + SP);
                                        } else {
                                            path.vert(endBounds.top - SP);
                                        }
                                    } else {
                                        if (end.y < start.y) {
                                            path.vert(startBounds.top - SP);
                                        } else {
                                            path.vert(startBounds.bottom + SP);
                                        }
                                    }
                                    path.horiz(endBounds.left - SP * 2);
                                    path.vert(end.y);
                                }

                                break;
                            case AnchorType.RIGHT:

                                if (endBounds.right < start.x - SP) {
                                    if (end.y !== start.y) {
                                        path.horiz(centerLine(end.x, start.x));
                                        path.vert(end.y);
                                    }
                                } else {
                                    path.horiz(start.x - SP);
                                    if (endBounds.bottom < startBounds.top - SP) {
                                        path.vert(startBounds.top - SP);
                                    } else if (endBounds.top > startBounds.bottom + SP) {
                                        path.vert(startBounds.bottom + SP);
                                    } else {
                                        path.vert(Math.max(startBounds.bottom + SP, endBounds.bottom + SP));
                                    }
                                    path.horiz(endBounds.right + SP * 2);
                                    path.vert(end.y);
                                }

                                break;
                        }
                        break;

                    case AnchorType.RIGHT:

                        switch (endAnchor) {
                            case AnchorType.TOP:
                                switch (location) {
                                    case LocationType.ABOVE_LEFT:
                                    case LocationType.LEFT:
                                        if (end.x > start.x + SP) {
                                            path.horiz(end.x);
                                        } else {
                                            path.horiz(start.x + SP);
                                        }
                                        path.vert(Math.min(endBounds.top, startBounds.top) - SP, { adj: true });
                                        path.horiz(end.x);
                                        break;
                                    case LocationType.ABOVE:
                                        if (endBounds.right >= start.x + SP) {
                                            path.horiz(endBounds.right + SP);
                                        }
                                        path.vert(endBounds.top - SP);
                                        path.horiz(end.x);
                                        break;
                                    case LocationType.BELOW_LEFT:
                                    case LocationType.BELOW:
                                        if (end.x > start.x + SP) {
                                            path.horiz(end.x);
                                        } else {
                                            path.horiz(start.x + SP);
                                        }
                                        path.vert(centerLine(startBounds.bottom, endBounds.top));
                                        path.horiz(end.x);
                                        break;
                                    case LocationType.ABOVE_RIGHT:
                                    case LocationType.RIGHT:
                                        if (endBounds.top <= start.y) {
                                            path.horiz(centerLine(start.x, endBounds.left));
                                            path.vert(this.model.adjustments.a2 || (endBounds.top - SP), {
                                                id: "a2",
                                                adj: "V"
                                            });
                                        }
                                        path.horiz(end.x);
                                        break;
                                    case LocationType.BELOW_RIGHT:
                                        path.horiz(end.x);
                                        break;
                                }
                                break;
                            case AnchorType.LEFT:
                                if (end.x > start.x + SP) {
                                    if (end.y != start.y) {
                                        path.horiz(centerLine(start.x, end.x));
                                        path.vert(end.y);
                                    }
                                } else {
                                    path.horiz(Math.max(start.x + (end.x - start.x) / 2, start.x + SP));
                                    if (endBounds.top > startBounds.bottom + SP || endBounds.bottom < startBounds.top - SP) {
                                        path.vert(start.y + (end.y - start.y) / 2);
                                    } else {
                                        path.vert(Math.max(endBounds.bottom + SP, startBounds.bottom + SP));
                                    }
                                    path.horiz(Math.min(end.x - SP * 2, startBounds.left - SP));
                                    path.vert(end.y);
                                }
                                break;
                            case AnchorType.RIGHT:

                                if (endBounds.right <= startBounds.left - SP) {
                                    // target is to left of source
                                    path.horiz(start.x + SP);
                                    if (endBounds.top < start.y + SP && endBounds.bottom > start.y - SP) {
                                        // target is inline with source
                                        if (end.y <= start.y) {
                                            path.vert(startBounds.top - SP);
                                        } else {
                                            path.vert(startBounds.bottom + SP);
                                        }
                                        path.horiz(end.x + SP);
                                        path.vert(end.y);
                                    } else {
                                        // target is above/below source
                                        path.horiz(Math.max(end.x + SP, start.x + SP));
                                        path.vert(end.y);
                                    }
                                } else {
                                    // target is to right of source
                                    if (endBounds.top < start.y + SP && endBounds.bottom > start.y - SP) {
                                        path.horiz(start.x + SP);
                                        if (end.y <= start.y) {
                                            path.vert(endBounds.top - SP);
                                        } else {
                                            path.vert(endBounds.bottom + SP);
                                        }
                                    }
                                    path.horiz(Math.max(end.x + SP, start.x + SP));
                                    path.vert(end.y);
                                }

                                break;
                            case AnchorType.BOTTOM:
                                if (end.x <= start.x + SP) {
                                    // target is to left of source
                                    if (endBounds.bottom < startBounds.top - SP) {
                                        // target is above
                                        path.horiz(start.x + SP);
                                        path.vert(endBounds.bottom + SP * 2);
                                        path.horiz(end.x);
                                    } else {
                                        // target is below
                                        path.horiz(start.x + SP);
                                        path.vert(Math.max(endBounds.bottom + SP * 2, startBounds.bottom + SP));
                                        path.horiz(end.x);
                                    }
                                } else {
                                    // target is to right of source
                                    if (endBounds.bottom > start.y - SP) {
                                        // target is inline with source
                                        path.horiz(start.x + SP);
                                        path.vert(endBounds.bottom + SP * 2);
                                        path.horiz(end.x);
                                    } else {
                                        path.horiz(end.x);
                                    }
                                }

                                break;
                        }
                        break;

                    case AnchorType.BOTTOM:
                        switch (endAnchor) {
                            case AnchorType.TOP:
                                if (endBounds.top > start.y + SP) {
                                    if (end.x != start.x) {
                                        path.vert(centerLine(start.y, end.y));
                                        path.horiz(end.x);
                                    }
                                } else {
                                    path.vert(start.y + SP);
                                    if (endBounds.right <= startBounds.left - SP) {
                                        path.horiz(centerLine(startBounds.left, endBounds.right));
                                    } else if (endBounds.left >= startBounds.right + SP) {
                                        path.horiz(centerLine(startBounds.right, endBounds.left));
                                    } else {
                                        path.horiz(Math.max(startBounds.right, endBounds.right) + SP);
                                    }
                                    path.vert(endBounds.top - SP * 2);
                                    path.horiz(end.x);
                                }
                                break;

                            case AnchorType.RIGHT:
                                if (endBounds.right > start.x - SP) {
                                    if (endBounds.top > start.y + SP) {
                                        path.vert(start.y + SP);
                                        path.horiz(endBounds.right + SP * 2);
                                        path.vert(end.y);
                                    } else {
                                        path.vert(Math.max(startBounds.bottom, endBounds.bottom) + SP);
                                        path.horiz(Math.max(startBounds.right + SP, endBounds.right + SP * 2));
                                        path.vert(end.y);
                                    }
                                } else {
                                    if (end.y > start.y + SP) {
                                        path.vert(end.y);
                                    } else if (endBounds.right > startBounds.left - SP) {
                                        path.vert(start.y + SP);
                                        path.horiz(startBounds.right + SP);
                                        path.vert(end.y);
                                    } else {
                                        path.vert(start.y + SP);
                                        path.horiz(centerLine(startBounds.left, endBounds.right));
                                        path.vert(end.y);
                                    }
                                }
                                break;

                            case AnchorType.LEFT:
                                if (endBounds.left <= start.x + SP) {
                                    if (endBounds.top > start.y + SP) {
                                        path.vert(centerLine(start.y, endBounds.top));
                                        path.horiz(endBounds.left - SP * 2);
                                        path.vert(end.y);
                                    } else {
                                        path.vert(Math.max(startBounds.bottom, endBounds.bottom) + SP);
                                        path.horiz(Math.min(startBounds.left - SP, endBounds.left - SP * 2));
                                        path.vert(end.y);
                                    }
                                } else {
                                    if (end.y > start.y + SP) {
                                        path.vert(end.y);
                                    } else if (endBounds.left < startBounds.right + SP) {
                                        path.vert(start.y + SP);
                                        path.horiz(startBounds.left - SP);
                                        path.vert(end.y);
                                    } else {
                                        path.vert(start.y + SP);
                                        path.horiz(centerLine(startBounds.right, endBounds.left));
                                        path.vert(end.y);
                                    }
                                }
                                break;

                            case AnchorType.BOTTOM:
                                if (endBounds.right <= start.x - SP || endBounds.left >= start.x + SP) {
                                    path.vert(Math.max(startBounds.bottom + SP, endBounds.bottom + SP * 2));
                                    path.horiz(end.x);
                                } else {
                                    path.vert(start.y + SP);
                                    if (endBounds.top >= start.y + SP) {
                                        if (end.x > start.x) {
                                            path.horiz(endBounds.left - SP);
                                        } else {
                                            path.horiz(endBounds.right + SP);
                                        }
                                    } else {
                                        if (end.x > start.x) {
                                            path.horiz(Math.max(startBounds.right + SP, end.x));
                                        } else {
                                            path.horiz(Math.min(startBounds.left - SP, end.x));
                                        }
                                    }
                                    path.vert(endBounds.bottom + SP * 2);
                                    path.horiz(end.x);
                                }
                                break;
                        }
                }

                path.addEndPoint(end);

                break;
            case ConnectorType.ARC:
                const { shape } = options;
                if (!shape) {
                    throw new Error("Arc connector must have shape option!");
                }

                let startAngle = sourceElement.circleLayoutAngle;
                let endAngle = targetElement.circleLayoutAngle;

                let startConnectionShape = sourceElement.connectionShape;
                let endConnectionShape = targetElement.connectionShape;

                /**
                 * Calculates the points of an arc line between the start and end angles
                 */
                const getArcPoints = (angleStep, boundsDeflationPadding) => {
                    const points = [];

                    let deflatedStartBounds = startBounds.deflate(boundsDeflationPadding);
                    let deflatedEndBounds = endBounds.deflate(boundsDeflationPadding);

                    let startConnectionEllipse = Ellipse.EllipseFromRect(deflatedStartBounds);
                    let endConnectionEllipse = Ellipse.EllipseFromRect(deflatedEndBounds);

                    // Calculating the angle our path should travel
                    let travelAngle = Math.abs(startAngle - endAngle) % (2 * Math.PI);
                    if (travelAngle > Math.PI) {
                        travelAngle = 2 * Math.PI - travelAngle;
                    }
                    const angleStepsCount = Math.round(travelAngle / angleStep);
                    // Adjusting the step
                    angleStep = travelAngle / angleStepsCount;

                    let currentStep = 0;
                    let currentAngle = startAngle;
                    while (currentStep <= angleStepsCount) {
                        const newPoint = shape.point(currentAngle);

                        // Clipping start and end
                        let clipStart;
                        if (startConnectionShape.type == "circle") {
                            clipStart = startConnectionEllipse.contains(newPoint);
                        } else {
                            clipStart = deflatedStartBounds.contains(newPoint);
                        }

                        let clipEnd;
                        if (startConnectionShape.type == "circle") {
                            clipEnd = endConnectionEllipse.contains(newPoint);
                        } else {
                            clipEnd = deflatedEndBounds.contains(newPoint);
                        }

                        if ((!clipStart && !clipEnd)) {
                            points.push(newPoint);
                        }

                        currentAngle += angleStep;
                        currentStep++;
                    }

                    return points;
                };

                // Setting the default step to 1 degree
                let angleStep = Math.PI / 180 / 2;
                // Won't be deflating start and end bounds by default
                let boundsDeflationPadding = 0;
                let points = [];

                // If the start and end are the same, set two identical points.
                //   This should be avoided, but this code is needed to
                //   avoid an inifinite while loop below.
                if (startAngle === endAngle) {
                    points = [
                        shape.point(startAngle),
                        shape.point(endAngle),
                    ];
                }

                // We need the path to contain at least two points (start and end)
                while (points.length < 2) {
                    points = getArcPoints(angleStep, boundsDeflationPadding);
                    if (angleStep > Math.PI / 180 / 4) {
                        // Reducing the step until 0.25 degree
                        angleStep = angleStep / 2;
                    } else {
                        // The step is minimal, now reducing the bounds
                        boundsDeflationPadding++;
                    }
                }

                // Adding the calc'd points to the path
                points.forEach(point => path.addPoint(point));

                // Assigning the adjusted start and end points
                start = path.points[0];
                end = path.points[path.points.length - 1];

                break;
            case ConnectorType.STRAIGHT:
            default:
                path.addPoint(start);
                path.addPoint(end);
                break;
        }
        //endregion

        // Not applicable to arc connectors
        // If the anchor is CENTER, trim the source/target point at the intersection of the anchor shape and the line
        if (this.connectorType !== ConnectorType.ARC) {
            if (sourceElement && startAnchor == AnchorType.CENTER && sourceElement.connectionShape) {
                path.points[0] = this.getIntersection(path.points[0], path.points[1], sourceElement.connectionShape.bounds.deflate(0).offset(sourceElement.bounds.position), sourceElement.connectionShape.type);
            }
            if (targetElement && endAnchor == AnchorType.CENTER && targetElement.connectionShape) {
                path.points[path.points.length - 1] = this.getIntersection(path.points[path.points.length - 1], path.points[path.points.length - 2], targetElement.connectionShape.bounds.deflate(0).offset(targetElement.bounds.position), targetElement.connectionShape.type);
            }
        }

        const fullPath = path.clone();
        // Store for authoring conversion
        this.fullConnectorPath = fullPath;

        let startDecorationPath, endDecorationPath;

        let {
            strokeWidth,
            arrowOffset,
            arrowLength,
            arrowWidth,
            circleRadius,
        } = connectorBoldValues;

        if (this.lineWeight !== "bold") {
            strokeWidth = this.lineWeight;
            arrowOffset = this.styles.arrowHeads.offset;
            arrowLength = this.styles.arrowHeads.arrowLength + this.lineWeight * 2;
            arrowWidth = this.styles.arrowHeads.arrowLength + this.lineWeight * 2;
            circleRadius = 2 + this.lineWeight;
        }

        props.strokeWidth = strokeWidth;

        // use ratio to calculate how much of the total connector path we should draw
        const totalLength = path.length * drawPercentage;
        if (drawPercentage === 0 && reverseDirection) {
            // hiding the whole path
            path.trimLength(totalLength);
        } else {
            path.trimLength(totalLength, reverseDirection);
        }

        if (path.length > 0) {
            let oldArrowLenth = arrowLength;
            if (["arrow", "circle"].includes(this.startDecoration) && ["arrow", "circle"].includes(this.endDecoration)) {
                circleRadius = Math.min(path.length / 4, circleRadius);
                arrowLength = Math.min(path.length / 4, arrowLength);
            } else if ([this.startDecoration, this.endDecoration].includes("arrow")) {
                arrowLength = Math.min(path.length / 2, arrowLength);
            } else if ([this.startDecoration, this.endDecoration].includes("circle")) {
                circleRadius = Math.min(path.length / 2, circleRadius);
            }
            arrowWidth = arrowLength / oldArrowLenth * arrowWidth;

            // adding "path.length > 0" check to avoid errors when path is 0 length after trimming
            if (this.startDecoration === "arrow") {
                path.trimStart(arrowLength + strokeWidth / 2);
                if (path.length > 0) {
                    startDecorationPath = Shape.drawArrowHead(path.getSegment(0), false, {
                        arrowLength,
                        arrowOffset,
                        arrowWidth
                    });
                }
            } else if (this.startDecoration === "circle") {
                path.trimStart(circleRadius + strokeWidth / 2);
                if (path.length > 0) {
                    startDecorationPath = Shape.drawCircle(circleRadius, path.points[0]).toPathData();
                }
            }

            if (this.endDecoration === "arrow") {
                path.trimEnd(arrowLength + strokeWidth / 2);
                if (path.length > 0) {
                    endDecorationPath = Shape.drawArrowHead(path.getSegment(path.segments - 1), true, {
                        arrowLength,
                        arrowOffset,
                        arrowWidth,
                    });
                }
            } else if (this.endDecoration === "circle") {
                path.trimEnd(circleRadius + strokeWidth / 2);
                if (path.length > 0) {
                    endDecorationPath = Shape.drawCircle(circleRadius, path.points[path.points.length - 1]).toPathData();
                }
            } else if (typeof this.endDecoration === "function") {
                let endDecoration = this.endDecoration(path.points[path.points.length - 1]);
                path.trimEnd(endDecoration.trimAmount ?? 0);
                endDecorationPath = endDecoration.path;
            }
        }

        this.connectorPath = path; // store for selection layer

        props.connectorPath = path.toPathData();

        if (this.startDecoration !== "none" && drawPercentage !== 0) {
            props.startDecoration = startDecorationPath;
        }

        if (this.endDecoration !== "none" && drawPercentage !== 0) {
            props.endDecoration = endDecorationPath;
        }

        const labelsProps = this.labels.calcProps(size, { path: fullPath, drawPercentage });
        labelsProps.bounds = new geom.Rect(0, 0, size);
        labelsProps.layer = 1;

        // Animator will be playing reverse if there is start decoration and there is no end decoration
        const animatorPlayReverse = this.startDecoration !== "none" && this.endDecoration === "none";

        // Animator parameters have changed or canvas is animating
        if (this.animator && (this.animator.type !== this.lineStyle || this.animator.playReverse !== animatorPlayReverse || this.ignoreAnimator)) {
            this.animator = null;
        }

        if (!this.animator && !this.ignoreAnimator) {
            switch (this.lineStyle) {
                case "animate_pulse":
                    this.animator = new ConnectorPulseAnimator(this, this.lineStyle, animatorPlayReverse);
                    break;
                case "animate_dash":
                    this.animator = new ConnectorDashAnimator(this, this.lineStyle, animatorPlayReverse);
                    break;
            }
        }

        return { size, path: fullPath };
    }

    getConnectorColor() {
        let connectorColor = this.resolvedColorsCache.connectorColor;
        if (!connectorColor) {
            let color = this.model.color ?? this.styles.connectorColor;
            connectorColor = this.canvas.getTheme().palette.getForeColor(color, this.getSlideColor(), this.getBackgroundColor()).setAlpha(1).toRgbString();
            this.resolvedColorsCache.connectorColor = connectorColor;
        }
        return connectorColor;
    }

    getDecorationStyle(decorationType, style) {
        const propertyName = `${decorationType}Decoration${style.charAt(0).toUpperCase() + style.slice(1)}`;
        if (this.styles[propertyName]) {
            if (propertyName.endsWith("Color")) {
                if (!this.resolvedColorsCache[propertyName]) {
                    this.resolvedColorsCache[propertyName] = this.canvas.getTheme().palette.getForeColor(this.styles[propertyName], this.getSlideColor(), this.getBackgroundColor()).setAlpha(1).toRgbString();
                }
                return this.resolvedColorsCache[propertyName];
            }
            return this.styles[propertyName];
        }

        if (style === "opacity") {
            return this.styles.connectorOpacity ?? 1;
        }

        if (style === "strokeWidth") {
            return this.calculatedProps.strokeWidth ?? 1;
        }

        return this.getConnectorColor();
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        let children = super.renderChildren(transition);

        let connectorColor = this.getConnectorColor();
        let connectorOpacity = this.styles.connectorOpacity ?? 1;

        let dash = this.styles[this.lineStyle]?.strokeDash ?? 0;

        children.insert(
            <SVGGroup id={this.id} key={`connector-${this.id}`}>
                <g opacity={connectorOpacity}>
                    <TransitionableSVGPath
                        ref={this.pathRef}
                        transition={transition}
                        d={props.connectorPath}
                        fill="none"
                        stroke={connectorColor}
                        opacity={this.styles.connectorStrokeOpacity ?? 1}
                        strokeWidth={props.strokeWidth}
                        strokeDasharray={dash}
                        strokeDashoffset={this.strokeDashoffset ?? 0}
                    />
                    {props.startDecoration &&
                        <TransitionableSVGPath
                            ref={this.startDecorationRef}
                            transition={transition}
                            d={props.startDecoration}
                            fill={this.getDecorationStyle("start", "fillColor")}
                            stroke={this.getDecorationStyle("start", "strokeColor")}
                            strokeWidth={this.getDecorationStyle("start", "strokeWidth")}
                        />
                    }
                    {props.endDecoration &&
                        <TransitionableSVGPath
                            ref={this.endDecorationRef}
                            transition={transition}
                            d={props.endDecoration}
                            fill={this.getDecorationStyle("end", "fillColor")}
                            stroke={this.getDecorationStyle("end", "strokeColor")}
                            strokeWidth={this.getDecorationStyle("end", "strokeWidth")}
                        />
                    }
                    {!this.isAnimating && this.animator && this.pulsePointData && this.lineStyle === "animate_pulse" &&
                        <TransitionableSVGPath
                            transition={transition}
                            d={Shape.drawCircle(this.pulsePointData.radius, this.pulsePointData.center).toPathData()}
                            fill={connectorColor}
                        />
                    }
                </g>
            </SVGGroup>
            , 0);

        return children;
    }

    getIntersection(startPt, endPt, connectorShapeBounds, connectorShapeType) {
        switch (connectorShapeType) {
            case NodeType.CIRCLE:
                let angle = Math.atan((endPt.y - startPt.y) / (endPt.x - startPt.x));
                let radius = Math.max(connectorShapeBounds.width, connectorShapeBounds.height) / 2;
                if (endPt.x < startPt.x) radius *= -1;
                return new geom.Point(startPt.x + radius * Math.cos(angle), startPt.y + radius * Math.sin(angle));
            case NodeType.DIAMOND:
                let segments = [];
                segments.push(new geom.Line(new geom.Point(connectorShapeBounds.centerH, connectorShapeBounds.top), new geom.Point(connectorShapeBounds.right, connectorShapeBounds.centerV)));
                segments.push(new geom.Line(new geom.Point(connectorShapeBounds.right, connectorShapeBounds.centerV), new geom.Point(connectorShapeBounds.centerH, connectorShapeBounds.bottom)));
                segments.push(new geom.Line(new geom.Point(connectorShapeBounds.centerH, connectorShapeBounds.bottom), new geom.Point(connectorShapeBounds.left, connectorShapeBounds.centerV)));
                segments.push(new geom.Line(new geom.Point(connectorShapeBounds.left, connectorShapeBounds.centerV), new geom.Point(connectorShapeBounds.centerH, connectorShapeBounds.top)));

                let line = new geom.Line(startPt, endPt);

                for (let seg of segments) {
                    let intersect = line.intersection(seg);
                    if (intersect.seg1 && intersect.seg2) {
                        return intersect.intersectPt;
                    }
                }

            case NodeType.BOX:
            case "square":
            case "rect":
            default:
                let x1 = startPt.x;
                let y1 = startPt.y;
                let x2 = endPt.x;
                let y2 = endPt.y;

                let leftIntersection = new geom.Point(connectorShapeBounds.left, y2 + (connectorShapeBounds.left - x2) / (x1 - x2) * (y1 - y2));
                if (leftIntersection.y >= connectorShapeBounds.top && leftIntersection.y <= connectorShapeBounds.bottom && ((x1 <= connectorShapeBounds.left && x2 >= connectorShapeBounds.left) || (x1 >= connectorShapeBounds.left && x2 <= connectorShapeBounds.left))) {
                    return leftIntersection;
                }

                let rightIntersection = new geom.Point(connectorShapeBounds.right, y2 + (connectorShapeBounds.right - x2) / (x1 - x2) * (y1 - y2));
                if (rightIntersection.y >= connectorShapeBounds.top && rightIntersection.y <= connectorShapeBounds.bottom && ((x1 <= connectorShapeBounds.right && x2 >= connectorShapeBounds.right) || (x1 >= connectorShapeBounds.right && x2 <= connectorShapeBounds.right))) {
                    return rightIntersection;
                }

                let topIntersection = new geom.Point(x2 + (connectorShapeBounds.top - y2) / (y1 - y2) * (x1 - x2), connectorShapeBounds.top);
                if (topIntersection.x >= connectorShapeBounds.left && topIntersection.x <= connectorShapeBounds.right && ((y1 <= connectorShapeBounds.top && y2 >= connectorShapeBounds.top) || (y1 >= connectorShapeBounds.top && y2 <= connectorShapeBounds.top))) {
                    return topIntersection;
                }

                let bottomIntersection = new geom.Point(x2 + (connectorShapeBounds.bottom - y2) / (y1 - y2) * (x1 - x2), connectorShapeBounds.bottom);
                if (bottomIntersection.x >= connectorShapeBounds.left && bottomIntersection.x <= connectorShapeBounds.right && ((y1 <= connectorShapeBounds.bottom && y2 >= connectorShapeBounds.bottom) || (y1 > connectorShapeBounds.bottom && y2 <= connectorShapeBounds.bottom))) {
                    return bottomIntersection;
                }
                return startPt;
        }
    }

    get animationElementName() {
        return `Connector #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow",
            prepare: () => {
                this.animationState.growProgress = 0;
                this.animationState.reverseDirection = this.startDecoration !== "none" && this.endDecoration === "none";
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.growProgress = progress;
                return this;
            }
        }];
    }

    containsPoint(point) {
        const elementPoint = point.offset(-this.canvasBounds.left, -this.canvasBounds.top);
        for (let i = 0; i < this.connectorPath.segments; i++) {
            if (this.connectorPath.getSegment(i).isPointOnLine(elementPoint, 10)) {
                return true;
            }
        }
        return false;
    }

    remove() {
        this.animator = null;

        super.remove();
    }
}
