import React, { Component } from "react";
import styled from "styled-components";
import { themeColors } from "js/react/sharedStyles";
import BeautifulDialog from "../../../components/Dialogs/BeautifulDialog.tsx";
import DialogContent from "../../../components/Dialogs/DialogContent.js";
import Spinner from "../../../components/Spinner";
import { Gap } from "../../../components/Gap";
import { Button, TextField, Select, MenuItem } from "@material-ui/core";
import { BlueButton } from "../../../components/UiComponents";

import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { WordCloudElement } from "js/canvas/v10/elements/elements/WordCloud"; // eslint-disable-line
import { aiCalcBreakpoints, aiResultsToWordStrings } from "./common";
import { trackActivity } from "js/core/utilities/utilities";

const StyledDialogContent = styled(DialogContent)`
    padding: 40px 50px;
    max-height: 600px;
`;

const StyledHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StyledHeaderTitle = styled.div`
    font-family: Source Sans Pro, sans-serif;
    font-weight: 600;
    font-size: 23px;
    line-height: 27.6px;
    letter-spacing: 0.5px;
    color: #333;
`;

const StyledBody = styled.div`
    display: flex;
    flex-direction: row;
    max-height: 300px;

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #999;
    }
`;

const StyledBodyLabel = styled.div`
    font-family: Source Sans Pro, sans-serif;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: ${themeColors.darkGray};
`;

const StyledBodyContents = styled.div`
    display: grid;
    grid-template-columns: 1fr 110px;
    flex-direction: row;
    width: 100%;
    gap: 10px;
`;

const StyledBodyLeftColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
`;

const StyledBodyRightColumn = styled.div`
    display: flex;
    flex-direction: column; 
    justify-content: center;
    flex: 1;
    height: 100%;
    gap: 5px;
`;

const StyledTextField = styled(TextField)`
    &&& {
        width: 100%;
        
        & .MuiOutlinedInput-multiline {
            padding: 10px;
            height: 44px;
        }
    
        input::placeholder {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: 400;
        }
    }
`;

const StyledSelect = styled(Select)`
    &&& {
        border: 0.5px solid #999;
        height: 39px;
        
        input::placeholder {
            font-family: "Roboto", "Helvetica", "Arial", sans-serif;
            font-weight: bolder;
        }
    }
`;

const StyledMenuItem = styled(MenuItem)`
    &&& {
        height: 40px;}
`;

const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    min-height: 38px;
    justify-content: right;
    gap: 20px;
`;

export class GenerateWordCloudDialog extends Component {
    state = {
        prompt: "",
        results: [],
        isGenerating: false,
        wordCount: 40,
        error: null
    };

    groupWords = words => {
        const breakpoints = aiCalcBreakpoints(this.wordCount);
        return aiResultsToWordStrings(words, breakpoints);
    }

    handleSuccess = groupedWords => {
        this.props.onSuccess(groupedWords);
        trackActivity("WordCloud", "Generate", null, null, { prompt: this.state.prompt, results: groupedWords });
        this.props.closeDialog();
    }

    handleGenerate = async ()=>{
        this.setState({ isGenerating: true });
        await WordCloudElement.aiGenerateWords({ prompt: this.state.prompt,
            wordCount: this.state.wordCount,
            onError: ()=> ShowErrorDialog({ title: "Sorry, custom prompts are limited to 200 characters" }),
            onReportState: results=> {
                this.setState({ isGenerating: results.isGenerating,
                    error: results.error });

                if (!results.isGenerating && !results.error && results.textResults && results.textResults.length) {
                    let groupedWords = this.groupWords(results.textResults);
                    this.setState({ results: groupedWords,
                        isGenerating: results.isGenerating,
                        error: results.error });
                    this.handleSuccess(groupedWords);
                }
            } });
    }

    handleCancel = ()=>{
        this.props.closeDialog();
    }

    render() {
        return (
            <>
                <BeautifulDialog closeDialog={this.handleCancel} maxWidth = { "md" }>
                    <StyledDialogContent>
                        <StyledHeader>
                            <StyledHeaderTitle>
                                Generate a word cloud with AI
                            </StyledHeaderTitle>
                        </StyledHeader>
                        <Gap size={30} />
                        <StyledBody>

                            <StyledBodyContents>
                                <StyledBodyLeftColumn>
                                    <StyledBodyLabel>Prompt</StyledBodyLabel>
                                    <StyledTextField variant={"outlined"}
                                        autoFocus={true}
                                        onChange={event=> this.setState({ prompt: event.target.value })}
                                        placeholder={"Describe the topic of your word cloud..."}
                                        onKeyDown={event=>{
                                            if (event.key === "Enter") {
                                                this.handleGenerate();
                                            }
                                        }}>
                                    </StyledTextField>
                                </StyledBodyLeftColumn>
                                <StyledBodyRightColumn>
                                    <StyledBodyLabel>Number of words</StyledBodyLabel>
                                    <StyledSelect
                                        value={this.state.wordCount}
                                        onChange={event=>this.setState({ wordCount: event.target.value })}
                                        displayEmpty={true}
                                        disableUnderline={true}
                                        onKeyDownCapture={event=>{
                                            if (event.key === "Enter") {
                                                this.handleGenerate();
                                                event.stopPropagation();
                                            }
                                            let num = Number(event.key);
                                            if (!isNaN(num) && num > 0 && num < 10) {
                                                this.setState({ wordCount: num * 10 });
                                            }
                                        }}
                                    >
                                        <StyledMenuItem value={10}>10</StyledMenuItem>
                                        <StyledMenuItem value={20}>20</StyledMenuItem>
                                        <StyledMenuItem value={30}>30</StyledMenuItem>
                                        <StyledMenuItem value={40}>40</StyledMenuItem>
                                        <StyledMenuItem value={50}>50</StyledMenuItem>
                                        <StyledMenuItem value={60}>60</StyledMenuItem>
                                        <StyledMenuItem value={70}>70</StyledMenuItem>
                                        <StyledMenuItem value={80}>80</StyledMenuItem>

                                    </StyledSelect>
                                </StyledBodyRightColumn>
                            </StyledBodyContents>

                        </StyledBody>
                        <Gap size={20}/>
                        {this.state.isGenerating &&
                            <Spinner style={{ top: "-60px" }}></Spinner>}
                        <StyledFooter>
                            <Button onClick={ ()=>{
                                this.handleCancel();
                            }}>CANCEL</Button>
                            <BlueButton
                                disabled={this.state.isGenerating}
                                onClick={ ()=>{
                                    this.handleGenerate();
                                }}
                                variant={"contained"}>
                                GENERATE
                            </BlueButton>
                        </StyledFooter>
                    </StyledDialogContent>
                </BeautifulDialog>
            </>
        );
    }
}
