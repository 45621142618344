import { AuthoringBlockType, ListStyleType, TextStyleType } from "common/constants";
import { TextList } from "./Lists/TextList";
import { GetBlockModelsFromMigratedHtml } from "../base/Text/TextElement";
import { _ } from "js/vendor";

class Agenda extends TextList {
    get listStyle() {
        return ListStyleType.NUMBERED;
    }

    get linkToSlideAsLink() {
        return false;
    }

    get allowedBlockTypes() {
        return [TextStyleType.BULLET_LIST, TextStyleType.SECTION, AuthoringBlockType.DIVIDER];
    }

    getChildOptions(model, index) {
        let options = super.getChildOptions(model, index);
        options.linkToSlideAsLink = this.linkToSlideAsLink;

        options.getPlaceholderForBlock = block => {
            return { text: "Type agenda item" };
        };
        return options;
    }

    _migrate_10() {
        if (!this.model.items) {
            this.model.items = [];
        }

        let columnsCount = this.model.items.length < 6 ? 1 : 2;

        const blockFontScales = {};
        blockFontScales[TextStyleType.BULLET_LIST] = [
            this.model.userFontScale?.["CanvasElement/Agenda/AgendaItem/TextGroup/TextGroupTitle"] ?? 1,
        ];

        let itemBreakIndex = this.model.items.length;
        if (columnsCount > 1) {
            itemBreakIndex = Math.ceil(this.model.items.length / 2);
        }

        const columnModels = [];

        for (let col = 0; col < columnsCount; col++) {
            columnModels.push({
                text: {
                    blocks: [],
                    blockFontScales
                },
            });
        }

        for (let i = 0; i < this.model.items.length; i++) {
            let col = i < itemBreakIndex ? columnModels[0] : columnModels[1];

            let item = this.model.items[i];

            let blocks;
            if (item.type == "section") {
                blocks = GetBlockModelsFromMigratedHtml(item.title, this, TextStyleType.SECTION, true);
            } else {
                blocks = GetBlockModelsFromMigratedHtml(item.title, this, TextStyleType.BULLET_LIST, true);
                blocks[0].listStyle = ListStyleType.NUMBERED;
                blocks[0].linkToSlide = item.linkToSlide;

                if (item.body && !_.isEmpty(item.body.text)) {
                    let subblocks = GetBlockModelsFromMigratedHtml(item.body, this, TextStyleType.BULLET_LIST, true);
                    for (let subblock of subblocks) {
                        subblock.indent = 1;
                        subblock.listStyle = ListStyleType.TEXT;
                    }
                    blocks.push(...subblocks);
                }
            }
            col.text.blocks.push(...blocks);
        }

        this.model.items = columnModels;
    }

    _importFromSharedModel(model) {
        const elModel = super._importFromSharedModel(model);

        if (elModel.items?.length) {
            elModel.items = elModel.items.map(item => {
                if (item.content_value) {
                    item = { id: item.id, text: item.text };
                }
                item.text = {
                    blocks: item.text.blocks.map(block => {
                        if (block.listStyle === ListStyleType.BULLET) {
                            block.listStyle = ListStyleType.NUMBERED;
                        }
                        return block;
                    })
                };
                return item;
            });
        }

        return elModel;
    }
}

export const elements = {
    Agenda,
};
