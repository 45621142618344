import { NodeType, ConnectorType, SeriesTypeLabels } from "common/constants";
import { ds } from "js/core/models/dataService";
import { $, _ } from "js/vendor";
import { app } from "js/namespaces";
import { controls } from "js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { Convert } from "js/core/utilities/geom";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import EditTextDialog from "js/react/components/Dialogs/EditTextDialog";
import { handleDragChangeInValueAnchor } from "./ChartChangeInValueHelper";

const WIDGET_SIZE = 30; // 30px

const getSeriesId = point => point.series.userOptions.id;

/**
 * Returns a dataSource object used for binding the value of an element to a point
 * of the chart
 */
const getPointDataSource = (elementId, seriesId, pointIndex) => ({
    elementId,
    seriesId,
    pointIndex
});

/**
 * Returns a snapOptions object used for snapping an element to a point
 * of the chart
 */
const getPointSnapOptions = (elementId, seriesId, pointIndex) => ({
    elementId,
    seriesId,
    pointIndex
});

/**
 * Returns a dataSource object used for binding an element to a difference
 * between two points of the chart
 */
const getPointsDiffDataSource = (elementId, seriesId, pointAIndex, pointBIndex) => ({
    elementId,
    seriesId,
    pointAIndex,
    pointBIndex,
    changeType: "percent",
    isDiff: true
});

const getChartAnnotationXOffset = point => {
    return (point.series.userOptions.type === "column" || point.series.userOptions.type === "bar") ? point.barX + point.pointWidth / 2 : point.plotX;
};

const getAnnotations = chartElement => chartElement.elements.annotations;

const getAnnotationsElementsLinkedToPoint = (chartElement, point) => Object.values(getAnnotations(chartElement).elements)
    .filter(element => {
        // Element uses the chart as a data source for its value
        if (element.model.dataSource) {
            // The data source uses the point
            if (element.model.dataSource.pointIndex === point.index && element.model.dataSource.seriesId === getSeriesId(point)) {
                return true;
            }
        }

        // Element has connectors which may be snapped to a point of the chart
        if (element.connectorsFromNode) {
            const connector = element.connectorsFromNode.find(({ model }) => model.target === chartElement.uniquePath);
            // A connector is snapped to the point
            if (connector && connector.targetSnapOptions &&
                                                                                       connector.targetSnapOptions.pointIndex === point.index &&
                                                                                       connector.targetSnapOptions.seriesId === getSeriesId(point)) {
                return true;
            }
        }

        return false;
    });

const getChartOffsets = (editor, chartElement) => ({
    chartOffsetX: editor.element.selectionPadding + chartElement.bounds.left,
    chartOffsetY: editor.element.selectionPadding + chartElement.bounds.top
});

export const getDataHiliteAnnotationModel = (chartElementId, seriesId, pointIndex) => (
    {
        nodeType: NodeType.CIRCLE,
        snapOptions: getPointSnapOptions(chartElementId, seriesId, pointIndex),
        canDrag: false,
        hideNodeConnectorWidget: true,
        annotationType: "DataHilite",
        dataSource: getPointDataSource(chartElementId, seriesId, pointIndex)
    });

export const getShowChangeInValueConnectorModel = (chartElementId, seriesId, pointAIndex, pointBIndex) => (
    {
        connectorType: ConnectorType.STEP,
        source: chartElementId,
        target: chartElementId,
        startDecoration: "circle",
        endDecoration: "circle",
        endPointIsLocked: false,
        canChangeConnectorType: false,
        lineStyle: "dotted",
        color: "primary",
        labels: [
            {
                dataSource: getPointsDiffDataSource(chartElementId, seriesId, pointAIndex, pointBIndex),
                offset: 0,
                userFontScale: 1.7
            }
        ],
        sourceSnapOptions: getPointSnapOptions(chartElementId, seriesId, pointAIndex),
        targetSnapOptions: getPointSnapOptions(chartElementId, seriesId, pointBIndex),
        adjustments: {
            a1: 75
        }
    });

export const getDataNoteAnnotationAndConnectorModels = (chartElementId, seriesId, pointIndex) => {
    const annotationId = _.uniqueId();

    const annotationModel = {
        id: annotationId,
        nodeType: NodeType.BOX,
        hideNodeConnectorWidget: true,
        annotationType: "DataNote",
        snapOptions: getPointSnapOptions(chartElementId, seriesId, pointIndex),
        canDrag: true
    };

    const connectorModel = {
        source: annotationId,
        target: chartElementId,
        endDecoration: "circle",
        endPointIsLocked: true,
        targetSnapOptions: getPointSnapOptions(chartElementId, seriesId, pointIndex)
    };

    return { annotationModel, connectorModel };
};

export const getAxisAnnotationAndConnectorModels = (chartElementId, axis, x, y) => {
    const annotationId = _.uniqueId();

    const annotationModel = {
        id: annotationId,
        nodeType: NodeType.TEXT,
        x,
        y,
        hideNodeConnectorWidget: true,
    };

    const connectorModel = {
        source: annotationId,
        target: chartElementId,
        endPointIsLocked: true,
        canChangeConnectorType: false,
        targetSnapOptions: {
            axis
        }
    };

    return { annotationModel, connectorModel };
};

const createPointWidgets = (editor, chartElement, point) => {
    if (point.isNull) return;

    const series = point.series;
    const seriesId = series.userOptions.id;

    const lineStyleItems = [{
        label: "Default",
        value: "default",
        image: "/images/ui/connectors/line-style-solid.svg"
    }, {
        label: "Projection",
        value: "projection",
        image: "/images/ui/connectors/line-style-dashed.svg"
    }];
    if (series.type === "area" || series.type === "areaspline") {
        lineStyleItems.push({
            label: "Emphasized",
            value: "emphasized",
            icon: "star_purple500"
        });
    }

    const annotations = getAnnotations(chartElement);
    const annotationsElementsLinkedToPoint = getAnnotationsElementsLinkedToPoint(chartElement, point);
    const existingDataHilite = annotationsElementsLinkedToPoint.find(element => element.model.annotationType === "DataHilite");
    const existingDataNote = annotationsElementsLinkedToPoint.find(element => element.model.annotationType === "DataNote");

    const menuItems = [];
    if (series.type === "waterfall") {
        menuItems.push(...[{ label: "Add Note", value: "DataNote" }]);
    } else {
        menuItems.push(existingDataHilite
            ? {
                label: "Remove Highlight Point",
                value: "RemoveDataHilite",
                icon: "star"
            }
            : {
                label: "Highlight Point",
                value: "DataHilite",
                icon: "star"
            }
        );

        menuItems.push(existingDataNote
            ? {
                label: "Remove Note",
                value: "RemoveDataNote",
                icon: "try"
            }
            : {
                label: "Add Note",
                value: "DataNote",
                icon: "try"
            }
        );

        menuItems.push({
            label: "Show Change/Growth/CAGR",
            value: "ShowChangeInValue",
            icon: "north_east"
        });

        menuItems.push({
            type: "divider"
        });

        menuItems.push({
            type: "menu", label: `${SeriesTypeLabels[series.type]} Style`, menu: {
                items: lineStyleItems,
                callback: type => {
                    chartElement.setLineStyle(point.x, series, type);
                    ds.selection.rolloverElement = null; // need to reset rollover element because setting zones rebuilds points
                }
            }
        });

        if (series.type == "column" || series.type == "bar") {
            menuItems.push({ type: "divider" });

            menuItems.push({
                type: "control",
                view: () => controls.createColorPalettePicker(editor, {
                    label: "Color",
                    showChartColors: true,
                    includeAuto: true,
                    selectedColor: chartElement.chartModel.series[series.index].data[point.index].color || "auto",
                    autoLabel: "SERIES",
                    getAutoColor: () => {
                        return chartElement.canvas.getTheme().palette.getColor(chartElement.chartModel.series[series.index].colorName).toRgbString();
                    },
                    callback: (color, x, onClose) => {
                        if (color == "auto") {
                            color = null;
                        }
                        chartElement.chartModel.series[series.index].data[point.index].color = color;
                        chartElement.canvas.updateCanvasModel(true).then(() => {
                            ds.selection.element = chartElement;
                            ds.selection.rolloverElement = chartElement;
                        });

                        // kinda hacky but we need to close the point menu because changing the data has rerendered the ui and the point we are showing a dropdown for is gone
                        // unfortunately there isn't any easy way to close the parent menu from within the control callback so i'm directly removing the view...
                        // $(".dropdown_menu").remove();
                        // $(".click_shield").remove();
                    }
                })
            });
        }
    }

    const $pointWidget = editor.$el.addEl($.div("ui_widget"));

    const $menu = controls.createPopupButton(editor, {
        icon: "gps_fixed",
        showArrow: false,
        items: menuItems,
        callback: type => {
            if (type === "DataHilite") {
                const annotation = annotations.addItem(getDataHiliteAnnotationModel(chartElement.uniquePath, seriesId, point.index));
                app.currentCanvas.updateCanvasModel().then(() => {
                    ds.selection.element = annotations.getItemElementById(annotation.id);
                });
                return;
            }

            if (type === "RemoveDataHilite") {
                annotations.deleteItem(existingDataHilite.id);
                app.currentCanvas.updateCanvasModel();
                return;
            }

            if (type === "DataNote") {
                const { annotationModel, connectorModel } = getDataNoteAnnotationAndConnectorModels(chartElement.uniquePath, seriesId, point.index);

                const annotation = annotations.addItem(annotationModel);
                annotations.connectors.addItem(connectorModel);

                app.currentCanvas.updateCanvasModel().then(() => {
                    ds.selection.element = annotations.getItemElementById(annotation.id);
                });
                return;
            }

            if (type === "RemoveDataNote") {
                annotations.deleteItem(existingDataNote.id);
                app.currentCanvas.updateCanvasModel();
                return;
            }

            if (type === "ShowChangeInValue") {
                const series = point.series;
                const endPoint = series.points[(point.index + 1) % series.points.length];

                let model = getShowChangeInValueConnectorModel(chartElement.uniquePath, seriesId, point.index, endPoint.index);

                const connector = annotations.connectors.addItem(model);

                app.currentCanvas.updateCanvasModel();

                handleDragChangeInValueAnchor(editor, point, annotations, connector, chartElement);

                // esc to cancel add
                $("body").on("keyup.showChange", event => {
                    if (event.which === 27) {
                        event.stopPropagation();
                        $("body").off("mousemove.drag");
                        $("body").off("keyup.showChange");

                        app.isDraggingItem = false;
                        // $selectionNotice.remove();

                        $("#selection_layer").show();

                        annotations.connectors.deleteItem(connector.id);

                        chartElement.canvas.updateCanvasModel();
                    }
                });
            }
        }
    });

    $menu.attr("class", "icon_button").left(4).top(4);
    $pointWidget.append($menu);

    const screenBounds = chartElement.getScreenBounds();
    const plotBox = point.series.getPlotBox();
    const boundsLeftOffset = getChartAnnotationXOffset(point);
    const boundsLeft = boundsLeftOffset + plotBox.translateX;
    const boundsTop = screenBounds.top + plotBox.translateY;

    const chart = chartElement.chart;

    const dragAxis = (point.series.type == "bar") ? "x" : "y";

    // Dragging is only available if the point is not a sum of another points
    if (!point.isSum && !chartElement.parentElement.hasDataSourceLink()) {
        $pointWidget.on("mousedown", event => {
            const mouseDownX = event.pageX;
            const mouseDownY = event.pageY;

            const yAxisModelSettings = chartElement.chartModel.yAxis
                ? {
                    min: chartElement.chartModel.yAxis.min,
                    max: chartElement.chartModel.yAxis.max,
                    tickInterval: chartElement.chartModel.yAxis.tickInterval
                }
                : null;

            const yAxis2ModelSettings = chartElement.chartModel.yAxis2
                ? {
                    min: chartElement.chartModel.yAxis2.min,
                    max: chartElement.chartModel.yAxis2.max,
                    tickInterval: chartElement.chartModel.yAxis2.tickInterval
                }
                : null;

            const startDrag = () => {
                $pointWidget.isDragging = true;
                $menu.attr("disabled", "disabled");
                $pointWidget.hide();

                // Set the yAxis and yAsix2 to fixed min/max range while dragging so they don't auto-adjust
                if (chartElement.chartModel.yAxis) {
                    const yAxis = chart.yAxis[0];
                    Object.assign(chartElement.chartModel.yAxis, {
                        min: yAxis.min,
                        max: yAxis.max,
                        tickInterval: yAxis.tickInterval
                    });
                }
                if (chartElement.chartModel.yAxis1) {
                    const yAxis2 = chart.yAxis[1];
                    Object.assign(chartElement.chartModel.yAxis2, {
                        min: yAxis2.min,
                        max: yAxis2.max,
                        tickInterval: yAxis2.tickInterval
                    });
                }
            };

            let dragHandledAt;
            const onDrag = event => {
                window.requestAnimationFrame(timestamp => {
                    if (dragHandledAt === timestamp) {
                        return;
                    }

                    dragHandledAt = timestamp;

                    if (!point.series) return; // weird bug that happens occasionally https://beautifulai.atlassian.net/browse/BA-4552

                    if (chartElement.chartModel.series[series.index].stacking === "percent") {
                        // Ignore resizing the first series since "percent" requires
                        //   resizing two series in order for things to look right
                        if (series.index > 0) {
                            // Determine how much to resize by
                            const mouseY = (event.pageY - boundsTop) / editor.canvasScale + $pointWidget.height() / 2;

                            let minVal = 0;
                            let maxVal = 0;
                            let totalVal = 0;
                            for (let seriesIndex = 0; seriesIndex < chartElement.chartModel.series.length; ++seriesIndex) {
                                let value = chartElement.chartModel.series[seriesIndex].data[point.index].y;
                                seriesIndex < series.index - 1 && (minVal += value);
                                seriesIndex < series.index + 1 && (maxVal += value);
                                totalVal += value;
                            }
                            let interp = mouseY / series.yAxis.height;

                            // Clamp the value between minVal and maxVal
                            let targetVal = Math.min(Math.max(interp * totalVal, minVal), maxVal);

                            let upperVal = targetVal - minVal;
                            let lowerVal = maxVal - targetVal;

                            chartElement.chartModel.series[series.index - 1].data[point.index].y = upperVal;
                            chartElement.chartModel.series[series.index].data[point.index].y = lowerVal;

                            // Refreshing the chart
                            chartElement.refreshElement(false);
                        }
                    } else {
                        // Determine how much to resize by
                        let mouse;
                        if (dragAxis == "y") {
                            mouse = (event.pageY - screenBounds.top) / editor.canvasScale + $pointWidget.height() / 2;
                        } else {
                            mouse = (event.pageX - screenBounds.left) / editor.canvasScale + $pointWidget.width() / 2;
                        }
                        let newVal = point.series.yAxis.toValue(mouse);

                        // check for graphs that have stacked data and offset
                        // any changes by the values beneath the target
                        if ("stackKey" in point.series) {
                            let below;
                            let index = series.index;
                            do {
                                below = chartElement.chartModel.series[++index];
                                if (below) {
                                    newVal -= below.data[point.index].y;
                                }
                            }
                            while (below);
                        }

                        if (point.series.yAxis.dataMax > 30) {
                            newVal = Math.round(newVal); // snap to yaxis points
                        } else {
                            newVal = parseFloat(newVal.toFixed(2));
                        }

                        chartElement.chartModel.series[series.index].data[point.index].y = newVal;

                        // Refreshing the chart
                        chartElement.refreshElement(false);
                    }
                });
            };

            const endDrag = () => {
                _.defer(() => $menu.removeAttr("disabled"));

                widget.bounds = new geom.Rect(point.plotX + plotBox.translateX - 25, point.plotY + plotBox.translateY - 25, 50, 50).multiply(editor.canvasScale);
                $pointWidget.show();

                chartElement.chartModel.series[series.index].data[point.index].y = point.y;
                // Restore the min/max settings for the axises
                if (yAxisModelSettings) {
                    Object.assign(chartElement.chartModel.yAxis, yAxisModelSettings);
                }
                if (yAxis2ModelSettings) {
                    Object.assign(chartElement.chartModel.yAxis2, yAxis2ModelSettings);
                }

                ds.selection.rolloverElement = null;

                chartElement.canvas.updateCanvasModel(false)
                    // We have to refresh the editor to force-adjust the widgets
                    .then(() => {
                        editor.render();
                        editor.layout();
                    });
            };

            $(document).on("mousemove.drag", event => {
                if (!$pointWidget.isDragging) {
                    if (Math.abs(event.pageX - mouseDownX) > 10 || Math.abs(event.pageY - mouseDownY) > 10) {
                        app.isDraggingItem = true;
                        startDrag(event);
                    }
                } else {
                    onDrag(event);
                }
            });

            $(document).one("mouseup.drag", event => {
                if ($pointWidget.isDragging) {
                    endDrag(event);
                    $pointWidget.isDragging = false;
                    app.isDraggingItem = false;
                }
                $(document).off(".drag");
            });
        });
    }

    const { chartOffsetX, chartOffsetY } = getChartOffsets(editor, chartElement);

    let bounds;
    if (series.userOptions.type == "column") {
        bounds = new geom.Rect(point.shapeArgs.x, point.shapeArgs.y, point.shapeArgs.width, point.shapeArgs.height).offset(chartOffsetX, chartOffsetY).offset(plotBox.translateX, plotBox.translateY).multiply(editor.canvasScale);
    } else if (series.userOptions.type == "bar") {
        bounds = new geom.Rect(chart.plotBox.width - point.shapeArgs.y - point.shapeArgs.height, chart.plotBox.height - point.shapeArgs.x - point.shapeArgs.width, point.shapeArgs.height, point.shapeArgs.width).offset(chartOffsetX, chartOffsetY).offset(plotBox.translateX, plotBox.translateY).multiply(editor.canvasScale);
    } else {
        bounds = new geom.Rect(boundsLeft - 15 + chartOffsetX, point.plotY + plotBox.translateY - 15 + chartOffsetY, 30, 30).multiply(editor.canvasScale);
    }

    const widget = editor.registerSubComponent({
        widget: $pointWidget,
        point: point,
        bounds: bounds,
        rollover: () => {
            if (app.isDraggingItem) return;

            if (!point.series) {
                // ds.selection.rolloverElement = null; // need to reset rollover element because setting zones rebuilds points
                const tempElement = ds.selection.element;
                ds.selection.element = null; // need to reset rollover element because setting zones rebuilds points
                ds.selection.element = tempElement;
                return;
            }

            editor.$XAxisAnnotationButton && editor.$XAxisAnnotationButton.opacity(0);
            const offset = getChartAnnotationXOffset(point);

            let rolloverLeft, rolloverTop;

            if (point.series.type == "bar") {
                // rolloverLeft = (plotBox.translateX + point.shapeArgs.height) * editor.canvasScale - WIDGET_SIZE / 4;
                rolloverLeft = (chartOffsetX + plotBox.translateX + chart.plotBox.width - point.shapeArgs.y) * editor.canvasScale - WIDGET_SIZE / 2;
                rolloverTop = (chartOffsetY + plotBox.translateY + chart.plotBox.height - point.shapeArgs.x - point.shapeArgs.width / 2) * editor.canvasScale - WIDGET_SIZE / 2;
            } else {
                rolloverLeft = (offset + plotBox.translateX + chartOffsetX) * editor.canvasScale - WIDGET_SIZE / 2;
                rolloverTop = (point.plotY + plotBox.translateY + chartOffsetY) * editor.canvasScale - WIDGET_SIZE / 2;
            }

            $pointWidget.left(rolloverLeft).top(rolloverTop);
        },
        click: event => {
        }
    });
};

export const renderChartWidgets = (editor, chartElement) => {
    const chart = chartElement.chart;

    // Annotation layer annotations
    const annotations = getAnnotations(chartElement);

    if (chart.series.length == 0) return;

    // annotation widgets
    const xAxis = chart.xAxis[0];
    const yAxis = chart.yAxis[0];
    const yAxis2 = chart.yAxis[1];

    const { chartOffsetX, chartOffsetY } = getChartOffsets(editor, chartElement);

    if (xAxis.visible) {
        const xAxisBounds = editor.canvasToSelectionCoordinates(geom.Rect.FromBBox(xAxis.axisGroup.element.getBBox()));
        xAxisBounds.height = 20;
        xAxisBounds.top = xAxisBounds.top + chartOffsetY * chartElement.canvas.canvasScale - 10;

        editor.$XAxisAnnotationButton = editor.$el.addEl($.div("ui_widget"));//.css("pointer-events", "none"));
        editor.$XAxisAnnotationButton.append($.div("vertical_line").left(0).top(-7).height(20));
        editor.$XAxisAnnotationButton.append($.div("add_component_button button", "XAxis Label").left(-50).top(-20));
        editor.$XAxisAnnotationButton.append($.div("add_item_button button").left(-10).top(13));

        editor.$XAxisAnnotationButton.on("click", event => {
            const screenBounds = chartElement.elements.annotations.getScreenBounds();
            const x = (event.pageX - screenBounds.left) / screenBounds.width;
            const y = 0.5;

            const { annotationModel, connectorModel } = getAxisAnnotationAndConnectorModels(chartElement.uniquePath, "xAxis", x, y);
            const annotation = annotations.addItem(annotationModel);
            annotations.connectors.addItem(connectorModel);

            app.currentCanvas.updateCanvasModel().then(() => {
                ds.selection.element = annotations.getItemElementById(annotation.id);
            });
        });

        editor.registerSubComponent({
            widget: editor.$XAxisAnnotationButton,
            bounds: xAxisBounds,
            rollover: (event, mouseX, mouseY) => {
                editor.$XAxisAnnotationButton.top(xAxisBounds.top - 15).width(120).left(mouseX).opacity(1);
            },
            click: () => {
            }
        });

        if (xAxis.axisTitle && xAxis.axisTitle.textStr.length) {
            const xAxisTitleBounds = Convert.ClientBoundingBoxToSelectionCoordinates(xAxis.axisTitle.element, editor.element).inflate(20);

            editor.$XAxis1TitleEditor = editor.$el.addEl($.div("ui_widget rollover_hilite fill_hilite"));
            editor.$XAxis1TitleEditor.setBounds(xAxisTitleBounds.inflate(5));

            editor.registerSubComponent({
                widget: editor.$XAxis1TitleEditor,
                bounds: xAxisTitleBounds,
                rollover: () => {
                },
                click: event => {
                    const targetPt = { x: event.pageX, y: event.pageY - 20 };

                    ShowDialog(EditTextDialog, {
                        targetPt,
                        value: chartElement.chartModel.xAxis.axisTitleText,
                        callback: value => {
                            chartElement.chartModel.xAxis.axisTitleText = value;
                            chartElement.canvas.updateCanvasModel(true);
                        },
                    });
                }
            });
        }
    }

    if (yAxis.visible) {
        const yAxisLine = editor.canvasToSelectionCoordinates(geom.Rect.FromBBox(yAxis.axisLine.element.getBBox()));
        const yAxisAnnotationBounds = new geom.Rect(yAxisLine.left - 10 + chartOffsetX * chartElement.canvas.canvasScale, yAxisLine.top + chartOffsetY * chartElement.canvas.canvasScale, 20, yAxisLine.height);
        editor.$yAxisAnnotationButton = editor.$el.addEl($.div("ui_widget").css("pointer-events", "none"));
        editor.$yAxisAnnotationButton.append($.div("horizontal_line").left(19).top(0));
        editor.$yAxisAnnotationButton.append($.div("add_component_button button", "YAxis Label").left(48).top(-12).width(100));
        editor.$yAxisAnnotationButton.append($.div("add_item_button button").left(14).top(-10));

        editor.$yAxisAnnotationButton.on("click", event => {
            const screenBounds = chartElement.elements.annotations.getScreenBounds();
            const x = 0.5;
            const y = (event.pageY - screenBounds.top) / screenBounds.height;

            const { annotationModel, connectorModel } = getAxisAnnotationAndConnectorModels(chartElement.uniquePath, "yAxis", x, y);
            const annotation = annotations.addItem(annotationModel);
            annotations.connectors.addItem(connectorModel);

            app.currentCanvas.updateCanvasModel().then(() => {
                ds.selection.element = annotations.getItemElementById(annotation.id);
            });
        });

        editor.registerSubComponent({
            widget: editor.$yAxisAnnotationButton,
            bounds: yAxisAnnotationBounds,
            rollover: (event, mouseX, mouseY) => {
                const left = yAxisAnnotationBounds.left - 15;
                editor.$yAxisAnnotationButton.top(mouseY).width(120).left(left).opacity(1);
            },
            click: () => {
            }
        });

        if (yAxis.axisTitle && yAxis.axisTitle.textStr.length) {
            let yAxisTitleBounds = Convert.ClientBoundingBoxToSelectionCoordinates(yAxis.axisTitle.element, editor.element).inflate(20);

            editor.$yAxisTitleEditor = editor.$el.addEl($.div("ui_widget rollover_hilite fill_hilite"));
            editor.$yAxisTitleEditor.setBounds(yAxisTitleBounds.inflate(5));

            editor.registerSubComponent({
                widget: editor.$yAxisTitleEditor,
                bounds: yAxisTitleBounds,
                rollover: () => {
                },
                click: event => {
                    const targetPt = { x: event.pageX, y: event.pageY - 20 };

                    ShowDialog(EditTextDialog, {
                        targetPt,
                        value: chartElement.chartModel.yAxis.axisTitleText,
                        callback: value => {
                            chartElement.chartModel.yAxis.axisTitleText = value;
                            chartElement.canvas.updateCanvasModel(true);
                        },
                    });
                }
            });
        }
    }

    if (yAxis2.visible) {
        const yAxis2Line = editor.canvasToSelectionCoordinates(geom.Rect.FromBBox(yAxis2.axisLine.element.getBBox()));
        const yAxis2AnnotationBounds = new geom.Rect(yAxis2Line.left, yAxis2Line.top + chartOffsetY * chartElement.canvas.canvasScale, 20, yAxis2Line.height);
        editor.$yAxis2AnnotationButton = editor.$el.addEl($.div("ui_widget").css("pointer-events", "none"));
        editor.$yAxis2AnnotationButton.append($.div("horizontal_line").right(19).top(0));
        editor.$yAxis2AnnotationButton.append($.div("add_component_button button", "YAxis Label").right(30).top(-10).width(100));
        editor.$yAxis2AnnotationButton.append($.div("add_item_button button").right(-8).top(-10));

        editor.registerSubComponent({
            widget: editor.$yAxis2AnnotationButton,
            bounds: yAxis2AnnotationBounds,
            rollover: (event, mouseX, mouseY) => {
                const left = yAxis2AnnotationBounds.left - 120 + 10; // subtract width of widget, add half of button width back
                editor.$yAxis2AnnotationButton.top(mouseY).width(120).left(left);
            },
            click: (event, mouseX, mouseY) => {
                const annotation = chartElement.addAnnotation({
                    type: "YAxis",
                    value: yAxis2.toValue(editor.selectionToCanvasCoordinates(mouseY - chartOffsetY * chartElement.canvas.canvasScale)),
                    offset: 0.7,
                    axisIndex: 1,
                    showIcon: false
                });
                chartElement.canvas.updateCanvasModel(true);
                annotation.text.select();
            }
        });

        if (yAxis2.axisTitle && yAxis2.axisTitle.textStr.length) {
            let yAxis2TitleBounds = Convert.ClientBoundingBoxToSelectionCoordinates(yAxis2.axisTitle.element, editor.element).inflate(20);

            const axisTitlePosition = chartElement.chartModel.yAxis2.axisTitle;
            editor.$yAxis2TitleEditor = editor.$el.addEl($.div("ui_widget rollover_hilite fill_hilite"));
            editor.$yAxis2TitleEditor.setBounds(yAxis2TitleBounds.inflate(5));

            editor.registerSubComponent({
                widget: editor.$yAxis2TitleEditor,
                bounds: yAxis2TitleBounds,
                rollover: () => {
                },
                click: event => {
                    const targetPt = { x: event.pageX, y: event.pageY - 20 };

                    ShowDialog(EditTextDialog, {
                        targetPt,
                        value: chartElement.chartModel.yAxis2.axisTitleText,
                        callback: value => {
                            chartElement.chartModel.yAxis2.axisTitleText = value;
                            chartElement.canvas.updateCanvasModel(true);
                        },
                    });
                }
            });
        }
    }

    chart.series.forEach(series => {
        series.points.forEach(point => createPointWidgets(editor, chartElement, point));
    });
};

