import React from "react";
import styled from "styled-components";

import { isRenderer } from "js/config";

import { BaseElement } from "../base/BaseElement";
import { sanitizeHtml } from "js/core/utilities/dompurify";

const baiUrl = "https://www.beautiful.ai/?";

const WebViewIFrame = styled.iframe.attrs(({ scale }) => ({
    style: {
        transform: `scale(${scale})`,
        width: `${100 / scale}%`,
        height: `${100 / scale}%`
    }
}))`
    pointer-events: auto;
    border: solid 1px #aaa;
    transform-origin: 0 0;
`;

export class WebView extends BaseElement {
    isCurrentCanvas = false;

    static get schema() {
        return {
            scale: 1,
            url: baiUrl,
            fullBleed: false
        };
    }

    get scale() {
        return this.model.scale ?? 1;
    }

    getCanvasMargins() {
        const fullBleed = this.model.fullBleed;
        if (fullBleed) {
            if (this.canvas.model.layout.showFooter) {
                return { left: 0, top: 0, bottom: 10, right: 0 };
            } else {
                return { left: 0, top: 0, bottom: 0, right: 0 };
            }
        } else {
            return { left: 50, top: 50, bottom: 50, right: 50 };
        }
    }

    _build() {
    }

    _calcProps(props, options) {
        const { size } = props;
        return { size };
    }

    _prepareToShowElement() {
        this.isCurrentCanvas = true;
        this.refreshRender();
    }

    _stopElement() {
        this.isCurrentCanvas = false;
        this.refreshRender();
    }

    getSanitizedUrl() {
        return this.model.url.startsWith("javascript:") ? baiUrl : sanitizeHtml(this.model.url);
    }

    renderChildren(transition) {
        if (isRenderer) {
            return null;
        }

        return (
            /**
                 * this is a hack to force the iframe to reload
                 * when the url changes this will fix the issue if
                 * there was a video/audio playing */
            this.isCurrentCanvas ? (
                <WebViewIFrame
                    sandbox="allow-forms allow-scripts allow-same-origin allow-presentation allow-orientation-lock	allow-pointer-lock"
                    style={{
                        display: this.isCurrentCanvas ? "block" : "none",
                    }}
                    src={this.getSanitizedUrl()}
                    scale={this.scale}
                />
            ) : null);
    }
}

export const elements = {
    WebView
};

