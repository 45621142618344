import { AssetType, AuthoringBlockType, FormatType, TextStyleType } from "common/constants";
import { $, _, numeral, SVG } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { controls } from "js/editor/ui";
import { v4 as uuid } from "uuid";
import * as geom from "js/core/utilities/geom";
import { ShowDialog, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";
import EditTextDialog from "js/react/components/Dialogs/EditTextDialog";

import { TextElementSelection } from "./TextEditor";
import {
    CollectionElementSelection,
    CollectionItemElementSelection
} from "../CollectionElementEditor";
import { FormatOptionsMenu } from "../FormatOptionsMenu";
import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";

const PictorialChartsSelection = CollectionElementSelection.extend({
    getAddItemLabel: function() {
        return "Add Stat";
    },

    renderControls() {
        this.createAddItemButton({ skipTextSelection: true });
    }
});

const PictorialChartsOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Fixed Number of Icons",
            property: "fitIcons"
        });
    }

});

const PictorialChartItemSelection = CollectionItemElementSelection.extend({
    getDragAxis() {
        return "y";
    },

    transitionOnDelete: function() {
        return false;
    },

    onStopDrag: function(event, position, dragProps) {
        CollectionItemElementSelection.prototype.onStopDrag.call(this, event, position, dragProps, { forceRender: true });
    },

    renderControls: function() {
        this.addControl({
            type: controls.SLIDER,
            min: 0,
            max: 100,
            label: "Value",
            property: "value"
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Icon",
            menuContents: closeMenu => {
                let $menu = $.div("pictorial-chart-menu");

                let loadIcon = ($menu, iconId) => {
                    let $item = $menu.addEl($.div("icon"));
                    $item.data("icon", iconId);
                    ds.assets.getAssetById(iconId, "icon").then(icon => {
                        const url = icon.get("original");
                        if (url.startsWith("http")) {
                            return fetch(url).then(res => {
                                return res.text();
                            });
                        } else {
                            return Promise.resolve(url);
                        }
                    }).then(svgData => {
                        $item.append(svgData);
                    });

                    $item.on("click", () => {
                        this.element.model.iconId = iconId;
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    });
                };

                let $icons = $menu.addEl($.div("preset-icons"));
                loadIcon($icons, "figure-woman");
                loadIcon($icons, "figure-man");
                loadIcon($icons, "torso-female");
                loadIcon($icons, "torso-male");
                loadIcon($icons, "baby");
                loadIcon($icons, "pregnant");
                loadIcon($icons, "stroller");
                loadIcon($icons, "people");
                loadIcon($icons, "laborer");
                loadIcon($icons, "business-man");
                loadIcon($icons, "business-woman");
                loadIcon($icons, "female");
                loadIcon($icons, "male");
                loadIcon($icons, "star");
                loadIcon($icons, "full");

                let $choose = $menu.addEl(controls.createButton(this, {
                    label: "Choose Icon...",
                    callback: () => {
                        closeMenu();
                        ShowDialog(AddAssetsContainer, {
                            assetType: AssetType.ICON,
                            workspaceId: ds.selection.presentation.getWorkspaceId(),
                            callback: model => {
                                const {
                                    content_type,
                                    content_value,
                                } = model;
                                if (content_type == AssetType.ICON) {
                                    this.element.model.iconId = content_value;
                                    this.element.canvas.updateCanvasModel(false);
                                } else {
                                    ShowWarningDialog({
                                        title: "Unsupported asset type",
                                        message: "Please choose an icon from the asset chooser.",
                                    });
                                }
                            },
                        });
                    }
                }));

                return $menu;
            },
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true,
            showBackgroundColors: true,
            omitCurrentBackgroundColor: true
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Format",
            menuContents: closeMenu => {
                let $menu = new FormatOptionsMenu({
                    element: this.element,
                    allowedFormats: [FormatType.PERCENT, FormatType.NUMBER],
                    showTableFormatOptions: false
                }).render().$el;
                return $menu;
            }
        });
    },
});

const PictorialChartItemOptionsMenu = ElementOptionsMenu.extend({

    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Text",
            value: this.element.showDescription,
            callback: value => {
                if (value) {
                    let blockId = uuid();

                    this.element.model.description = {
                        blocks: [{
                            id: blockId,
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.BODY,
                            html: ""
                        }]
                    };
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = this.element.description;
                        this.element.description.overlay.focusBlock(blockId);
                    }).catch(err => {
                        this.element.model.description = null;
                        this.element.canvas.revertCanvasModel();
                        ShowWarningDialog({
                            title: "Sorry, we were unable to make this change",
                            message: err.message,
                        });
                    });
                } else {
                    this.element.model.description = null;
                    this.element.canvas.updateCanvasModel(false);
                }
            }
        });
    }
});

const PictorialChartDescriptionSelection = TextElementSelection.extend({
    renderControls: function() {
        TextElementSelection.prototype.renderControls.apply(this, arguments);

        this.createDeleteComponentWidget({
            action: () => {
                delete this.element.model.description;
                this.element.canvas.updateCanvasModel(true).then(() => {
                    ds.selection.element = this.element.parentElement;
                });
            }
        });
    }
});

const PictorialChartItemLabelSelection = ElementSelection.extend({

    showSelectionBox: false,

    render: function() {
        ds.selection.element = null;

        const selectionBounds = this.element.selectionBounds;
        const screenBounds = this.element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        ShowDialog(EditTextDialog, {
            targetPt,
            target: this.element,
            minWidth: 150,
            value: (this.element.parentElement.formatType == FormatType.PERCENT) ? this.element.parentElement.model.value : this.element.parentElement.model.labelValue,
            callback: value => {
                if (numeral.validateEx(value)) {
                    if (this.element.parentElement.format == FormatType.PERCENT) {
                        this.element.parentElement.model.value = numeral(value).value();
                    } else {
                        this.element.parentElement.model.labelValue = numeral(value).value();
                    }

                    this.element.parentElement.canvas.updateCanvasModel(true);
                }
            },
        });

        return this;
    },
});

export const editors = {
    PictorialChartsSelection,
    PictorialChartsOptionsMenu,
    PictorialChartItemOptionsMenu,
    PictorialChartItemSelection,
    PictorialChartDescriptionSelection,
    PictorialChartItemLabelSelection,
};
