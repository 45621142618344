import { OrientationType } from "common/constants";

import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { CreateMediaMenu } from "./pictureEditor";

const TeamSelection = CollectionElementSelection.extend({
    getAddItemLabel: function() {
        return "Add Team Member";
    }
});

const TeamItemSelection = CollectionItemElementSelection.extend({
    getGridOrientation: function() {
        return OrientationType.HORIZONTAL;
    },

    renderControls() {
        CreateMediaMenu(this, this.element.content);
    }
});

export const editors = {
    TeamSelection,
    TeamItemSelection,
};
