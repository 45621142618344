import * as geom from "js/core/utilities/geom";
import { FormatType, BlockStructureType } from "common/constants";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";

import { SVGRectElement } from "../../base/SVGElement";
import { layoutHelper } from "../../layouts/LayoutHelper";
import { TextElement } from "../../base/Text/TextElement";

import CompareValuesItem from "./CompareValuesItem";
import CompareValuesItemLabel from "./CompareValuesItemLabel";

export default class CompareVerticalBarItem extends CompareValuesItem {
    get labelText() {
        return this.parentElement.formatValue(this.currentValue);
    }

    _build() {
        this.shape = this.addElement("shape", () => SVGRectElement);
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            syncFontSizeWithSiblings: true
        });

        if (this.parentElement.format != FormatType.NONE) {
            this.label = this.addElement("label", () => CompareValuesItemLabel, {
                html: this.labelText,
                autoWidth: true,
                autoHeight: true,
                canEdit: false
            });
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let textProps = this.text.calcProps(new geom.Size(size.width, options.textHeight));
        textProps.bounds = new geom.Rect(0, size.height - options.textHeight, size.width, options.textHeight);

        let rectBounds;

        let compare = this.parentElement;
        let availableHeight = size.height - textProps.bounds.height;
        let barHeight = availableHeight * this.percentageValue;
        if (this.isAnimating) {
            barHeight *= this.drawPercentage;
        }

        if (compare.minValue >= 0) {
            rectBounds = new geom.Rect(0, availableHeight - barHeight, size.width, barHeight);
        } else {
            rectBounds = new geom.Rect(0, 0, size.width, barHeight);
            let baseLineY = availableHeight * Math.abs(compare.maxValue) / (compare.maxValue - compare.minValue);
            if (this.model.value >= 0) {
                rectBounds.top = baseLineY - barHeight;
            } else {
                rectBounds.top = baseLineY;
            }
        }

        if (this.label) {
            if (this.isAnimating) {
                this.label.options.html = sanitizeHtmlText(this.labelText);
            }

            let labelProps = this.label.calcProps(size);
            props.isLabelInside = this.isAnimating ? this.finalLabelPositionIsInside : rectBounds.height > labelProps.size.height;

            if (props.isLabelInside) {
                labelProps.bounds = new geom.Rect(layoutHelper.getAlignOffset(labelProps.size, rectBounds.size).offset(0, rectBounds.top), labelProps.size);
            } else {
                labelProps.bounds = new geom.Rect(size.width / 2 - labelProps.size.width / 2, rectBounds.top - labelProps.size.height, labelProps.size);
            }

            if (this.hasStoredPropChanged("isLabelInside", props.isLabelInside)) {
                this.markStylesAsDirty();
            }
        }

        let shapeProps = this.shape.calcProps(size);
        shapeProps.bounds = rectBounds;
        shapeProps.layer = -1;

        return { size };
    }

    _getBackgroundColor(forElement) {
        if (this.label && forElement === this.label && this.calculatedProps.isLabelInside) {
            return this.getShapeFillColor(this.shape);
        } else {
            return super._getBackgroundColor(forElement);
        }
    }

    get animationElementName() {
        return `Column #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            animatingElements: [this.parentElement, this],
            prepare: () => {
                this.text.animationState.fadeInProgress = 0;
                this.animationState.value = 0;

                if (this.label) {
                    this.label.animationState.fadeInProgress = 0;
                    this.finalLabelPositionIsInside = this.calculatedProps.isLabelInside;
                }
            },
            onBeforeAnimationFrame: progress => {
                this.text.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.animationState.value = progress;

                if (this.label) {
                    this.label.animationState.fadeInProgress = Math.clamp((progress - 0.4) / 0.6, 0, 1);
                }

                return this;
            }
        }];
    }
}
