import React from "react";
import { _, $ } from "js/vendor";
import { app } from "js/namespaces.js";
import { controls } from "js/editor/ui";
import { ElementSelection } from "../BaseElementEditor";
import { Divider } from "js/react/components/UiComponents";
import { reactMount, reactUnmount } from "js/react/renderReactRoot";

const FooterSelection = ElementSelection.extend({
    getOffset: function() {
        return 10;
    },

    getTitle: function() {
        return "Footer";
    },

    renderControls: function() {
        let theme = this.element.canvas.getTheme();
        let hasLogo = !_.isEmpty(theme.get("logo"));
        let hasDarkLogo = !_.isEmpty(theme.get("logo_dark"));

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Edit Footer",
            icon: "edit",
            menuContents: closeMenu => {
                let $menu = $.div("menu");
                let $row = $menu.addEl($.div("row"));
                $row.append(controls.createToggle(this, {
                    label: "Page Number",
                    value: this.element.showPageNum,
                    labelAtEnd: true,
                    callback: value => {
                        this.element.model.showPageNumOverride = value;
                        this.element.canvas.updateCanvasModel();
                    }
                }));

                $row = $menu.addEl($.div("row"));
                $row.append(controls.createToggle(this, {
                    label: "Message",
                    value: this.element.showMessage,
                    labelAtEnd: true,
                    callback: value => {
                        this.element.model.showMessageOverride = value;
                        this.element.canvas.updateCanvasModel();
                    }
                }));

                $row = $menu.addEl($.div("row"));
                $row.append(controls.createToggle(this, {
                    label: "Logo",
                    value: this.element.showLogo,
                    enabled: hasLogo || hasDarkLogo,
                    labelAtEnd: true,
                    callback: value => {
                        this.element.model.showLogoOverride = value;
                        this.element.canvas.updateCanvasModel();
                    }
                }));

                this.$divider = $menu.addEl($.div("divider"));
                reactMount(<Divider margin={0} />, this.$divider[0]);

                $row = $menu.addEl($.div("row"));
                $row.append(controls.createDropdownMenu(this, {
                    label: "Text",
                    items: [{
                        value: "auto", label: "Auto"
                    }, {
                        value: "light", label: "Light"
                    }, {
                        value: "dark", label: "Dark"
                    }],
                    value: this.element.overrideStyle,
                    callback: value => {
                        if (value == "auto") {
                            this.element.model.overrideStyle = null;
                        } else {
                            this.element.model.overrideStyle = value;
                        }
                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel();
                    }
                }));

                $row = $menu.addEl($.div("row"));
                $row.append(controls.createDropdownMenu(this, {
                    label: "Logo Type",
                    items: [{
                        value: "auto", label: "Auto"
                    }, {
                        value: "light", label: "On Light"
                    }, {
                        value: "dark", label: "On Dark"
                    }],
                    value: this.element.logoType,
                    enabled: hasLogo && hasDarkLogo,
                    callback: value => {
                        if (value == "auto") {
                            this.element.model.logoTypeOverride = null;
                        } else {
                            this.element.model.logoTypeOverride = value;
                        }
                        this.element.canvas.updateCanvasModel();
                    },
                }));

                $row = $menu.addEl($.div("row"));
                $row.append(controls.createDropdownMenu(this, {
                    label: "Logo Position",
                    items: [{
                        value: "left", label: "Left"
                    }, {
                        value: "right", label: "Right"
                    }],
                    value: this.element.logoPosition,
                    enabled: hasLogo || hasDarkLogo,
                    callback: value => {
                        this.element.model.logoPositionOverride = value;
                        this.element.canvas.updateCanvasModel();
                    },
                }));

                return $menu;
            }
        });
    }
});

export const editors = {
    FooterSelection
};
