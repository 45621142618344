import React, { Component } from "react";
import styled from "styled-components";

import { $, _ } from "js/vendor";
import { BlockStructureType, TextFocusType, TextStyleType } from "common/constants";
import { ds } from "js/core/models/dataService";
import { reactMount, reactUnmount } from "js/react/renderReactRoot";
import { Key } from "js/core/utilities/keys";
import { app } from "js/namespaces";
import { getSelectionState } from "js/core/utilities/htmlTextHelpers";

import { ElementRollover, ElementSelection } from "../BaseElementEditor";
import { AuthoringBlockEditor } from "../authoring/Editors/AuthoringBlockEditor";
import { TabKeyController } from "js/editor/PresentationEditor/TabKeyController";

const Container = styled.div`
  position: relative;
  pointer-events: none;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
`;

export const TextElementSelection = ElementSelection.extend({
    showSelectionBox: false,
    recreateOnRefresh: false,

    renderControls() {
        ds.selection.on("change:element", this.handleSelectionElementChange);
        this.handleSelectionElementChange();
        if (this.element.options.canDelete) {
            this.createDeleteComponentWidget({
                action: this.element.options.deleteCallback,
            });
        }
    },

    handleSelectionElementChange: function() {
        if (ds.selection.element?.parentBlockContainer === this.element && ds.selection.element.rootBlockElement) {
            this.handleFocusElementBlock(ds.selection.element.rootBlockElement);
        }
    },

    async handleFocusElementBlock(element) {
        if (!this.textEditorRef) {
            await this.setupTextEditor();
        }

        this.textEditorRef.current.focusElementBlock(element.options.blockId);
    },

    async focusBlock(blockId, focusType, focusPosition, selectionSize) {
        if (!this.textEditorRef) {
            await this.setupTextEditor();
        }

        const block = this.textEditorRef.current.blockContainer.blockRefs[blockId]?.current;
        if (block) {
            this.textEditorRef.current.focusBlock(block, focusType, focusPosition, selectionSize);
        }
    },

    removeFocus() {
        this.textEditorRef?.current?.clearSelection();
    },

    onMouseDown(event) {
        if (this.element.requireParentSelection) {
            // When text blocks are inactive until parent is selected we have to trigger
            // setupTextEditor from here
            if (!this.textEditorRef) {
                event.stopPropagation();
                this.setupTextEditor();
            }
        }
    },

    handleRefreshLayout() {
        this.selectionLayer.relayoutSelectionViews();
    },

    handleRefreshEditor() {
        this.cleanUp();
        this.setupTextEditor();
    },

    handleKeyDownInBlock(event, block) {
        if (event.which === Key.TAB) {
            this.handleTabKey(event, block);
            return true;
        }

        return false;
    },

    handleTabKey(event, focusedBlock) {
        if (!event.shiftKey && !focusedBlock.props.isLastBlock) {
            // focus next block in text
            this.focusBlock(
                this.textEditorRef.current.blockContainer.blocks[focusedBlock.props.index + 1].props.id,
                TextFocusType.ALL
            );
        } else if (event.shiftKey && !focusedBlock.props.isFirstBlock) {
            // focus prev block in text
            this.focusBlock(
                this.textEditorRef.current.blockContainer.blocks[focusedBlock.props.index - 1].props.id,
                TextFocusType.ALL
            );
        } else {
            TabKeyController.handleTabKey(event);
        }
    },

    async setupTextEditor(initialFocusedBlock) {
        if (this.element.canEdit && !this.element.parentElement?.model.isLocked && !this.isRemoved) {
            this.textEditorContainerRef = React.createRef();
            this.textEditorRef = React.createRef();

            this.$textEditorEl = this.$el.addEl($.div().css({ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }));

            await new Promise(resolve => {
                reactMount(<TextEditorContainer
                    ref={this.textEditorContainerRef}
                    element={this.element}
                    textEditorRef={this.textEditorRef}
                    allowMultipleBlocks={false}
                    initialFocusedBlock={initialFocusedBlock}
                    onRefreshLayout={() => this.handleRefreshLayout()}
                    onRefreshEditor={() => this.handleRefreshEditor()}
                    onKeyDownInBlock={(event, block) => this.handleKeyDownInBlock(event, block)}
                    onReady={resolve}
                />, this.$textEditorEl[0]);
            });
        }
    },

    _layout() {
        this.textEditorContainerRef?.current?.refreshBounds();
    },

    cleanUp() {
        ds.selection.off("change:element", this.handleSelectionElementChange);

        if (this.$textEditorEl?.length) {
            this.textEditorRef = null;
            this.textEditorContainerRef = null;
            reactUnmount(this.$textEditorEl[0]);
            this.$textEditorEl.remove();

            if (this.element.canvas.isLockedForCollaborators()) {
                this.element.canvas.unlockSlideForCollaborators();
            }
        }

        this.isRemoved = true;
    },

});

class TextEditorContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bounds: props.element.selectionBounds.multiply(props.element.canvas.getScale())
        };

        this.lockSlideForCollaborators = _.throttle(() => props.element.canvas.lockSlideForCollaborators(10), 5000);

        this.focusedBlock = null;
        this.selectedBlocks = [];
    }

    get isOnAuthoringCanvas() {
        const { element } = this.props;

        return element.canvas.layouter.primaryElement.type === "AuthoringCanvas";
    }

    componentDidMount() {
        this.lockSlideForCollaborators();
    }

    componentWillUnmount() {
        const { element } = this.props;

        this.lockSlideForCollaborators.cancel();

        if (element.canvas.isLockedForCollaborators()) {
            element.canvas.unlockSlideForCollaborators();
        }
    }

    refreshBounds = () => {
        if (this.props.element.canvas.layouter.isGenerating) return;

        this.setState({
            bounds: this.props.element.selectionBounds.multiply(this.props.element.canvas.getScale())
        });
    }

    onRefresh = () => {
        const { element } = this.props;

        if (this.isOnAuthoringCanvas && element.canvas.layouter.primaryElement.overlay?.onRefresh) {
            element.canvas.layouter.primaryElement.overlay.onRefresh();
        }
    }

    refreshAndSaveCanvas = async refreshEditor => {
        const { element, onRefreshEditor, onRefreshLayout } = this.props;
        this.lockSlideForCollaborators();

        await element.canvas.refreshCanvasAutoRevert({ suppressRefreshCanvasEvent: false });

        if (refreshEditor) {
            onRefreshEditor();
        }

        onRefreshLayout();
        this.refreshBounds();
        this.onRefresh();

        this.saveChanges();
    }

    refreshCanvas = async refreshEditor => {
        const { element, onRefreshEditor, onRefreshLayout } = this.props;
        this.lockSlideForCollaborators();

        await element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: false });

        if (refreshEditor) {
            onRefreshEditor();
        }

        onRefreshLayout();
        this.refreshBounds();
        this.onRefresh();
    }

    refreshElement = async () => {
        const { element } = this.props;

        this.lockSlideForCollaborators();

        if (element.options?.autoWidth || element.options?.syncFontSizeWithSiblings) {
            if (element.options?.autoWidth) {
                // If auto width then it's cheaper to always do full refresh
                await element.canvas.refreshCanvas();
            } else if (element.parentTextScaleManagingElement && element.parentTextScaleManagingElement.canRefreshElement) {
                // Need to sync font sizes but can refresh managing parent element
                element.parentTextScaleManagingElement.refreshElement();
            } else {
                // Need to sync font sizes but *cannot* refresh managing parent element
                await element.canvas.refreshCanvas();
            }
        } else {
            const currentSize = element.calculatedProps.size;
            const currentShowTextIsClippedWarning = element.options.showTextIsClippedWarning;
            // Make sure we don't show text is clipped warning
            element.options.showTextIsClippedWarning = false;
            element.refreshElement();

            // Restore original value
            element.options.showTextIsClippedWarning = currentShowTextIsClippedWarning;
            if (
                !currentSize.equals(element.calculatedProps.size) ||
                !element.calculatedProps.isTextFit ||
                (element.syncFontSizeWithSiblings && element.hasStoredPropChanged("textScale", element.calculatedProps.textScale))
            ) {
                // Something significant changed -> need full refresh
                await element.canvas.refreshCanvas();
            }
        }

        this.refreshBounds();
        this.onRefresh();
    }

    getUndoState = () => {
        const { element } = this.props;
        return {
            selectedElementUniquePath: element.uniquePath,
            focusedBlockId: this.focusedBlock?.model.id,
            focusedBlockSelectionState: this.focusedBlock?.ref.current ? getSelectionState(this.focusedBlock?.ref.current) : null,
            selectedBlockIds: this.selectedBlocks.map(({ model: { id } }) => id)
        };
    }

    saveChanges = (save = true) => {
        const { element } = this.props;

        const undoState = this.getUndoState();

        if (!this.undoState) {
            this.undoState = undoState;
        }

        const undoOldState = { ...this.undoState };
        const undoNewState = { ...undoState };

        this.undoState = undoState;

        return element.canvas.saveCanvasModel({ undoOldState, undoNewState, save, forceUndo: true });
    }

    onSelectedBlocksChanged = (focusedBlock, selectedBlocks) => {
        this.focusedBlock = focusedBlock;
        this.selectedBlocks = selectedBlocks;

        if (!app.undoManager.isUndoing) {
            this.saveChanges(false);
        }
    }

    render() {
        const {
            element,
            textEditorRef,
            initialFocusedBlock,
            onKeyDownInBlock,
            onReady
        } = this.props;
        const {
            bounds
        } = this.state;

        const editorConfig = {
            allowStyling: element.options.allowStyling ?? true,
            allowedBlockTypes: element.allowedBlockTypes,
            allowedBlockStyles: [],
            canAddBlocks: element.options.canAddBlocks ?? false, // whether to show the inline add block widget
            canReorderBlocks: true,
            showBlockTypesInMenu: false,
            showBlockStylesInMenu: false,
            showEvenBreak: element.options.allowEvenBreak ?? true,
            showMatchSizes: element.options.syncFontSizeWithSiblings ?? false,
            showTextAlign: element.allowAlignment, // whether to show text alignment in textformat bar
            showVerticalAlign: false,
            showColumns: element.options.allowColumns ?? false,
            showMargins: true,
            showBlockStyles: false,
            showListStyles: element.allowListStyling ?? true,
            syncTextAlignAcrossBlocks: element.options.syncTextAlignAcrossBlocks ?? true,
            showRolloverBlock: true,
            showFocusedBlock: element.options.showFocusedBlock ?? true,
            showAdvancedStylesMenu: element.options.showAdvancedStylesMenu === true,
            canDeleteLastBlock: element.options.canDeleteLastBlock,
            positionWidgetBarWithTextBlock: element.options.positionWidgetBarWithTextBlock,
            showFontSize: element.options.showFontSize ?? false, // whether to use font size or font scaling
            createSubBulletOnEnter: element.options.createSubBulletOnEnter ?? false,
            showFullSpectrumColorPicker: element.isOnAuthoringCanvas
        };

        if (element.getRootElement().type == "DocumentElement") {
            editorConfig.showColumns = true;
            editorConfig.showBlockStyles = true;
            editorConfig.showRolloverBlock = false;
            editorConfig.positionWidgetBarWithTextBlock = true;
        }

        switch (element.blockStructure) {
            case BlockStructureType.SINGLE_BLOCK:
                editorConfig.allowNewLines = element.options.allowNewLines ?? false;
                break;
            case BlockStructureType.FREEFORM:
                editorConfig.showBlockTypesInMenu = true;
                editorConfig.showBlockStylesInMenu = true;
                break;
            case BlockStructureType.TITLE_AND_BODY:
                editorConfig.allowedBlockTypes = [TextStyleType.TITLE, TextStyleType.BODY];
                break;
        }

        return (
            <Container className="authoring-block-editor-container">
                <AuthoringBlockEditor
                    refreshCanvasAndSaveChanges={this.refreshAndSaveCanvas}
                    saveChanges={this.saveChanges}
                    refreshCanvas={this.refreshCanvas}
                    refreshElement={this.refreshElement}
                    bounds={bounds}
                    editorConfig={editorConfig}
                    ref={textEditorRef}
                    initialFocusedBlock={initialFocusedBlock}
                    element={element}
                    scale={element.calculatedProps.textScale}
                    blockStructure={element.blockStructure}
                    onKeyDownInBlock={onKeyDownInBlock}
                    onSelectedBlocksChanged={this.onSelectedBlocksChanged}
                    onReady={onReady}
                    lockSlideForCollaborators={this.lockSlideForCollaborators}
                />
            </Container>
        );
    }
}

const CheckListDecorationRollover = ElementRollover.extend({
    showSelectionBox: false,

    renderControls() {
        let $click = this.$el.addEl($.div());
        $click.css({
            background: "none",
            width: "100%",
            height: "100%",
            pointerEvents: "auto"
        });
        $click.on("click", () => {
            if (app.isEditingText) return;

            switch (this.element.blockModel.checkState) {
                case "unchecked":
                    this.element.blockModel.checkState = "crossed-out";
                    break;
                case "crossed-out":
                    this.element.blockModel.checkState = "checked";
                    break;
                case "checked":
                default:
                    this.element.blockModel.checkState = "unchecked";
                    break;
            }
            this.element.canvas.markStylesAsDirty();
            this.element.canvas.updateCanvasModel(false);
        });
    }
});

export const editors = {
    TextElementSelection,
    CheckListDecorationRollover
};
