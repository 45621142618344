import { ds } from "js/core/models/dataService";
import { $, _ } from "js/vendor";
import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { DragType, WidgetPositionType } from "common/constants";

import { Key } from "js/core/utilities/keys";
import { controls } from "js/editor/ui";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import { ShowWarningDialog, ShowDialogAsync, ShowDialog } from "js/react/components/Dialogs/BaseDialog";

import { CollectionElement, CollectionItemElement } from "../elements/base/CollectionElement";
import { TextElement } from "../elements/base/Text/TextElement";
import { ContentElement } from "../elements/base/ContentElement";
import { ElementRollover, ElementSelection } from "./BaseElementEditor";
import AiGenerationService from "js/core/services/aiGeneration";
import ProgressDialog from "js/react/components/Dialogs/ProgressDialog";

export const CollectionElementSelection = ElementSelection.extend({
    renderControls: function() {
        this.createAddItemButton();
    },

    getAddItemLabel: function() {
        return "Add Item";
    },

    createAddItemButton: function(config = {}) {
        let menuItems = [];

        // Disable ai generation for now
        config.skipAddItemPopup = true;

        if (!config.skipAddItemPopup) {
            menuItems.push({ value: "generate", label: "Add with A.I." });
            menuItems.push({ type: "divider" });
        }

        for (let i = this.element.minItemCount; i <= this.element.maxItemCount; i++) {
            menuItems.push({ value: i, label: `${i}  ${this.getAddItemLabel().replace("Add ", "").pluralize(i != 1)}` });
        }

        this.addControl({
            type: controls.BUTTON,
            label: this.getAddItemLabel(),
            className: config.skipAddItemPopup ? "" : "no-border",
            icon: "add_circle",
            enabled: this.element.maxItemCount ? (this.element.itemCount < this.element.maxItemCount) : true,
            callback: () => {
                this.addItem(config)
                    .then(item => {
                        if (item) {
                            if (config.callback) {
                                config.callback(item);
                            } else if (!config.skipTextSelection) {
                                // this.selectNewItemText(item.id);
                                ds.selection.element = this.element.findChildById(item.id);
                            }
                        }
                    });
            },
        });

        if (!config.skipAddItemPopup) {
            this.addControl({
                type: controls.POPUP_BUTTON,
                showArrow: true,
                items: menuItems,
                callback: value => {
                    if (value == "generate") {
                        const progressDialog = ShowDialog(ProgressDialog, {
                            title: `Generating new item...`,
                        });
                        AiGenerationService.generateNextCollectionItem({ element: this.element }).then(() => {
                            progressDialog.props.closeDialog();
                        });
                    } else {
                        let curCount = this.element.itemCount;
                        ds.selection.element = null;
                        if (value > curCount) {
                            for (let i = 0; i < value - curCount; i++) {
                                this.element.addItem(config.model);
                            }
                        } else if (value < curCount) {
                            for (let i = 0; i < curCount - value; i++) {
                                this.element.deleteItem(_.last(this.element.itemCollection).id);
                            }
                        }

                        config.markStylesAsDirty && this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(config.transition ?? true)
                            .catch(() => {
                                ShowDialogAsync(BadFitDialog, {
                                    title: "Sorry, we aren't able to fit another item to this layout",
                                });
                            });
                    }
                }
            });
        }
    },

    selectNewItemText(path) {
        //TODO this functionality is not working to actually give focus to the block so i am disabling because it causes an issue with clipboard to have the textElement selected but not have focus in a block

        // function findTextItem(children) {
        //     let text = _.find(children, child => {
        //         return child instanceof TextElement && child.canEdit;
        //     });
        //     if (text) {
        //         return text;
        //     } else if (Object.keys(children)) {
        //         _.find(children, child => {
        //             if (child.elements) {
        //                 text = findTextItem(child.elements);
        //                 return text;
        //             }
        //         });
        //         return text;
        //     }
        // }
        //
        // // try to select a new item's textGroup title, otherwise find a new item's first editable textElement
        // // if no text is found, follow above rules recursively
        // // if an editable text element of some sort was found, select it
        // let newItem = _.get(this.element.elements, path);
        // if (newItem && newItem.elements) {
        //     let text = findTextItem(newItem.elements);
        //     if (text) {
        //         ds.selection.element = text;
        //     }
        // }
    },

    addItem: function(options = {}) {
        if (this.element.canvas.layouter.isGenerating) {
            return Promise.resolve();
        }

        options = _.defaults(options, { model: {}, transition: true });

        if (this.element.addItem && (this.element.maxItemCount ? (this.element.itemCount < this.element.maxItemCount) : true)) {
            let newItem = this.element.addItem(options.model || {}, options.index);

            // if the layout is force fit, we need to set the last layout to fit so if the layout it won't allow the user to proceed to bad state
            if (this.element.forceFitLayout) {
                this.element.canvas.layouter.setLastLayoutFit(true);
            }

            ds.selection.element = null;

            options.markStylesAsDirty && this.element.markStylesAsDirty();

            return this.element.canvas.updateCanvasModel(options.transition).then(() => {
                return newItem;
            }).catch(() => {
                ShowDialogAsync(BadFitDialog, {
                    title: "Sorry, we aren't able to fit another item to this layout",
                });
            });
        }
    },

    _handleKeyboardShortcut(event) {
        switch (event.which) {
            case Key.KEY_D:
                this.addItem();
                break;
            default:
                // Let child selection editors handle the event
                ds.selection.element?.overlay?.handleKeyboardShortcut(event);
                break;
        }
    }
});

export const CollectionItemElementSelection = ElementSelection.extend({
    captureMouseEvents: true,
    showDragDropTarget: true,
    dragDistance: 5,
    dropTargetOverlapTreshold: 0.1,

    setup: function(options) {
        this.options = options;
    },

    getTitle: function() {
        return `${this.element.name} #${this.element.itemIndex + 1}`;
    },

    canDrag: function() {
        return this.element.canDrag && this.element.parentElement.itemCollection.length > 1;
    },

    getDragAxis: function() {
        return "";
    },

    getDragOptions: function() {
        return {
            type: DragType.SWAP,
            transitionOnDrop: true
        };
    },

    canDelete: function() {
        return !app.tour && this.element.canDelete && this.element.parentElement.itemCollection.length > this.element.parentElement.minItemCount;
    },

    _handleKeyboardShortcut(event) {
        switch (event.which) {
            case Key.DELETE:
            case Key.BACKSPACE:
                this.canDelete() && this.onDeleteItem();
                break;
        }
    },

    transitionOnDelete: function() {
        return true;
    },

    getOffset: function() {
        return 0;
    },

    onCheckDrag: function(event) {
        // don't start drag if we moused down on an ui button within the draggable element
        if (($(event.target).hasClass("button") && !$(event.target).hasClass("drag_button")) || $(event.target).closest(".control").length || $(event.target).hasClass("micon")) {
            return false;
        }

        // dont start drag when we are editing text or rolling over a text element (where the user can drag-select text)
        if (!$(event.target).hasClass("drag_button") && (app.isEditingText || ds.selection.rolloverElement instanceof TextElement || ds.selection.rolloverElement instanceof ContentElement)) {
            event.preventDefault();
            return false;
        }

        this.recreateOnRefresh = false;
        if (ds.selection.element !== this.element) {
            ds.selection.element = this.element;
        }

        return true;
    },

    render() {
        ElementSelection.prototype.render.apply(this, arguments);

        if (this.canDrag()) {
            this.createDragWidget(this.element, {
                position: this.getDragWidgetPosition()
            });
        }
        this.setupGridDragging();

        // create the item delete button
        if (this.canDelete()) {
            this.createDeleteComponentWidget({
                target: this.element,
                action: () => this.onDeleteItem(),
                position: this.getDeleteButtonPosition()
            });
        }

        return this;
    },

    getDeleteButtonPosition() {
        return WidgetPositionType.DELETE_BUTTON;
    },

    onDeleteItem() {
        let containerElement = this.element.parentElement;
        if (containerElement instanceof CollectionElement && (containerElement.itemCollection.length > containerElement.minItemCount)) {
            containerElement.deleteItem(this.element.id);

            ds.selection.element = null;

            // note: we need to set the forceRender flag on delete so we can get out of layout does not fit states
            this.element.canvas.updateCanvasModel(this.transitionOnDelete(), true);
        } else {
            ShowWarningDialog({
                title: "Sorry, we can't delete this item",
                message: `This smart slide requires at least ${containerElement.minItemCount} ${"item".pluralize(containerElement.minItemCount > 1)}.`,
            });
        }
    },

    onStartDrag: function(event, dragProps, axis) {
        app.isDraggingItem = true;
        ds.selection.rolloverElement = null;
        dragProps.dragItem.isDragging = true;
        dragProps.dragItem.showDragDropTarget = this.showDragDropTarget;
        this.selectionLayer.hideWidgets($(".rollover_hilite, .drag_button"));
    },

    onDrag: async function(event, position, dragProps) {
        dragProps.dragItem.dragPosition = new geom.Point(position.elementPosition.x, position.elementPosition.y);

        this.calcDropTarget(dragProps, position);
        if (!this.canvas.layouter.isGenerating) {
            this.element.canvas.markStylesAsDirty();
            this.element.canvas.refreshElement(this.element, false, true);
        }

        this.options.onDrag && this.options.onDrag();
    },

    getDropTargets: function(containerElement) {
        return containerElement.elements;
    },

    calcDropScore: function(dragBounds, target, position) {
        switch (position.axis) {
            case "x":
                return dragBounds.intersection(target.bounds).width;
            case "y":
                return dragBounds.intersection(target.bounds).height;
            default:
                return dragBounds.intersection(target.bounds).area();
        }
    },

    calcDropOverlap: function(score, dragBounds, dropTarget, dragProps, position) {
        switch (position.axis) {
            case "x":
                return score > dropTarget.offsetBounds.width * this.dropTargetOverlapTreshold || score > dragBounds.width * this.dropTargetOverlapTreshold;
            case "y":
                return score > dropTarget.offsetBounds.height * this.dropTargetOverlapTreshold || score > dragBounds.height * this.dropTargetOverlapTreshold;
            default:
                return score > dropTarget.offsetBounds.area() * this.dropTargetOverlapTreshold || score > dragBounds.area() * this.dropTargetOverlapTreshold;
        }
    },

    calcDropTarget: function(dragProps, position) {
        if (this.canvas.layouter.isGenerating) return;

        let dragOptions = this.getDragOptions();

        let dropTargets = _.map(this.getDropTargets(dragProps.containerElement), el => el);

        if (dragOptions.type === DragType.SWAP) {
            let dragBounds = dragProps.dragItem.offsetBounds;
            dragBounds.left = position.elementPosition.x;
            dragBounds.top = position.elementPosition.y;

            let score = 0;
            let dropTarget;

            for (let target of dropTargets) {
                const overlap = this.calcDropScore(
                    dragBounds,
                    target,
                    position,
                );

                if (overlap > score) {
                    score = overlap;
                    dropTarget = target;
                }
            }

            if (score > 0 && dropTarget !== dragProps.dragItem) {
                this.updateModelFromDropAction(dropTarget, dragProps);
            }
        } else {
            let dragBounds = dragProps.dragItem.canvasBounds;
            dragBounds.left = position.canvasPosition.x + (dragOptions.dragTargetOffsetX ?? 0);
            dragBounds.top = position.canvasPosition.y + (dragOptions.dragTargetOffsetY ?? 0);

            // dragging for variable-height elements in a collection
            // in this case, we just draw a drop indicator instead of recalcing the entire list during drag
            // this avoids the flicker drag effect that occurred when the list was regenerated and the dragged item is suddenly over a new target
            let dropTarget;
            let dropEdge;

            for (let target of dropTargets) {
                // check each dropTarget for simple overlap
                let overlap;
                if (dragOptions.multipleColumns || dragOptions.multipleRows) {
                    // check for target containing dragBounds
                    overlap = target.canvasBounds.contains(dragBounds.position);
                } else {
                    // just check target intersecting dragBounds
                    overlap = target.canvasBounds.intersects(dragBounds);
                }
                if (overlap) {
                    if (dragOptions.type === DragType.INSERT_VERTICAL) {
                        // detect top or bottom edge of drop target based on which column we are in
                        if (dragBounds.position.y < target.canvasBounds.centerV) {
                            let targets = dropTargets.filter(target => dragBounds.position.x >= target.canvasBounds.left && dragBounds.position.x <= target.canvasBounds.right);

                            if (target.itemIndex != null) {
                                if (target === _.minBy(targets, target => target.itemIndex)) {
                                    dropTarget = target;
                                    dropEdge = "top";
                                } else {
                                    dropTarget = dropTargets.find(t => t.itemIndex === target.itemIndex - 1);
                                    dropEdge = "bottom";
                                }
                            } else {
                                dropTarget = target;
                            }
                        } else {
                            dropTarget = target;
                            dropEdge = "bottom";
                        }
                    } else {
                        // detect left or right edge of drop target based on which row we are in
                        if (dragBounds.position.x < target.canvasBounds.centerH) {
                            let targets = dropTargets.filter(target => dragBounds.position.y >= target.canvasBounds.top && dragBounds.position.y <= target.canvasBounds.bottom);

                            if (target.itemIndex != null) {
                                if (target === _.minBy(targets, target => target.itemIndex)) {
                                    dropTarget = target;
                                    dropEdge = "left";
                                } else {
                                    dropTarget = dropTargets.find(t => t.itemIndex === target.itemIndex - 1);
                                    dropEdge = "right";
                                }
                            } else {
                                dropTarget = target;
                            }
                        } else {
                            dropTarget = target;
                            dropEdge = "right";
                        }
                    }
                }
            }

            if (!dropTarget) {
                // if we aren't over a drop target, insert at the beginning or end of the column/row of items
                if (dragOptions.type === DragType.INSERT_VERTICAL) {
                    let targets = dropTargets;
                    if (dragOptions.multipleColumns) {
                        targets = targets.filter(target => dragBounds.position.x >= target.canvasBounds.left && dragBounds.position.x <= target.canvasBounds.right);
                    }
                    let targetsAbove = targets.filter(target => dragBounds.position.y > target.canvasBounds.bottom);

                    dropTarget = _.maxBy(targetsAbove, target => target.itemIndex);
                    dropEdge = "bottom";

                    if (!dropTarget) {
                        dropTarget = _.minBy(targets, target => target.itemIndex);
                        dropEdge = "top";
                    }
                } else {
                    let targets = dropTargets;
                    if (dragOptions.multipleRows) {
                        targets = targets.filter(target => dragBounds.position.y >= target.canvasBounds.top && dragBounds.position.y <= target.canvasBounds.bottom);
                    }
                    let targetsBefore = targets.filter(target => dragBounds.position.x > target.canvasBounds.right);

                    dropTarget = _.maxBy(targetsBefore, target => target.itemIndex);
                    dropEdge = "right";

                    if (!dropTarget) {
                        dropTarget = _.minBy(targets, target => target.itemIndex);
                        dropEdge = "left";
                    }
                }
            }

            this.dropTarget = dropTarget;
            this.dropEdge = dropEdge;

            this.renderInsertDropIndicator(dropTarget, dropEdge);
        }
    },

    renderInsertDropIndicator: function(dropTarget, dropEdge) {
        $(".drop-target-indicator").remove();

        if (dropTarget) {
            let insertGap = this.getDragOptions().insertGap ?? 0;

            // remder the insert drop indicator
            let $dropIndicator = dropTarget.canvas.$el.addEl($.div("drop-target-indicator"));

            let dropTargetBounds = dropTarget.canvasBounds;// dropTarget.getCanvasBounds(dropTarget.marginBounds);

            switch (dropEdge) {
                case "top":
                    $dropIndicator.setBounds(new geom.Rect(dropTargetBounds.left, dropTargetBounds.top - insertGap / 2 - 1, dropTargetBounds.width, 3));
                    break;
                case "bottom":
                    $dropIndicator.setBounds(new geom.Rect(dropTargetBounds.left, dropTargetBounds.bottom + insertGap / 2 - 1, dropTargetBounds.width, 3));
                    break;
                case "left":
                    $dropIndicator.setBounds(new geom.Rect(dropTargetBounds.left - insertGap / 2 - 1, dropTargetBounds.top, 3, dropTargetBounds.height));
                    break;
                case "right":
                    $dropIndicator.setBounds(new geom.Rect(dropTargetBounds.right + insertGap / 2 - 1, dropTargetBounds.top, 3, dropTargetBounds.height));
                    break;
            }
        }
    },

    updateModelFromDropAction: async function(dropTarget, dragProps, insertBeforeTarget) {
        if (!dropTarget) {
            return;
        }

        let dragOptions = this.getDragOptions();

        let dragItem = dragProps.dragItem;
        let dropTargetIndex = dropTarget.itemIndex;

        if (dragOptions.type !== DragType.SWAP) {
            // when drag inserting, calculate the dropTargetIndex if the dragItem is before or after the target index
            if (dropTargetIndex < dragItem.itemIndex || dragItem.parentElement !== dropTarget.parentElement) {
                dropTargetIndex += 1;
            }
            if (insertBeforeTarget) {
                dropTargetIndex -= 1;
            }
        }

        // update the collection model
        dragItem.parentElement.deleteItem(dragItem.id); // remove the dragged item from it's collection
        dropTarget.parentElement.addItem(dragItem.model, dropTargetIndex); // add the dragged item to the droptarget's collection

        this.canvas.markStylesAsDirty();

        if (dragOptions.type === DragType.SWAP) {
            // when using SWAP drag, refresh the container element immediately to reflect the new order
            // INSERT drag does not refresh the container element until drop!
            if (dragProps.containerElement.canRefreshElement) {
                dragProps.containerElement.refreshElement(dragOptions.transitionOnDrop);
            } else {
                await this.canvas.refreshCanvas(dragOptions.transitionOnDrop);
            }
        }
    },

    onStopDrag: function(event, position, dragProps, options = { forceRender: false }) {
        event.stopPropagation();

        this.recreateOnRefresh = false;

        app.isDraggingItem = false;
        let dragOptions = this.getDragOptions();

        if (dragOptions.type !== DragType.SWAP) {
            // When DragType is INSERT, we only update the model on drop here
            if (this.dropTarget && this.dropTarget !== dragProps.dragItem) {
                this.updateModelFromDropAction(this.dropTarget, dragProps, this.dropEdge === "top" || this.dropEdge === "left");
            }
            $(".drop-target-indicator").remove();
            this.dropTarget = null;
        }

        // update the canvas model and save changes
        dragProps.dragItem.isDragging = false;
        dragProps.dragItem.markStylesAsDirty();
        dragProps.dragItem.canvas.updateCanvasModel(dragOptions.transitionOnDrop, options.forceRender).then(() => {
            ds.selection.element = null;
            ds.selection.rolloverElement = this.element;
            ds.selection.element = this.element;
            this.selectionLayer.showWidgets();

            ds.selection.element.overlay && ds.selection.element.overlay.layout();
            this.options.onStopDrag && this.options.onStopDrag();
        });
    },

    setupGridDragging: function() {
        if (this.canDrag()) {
            let dragProps = {
                dragItem: this.element.findClosestOfType(CollectionItemElement),
                containerElement: this.element.findClosestOfType(CollectionElement)
            };

            this.$el.makeDraggable({
                element: dragProps.dragItem,
                axis: this.getDragAxis(),
                dragDistance: 5,
                check: event => this.onCheckDrag(event),
                start: (event, axis) => this.onStartDrag(event, dragProps, axis),
                drag: (event, position) => this.onDrag(event, position, dragProps),
                stop: (event, position) => this.onStopDrag(event, position, dragProps),
            });
        }
    },

});

const CollectionItemElementRollover = ElementRollover.extend({
    showSelectionBox: true
});

export const editors = {
    CollectionItemElementRollover
};
