import * as geom from "js/core/utilities/geom";

import { OrgChartNode } from "./OrgChartNode";
import { BlockStructureType, HorizontalAlignType, TextStyleType } from "common/constants";
import { TextElement } from "../../base/Text/TextElement";

export class BoxOrgChartNode extends OrgChartNode {
    _build() {
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            allowedBlockTypes: [TextStyleType.TITLE, TextStyleType.BODY],
            autoWidth: false,
            autoHeight: true,
            requireParentSelection: true,
            passThroughSelection: false,
            allowAlignment: true,
            syncFontSizeWithSiblings: true,
            getSiblings: () => {
                return this.getRootElement().findChildElements("TextElement");
            }
        });
    }

    _loadStyles(styles) {
        super._loadStyles(styles);

        if (this.model.textAlign) {
            styles.text.textAlign = this.model.textAlign;
        } else {
            if (this.parentElement.layoutDirection == "horizontal") {
                styles.text.textAlign = HorizontalAlignType.LEFT;
            } else {
                styles.text.textAlign = HorizontalAlignType.CENTER;
            }
        }
    }

    _calcProps(props, options) {
        const { size } = props;
        if (this.parentElement.isRowSmall(this.rowIndex) && this.styles.small) {
            this.updateStyles(this.styles.small, true);
        }

        const textProps = this.text.calcProps(new geom.Size(size.width, size.height), options);

        if (options.fillHeight) {
            return { size: new geom.Size(textProps.size.width, size.height) };
        }

        return { size: textProps.size };
    }
}
