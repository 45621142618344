import { controls } from "js/editor/ui";
import { _, $ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import renderReactRoot from "js/react/renderReactRoot";
import {
    ListStyleType, PositionType,
    TextStyleType
} from "common/constants";

import { CollectionElementSelection, CollectionItemElementSelection } from "../../CollectionElementEditor";
import { ElementOptionsMenu } from "../../BaseElementEditor";
import { ImageFrameMenu } from "../../EditorComponents/ImageFrameMenu";
import { createResizer } from "../../EditorComponents/Resizer";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";

export const TextListSelection = CollectionElementSelection.extend({
    renderControls() {
        this.addControl({
            type: controls.BUTTON,
            label: "Add Item",
            className: "",
            icon: "add_circle",
            enabled: this.element.textColumnsCount > 0 || this.element.maxItemCount > this.element.itemCollection.length,
            callback: () => {
                const lastColumn = _.last(this.element.itemElements.filter(element => !element.isMediaColumn));
                if (!lastColumn) {
                    this.element.model.autoArrangeColumnsCount = this.element.itemCollection.length + 1;
                    this.addItem()
                        .then(() => {
                            ds.selection.element = this.element.itemElements[this.element.itemElements.length - 1];
                        });
                    return;
                }

                // Get the last root block
                let lastBlock = _.last(lastColumn.text.textModel.blocks.filter(x => x.indent === 0));

                lastColumn.text.addBlock({
                    textStyle: TextStyleType.BULLET_LIST,
                    listStyle: lastBlock.listStyle,
                    listDecorationStyle: lastBlock.listDecorationStyle,
                    useThemedListDecoration: lastBlock.useThemedListDecoration
                });

                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "List Style",
            markStylesAsDirty: true,
            items: [{
                label: "Bullet",
                value: ListStyleType.BULLET,
                icon: "format_list_bulleted"
            }, {
                label: "Numbered",
                value: ListStyleType.NUMBERED,
                icon: "pin"
            }, {
                label: "Text",
                value: ListStyleType.TEXT,
                icon: "notes"
            }, {
                label: "Check",
                value: ListStyleType.CHECKBOX,
                icon: "check"
            }, {
                label: "Icon",
                value: ListStyleType.ICON,
                icon: "star"
            }],
            callback: value => {
                this.element.allBlockModels
                    .filter(block => block.listStyle && !block.indent)
                    .forEach(block => {
                        block.listStyle = value;
                    });
                this.element.model.listStyle = value;
                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Auto-arrange",
            items: [{
                value: 1, label: "One Column"
            }, {
                value: 2, label: "Two Columns"
            }, {
                value: 3, label: "Three Columns"
            }].slice(0, this.element.maxTextColumnsCount),
            value: this.element.textColumnsCount,
            callback: value => {
                try {
                    this.element.distributeBullets(value);
                } catch (err) {
                    ShowErrorDialog({ title: "Sorry, we couldn't make this change", message: err });
                    this.element.canvas.revertCanvasModel();
                    return;
                }
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const TextListOptionsMenu = ElementOptionsMenu.extend({
    renderControls() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Column Headers",
            property: "showColumnHeaders",
        });

        this.addControl({
            type: controls.NUMERIC,
            label: "Start Numbering at",
            min: 1,
            max: 999,
            property: "startNum",
            enabled: !this.element.model.showColumnHeaders
        });
    }
});

const TextListColumnSelection = CollectionItemElementSelection.extend({
    showSelectionBox: false,
    getDragAxis() {
        return "x";
    },
    canDrag() {
        return false;
    },
    canDelete() {
        return false;
    }
});

const TextListMediaColumnSelection = CollectionItemElementSelection.extend({
    showSelectionBox: true,
    getDragAxis() {
        return "x";
    },
    canDrag() {
        return false;
    },
    canDelete() {
        return false;
    },

    renderControls() {
        if (this.element.parentElement.textColumnsCount > 0) {
            this.renderResizer();
        }
    },

    renderResizer: function() {
        let resizerProps = {
            view: this,
            position: this.element.itemIndex < (this.element.itemCount - 1) ? PositionType.RIGHT : PositionType.LEFT,
            minSize: 100,
            maxSize: 1000,
            element: this.element,
            scaleFromCenter: this.element.itemCount > 2 && this.element.itemIndex > 0 && this.element.itemIndex < (this.element.itemCount - 1),
            offset: 0,
            dividerSize: 300,
            handleType: "tray",
            onResize: value => {
                this.element.model.size = value;
                this.element.canvas.refreshCanvas();
            }
        };
        createResizer(resizerProps, this.selectionLayer);
    },
});

export const editors = {
    TextListSelection,
    TextListColumnSelection,
    TextListMediaColumnSelection,
    TextListOptionsMenu
};
