import React, { Component } from "react";

import perf from "js/core/utilities/perf";
import { breakLines } from "js/core/utilities/linebreak";
import { $, _ } from "js/vendor";
import {
    AuthoringBlockType,
    HorizontalAlignType,
    BlockStyleType,
    TextStyleType
} from "common/constants";
import { reactMount, reactUnmount } from "js/react/renderReactRoot";
import * as geom from "js/core/utilities/geom";
import { app } from "js/namespaces";
import Cache from "common/utils/Cache";
import { computeChangeSet } from "common/utils/changeset";
import getObjectHash from "common/utils/getObjectHash";
import getLogger, { LogGroup } from "js/core/logger";

import { AuthoringBlockContainer } from "../../elements/authoring/AuthoringBlockContainer";
import { getListDecorationSpacing } from "../../elements/authoring/AuthoringBlocks/TextBlock";
import { sanitizeHtml } from "js/core/utilities/dompurify";

const logger = getLogger(LogGroup.ELEMENTS);

const DEBUG_CACHE = false;

const calcAuthoringBlockPropsCache = new Cache(DEBUG_CACHE ? null : 10);

export function clearAuthoringBlockPropsCache() {
    calcAuthoringBlockPropsCache.clear(true);
}

export function calculateEvenBreakTextWidth(blockProps, allowedWidth) {
    perf.start("calculateEvenBreakTextWidth");

    // Clone lines to ensure mutation safety
    const lines = blockProps.lines.map(line => line.map(({ word, bounds }) => ({ word, bounds: bounds.clone() })));

    // Add leading whitespace width to all words
    lines.forEach((line, lineIdx) =>
        line.forEach((word, wordIdx) => {
            if (wordIdx === 0) {
                if (lineIdx === 0) {
                    word.leadingWhitespaceWidth = 0;
                } else {
                    word.leadingWhitespaceWidth = blockProps.whitespaceWidth;
                }
                return;
            }

            const prevWord = line[wordIdx - 1];
            word.leadingWhitespaceWidth = word.bounds.left - prevWord.bounds.right;
        })
    );

    // Flatten and add whitespace width to word width
    const words = _.flatten(lines).map(({ word, bounds, leadingWhitespaceWidth }) => ({ value: word, width: bounds.width + leadingWhitespaceWidth }));

    // Calculate layout
    const brokenLines = breakLines(words, allowedWidth, { goalWidth: allowedWidth });
    if (brokenLines.length === 1) {
        return -1;
    }

    const maxLineLength = _.maxBy(brokenLines.map(lines => _.sumBy(lines, word => words.find(w => w.value === word).width)));

    perf.stop("calculateEvenBreakTextWidth");

    return maxLineLength;
}

function calcFontMetrics(font) {
    if (!app.fontMetrics) {
        app.fontMetrics = {};
    }

    if (!app.fontMetrics[font]) {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        ctx.font = `100px ${font}`;
        ctx.imageSmoothingEnabled = false;

        const fontSize = 100;

        // use canvas to measure the height of the "H" character
        const textMetrics = ctx.measureText("H");
        const fontHeight = textMetrics.actualBoundingBoxAscent / fontSize;

        $(".baseline-font-checker").remove();

        // use DOM text with a baseline vertical-aligned div to measure the baseLine height
        const $tempText = $("body").addEl($.div("baseline-font-checker", "H").css({
            fontFamily: font,
            fontSize,
            position: "absolute",
            background: "white",
            top: 0,
            zIndex: 10000,
            userSelect: "text"
        }));
        const $marker = $tempText.addEl($.div("marker"));

        const baseline = $marker[0].offsetTop;
        const middleLineHeight = (baseline - fontHeight * fontSize / 2) / fontSize;

        const middleOfText = fontSize * middleLineHeight;
        const topSpace = middleOfText - fontHeight * fontSize / 2;
        const bottomSpace = fontSize - (middleOfText + fontHeight * fontSize / 2);

        const range = document.createRange();
        range.selectNodeContents($tempText[0]);
        const rangeBounds = range.getBoundingClientRect();

        const middleOfRange = rangeBounds.height * middleLineHeight;
        const rangeTopSpace = middleOfRange - fontHeight * fontSize / 2;
        const rangeBottomSpace = rangeBounds.height - (middleOfRange + fontHeight * fontSize / 2);

        const normalizedTopSpaceMultiplier = rangeTopSpace / topSpace;
        const normalizedBottomSpaceMultiplier = rangeBottomSpace / bottomSpace;

        app.fontMetrics[font] = {
            fontHeight,
            middleLineHeight,
            normalizedTopSpaceMultiplier,
            normalizedBottomSpaceMultiplier,
            letterWidth: rangeBounds.width / fontSize
        };

        $tempText.remove();
    }

    return app.fontMetrics[font];
}

class TempBlockContainer extends Component {
    state = {
        element: null,
        blockProps: [],
        model: {},
        blockSpacing: 0,
        columns: 1,
        columnGap: 0,
        textAlign: HorizontalAlignType.LEFT
    }

    constructor() {
        super();

        this.authoringBlockContainerRef = React.createRef();
    }

    render() {
        const { element, blockProps, model, blockSpacing, columns, columnGap, textAlign } = this.state;

        return (<AuthoringBlockContainer
            ref={this.authoringBlockContainerRef}
            element={element}
            model={model}
            blockSpacing={blockSpacing}
            blockProps={blockProps}
            columns={columns}
            columnGap={columnGap}
            isCalculatingLayout={true}
            textAlign={textAlign}
        />);
    }
}

function getTempContainer(forceReset = false) {
    if (!app.tempBlockContainer) {
        const ref = React.createRef();

        const $tempNode = $("body").addEl($.div("temp-text")).css({
            position: "absolute",
            top: 0,
            left: 0,
            width: 300,
            background: "blue",
            zIndex: 10000,
            opacity: 0,
            visibility: "hidden",
            pointerEvents: "none"
        });

        reactMount(<TempBlockContainer ref={ref} />, $tempNode[0]);

        app.tempBlockContainer = {
            $el: $tempNode,
            ref
        };

        return app.tempBlockContainer;
    }

    if (forceReset) {
        reactUnmount(app.tempBlockContainer.$el[0]);
        app.tempBlockContainer.$el.remove();
        app.tempBlockContainer = null;

        return getTempContainer();
    }

    return app.tempBlockContainer;
}

export function buildBlockProps(element, model, scale, canvas, blockSpacing) {
    const blockProps = model.blocks.map((blockModel, index) => {
        const block = {
            id: blockModel.id,
            type: blockModel.type,
            element,
            model: blockModel,
            canvas,
            scale,
            sequenceNum: 0,
            blockSpacing,
            placeholder: element.getPlaceholderForBlock(blockModel)
        };

        block.index = index;
        block.isFirstBlock = block.index == 0;
        block.isLastBlock = block.index == model.blocks.length - 1;

        if (element.blockElements) {
            block.blockElement = element.blockElements[blockModel.id];
            if (block.blockElement) {
                if (block.model.backgroundColor) {
                    block.blockElement.getBackgroundColor = () => {
                        return element.canvas.getTheme().palette.getColor(block.model.backgroundColor);
                    };
                }

                block.canResize = !block.model.autoHeight;
            }
        }

        if (element.listDecorations) {
            block.listDecorationElement = element.listDecorations[blockModel.id];
        }

        if (block.type == AuthoringBlockType.DIVIDER) {
            block.canResize = true;
        }

        if (blockModel.type == AuthoringBlockType.TEXT) {
            block.textStyle = element.getTextStyleForBlock(blockModel);

            //  get styles for this block
            let textStyles = element.getTextStylesForBlock(blockModel.id);

            // load or calc font metrics
            let fontMetrics = calcFontMetrics(textStyles.fontFamily);

            // textStyles.fontSize = Math.round(textStyles.fontSize * scale);
            textStyles.fontSize = textStyles.fontSize * scale;

            // calculate actual font height of "H" character at fontsize
            block.fontHeight = fontMetrics.fontHeight * textStyles.fontSize;

            // NOTE: Prior to using HTML text, we calculated lineHeight by multiplying by the fontHeight (ie. the height of an "H" character)
            // Because there are theme models and styles that will have this "incorrect" lineHeight, we are adjusting
            // the pre-HTML lineHeight to the post-HTML lineHeight value
            textStyles.lineHeight = blockModel.trueLineHeight ?? ((textStyles.lineHeight * block.fontHeight) / textStyles.fontSize);

            // calculate the middle of the font text
            block.middleOfText = textStyles.fontSize * fontMetrics.middleLineHeight + ((textStyles.lineHeight - 1) * textStyles.fontSize / 2);

            // calculate space above and below the H character in a line at the given lineHeight
            block.topSpace = block.middleOfText - block.fontHeight / 2;
            block.bottomSpace = (textStyles.fontSize * textStyles.lineHeight) - (block.middleOfText + block.fontHeight / 2);

            // normalized middleof text and spacing values (used for word bounds calculation)
            block.normalizedMiddleOfText = textStyles.fontSize * fontMetrics.middleLineHeight;
            // 0.22 is an empirically derived coefficient to compensate for the artificial selection range height increase
            block.normalizedTopSpace = block.normalizedMiddleOfText - block.fontHeight / 2;
            block.normalizedTopSpace *= fontMetrics.normalizedTopSpaceMultiplier;
            block.normalizedBottomSpace = textStyles.fontSize - (block.normalizedMiddleOfText + block.fontHeight / 2);
            block.normalizedBottomSpace *= fontMetrics.normalizedBottomSpaceMultiplier;

            // rough estimation of a letter width, will be used for checking text fit
            block.letterWidth = fontMetrics.letterWidth * textStyles.fontSize;

            block.fontMetrics = fontMetrics;
            block.textStyles = textStyles;

            block.hyphenation = textStyles.evenBreak ? false : (model.hyphenation ?? true);

            block.calcWordBounds = (textStyles.maxLines && textStyles.maxLines < Number.POSITIVE_INFINITY) || !!textStyles.evenBreak || !!element.options?.calcWordBounds;
        }

        return block;
    });

    const getBlockStyle = block => block.model.blockStyle ?? BlockStyleType.NONE;

    let currentIndentWidth;
    let regionBlock;

    // Calculating margins
    for (let block of blockProps) {
        if (block.type === AuthoringBlockType.TEXT) {
            // calculate indent width
            block.indent = block.model.indent ?? 0;

            if (block.indent == 0) {
                block.indentWidth = 0;
                currentIndentWidth = getListDecorationSpacing(block);
            } else {
                block.indentWidth = currentIndentWidth + (block.indent - 1) * block.textStyles.bulletIndent;
            }
        }

        let margins = calcBlockMargins({ block, allBlocks: blockProps, scale });
        block.blockMargin = margins.blockMargins;
        block.additionalBlockSpacing = margins.additionalBlockSpacing;
        block.blockSpacing = margins.blockSpacing;
    }

    // Nomarlize margins
    blockProps.forEach((block, index) => {
        const nextBlock = blockProps[index + 1];

        if (nextBlock) {
            nextBlock.blockMargin.top += block.blockMargin.bottom;
            block.blockMargin.bottom = 0;
        }
    });

    // Calculating sequence nums
    let indentSequenceNums = {};
    blockProps.forEach(block => {
        const indent = block.model.indent ?? 0;
        let sequenceNum;
        if (block.model.textStyle === TextStyleType.BULLET_LIST) {
            // increment the sequence for any bullet list block
            if (!indentSequenceNums[indent]) {
                indentSequenceNums[indent] = sequenceNum = element.getNumberedBulletsStartSequenceNum(indent);
            } else {
                indentSequenceNums[indent]++;
                sequenceNum = indentSequenceNums[indent];
            }
        } else if (block.model.textStyle === TextStyleType.SECTION) {
            // do nothing for section blocks
        } else {
            // reset the sequence for any non bullet or section block
            indentSequenceNums = {};
        }

        // clear any sequence numbers nested below this block
        for (let key in Object.keys(indentSequenceNums)) {
            if (parseInt(key) > indent) {
                indentSequenceNums[key] = null;
            }
        }

        block.indent = indent;
        block.sequenceNum = sequenceNum;
    });

    return blockProps;
}

// calculate the margins for a block
// note: this function can either take block models or block props objects
export function calcBlockMargins({ block, allBlocks, scale }) {
    let index = allBlocks.indexOf(block);
    const prevBlock = allBlocks[index - 1];
    const nextBlock = allBlocks[index + 1];

    if (block.type === AuthoringBlockType.REGION_START || block.type === AuthoringBlockType.REGION_END) {
        return;
    }

    let marginTop = 0;
    let marginBottom = 0;
    let marginLeft = 0;
    let marginRight = 0;
    let additionalBlockSpacing = 0;
    let blockSpacing = 0;

    if (block.type === AuthoringBlockType.TEXT) {
        marginTop -= block.topSpace ?? 0;
        marginBottom -= block.bottomSpace ?? 0;

        if (index !== allBlocks.length - 1 && nextBlock.textStyle != block.textStyle) {
            marginBottom += block.textStyles?.spaceBelow ?? 0;
        }

        if (index > 0) {
            let spacing = block.textStyles.blockSpacing ?? 0;

            // add block-specific additional spacing
            additionalBlockSpacing = (block.spaceAbove ?? block.model?.spaceAbove ?? 0);

            // if sequence of same block textStyles, use any defined paragraphSpacing to override blockSpacing
            if (prevBlock.textStyle === block.textStyle &&
                prevBlock.type === AuthoringBlockType.TEXT &&
                ((prevBlock.indent > 0 && block.indent > 0) || block.indent == 0)
            ) {
                spacing = block.textStyles.paragraphSpacing ?? spacing;
                // scale the style-driven blockSpacing by the block's fontScale
                spacing *= block.textStyles.fontScale ?? 1;
            } else {
                additionalBlockSpacing += (block.textStyles.spaceAbove ?? 0);
            }

            blockSpacing = spacing;

            spacing += additionalBlockSpacing;

            marginTop += spacing * scale;
        }
    } else if (index > 0) {
        marginTop += block.blockSpacing * scale;
    }

    if (prevBlock && prevBlock.type == AuthoringBlockType.REGION_START) {
        marginTop = 0;
    }
    if (nextBlock && nextBlock.type == AuthoringBlockType.REGION_END) {
        marginBottom = 0;
    }

    return { blockMargins: { top: marginTop, bottom: marginBottom, left: marginLeft, right: marginRight }, additionalBlockSpacing, blockSpacing };
}

function calcTextBlockLines(blockNode, blockProps) {
    perf.start("calcTextBlockLines");

    const blockBounds = geom.Rect.FromBoundingClientRect(blockNode.getBoundingClientRect());

    // Calculate the width of the actual text (not the container)
    if (blockNode.textContent !== "") {
        const walker = document.createTreeWalker(blockNode, NodeFilter.SHOW_TEXT, null, false);

        const range = document.createRange();

        const words = [];

        let currentWordStartNode;
        let currentWordStartIndex;
        let currentWordEndNode;
        let currentWordEndIndex;
        let currentWordText = "";
        let currentWordStartsWithLineBreak = false;
        let prevCharBounds;

        const getCurrentWordBounds = () => {
            range.setStart(currentWordStartNode, currentWordStartIndex);
            range.setEnd(currentWordEndNode, currentWordEndIndex + 1);
            return geom.Rect.FromBoundingClientRect(range.getBoundingClientRect()).offset(-blockBounds.left, -blockBounds.top);
        };

        const getCurrentCharBounds = (currentNode, charIndex) => {
            range.setStart(currentNode, charIndex);
            range.setEnd(currentNode, charIndex + 1);
            return geom.Rect.FromBoundingClientRect(range.getBoundingClientRect()).offset(-blockBounds.left, -blockBounds.top);
        };

        const isNewLine = (prevBounds, currentBounds) => {
            const threshold = prevBounds.height / 3;
            return currentBounds.bottom - prevBounds.bottom > threshold;
        };

        const terminateCurrentWord = () => {
            if (currentWordText !== "") {
                const fullWordBounds = getCurrentWordBounds();
                if (currentWordStartsWithLineBreak) {
                    if (currentWordText.length > 1) {
                        // Hack!
                        currentWordStartIndex++;
                        const shortenWordBounds = getCurrentWordBounds();
                        fullWordBounds.top = shortenWordBounds.top;
                        fullWordBounds.height = shortenWordBounds.height;
                        if (fullWordBounds.right > shortenWordBounds.right) {
                            fullWordBounds.width -= fullWordBounds.right - shortenWordBounds.right;
                        }
                    } else {
                        // Dummy hack!
                        fullWordBounds.height = fullWordBounds.height / 2;
                        fullWordBounds.top += fullWordBounds.height;
                    }
                }

                fullWordBounds.top += blockProps.normalizedTopSpace ?? 0;
                fullWordBounds.height -= (blockProps.normalizedTopSpace ?? 0) + (blockProps.normalizedBottomSpace ?? 0);

                words.push({ word: currentWordText, bounds: fullWordBounds });
            }

            currentWordStartNode = null;
            currentWordStartIndex = null;
            currentWordEndNode = null;
            currentWordEndIndex = null;
            currentWordStartsWithLineBreak = false;
            prevCharBounds = null;
            currentWordText = "";
        };

        let currentNode = walker.firstChild();
        while (currentNode) {
            for (let charIndex = 0; charIndex < currentNode.textContent.length; charIndex++) {
                const char = currentNode.textContent[charIndex];

                if (/[\s\-]/.test(char)) {
                    terminateCurrentWord();
                    continue;
                }

                if (blockProps.hyphenation) {
                    const currentCharBounds = getCurrentCharBounds(currentNode, charIndex);
                    if (prevCharBounds) {
                        if (isNewLine(prevCharBounds, currentCharBounds)) {
                            terminateCurrentWord();
                            currentWordStartsWithLineBreak = true;
                        }
                    }
                    prevCharBounds = currentCharBounds;
                }

                if (!currentWordStartNode) {
                    currentWordStartNode = currentNode;
                    currentWordStartIndex = charIndex;
                }
                currentWordEndNode = currentNode;
                currentWordEndIndex = charIndex;

                currentWordText += char;
            }

            // Force-terminate word when node ends
            terminateCurrentWord();

            currentNode = walker.nextNode();
        }

        terminateCurrentWord();

        // Determine lines by words bounds
        let currentLine = [];
        words.forEach((word, wordIdx) => {
            if (wordIdx === 0) {
                currentLine.push(word);
                return;
            }

            const prevWord = words[wordIdx - 1];
            if (isNewLine(prevWord.bounds, word.bounds)) {
                blockProps.lines.push(currentLine);
                currentLine = [word];
                return;
            }

            currentLine.push(word);
        });

        blockProps.lines.push(currentLine);

        // Now calculate whitespace width (needed for calculating even break layout)
        const innerHTML = blockNode.innerHTML;
        blockNode.innerHTML = "&nbsp;";
        range.selectNodeContents(blockNode);
        blockProps.whitespaceWidth = range.getBoundingClientRect().width;
        blockNode.innerHTML = sanitizeHtml(innerHTML);
    }

    perf.stop("calcTextBlockLines");
}

let cacheStats = {};
if (DEBUG_CACHE) {
    window.getCacheStats = (forElementPath = null, hiliteCacheKey = null) => {
        const printableStats = {};

        Object.entries(cacheStats).forEach(([elementPath, blocksCacheStats]) => {
            if (forElementPath && elementPath !== forElementPath) {
                return;
            }

            printableStats[elementPath] = {};

            Object.entries(blocksCacheStats).forEach(([blockId, blockCacheStats]) => {
                printableStats[elementPath][blockId] = {};

                Object.entries(blockCacheStats).forEach(([cacheKey, blockObject]) => {
                    const scale = `${blockObject.scale}`;
                    if (!printableStats[elementPath][blockId][scale]) {
                        printableStats[elementPath][blockId][scale] = {};
                    }

                    Object.entries(blockCacheStats)
                        .filter(([otherCacheKey, otherBlockObject]) => otherCacheKey !== cacheKey && otherBlockObject.scale === blockObject.scale)
                        .forEach(([otherCacheKey, otherBlockObject]) => {
                            if (printableStats[elementPath][blockId][scale][`${otherCacheKey}<->${cacheKey}`]) {
                                return;
                            }

                            printableStats[elementPath][blockId][scale][`${cacheKey}<->${otherCacheKey}`] = computeChangeSet(blockObject, otherBlockObject);
                            if (cacheKey === hiliteCacheKey) {
                                printableStats[elementPath][blockId][scale][`${cacheKey}<->${otherCacheKey}`].HILITED = true;
                            }
                        });
                });
            });
        });

        // eslint-disable-next-line no-console
        console.log(JSON.stringify(printableStats, null, 2));
    };

    window.resetCacheStats = () => {
        cacheStats = {};
    };
}

function getBlockPropsCacheKey(builtBlocksProps, width, columns, columnGap, height, autoWidth, textAlign) {
    const cachingObjects = builtBlocksProps.map(blockProps => ({
        ...blockProps,
        scale: blockProps.scale ?? 1,
        slideId: blockProps.element.canvas.dataModel.id,
        slideLayout: blockProps.element.canvas.dataModel.dataState.layout,
        canvas: undefined,
        element: undefined,
        elementUniquePath: blockProps.element.uniquePath ?? "none",
        isTryingLayout: blockProps.element.isTryingLayout,
        blockElement: undefined,
        blockElementUniquePath: blockProps.blockElement?.uniquePath,
        listDecorationElement: undefined,
        listDecorationElementUniquePath: blockProps.listDecorationElement?.uniquePath,
        width,
        columns,
        columnGap,
        height,
        autoWidth,
        textAlign
    }));

    if (DEBUG_CACHE) {
        cachingObjects.forEach(object => {
            if (!cacheStats[object.elementUniquePath]) {
                cacheStats[object.elementUniquePath] = {};
            }
            if (!cacheStats[object.elementUniquePath][object.id]) {
                cacheStats[object.elementUniquePath][object.id] = {};
            }

            cacheStats[object.elementUniquePath][object.id][getObjectHash(object, true)] = _.cloneDeep(object);
        });
    }

    return getObjectHash(cachingObjects, true);
}

export function calcAuthoringBlockProps(
    { element, model, width, scale, canvas, blockSpacing, columns, columnGap, height, autoWidth, textAlign },
    initialCacheKey = null,
    widestBlockId = null
) {
    perf.start("calcAuthoringBlockProps");

    const props = buildBlockProps(element, model, scale, canvas, blockSpacing);

    perf.start("calcAuthoringBlockProps:getBlockPropsCacheKey");
    const cacheKey = initialCacheKey ?? getBlockPropsCacheKey(props, width, columns, columnGap, height, autoWidth, textAlign);
    perf.stop("calcAuthoringBlockProps:getBlockPropsCacheKey");

    const cache = initialCacheKey ? null : calcAuthoringBlockPropsCache.get(cacheKey);
    if (DEBUG_CACHE && !initialCacheKey) {
        // eslint-disable-next-line no-console
        console.log(`calcAuthoringBlockPropsCache key: ${cacheKey} hit: ${!!cache} hitCount: ${calcAuthoringBlockPropsCache.hitCount} missCount: ${calcAuthoringBlockPropsCache.missCount}`);
        if (!cache) {
            window.getCacheStats(element.uniquePath, cacheKey);
        }
    }
    if (cache) {
        // We have to remount models and block elements because they're stored in cache and aren't cloned and not mutation-safe
        cache.blockProps.forEach((block, idx) => {
            block.model = props[idx].model;
            block.blockElement = props[idx].blockElement;
            block.listDecorationElement = props[idx].listDecorationElement;
        });

        perf.stop("calcAuthoringBlockProps", "cached");
        return cache;
    }

    let tempBlock;
    const prepareTempBlock = (forceReset = false) => {
        tempBlock = getTempContainer(forceReset);

        tempBlock.$el.width(width);

        // height is only provided when multi-column because we need to have a defined height for column wrapping
        if (height) {
            tempBlock.$el.height(height);
        } else {
            tempBlock.$el.height("auto"); //reset to auto height
        }

        // set state on the temp block to update with our block props
        tempBlock.ref.current.setState({
            blockProps: props,
            model,
            element,
            scale,
            blockSpacing,
            columns,
            columnGap,
            textAlign
        });
    };

    try {
        prepareTempBlock();
    } catch (err) {
        // This can happen if we set incorrect state on the temp block which broke the React element so tempBlock.ref.current.setState() fails,
        // so we'll remount the React tree and try again
        logger.error(err, "calcAuthoringBlockProps() error preparing temp block, will try again with fresh temp block...");
        prepareTempBlock(true);
    }

    let tempBlockBounds;
    let containerSize;

    const calcBlockBoundsAndLayout = isBlockBoundsRecalc => {
        let needsRecalc = false;

        tempBlockBounds = geom.Rect.FromBoundingClientRect(tempBlock.$el[0].getBoundingClientRect());
        containerSize = tempBlockBounds.size.clone();

        for (const blockProps of props) {
            const block = tempBlock.ref.current.authoringBlockContainerRef.current.blockRefs[blockProps.model.id].current;
            const blockNode = block.ref.current;

            blockProps.bounds = geom.Rect.FromBoundingClientRect(blockNode.getBoundingClientRect());
            blockProps.containerBounds = blockProps.bounds.clone();

            if (blockProps.type === AuthoringBlockType.TEXT) {
                const range = document.createRange();

                const placeholderNode = blockNode.parentElement.querySelector(".text-block-placeholder");
                if (placeholderNode) {
                    range.selectNodeContents(placeholderNode);
                    blockProps.textBounds = geom.Rect.FromBoundingClientRect(range.getBoundingClientRect());

                    // Override block bounds so they correctly match the bounds of the placeholder
                    blockProps.bounds = geom.Rect.FromBoundingClientRect(placeholderNode.getBoundingClientRect());
                    blockProps.containerBounds = blockProps.bounds.clone();
                } else {
                    range.selectNodeContents(blockNode);
                    blockProps.textBounds = geom.Rect.FromBoundingClientRect(range.getBoundingClientRect());
                }

                // Account for paddings (used for lists)
                const paddingLeft = parseFloat((blockNode.style.getPropertyValue("padding-left") ?? "0px").replace("px", ""));
                const paddingRight = parseFloat((blockNode.style.getPropertyValue("padding-right") ?? "0px").replace("px", ""));
                blockProps.textBounds = blockProps.textBounds.inflate({ left: paddingLeft, top: 0, right: paddingRight, bottom: 0 });

                blockProps.textBounds.top += blockProps.normalizedTopSpace ?? 0;
                blockProps.textBounds.height -= (blockProps.normalizedTopSpace ?? 0) + (blockProps.normalizedBottomSpace ?? 0);

                blockProps.bounds.top += blockProps.topSpace ?? 0;
                blockProps.bounds.height -= (blockProps.topSpace ?? 0) + (blockProps.bottomSpace ?? 0);

                blockProps.spansColumns = blockProps.bounds.width > blockNode.offsetWidth * 1.5;

                blockProps.lines = [];
                if (blockProps.calcWordBounds) {
                    calcTextBlockLines(blockNode, blockProps);

                    if (!isBlockBoundsRecalc && blockProps.textStyles.evenBreak && blockProps.id !== widestBlockId) {
                        const evenBreakTextWidth = calculateEvenBreakTextWidth(blockProps, width);

                        if (evenBreakTextWidth > 0) {
                            const blockTextAlign = blockProps.textStyles.textAlign ?? element.textAlign ?? HorizontalAlignType.LEFT;

                            switch (blockTextAlign) {
                                case HorizontalAlignType.LEFT:
                                    blockProps.blockPadding = `0px ${Math.max(0, (containerSize.width - evenBreakTextWidth))}px 0px 0px`;
                                    break;
                                case HorizontalAlignType.CENTER:
                                    blockProps.blockPadding = `0px ${Math.max(0, (containerSize.width - evenBreakTextWidth) / 2)}px`;
                                    break;
                                case HorizontalAlignType.RIGHT:
                                    blockProps.blockPadding = `0px 0px 0px ${Math.max(0, (containerSize.width - evenBreakTextWidth))}px`;
                                    break;
                            }

                            needsRecalc = true;
                        }
                    }
                }
            }
        }

        return needsRecalc;
    };

    const needsRecalc = calcBlockBoundsAndLayout(false);
    if (needsRecalc) {
        tempBlock.ref.current.forceUpdate();
        calcBlockBoundsAndLayout(true);
    }

    for (const blockProps of props) {
        delete blockProps.ref;
    }

    const maxBlockWidth = props.reduce((maxBlockWidth, block) => Math.max((block.textBounds ?? block.bounds).width, maxBlockWidth), 0) ?? tempBlockBounds.width;

    if (autoWidth) {
        containerSize.width = maxBlockWidth;
    } else {
        containerSize.width = Math.max(
            maxBlockWidth,
            containerSize.width
        );
    }

    if (autoWidth && Math.ceil(containerSize.width) !== Math.ceil(tempBlockBounds.width)) {
        // Mark the widest block so we don't calc even break for it
        let widestBlockId;
        const widestTextBlockProps = props
            .filter(blockProps => !!blockProps.textBounds)
            .find(blockProps => !props
                .filter(otherBlockProps => otherBlockProps !== blockProps)
                .filter(otherBlockProps => !!otherBlockProps.textBounds)
                .some(otherBlockProps => otherBlockProps.textBounds.width > blockProps.textBounds.width)
            );
        if (widestTextBlockProps) {
            widestBlockId = widestTextBlockProps.id;
        }

        // Force recalc so we correctly calculate block bounds for the new width
        return calcAuthoringBlockProps({
            width: Math.ceil(containerSize.width),
            autoWidth: false,
            element,
            model,
            scale,
            canvas,
            blockSpacing,
            columns,
            columnGap,
            height
        }, cacheKey, widestBlockId);
    }

    const blocksOuterBounds = props.reduce((blocksOuterBounds, block) => blocksOuterBounds ? blocksOuterBounds.union(block.textBounds ?? block.bounds) : (block.textBounds ?? block.bounds), null) ?? tempBlockBounds;

    // Make sure there's no vertical overflow
    containerSize.height = Math.max(
        containerSize.height,
        blocksOuterBounds.height
    );

    // Bounds that are shrunk by some margins to be used for checking if text is fit the given size,
    // this accounts for measured text ranges being slightly wider than they actually is on Safari
    const blocksOverflowBounds = props.reduce((blocksOverflowBounds, block) => {
        let paddedBlockBounds;

        if (block.type === AuthoringBlockType.TEXT) {
            paddedBlockBounds = block.textBounds.deflate({
                // 1/2 letter width in total
                left: block.letterWidth / 4,
                right: block.letterWidth / 4,
                // 1px in total
                top: 0.5,
                bottom: 0.5
            });
        } else {
            paddedBlockBounds = block.bounds;
        }

        return blocksOverflowBounds ? paddedBlockBounds.union(blocksOverflowBounds) : paddedBlockBounds;
    }, null) ?? tempBlockBounds;

    // Adjust block bounds to respect horizontal alignment
    for (const blockProps of props) {
        if (blockProps.type === AuthoringBlockType.TEXT) {
            // Text block container if always full width
            continue;
        }

        if (blockProps.bounds.width !== containerSize.width) {
            // Positioning block
            const blockTextAlign = element.textAlign ?? HorizontalAlignType.LEFT;
            if (blockTextAlign === HorizontalAlignType.LEFT) {
                blockProps.bounds.left = 0;
            } else if (blockTextAlign === HorizontalAlignType.RIGHT) {
                blockProps.bounds.left = containerSize.width - blockProps.bounds.width;
            } else {
                blockProps.bounds.left = (containerSize.width - blockProps.bounds.width) / 2;
            }

            blockProps.containerBounds = blockProps.bounds.clone();
        }
    }

    const result = {
        // We have to ceil the container size to make sure we don't have wrapping on safari
        // on displays with pixel density > 1 due to sizes rounding
        containerSize: new geom.Size(Math.ceil(containerSize.width), Math.ceil(containerSize.height)),
        blocksOverflowSize: blocksOverflowBounds.size,
        blockProps: props
    };

    calcAuthoringBlockPropsCache.set(cacheKey, result);

    perf.stop("calcAuthoringBlockProps", "non-cached");

    return result;
}

if (import.meta.webpackHot) {
    const onWebpackReload = status => {
        if (status === "prepare") {
            calcAuthoringBlockPropsCache.clear();
        }
    };

    import.meta.webpackHot.addStatusHandler(onWebpackReload);
}
