import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { MenuItem } from "@material-ui/core";
import hljs from "highlight.js/lib/core";

import { FlexBox } from "js/react/components/LayoutGrid";
import { _ } from "js/vendor";
import { Key } from "js/core/utilities/keys";
import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";
import { PopupMenu } from "js/react/components/PopupMenu";

import { BoundsBox, ReverseScaleBox } from "../../SelectionBox";
import { ControlBar, ControlBarGroup } from "../../../EditorComponents/ControlBar";
import { stopEventPropagation } from "../AuthoringHelpers";
import { CodeBlockStyles } from "../../../../elements/elements/authoring/AuthoringBlocks/CodeBlock";
import { BlockTypePopupMenu } from "./TextFormatBar";

const LANGUAGE_MAP = {};

import c from "highlight.js/lib/languages/c";

hljs.registerLanguage("c", c);
LANGUAGE_MAP["c"] = "C";

import cpp from "highlight.js/lib/languages/cpp";

hljs.registerLanguage("cpp", cpp);
LANGUAGE_MAP["cpp"] = "C++";

import csharp from "highlight.js/lib/languages/csharp";

hljs.registerLanguage("csharp", csharp);
LANGUAGE_MAP["csharp"] = "C#";

import css from "highlight.js/lib/languages/css";

hljs.registerLanguage("css", css);
LANGUAGE_MAP["css"] = "CSS";

import go from "highlight.js/lib/languages/go";

hljs.registerLanguage("go", go);
LANGUAGE_MAP["go"] = "Go";

import xml from "highlight.js/lib/languages/xml";

hljs.registerLanguage("xml", xml);
LANGUAGE_MAP["xml"] = "HTML/XML";

import javascript from "highlight.js/lib/languages/javascript";

hljs.registerLanguage("javascript", javascript);
LANGUAGE_MAP["javascript"] = "Javascript";

import java from "highlight.js/lib/languages/java";

hljs.registerLanguage("java", java);
LANGUAGE_MAP["java"] = "Java";

import json from "highlight.js/lib/languages/json";

hljs.registerLanguage("json", json);
LANGUAGE_MAP["json"] = "JSON";

import lua from "highlight.js/lib/languages/lua";

hljs.registerLanguage("lua", lua);
LANGUAGE_MAP["lua"] = "Lua";

import objectivec from "highlight.js/lib/languages/objectivec";

hljs.registerLanguage("objectivec", objectivec);
LANGUAGE_MAP["objectivec"] = "Objective-C";

import php from "highlight.js/lib/languages/php";

hljs.registerLanguage("php", php);
LANGUAGE_MAP["php"] = "PHP";

import python from "highlight.js/lib/languages/python";

hljs.registerLanguage("python", python);
LANGUAGE_MAP["python"] = "Python";

import ruby from "highlight.js/lib/languages/ruby";

hljs.registerLanguage("ruby", ruby);
LANGUAGE_MAP["ruby"] = "Ruby";

import scala from "highlight.js/lib/languages/scala";

hljs.registerLanguage("scala", scala);
LANGUAGE_MAP["scala"] = "Scala";

import sql from "highlight.js/lib/languages/sql";

hljs.registerLanguage("sql", sql);
LANGUAGE_MAP["sql"] = "SQL";

import swift from "highlight.js/lib/languages/swift";

hljs.registerLanguage("swift", swift);
LANGUAGE_MAP["swift"] = "Swift";

import typescript from "highlight.js/lib/languages/typescript";

hljs.registerLanguage("typescript", typescript);
LANGUAGE_MAP["typescript"] = "TypeScript";

import vbnet from "highlight.js/lib/languages/vbnet";
import { themeColors } from "js/react/sharedStyles";

hljs.registerLanguage("vbnet", vbnet);
LANGUAGE_MAP["vbnet"] = "VB.net";

const EditorContents = styled.div`
  width: 100%;
  height: 100%;
  background: none;
  pointer-events: auto;

  .code {
    font-family: monospace;
    line-height: 1.5em;
    white-space: pre-wrap;
    border: none;
  }

  textarea {
    position: absolute;
    top: 20px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    resize: none;
    color: #666;
    margin: 0px;
    padding: 0px;
    overflow: hidden;
    outline: none;
    background: transparent;
  }

  pre {
    margin: 0px;
    position: absolute;
    pointer-events: none;
    background: transparent;
    letter-spacing: 0px;
  }

  code {
    font-family: monospace;
  }

  .hljs {
    background: transparent !important;
    padding: 0px;
  }
`;

const StyledReverseScaleBox = styled(ReverseScaleBox)`
    background: white;
`;

export class CodeBlockEditor extends Component {
    constructor(props) {
        super(props);

        this.textRef = React.createRef();

        this.state = {
            value: props.block.model.value,
            html: props.block.model.html,
            language: props.block.model.language || "javascript",
            style: props.block.model.style || "light",
            textSize: props.block.model.textSize || "medium"
        };
    }

    componentDidMount() {
        // Remove current selection
        window.getSelection().removeAllRanges();
    }

    handleKeydown = event => {
        event.stopPropagation();

        const { value } = this.state;

        switch (event.which) {
            case Key.TAB:
                // handle 4-space indent on TAB
                const selStartPos = event.currentTarget.selectionStart;
                const newValue = value.substring(0, selStartPos) + "    " + value.substring(selStartPos, value.length);
                event.currentTarget.selectionStart = selStartPos + 3;
                event.currentTarget.selectionEnd = selStartPos + 4;
                event.preventDefault();
                event.stopPropagation();
                this.setState({ value: newValue });
                this.formatCode(this.state.language, newValue);
                break;
        }
    }

    updateModel = updates => {
        const { block, refreshCanvasAndSaveChanges } = this.props;

        Object.entries(updates).forEach(([key, value]) => {
            block.model[key] = value;
        });

        refreshCanvasAndSaveChanges();
    }

    handleChangeCode = event => {
        const { language } = this.state;

        this.formatCode(language, event.target.value);
    }

    formatCode = (language, value) => {
        let html = "";
        if (value) {
            const formattedCode = hljs.highlight(language, value, true);
            html = formattedCode.value;
            if (value.charCodeAt(value.length - 1) == 10) {
                html += "<br>";
            }
        }

        this.setState({ value, html, language });
        this.updateModel({ value, html, language });
    }

    handleChangeStyle = style => {
        this.setState({ style });
        this.updateModel({ style });
    }

    handleChangeLanguage = language => {
        const { value } = this.state;

        this.formatCode(language, value);
    }

    handleChangeTextSize = textSize => {
        this.setState({ textSize });
        this.updateModel({ textSize });
    }

    render() {
        const { bounds, containerBounds, block, editorConfig, refreshCanvasAndSaveChanges, element } = this.props;
        const { value, html, style, language, textSize } = this.state;

        const sizes = {
            "small": "Small",
            "medium": "Medium",
            "large": "Large"
        };
        const styles = {
            "light": "Light",
            "dark": "Dark"
        };

        return (
            <Fragment>
                <BoundsBox
                    showOutline
                    bounds={bounds}
                >
                    {/* inner div reverse scales contenteditable so that selection layer coordinate-space matches canvas */}
                    <StyledReverseScaleBox
                        bounds={bounds}
                        canvasScale={element.canvas.getScale()}
                    >
                        <FlexBox fill middle {...stopEventPropagation} onMouseUp={() => { }}>
                            <EditorContents>
                                <CodeBlockStyles className={`style-${style}`}>
                                    <textarea
                                        ref={this.textRef}
                                        className={`code ${textSize}`}
                                        spellCheck={false}
                                        onInput={this.handleChangeCode}
                                        onKeyDown={this.handleKeydown}
                                        onChange={() => { }}
                                        value={value ?? html}
                                    />
                                    <pre className={`code hljs ${textSize}`}>
                                        <code dangerouslySetInnerHTML={{ __html: sanitizeHtmlText(html) }} />
                                    </pre>
                                </CodeBlockStyles>
                            </EditorContents>
                        </FlexBox>
                    </StyledReverseScaleBox>
                    <ControlBar bounds={containerBounds} position="above" offset={10}>
                        <ControlBarGroup color={themeColors.darkBlue}>
                            <BlockTypePopupMenu
                                selectedBlocks={[block]}
                                allowedBlockTypes={editorConfig.allowedBlockTypes}
                                onChange={refreshCanvasAndSaveChanges}
                            />
                        </ControlBarGroup>
                        <ControlBarGroup>
                            <PopupMenu label={LANGUAGE_MAP[language]} onChange={this.handleChangeLanguage} showArrow
                                childrenAreMenuItems>
                                {Object.entries(LANGUAGE_MAP).map(([value, label]) => (<MenuItem key={value}
                                    value={value}>{label}</MenuItem>))}
                            </PopupMenu>
                            <PopupMenu label={styles[style]} onChange={this.handleChangeStyle} showArrow
                                childrenAreMenuItems>
                                {Object.entries(styles).map(([value, label]) => (<MenuItem key={value}
                                    value={value}>{label}</MenuItem>))}
                            </PopupMenu>
                            <PopupMenu label={sizes[textSize]} onChange={this.handleChangeTextSize} showArrow
                                childrenAreMenuItems>
                                {Object.entries(sizes).map(([value, label]) => (<MenuItem key={value}
                                    value={value}>{label}</MenuItem>))}
                            </PopupMenu>
                        </ControlBarGroup>
                    </ControlBar>`
                </BoundsBox>
            </Fragment>
        );
    }
}
