import equal from "fast-deep-equal/es6";

import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { AuthoringElementType, AuthoringShapeType } from "common/constants";
import { createHash } from "js/core/utilities/utilities";
import { ds } from "js/core/models/dataService";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { AuthoringShapeElement } from "./authoring/AuthoringShape";
import { AuthoringContentElement } from "./authoring/AuthoringContent";
import { AuthoringPathElement } from "./authoring/AuthoringPath";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { AuthoringSvgElement } from "./authoring/AuthoringSvg";
import { AuthoringRadialBar } from "./authoring/AuthoringRadialBar";
import { AuthoringVideo } from "./authoring/AuthoringVideo";
import { BaseElement } from "../base/BaseElement";

export class AuthoringCanvas extends CollectionElement {
    static get schema() {
        return {
            snapToGrid: false,
            elements: [],
        };
    }

    get collectionPropertyName() {
        return "elements";
    }

    get isOnAuthoringCanvas() {
        return true;
    }

    get minItemCount() {
        return 0;
    }

    get snapToGrid() {
        return !!this.model.snapToGrid;
    }

    get gridSpacing() {
        return 20;
    }

    get padding() {
        return 40;
    }

    get disableAllAnimationsByDefault() {
        return true;
    }

    get canRefreshElement() {
        return true;
    }

    get clipboardElement() {
        return this;
    }

    get canPasteImage() {
        return true;
    }

    refreshElement(transition, requireFit = false) {
        this.canvas.refreshElement(this, transition, true, requireFit);
    }

    getDefaultElementSize(elementType) {
        return new geom.Size(200, 200);
    }

    getCanvasMargins() {
        return { left: 0, top: 0, right: 0, bottom: 0 };
    }

    getChildItemType() {
        return AuthoringElementContainer;
    }

    resetUserColors() {
        return false;
    }

    _calcProps(props, options) {
        const { size } = props;

        this.itemElements.forEach((element, elementIndex) => {
            // Only calc props on authoring elements that have had their model changed - not everything on the authoring canvas
            if (!equal(element.model, element.calculatedProps?.calculatedModel)) {
                const { x, y, width, height } = element.model;

                const sizeForCalcProps = new geom.Size(width, height);
                if (element.childElement.fitToContents?.width && element.childElement.needsFullSizeToCalcFit?.width) {
                    sizeForCalcProps.width = size.width;
                }
                if (element.childElement.fitToContents?.height && element.childElement.needsFullSizeToCalcFit?.height) {
                    sizeForCalcProps.height = size.height;
                }

                const elementProps = element.calcProps(sizeForCalcProps);

                if (element.childElement.fitToContents?.width) {
                    if (element.childElement.fitToContentsAnchor && element.childElement.fitToContentsAnchor.width === geom.AnchorType.RIGHT) {
                        const widthDiff = element.model.width - elementProps.size.width;
                        element.model.x += widthDiff;
                    }

                    element.model.width = elementProps.size.width;
                }
                if (element.childElement.fitToContents?.height) {
                    if (element.childElement.fitToContentsAnchor && element.childElement.fitToContentsAnchor.height === geom.AnchorType.BOTTOM) {
                        const heightDiff = element.model.height - elementProps.size.height;
                        element.model.y += heightDiff;
                    }

                    element.model.height = elementProps.size.height;
                }

                elementProps.bounds = new geom.Rect(x, y, elementProps.size);
                elementProps.rotate = element.model.rotation;
                elementProps.layer = elementIndex;
                elementProps.calculatedModel = _.cloneDeep(element.model);
            }
        });

        return { size };
    }

    getAnimations() {
        const animations = super.getAnimations();

        // Combining animations of grouped elements
        const groupedAnimations = [];
        let groupIdx = 1;
        animations.forEach(animation => {
            const groupId = animation.element?.groupId;

            // Element not in a group
            if (!groupId) {
                groupedAnimations.push(animation);
                return;
            }

            // Already grouped
            if (groupedAnimations.some(groupedAnimation => groupedAnimation.element?.groupId === groupId)) {
                return;
            }

            const groupAnimations = animations.filter(animation => animation.element?.groupId === groupId);
            // Only one element in group
            if (groupAnimations.length === 1) {
                groupedAnimations.push(animation);
                return;
            }

            const groupAnimationElementIds = groupAnimations.map(({ element }) => element.uniquePath).sort();
            groupedAnimations.push({
                ...animation,
                id: createHash(groupAnimationElementIds.join("|")),
                element: new AuthoringElementsGroup({ id: groupId, parentElement: this, canvas: this.canvas, options: { groupId } }),
                elementId: groupId,
                elementName: `Group #${groupIdx}`,
                animatingElements: groupAnimations.map(({ element }) => element),
                name: "Animate in",
                prepare: () => groupAnimations.filter(({ prepare }) => !!prepare).forEach(({ prepare }) => prepare()),
                onBeforeAnimationFrame: progress => {
                    const elements = groupAnimations
                        .filter(({ onBeforeAnimationFrame }) => !!onBeforeAnimationFrame)
                        .map(({ onBeforeAnimationFrame }) => onBeforeAnimationFrame(progress));
                    if (elements.some(element => !!element)) {
                        return this;
                    }
                },
                finalize: () => groupAnimations.filter(({ finalize }) => !!finalize).forEach(({ finalize }) => finalize())
            });

            groupIdx++;
        });

        return groupedAnimations;
    }

    _exportToSharedModel() {
        const childElements = Object.values(this.elements).map(containerEl => Object.values(containerEl.elements)).flat();
        return childElements.reduce((model, el) => _.mergeWith(model, el.exportToSharedModel(), (a, b) => {
            if (_.isArray(a)) return a.concat(b);
        }), {});
    }

    _importFromSharedModel(model) {
        const childElements = Object.values(this.elements).map(containerEl => Object.values(containerEl.elements)).flat();
        return childElements.map(element => element.importFromSharedModel(model));
    }
}

/**
 * Fake element class representing a group of authoring elements
 */
class AuthoringElementsGroup extends BaseElement {
    get groupId() {
        return this.options.groupId;
    }
}

class AuthoringElementContainer extends CollectionItemElement {
    static get schema() {
        return {
            isLocked: false,
            shadow: {},
            opacity: 100
        };
    }

    get isAuthoringElement() {
        return true;
    }

    get canSelect() {
        if (ds.selection.element?.isChildOf(this)) {
            return true;
        }

        return this.model.type !== AuthoringElementType.COMPONENT;
    }

    get canSelectChildren() {
        return this.canSelect;
    }

    get allowDecorationStyles() {
        return false;
    }

    get lockAspectRatio() {
        return this.childElement.lockAspectRatio ?? false;
    }

    get preserveAspectRatioOnCornerResize() {
        return this.childElement.preserveAspectRatioOnCornerResize ?? false;
    }

    get groupId() {
        if (this.model.groupIds.length === 0) {
            return null;
        }

        return this.model.groupIds[0];
    }

    set groupId(value) {
        if (value) {
            this.model.groupIds.unshift(value);
        } else {
            this.model.groupIds.shift();
        }
    }

    get isLocked() {
        return !!this.model.isLocked;
    }

    get canChangeDirection() {
        return !!this.childElement.canChangeDirection;
    }

    get canDelete() {
        return false;
    }

    isChildOf(element) {
        if (element instanceof AuthoringElementsGroup) {
            return this.groupId === element.groupId;
        }

        return super.isChildOf(element);
    }

    onResize(boundsBeforeResize) {
        if (this.childElement.onResize) {
            return this.childElement.onResize(boundsBeforeResize);
        }
    }

    getSelectionElement() {
        return this.childElement;
    }

    get name() {
        switch (this.model.type) {
            case AuthoringElementType.SHAPE:
                return this.childElement?.shape?.toTitleCase();
            case AuthoringElementType.CONTENT:
                return this.childElement.model.content_type?.toTitleCase() || "Content";
            default:
                return this.model?.type?.toTitleCase();
        }
    }

    getElementFactory() {
        switch (this.model.type) {
            case AuthoringElementType.SHAPE:
                return () => AuthoringShapeElement;
            case AuthoringElementType.PATH:
                return () => AuthoringPathElement;
            case AuthoringElementType.CONTENT:
                return () => AuthoringContentElement;
            case AuthoringElementType.COMPONENT:
                if (this.model.componentType) {
                    return () => this.canvas.elementManager.get(this.model.componentType);
                } else {
                    return () => AuthoringShapeElement;
                }
            case AuthoringElementType.HEADER:
                return () => Header;
            case AuthoringElementType.FOOTER:
                return () => Footer;
            case AuthoringElementType.SVG:
                return () => AuthoringSvgElement;
            case AuthoringElementType.RADIAL_BAR:
                return () => AuthoringRadialBar;
            case AuthoringElementType.VIDEO:
                return () => AuthoringVideo;
        }

        throw new Error(`Unknown element type ${this.model.type}, model: ${JSON.stringify(this.model)}`);
    }

    containsPoint(pt, isSelected) {
        switch (this.model.type) {
            case AuthoringElementType.SHAPE:
            case AuthoringElementType.PATH:
                if (this.model.shape !== AuthoringShapeType.RAW_SVG) {
                    return this.childElement.containsPoint(pt, isSelected);
                }

            default:
                return this.bounds.contains(pt);
        }
    }

    _build() {
        if (!this.model.groupIds) {
            this.model.groupIds = [];
        }

        this.childElement = this.addElement("childElement", this.getElementFactory(), {
            // NOTE: this.model.element may be undefined, in this case element and container will be sharing model
            model: this.model.element,
        });
    }

    getHTMLFilter() {
        switch (this.model.shadow) {
            case "drop":
                return "drop-shadow(3px 5px 6px rgba(0,0,0,.4)";
            case "soft":
                return "drop-shadow(0px 0px 10px rgba(0,0,0,.4)";
            case "block":
                return "drop-shadow(10px 10px 0px rgba(0,0,0,.5";
            default:
                if (this.model.shadow.hasOwnProperty("color") && this.model.shadow.color != "rgba(0, 0, 0, 0)") {
                    return `drop-shadow(${this.model.shadow.x}px ${this.model.shadow.y}px ${this.model.shadow.blur}px ${this.model.shadow.color})`;
                }
        }
    }

    _calcProps(props, options) {
        const { size } = props;

        const elementProps = this.childElement.calcProps(size);
        elementProps.bounds = new geom.Rect(0, 0, elementProps.size);

        return {
            size: elementProps.bounds.size,
            opacity: _.defaultTo(this.model.opacity, 100) / 100,
        };
    }

    get animationElementName() {
        return `${this.model.type.charAt(0).toUpperCase()}${this.model.type.slice(1)} #${this.itemIndex + 1}`;
    }

    get animateChildren() {
        return this.model.type === AuthoringElementType.PATH;
    }

    _getAnimations() {
        if (this.model.type === AuthoringElementType.PATH) {
            return [];
        }

        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }
}

export const elements = {
    AuthoringCanvas
};

