import React from "reactn";
import styled from "styled-components";
import { Icon, MenuItem, MenuList, Slider, Tooltip } from "@material-ui/core";

import { ds } from "js/core/models/dataService";
import { AssetType, ContentFrameType } from "common/constants";
import { _, tinycolor } from "js/vendor";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";
import { PopupMenu, PopupMenuPaddedContainer } from "js/react/components/PopupMenu";
import { LabeledContainer } from "js/react/components/LabeledContainer";
import { ColorPicker } from "js/react/components/ColorPicker";

import { ControlBar } from "../../EditorComponents/ControlBar";
import { GetValueFromSelection } from "./AuthoringHelpers";
import { ShadowEditor } from "./Components/ShadowEditor";
import { AuthoringBaseEditor } from "./AuthoringBaseEditor";
import { ImageFrameMenu } from "../../EditorComponents/ImageFrameMenu";
import { mergeMediaElementModelDefaults } from "common/assetUtils";

const CenterFrame = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BlueBox = styled.div`
  box-sizing: border-box;
  background: #11a9e2;
  color: white;
  padding: 10px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
`;

export class AuthoringContentEditor extends AuthoringBaseEditor {
    constructor() {
        super();

        this.controlBarRef = React.createRef();
    }

    get authoringLayer() {
        const { selection } = this.props;
        return selection[0]?.canvas?.layouter?.canvasElement?.elements?.primary?.overlay?.authoringRef?.current;
    }

    setSelectionState() {
        super.setSelectionState();
        this.setState({
            frameType: this.getElementValue("frameType"),
            frameColor: this.getElementValue("frameColor") || "none",
            framePadding: this.getElementValue("framePadding") || 0
        });
    }

    handleChooseAsset = () => {
        const { selection } = this.props;

        const element = selection[0];
        const contentElement = element.childElement.content;
        const containerModel = element.model;
        const contentModel = contentElement.model;

        ShowDialog(AddAssetsContainer, {
            assetType: contentElement.defaultAssetType,
            workspaceId: ds.selection.presentation.getWorkspaceId(),
            backgroundVideoOnly: true,
            callback: model => {
                const {
                    content_value,
                    assetProps: {
                        originalSize,
                    },
                } = model;

                mergeMediaElementModelDefaults(
                    contentModel,
                    model,
                );
                contentModel[contentElement.bindTo] = content_value;

                contentElement.canvas.updateCanvasModel(false);
            }
        });
    }

    handleAdjustImage = () => {
        ds.selection.element = this.props.selection[0].childElement.content.assetElement;
    }

    handleChangeColor = color => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            element.childElement.model.color = color;
        }

        refreshCanvasAndSaveChanges();
    }

    // attempts to undo any sizing changes from a previous frame change
    tryRevertFrameChange = (containerElement, axis, prop) => {
        const revertProp = `_revertFrameChange${axis.toUpperCase()}`;

        // nothing to revert
        if (!(revertProp in containerElement.model)) {
            return;
        }

        const {
            [prop]: original,
            [revertProp]: revert
        } = containerElement.model;

        // scale back to the original size
        containerElement.model[prop] *= revert;

        // calculate the positional difference and shift it back
        // to the correct spot
        const offset = (original - containerElement.model[prop]) / 2;
        containerElement.model[axis] += offset;

        // remove the revert prop
        delete containerElement.model[revertProp];
    }

    adjustForFrame = containerElement => {
        const frameType = containerElement.childElement.model.frameType || "none";
        let frameAspectRatio = containerElement.childElement.model.frameAspectRatio || null;

        // NOTE: This revert might have to be reworked now that 'startingWidth' and
        //   'startingHeight' come from 'calculatedProps' instead of 'containerElement'

        // check if there's a frame change scaling value
        this.tryRevertFrameChange(containerElement, "x", "width");
        this.tryRevertFrameChange(containerElement, "y", "height");

        if (frameType.equalsAnyOf("none", "light", "dark")) {
            // resize container to image aspect ratio
            const contentElement = containerElement.childElement.content;
            if (contentElement.assetElement.mediaSize) {
                const aspectRatio = contentElement.assetElement.mediaSize.aspectRatio;
                if (aspectRatio > 1) {
                    const newWidth = containerElement.model.height * aspectRatio;
                    containerElement.model.x += (containerElement.model.width - newWidth) / 2;
                    containerElement.model.width = newWidth;
                } else {
                    const newHeight = containerElement.model.width / aspectRatio;
                    containerElement.model.y += (containerElement.model.height - newHeight) / 2;
                    containerElement.model.height = newHeight;
                }
                contentElement.model.aoiLeft = contentElement.model.aoiRight = contentElement.model.aoiTop = contentElement.model.aoiBottom = contentElement.model.scale = null;
            }
        } else {
            // grab the size before changing the frame
            const { width: startingWidth, height: startingHeight } = containerElement.calculatedProps.bounds;

            // if no aspect ratio was detected, determine it now
            if (!frameAspectRatio) {
                frameAspectRatio = Math.min(
                    startingHeight / startingWidth,
                    startingWidth / startingHeight
                );
            }

            // resize container to frame aspectRatio instead of image
            const width = containerElement.model.height * frameAspectRatio;
            containerElement.model.x = containerElement.model.x + startingWidth / 2 - width / 2;
            containerElement.model.width = width;

            // when changing the frame, if the width or height changed at all, save a value
            // to use when reverting
            const revertX = startingWidth / containerElement.model.width;
            if (revertX !== 1) {
                containerElement.model._revertFrameChangeX = revertX;
            }

            const revertY = startingHeight / containerElement.model.height;
            if (revertY !== 1) {
                containerElement.model._revertFrameChangeY = revertY;
            }
        }
    }

    handleChangeFrame = (frameType, frameAspectRatio) => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            // set the frame model
            element.childElement.model.frameType = frameType;
            element.childElement.model.frameAspectRatio = frameAspectRatio;

            this.adjustForFrame(element);
        }

        this.setState({ frameType });

        refreshCanvasAndSaveChanges();
    }

    handleChangeFrameColor = frameColor => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            element.childElement.model.frameColor = frameColor;
        }
        this.setState({ frameColor });
        refreshCanvasAndSaveChanges();
    }

    handleChangeFrameDecorationStyle = decorationStyle => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            element.childElement.model.decorationStyle = decorationStyle;
        }
        refreshCanvasAndSaveChanges();
    }

    handleChangeFramePadding = framePadding => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            element.childElement.model.framePadding = framePadding;
        }
        this.setState({ framePadding });
        refreshCanvasAndSaveChanges();
    }

    handleChangeVideoMuted = event => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { content } = element.childElement;
            if (content) {
                content.model.assetProps.muted = !event.target.checked;
            }
        }
        refreshCanvasAndSaveChanges();
    }

    handleChangeVideoAutoPlay = event => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { content } = element.childElement;
            const props = content?.model?.assetProps;
            if (props) {
                props.autoPlay = event.target.checked;
                content.elements.asset.togglePlayback({ value: props.autoPlay });
            }
        }
        refreshCanvasAndSaveChanges();
    }

    handleChangeVideoLoop = event => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { content } = element.childElement;
            if (content) {
                content.model.assetProps.loop = event.target.checked;
            }
        }
        refreshCanvasAndSaveChanges();
    }

    handleChangeVideoControls = event => {
        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { content } = element.childElement;
            if (content) {
                const value = event.currentTarget.checked;

                const { asset } = content.elements;
                asset.toggleControls({ value, refresh: false });
            }
        }
        refreshCanvasAndSaveChanges();
    }

    handleChangeVideoTime = (value, save = true) => {
        const [
            startTime,
            endTime,
        ] = value;

        const { selection, refreshCanvasAndSaveChanges, refreshElement } = this.props;

        for (const element of selection) {
            const { content } = element.childElement;
            if (content) {
                content.model.assetProps.startTime = startTime;
                content.model.assetProps.endTime = endTime;
            }
        }

        if (save) {
            refreshCanvasAndSaveChanges();
        } else {
            refreshElement();
        }
    }

    handleEditorPlayback = event => {
        event.stopPropagation();
        event.preventDefault();

        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { content } = element.childElement;
            if (content) {
                const { asset } = content.elements;
                asset.togglePlayback();
            }
        }

        refreshCanvasAndSaveChanges();
    }

    handleEditorAudio = event => {
        event.stopPropagation();
        event.preventDefault();

        const { selection, refreshCanvasAndSaveChanges } = this.props;

        for (const element of selection) {
            const { content } = element.childElement;
            if (content) {
                const { asset } = content.elements;
                asset.toggleAudio();
            }
        }

        refreshCanvasAndSaveChanges();
    }

    render() {
        const { selection, editingElement, isTemplate } = this.props;
        const { shadow, opacity, rotation } = this.state;

        const selectedAssetType = GetValueFromSelection(selection, "childElement.content.assetType");

        const element = selection[0];
        const slideColor = element.canvas.getTheme().palette.getColor(element.canvas.getSlideColor()) ?? tinycolor("black");
        const { content } = element.childElement;

        if (selection.length > 0 && editingElement === selection[0]) {
            this.handleAdjustImage();
        }

        let settingsMenu = (
            <PopupMenu icon="settings">
                <PopupMenuPaddedContainer>
                    <LabeledContainer icon="lens_blur" label="Shadow">
                        <ShadowEditor shadow={shadow} onChange={shadow => this.setContainerValues({ shadow })}/>
                    </LabeledContainer>
                    <LabeledContainer icon="opacity" label="Opacity">
                        <Slider
                            value={opacity}
                            onChange={(event, value) => this.setContainerValues({ opacity: value }, false)}
                            onChangeCommitted={(event, value) => this.setContainerValues({ opacity: value })}
                            valueLabelDisplay="auto"
                            min={0}
                            max={100}
                        />
                    </LabeledContainer>
                </PopupMenuPaddedContainer>
            </PopupMenu>
        );

        switch (selectedAssetType) {
            case AssetType.IMAGE:
            case AssetType.LOGO:
                return (
                    <ControlBar ref={this.controlBarRef}>
                        {selection.length == 1 &&
                            <Tooltip title="Adjust Image" arrow>
                                <PopupMenu icon="photo_camera" childrenAreMenuItems>
                                    <MenuList>
                                        <MenuItem onClick={this.handleChooseAsset}>
                                            <Icon>add_a_photo</Icon>
                                            Replace Image...
                                        </MenuItem>
                                        <MenuItem onClick={this.handleAdjustImage}>
                                            <Icon>crop</Icon>
                                            Adjust Image
                                        </MenuItem>
                                    </MenuList>
                                </PopupMenu>
                            </Tooltip>
                        }
                        <PopupMenu icon="filter_frames">
                            <ImageFrameMenu
                                selectedFrame={element.childElement.model.frameType}
                                selectedStyle={element.childElement.model.decorationStyle}
                                onSelect={this.handleChangeFrame}
                                onSelectStyle={this.handleChangeFrameDecorationStyle}
                            />
                        </PopupMenu>
                        {content.model.frameType != "none" &&
                            <ColorPicker
                                showAuto
                                showColorPicker
                                slideColor={slideColor}
                                color={element.childElement.model.color ?? this.getElementValue("color")}
                                onChange={this.handleChangeColor}
                                onChangeDecorationStyle={style => this.handleChangeFrameDecorationStyle(style)}
                            />
                        }
                    </ControlBar>
                );
            case AssetType.ICON:
                return (
                    <ControlBar ref={this.controlBarRef}>
                        {(selection.length == 1) &&
                            <Tooltip title="Adjust Icon" arrow>
                                <PopupMenu icon="photo_camera" childrenAreMenuItems>
                                    <MenuList>
                                        <MenuItem onClick={this.handleChooseAsset}>
                                            <Icon>add_a_photo</Icon>
                                            Replace Icon...
                                        </MenuItem>
                                    </MenuList>
                                </PopupMenu>
                            </Tooltip>
                        }
                        <PopupMenu icon="filter_frames">
                            <ImageFrameMenu
                                selectedFrame={element.childElement.model.frameType}
                                selectedStyle={element.childElement.model.decorationStyle}
                                onSelect={this.handleChangeFrame}
                                onSelectStyle={this.handleChangeFrameDecorationStyle}
                            />
                        </PopupMenu>
                        <ColorPicker
                            showAuto
                            showColorPicker
                            slideColor={slideColor}
                            color={element.childElement.model.color ?? this.getElementValue("color")}
                            onChange={this.handleChangeColor}
                        />
                        <Slider
                            style={{ width: 100 }}
                            value={this.getElementValue(element => element.childElement.model.iconScale) ?? 1}
                            onChange={(event, value) => this.setModelValues({ iconScale: value }, false)}
                            onChangeCommitted={(event, value) => this.setModelValues({ iconScale: value })}
                            min={0.1}
                            max={2}
                            step={0.01}
                        />
                        {settingsMenu}
                    </ControlBar>
                );
            case AssetType.VIDEO:
            case AssetType.STOCK_VIDEO:
                const { asset } = content.elements;
                const showDecimals = Intl
                    .NumberFormat(navigator.language, {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 6,
                    }).format;
                return (
                    <>
                        <ControlBar ref={this.controlBarRef}>
                            {selection.length == 1 &&
                                <Tooltip title="Adjust Video" arrow>
                                    <PopupMenu icon="photo_camera" childrenAreMenuItems>
                                        <MenuList>
                                            <MenuItem onClick={this.handleChooseAsset}>
                                                <Icon>video_library</Icon>
                                                Replace Video...
                                            </MenuItem>
                                            <MenuItem onClick={this.handleAdjustImage}>
                                                <Icon>crop</Icon>
                                                Adjust Video
                                            </MenuItem>
                                        </MenuList>
                                    </PopupMenu>
                                </Tooltip>
                            }
                            {!isTemplate &&
                                <>
                                    <PopupMenu icon="filter_frames">
                                        <ImageFrameMenu
                                            selectedFrame={element.childElement.model.frameType}
                                            selectedStyle={element.childElement.model.decorationStyle}
                                            onSelect={this.handleChangeFrame}
                                            onSelectStyle={this.handleChangeFrameDecorationStyle}
                                        />
                                    </PopupMenu>
                                    <ColorPicker
                                        showAuto
                                        color={this.getElementValue("color")}
                                        onChange={this.handleChangeColor}
                                        onChangeDecorationStyle={style => this.handleChangeFrameDecorationStyle(style)}
                                        showDecorationStyles
                                    />
                                </>
                            }
                            <PopupMenu icon="settings">
                                <PopupMenuPaddedContainer>
                                    <LabeledContainer icon="lens_blur" label="Shadow">
                                        <ShadowEditor shadow={shadow}
                                            onChange={shadow => this.setContainerValues({ shadow })} />
                                    </LabeledContainer>
                                    <LabeledContainer icon="opacity" label="Opacity">
                                        <Slider
                                            value={opacity}
                                            onChange={(event, value) => this.setContainerValues({ opacity: value }, false)}
                                            onChangeCommitted={(event, value) => this.setContainerValues({ opacity: value })}
                                            valueLabelDisplay="auto"
                                            min={0}
                                            max={100}
                                        />
                                    </LabeledContainer>
                                </PopupMenuPaddedContainer>
                            </PopupMenu>
                        </ControlBar>
                    </>
                );
            case "mixed":
                return null;
        }
    }
}

