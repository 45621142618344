import React from "react";
import styled from "styled-components";

import { _, tinycolor } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { AssetType, ShapeType } from "common/constants";
import { fitRectInCircle } from "js/core/utilities/geom";
import { getClipPath } from "js/core/utilities/svgHelpers";
import Spinner from "js/react/components/Spinner";
import { findElementsWithImages } from "js/core/services/slideModelBuilder";

import { BaseElement } from "./BaseElement";
import { Icon } from "./MediaElements/IconElement";
import { Picture, Logo } from "./MediaElements/PictureElement";
import { VideoElement } from "./MediaElements/VideoElement";
import { ClipboardType } from "../../../../core/utilities/clipboard";

const AddContentButtonFrame = styled.div.attrs(({ showFrame }) => ({
    style: {
        background: showFrame ? "#11a9e226" : "none",
        border: showFrame ? "dotted 1px #11a9e2" : "none"
    }
}))`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AddContentButton = styled.div.attrs(({ scale }) => ({
    style: {
        transform: `scale(${scale})`
    }
}))`
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  background: #11a9e2;
  color: white;
  padding: 10px;
  pointer-events: auto;
`;

class ContentElement extends BaseElement {
    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return true;
    }

    get bindTo() {
        return this.options.bindTo || "content_value";
    }

    get contentType() {
        return this.model["content_type"];
    }

    get contentValue() {
        return this.model[this.bindTo];
    }

    get canDropImage() {
        return !this.options.disableDropImage;
    }

    setDisableDropImage(disableDropImage) {
        this.options.disableDropImage = disableDropImage;
    }

    get clipboardElement() {
        return this.assetElement;
    }

    getClipboardData() {
        return { [ClipboardType.ASSET]: this.model };
    }

    get canPasteImage() {
        return true;
    }

    get asset() {
        if (this.assetElement) {
            return this.assetElement.asset;
        }
    }

    get defaultAssetType() {
        return this.options.defaultAssetType || this.contentType || AssetType.IMAGE;
    }

    get hasAsset() {
        return this.asset != null;
    }

    get showInlineAddMediaButton() {
        return true;
    }

    getContentSize() {
        switch (this.assetElement.type) {
            case "Picture":
                if (this.asset && this.asset.getImageType() == AssetType.LOGO) {
                    return new geom.Size(200, 200);
                } else {
                    return this.assetElement.mediaSize;
                }
            case "VideoElement":
                return this.assetElement.mediaSize;
            case AssetType.ICON:
            case AssetType.LOGO:
            default:
                return new geom.Size(200, 200);
        }
    }

    get aspectRatio() {
        return "fill";
    }

    get constrainAspectRatio() {
        return this.asset.constrainAspectRatio || "fit";
    }

    get showDefaultOverlay() {
        return !this.hasValidAsset;
    }

    get hasValidAsset() {
        if (!this.contentType || this.contentType.equalsAnyOf(AssetType.IMAGE, AssetType.ICON, AssetType.LOGO)) {
            if (this.assetElement && this.assetElement instanceof Picture && this.assetElement.hasMedia == false) {
                return false;
            }
            return !_.isEmpty(this.contentValue);
        } else {
            return true;
        }
    }

    get selectionPadding() {
        return 0;
    }

    get isBackgroundVisible() {
        if (this.contentType == AssetType.ICON) {
            return true;
        }
        if (this.assetElement.hasAlpha) {
            return true;
        }
        if (this.options.allowImageScaling && this.assetElement.isScaledBelowFill && !this.assetElement.hasSolidBackground) {
            return true;
        }
        return false;
    }

    get hasAlpha() {
        return this.hasAsset && (this.contentType === AssetType.ICON || this.assetElement.hasAlpha);
    }

    get hasSolidBackground() {
        return this.hasAsset && (this.contentType === AssetType.IMAGE || this.assetElement.hasSolidBackground);
    }

    get assetType() {
        return this.contentType || this.options.defaultAssetType || AssetType.IMAGE;
    }

    get assetIconScale() {
        return null; // use style iconScale setting unless overridden
    }

    get isVideo() {
        return this.contentType === AssetType.VIDEO || this.contentType === AssetType.STOCK_VIDEO;
    }

    setDisabled(disabled) {
        this.disabled = disabled;
        if (this.isVideo) {
            this.assetElement.disabled = disabled;
        }
    }

    _build() {
        switch (this.assetType) {
            case AssetType.ICON:
                this.assetElement = this.addElement("asset", () => Icon, {
                    iconScale: this.assetIconScale,
                    allowBackdrop: this.options.allowBackdrop,
                    canDoubleClickToEdit: this.isOnAuthoringCanvas ? false : this.options.canDoubleClickToEdit,
                    forceIconColor: this.options.forceIconColor
                });
                break;
            case AssetType.IMAGE:
                this.assetElement = this.addElement("asset", () => Picture, {
                    bindTo: this.bindTo,
                    allowScaleBelowFill: this.options.allowScaleBelowFill,
                    allowImageScaling: this.options.allowImageScaling,
                    allowBackdrop: this.options.allowBackdrop,
                    canDoubleClickToEdit: this.options.canDoubleClickToEdit
                });
                break;
            case AssetType.LOGO:
                this.assetElement = this.addElement("asset", () => Logo, {
                    bindTo: this.bindTo,
                    defaultScale: this.options.defaultLogoScale || 0.9,
                    allowBackdrop: this.options.allowBackdrop,
                    canDoubleClickToEdit: this.options.canDoubleClickToEdit
                });
                break;
            case AssetType.VIDEO:
            case AssetType.STOCK_VIDEO:
                const {
                    assetProps: {
                        controls,
                        loop,
                        autoPlay,
                        duration,
                        start,
                        end,
                        speed,
                        muted,
                    },
                    content_value,
                } = this.model;
                this.assetElement = this.addElement("asset", () => VideoElement, {
                    bindTo: this.bindTo,
                    content_value,
                    controls,
                    loop,
                    autoPlay,
                    duration,
                    start,
                    end,
                    speed,
                    muted,
                    allowScaleBelowFill: this.options.allowScaleBelowFill,
                    allowImageScaling: this.options.allowImageScaling,
                    canSelect: this.options.canSelect
                });
                break;
        }
    }

    hitCheck(pt) {
        if (this.styles.decoration && this.styles.decoration.shape === ShapeType.CIRCLE) {
            let r = Math.min(this.calculatedSize.width, this.calculatedSize.height) / 2;
            return pt.minus(r, r).magnitude() <= r;
        } else {
            let w = this.calculatedSize.width;
            let h = this.calculatedSize.height;
            return pt.x <= w && pt.x >= 0 && pt.y <= h && pt.y >= 0;
        }
    }

    _calcProps(props, options = {}) {
        let { size } = props;

        if (!this.model.frameType || this.model.frameType != "none" && this.model.content_type != AssetType.ICON) {
            let backdropColor;

            if (options.backgroundColor || (this.model.backgroundColor && this.model.backgroundColor != "none")) {
                backdropColor = this.canvas.getTheme().palette.getColor(options.backgroundColor || this.model.backgroundColor).toRgbString();
            }

            // if the image has a solid background, we will fill the contentElement frame with it's background color (most commonly with logos so they are on a field of white)
            if ((this.asset?.get("hasSolidBackground") && this.asset?.get("hasAlpha") == false) || (this.asset?.get("hasAlpha") && this.model.showBackdrop)) {
                backdropColor = tinycolor(this.asset.get("imageBackgroundColor")).toRgbString();
            }

            if (backdropColor) {
                if (this.decoration) {
                    this.decoration.styles.fillColor = backdropColor;
                } else {
                    this.createDecoration({
                        type: "frame",
                        shape: "rect",
                        fillColor: backdropColor
                    });
                }
            }
        }

        // Always fitting icons
        let fitAsset = this.assetType === AssetType.ICON ? true : options.fitAsset;

        if (this.hasValidAsset &&
            (this.contentType == AssetType.IMAGE || this.contentType == AssetType.LOGO || this.isVideo) &&
            this.decoration &&
            this.decoration.styles.type === "frame" &&
            this.decoration.styles.strokeWidth
        ) {
            // Decrease the inner radius by the strokeWidth to eliminate gaps in the corners.
            const innerRadius = Math.max(this.decoration.styles.cornerRadius, 0);
            const assetProps = this.assetElement.calcProps(size, Object.assign(options, { fitAsset }));
            assetProps.bounds = new geom.Rect(0, 0, size);
            assetProps.clipPath = getClipPath(this.decoration.styles.shape, new geom.Rect(0, 0, size), innerRadius, this.isVideo, this.canvas.getScale());
        } else {
            let assetSize = size.deflate(options.framePadding ?? 0);
            let assetHeight = options.framePadding ?? 0;
            // Adding `matchCellHeight` option to match the height of the icon to the height of the cell
            // and to ensure if we use icons elsewhere in the app, they will not be affected
            if (this.assetType === AssetType.ICON && options.matchCellHeight) {
                assetSize = new geom.Size(size.width, options.iconCellHeight);
                assetHeight = size.height / 2 - options.iconCellHeight / 2;
            }

            const assetProps = this.assetElement.calcProps(assetSize, Object.assign(options, { fitAsset }));
            assetProps.bounds = new geom.Rect(options.framePadding ?? 0, assetHeight, assetProps.size);
        }

        return { size };
    }

    renderChildren(transition) {
        const props = this.calculatedProps;
        const children = super.renderChildren(transition);

        const isLoadingImages = findElementsWithImages(this.model).length > 0;
        if (isLoadingImages) {
            children.push(<Spinner key="spinner"/>);
            return children;
        }

        if (_.isEmpty(this.model.content_value) && !this.canvas.isPlayback && !this.disabled && !this.options.prevent && this.showInlineAddMediaButton) {
            this.addContentButtonRef = React.createRef();

            let showFrame = !this.styles.decoration || this.styles.decoration.shape == "none" || (this.styles.decoration.fillColor == "none" && this.styles.decoration.strokeColor == "none");

            let size = Math.max(Math.min(props.bounds.width, props.bounds.height), 40);
            let scale = Math.min(1, (size - 20) / 50);

            children.push(
                <AddContentButtonFrame key="addContentButtonFrame" showFrame={showFrame}>
                    <AddContentButton
                        ref={this.addContentButtonRef}
                        scale={scale}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="100%"
                            viewBox="0 0 24 24" width="100%" fill="white">
                            <rect fill="none" height="24" width="24" />
                            <path
                                d="M3,4V1h2v3h3v2H5v3H3V6H0V4H3z M6,10V7h3V4h7l1.83,2H21c1.1,0,2,0.9,2,2v12c0,1.1-0.9,2-2,2H5c-1.1,0-2-0.9-2-2V10H6z M13,19c2.76,0,5-2.24,5-5s-2.24-5-5-5s-5,2.24-5,5S10.24,19,13,19z M9.8,14c0,1.77,1.43,3.2,3.2,3.2s3.2-1.43,3.2-3.2 s-1.43-3.2-3.2-3.2S9.8,12.23,9.8,14z" />
                        </svg>
                    </AddContentButton>
                </AddContentButtonFrame>
            );
        }

        return children;
    }

    _getBackgroundColor(forElement) {
        return super._getBackgroundColor(forElement);
    }

    _exportToSharedModel() {
        if (!this.hasValidAsset) return { assets: [] };

        const assets = [{
            type: this.model.content_type,
            value: this.model.content_value,
            name: this.model.assetName,
            props: this.model.assetProps,
            configProps: _.omit(this.model, ["content_type", "content_value", "assetName", "assetProps", "frameType", "text"])
        }];

        return { assets };
    }

    _importFromSharedModel(model) {
        const { assets } = model;
        if (!assets?.length) return;

        return {
            content_type: assets[0].type,
            content_value: assets[0].value,
            assetName: assets[0].name,
            assetProps: assets[0].props,
            ...(assets[0].configProps ?? {})
        };
    }
}

class AutoSizeContentElement extends ContentElement {
    get _canSelect() {
        return true;
    }

    get _canRollover() {
        return true;
    }

    get defaultOverlayType() {
        return "ContentElementDefaultOverlay";
    }

    get fitWidth() {
        return Boolean(this.options.fitWidth);
    }

    get fitHeight() {
        return Boolean(this.options.fitHeight);
    }

    get showBackdrop() {
        return this.model.showBackdrop ?? this.options.defaultShowBackdrop ?? false;
    }

    _loadStyles(styles) {
        styles.paddingLeft = styles.paddingRight = 16;
        styles.paddingTop = styles.paddingBottom = 10;
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.showBackdrop) {
            this.createDecoration({
                type: "frame",
                shape: "rect",
                fillColor: "white"
            });
        }

        // fit the logo into the availableSize
        let assetSize;

        if (this.assetElement.mediaSize && this.hasValidAsset) {
            assetSize = this.assetElement.mediaSize;
        } else {
            assetSize = size.square();
        }

        let defaultScale;
        if (this.fitWidth) {
            defaultScale = size.width / assetSize.width;
        } else if (this.fitHeight) {
            defaultScale = size.height / assetSize.height;
        } else {
            defaultScale = Math.min(size.width / assetSize.width, size.height / assetSize.height);
        }

        assetSize = assetSize.scale(defaultScale);

        let assetProps = this.assetElement.calcProps(assetSize, { ...options, fitAsset: true });
        assetProps.bounds = new geom.Rect(0, 0, assetSize);

        return { size: assetSize };
    }
}

class AdjustableContentElement extends ContentElement {
    get frameType() {
        if (this.model.frameType) {
            return this.model.frameType;
        } else {
            switch (this.canvas.getTheme().get("styleShape")) {
                case "rect":
                case "rounded_rect":
                    return "square";
                case "circle":
                    return "circle";
                case "none":
                    return "none";
                default:
                    return "circle";
            }
        }
    }

    get passThroughSelection() {
        return false;
    }

    get contentSize() {
        return this.model.contentSize;
    }

    get minWidth() {
        return 20;
    }

    get maxWidth() {
        return 300;
    }

    get allowDecorationStyles() {
        return this.frameType != "none";
    }

    get decorationStyle() {
        if (this.allowDecorationStyles) {
            return this.model.decorationStyle;
        } else {
            return "outlined";
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.canvas.layouter.isGenerating) {
            switch (this.frameType) {
                case "circle":
                    this.decoration.styles.shape = "circle";
                    break;
                case "square":
                    this.decoration.styles.shape = "rect";
                    if (this.canvas.getTheme().get("styleShape") != "rounded_rect") {
                        this.decoration.styles.cornerRadius = 0;
                    }
                    break;
                case "none":
                    this.assetElement.styles.fillColor = "auto";
                    this.assetElement.styles.iconScale = 0.8;
                    this.decoration.styles.shape = "none";
                    break;
            }

            if (this.asset && this.asset.get("hasSolidBackground") && this.asset.get("hasAlpha") == false) {
                if (this.styles.decoration) {
                    this.styles.decoration.fillColor = tinycolor(this.asset.get("imageBackgroundColor")).toHexString();
                    this.styles.decoration.strokeColor = "slide";
                    this.styles.decoration.strokeWidth = 2;
                } else {
                    this.styles.decoration = {
                        type: "frame",
                        shape: "rect",
                        fillColor: tinycolor(this.asset.get("imageBackgroundColor")).toHexString()
                    };
                }
            }
        }

        let aspectRatio;
        if (!this.assetElement.mediaSize || (this.decoration && this.decoration.styles.shape != "none")) {
            aspectRatio = 1;
        } else {
            aspectRatio = this.assetElement.mediaSize.aspectRatio;
        }

        if (options.fitToHeight) {
            size = new geom.Size(size.height * aspectRatio, size.height);
        } else {
            let width = this.contentSize || options.contentSize || size.width;
            size = new geom.Size(width, width / aspectRatio);
        }

        if (!this.hasValidAsset) {
            let assetProps = this.assetElement.calcProps(size);
            assetProps.bounds = new geom.Rect(0, 0, size);
            return { size };
        }

        let availableAssetSize = size.clone();
        if (this.frameType == "circle" && this.contentType == AssetType.LOGO) {
            let fit = fitRectInCircle(size.width, this.assetElement.mediaSize.width, this.assetElement.mediaSize.height);
            options.optimalFitSize = fit.deflate(4);
        }

        let assetProps = this.assetElement.calcProps(availableAssetSize, options);
        assetProps.bounds = new geom.Rect(0, 0, size);

        const isMedia = this.contentType === AssetType.IMAGE || this.contentType === AssetType.LOGO || this.isVideo;
        const hasFrame = this.decoration && this.decoration.styles.type === "frame";
        if (this.hasValidAsset && isMedia && hasFrame) {
            assetProps.clipPath = getClipPath(this.decoration.styles.shape, assetProps.bounds, this.decoration.styles.cornerRadius, this.isVideo, this.canvas.getScale());
        }

        return { size };
    }
}

export { ContentElement, AutoSizeContentElement, AdjustableContentElement };
