import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { controls } from "js/editor/ui";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddSlideContainer } from "js/react/views/AddSlide";
import { themeColors } from "js/react/sharedStyles";
import { getStaticUrl } from "js/config";
import { FeatureType } from "common/features";
import AppController from "js/core/AppController";

import { ElementSelection } from "../BaseElementEditor";

const SlidePlaceholderSelection = ElementSelection.extend({

    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: "CHOOSE TEMPLATE",
            icon: "autorenew",
            className: "choose-template-button",
            callback: () => {
                ShowDialog(AddSlideContainer, {
                    replaceSlide: ds.selection.slide
                });
            }
        });

        const designerBotDisabled = app.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, AppController.workspaceId);
        const designerBotAccessible = app.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, AppController.workspaceId);
        if (!designerBotDisabled && designerBotAccessible) {
            this.addGap(5);
            this.addControl({
                type: controls.BUTTON,
                color: themeColors.aiColor,
                icon: getStaticUrl("/images/bai-bot/white-bot.svg"),
                label: "GENERATE SLIDE",
                className: "ai-button",
                callback: () => {
                    this.element.finishWithAi();
                }
            });
        }
    }
});

export const editors = {
    SlidePlaceholderSelection,
};
