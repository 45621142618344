import { ds } from "js/core/models/dataService";
import { _ } from "js/vendor";
import { controls } from "js/editor/ui";
import * as geom from "js/core/utilities/geom";
import { v4 as uuid } from "uuid";

import { TextElementSelection } from "./TextEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { AuthoringBlockType, TextStyleType } from "common/constants";

const ProcessDiagramSelection = CollectionElementSelection.extend({
    getAddItemLabel: function() {
        return "Add Step";
    },

    renderControls: function() {
        this.createAddItemButton();
    }
});

const ProcessDiagramChevronsSelection = ProcessDiagramSelection.extend({
    renderControls: function() {
        this.createAddItemButton();

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Layout",
            items: [{
                value: "alternate", label: "Alternate Above and Below"
            }, {
                value: "above", label: "Text Above"
            }, {
                value: "below", label: "Text Below"
            }],
            property: "textLayout",
            markStylesAsDirty: true
        });
    }
});
const ProcessDiagramBoxesSelection = ProcessDiagramSelection.extend({});

const ProcessDiagramBoxItemSelection = CollectionItemElementSelection.extend({});

const ProcessDiagramChevronItemSelection = CollectionItemElementSelection.extend({
    getDragAxis: function() {
        return this.element.totalRows > 1 ? "" : "x";
    },

    getElementDragOffset() {
        return new geom.Point(
            (this.element.selectionPadding.left == undefined ? this.element.selectionPadding : this.element.selectionPadding.left),
            (this.element.selectionPadding.top == undefined ? this.element.selectionPadding : this.element.selectionPadding.top) - this.element.shapeContainer.bounds.top
        );
    },

    renderControls: function() {
        this.createEmphasizeWidget(this.element.showBody);

        if (!this.element.showBody) {
            this.addControl({
                type: controls.BUTTON,
                icon: "add",
                label: "Add Description",
                callback: () => {
                    ds.selection.element = null;

                    let block = {
                        id: uuid(),
                        type: AuthoringBlockType.TEXT,
                        textStyle: TextStyleType.BODY
                    };

                    this.element.model.body = {
                        blocks: [block]
                    };

                    this.element.canvas.refreshCanvas().then(() => {
                        ds.selection.element = this.element.body;
                        this.element.body.overlay.focusBlock(block.id);
                    });
                }
            });
        }
    }
});

export const editors = {
    ProcessDiagramSelection,
    ProcessDiagramChevronsSelection,
    ProcessDiagramBoxesSelection,
    ProcessDiagramBoxItemSelection,
    ProcessDiagramChevronItemSelection
};
