import { ds } from "js/core/models/dataService";
import { TextFocusType } from "common/constants";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

export class TabKeyController {
    static handleTabKey(event) {
        event.preventDefault();

        const currentCanvasController = PresentationEditorController.getCurrentCanvasController();
        const canvas = currentCanvasController.canvas;

        // find next TextElement to focus
        let textElements = canvas.getCanvasElement().findChildElements("TextElement").filter(text => text.canEdit && text.textModel.blocks.length);
        if (textElements.length > 0) {
            let index;
            if (ds.selection.element && ds.selection.element.isInstanceOf("TextElement")) {
                index = textElements.indexOf(ds.selection.element);
                if (event.shiftKey) {
                    index -= 1;
                } else {
                    index += 1;
                }
            } else {
                index = 0;
            }

            if (index < 0) {
                index = textElements.length - 1;
            } else if (index >= textElements.length) {
                index = 0;
            }
            let element = textElements[index];
            ds.selection.element = element;

            let block = element.textModel.blocks[event.shiftKey ? Math.max(0, element.textModel.blocks.length - 1) : 0];
            ds.selection.element.overlay.focusBlock(block.id, TextFocusType.ALL);
        }
    }
}
