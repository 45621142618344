import React, { Component, useRef } from "react";
import { Button, DialogActions, DialogTitle, Icon, IconButton } from "@material-ui/core";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import styled from "styled-components";

import { app } from "js/namespaces";
import { controls } from "js/editor/ui";
import { ds } from "js/core/models/dataService";
import { $, _ } from "js/vendor";
import renderReactRoot from "js/react/renderReactRoot";
import { PositionType } from "common/constants";
import { BeautifulDialog, DialogContent, ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { FlexSpacer } from "js/react/components/Gap";
import { BlueButton } from "js/react/components/UiComponents";
import { getStaticUrl } from "js/config";
import { themeColors } from "js/react/sharedStyles";
import { sanitizeHtml } from "js/core/utilities/dompurify";

import { CollectionElementSelection, CollectionItemElementSelection } from "../../CollectionElementEditor";
import { ElementOptionsMenu } from "../../BaseElementEditor";
import { ImageFrameMenu } from "../../EditorComponents/ImageFrameMenu";
import { createResizer } from "../../EditorComponents/Resizer";
import { CreateMediaMenu } from "../pictureEditor";

const ImageCarouselSelection = CollectionElementSelection.extend({
    setup: function() {
        this.listenTo(app.undoManager, "undo", () => {
            this.element.canvas.updateCanvasModel(true);
            this.element.currentIndex = Math.min(this.element.currentIndex, this.element.itemCount - 1);
        });
        this.listenTo(app.undoManager, "redo", () => {
            this.element.canvas.updateCanvasModel(true);
        });
    },

    renderControls: function() {
        this.addControl({
            type: controls.BUTTON,
            label: this.getAddItemLabel(),
            icon: "add_circle",
            callback: () => {
                ds.selection.element = null;
                let index = this.element.currentIndex + 1;
                this.element.addItem({
                    frameType: this.element.itemCollection[this.element.currentIndex]?.frameType,
                    color: this.element.itemCollection[this.element.currentIndex]?.color,
                    decorationStyle: this.element.itemCollection[this.element.currentIndex]?.decorationStyle,
                }, index);
                this.element.currentIndex = index;
                this.element.reverse = false;
                this.element.canvas.updateCanvasModel(true);
            }
        });

        let prevButton = this.addControl({
            type: controls.BUTTON,
            label: "Prev",
            icon: "arrow_back",
            callback: () => {
                if (this.element.canvas.layouter.isGenerating) {
                    return;
                }

                ds.selection.element = null;
                this.selectionLayer.$el.find(".element-default-overlay").css("visibility", "hidden");
                this.element.goPrevItem();
            }
        });

        let $index = $.div("carousel_index control label", "1 of 5");
        this.$controlBar.append($index);

        let nextButton = this.addControl({
            type: controls.BUTTON,
            label: "Next",
            icon: "arrow_forward",
            callback: () => {
                if (this.element.canvas.layouter.isGenerating) {
                    return;
                }

                ds.selection.element = null;
                this.selectionLayer.$el.find(".element-default-overlay").css("visibility", "hidden");
                this.element.goNextItem();
            }
        });

        prevButton.toggleClass("disabled", this.element.currentIndex === 0);
        nextButton.toggleClass("disabled", this.element.currentIndex === this.element.itemCount - 1);

        $index.text(this.element.currentIndex + 1 + " of " + this.element.itemCount);
        prevButton.toggleClass("disabled", this.element.currentIndex === 0);
        nextButton.toggleClass("disabled", this.element.currentIndex === this.element.itemCount - 1);

        this.addControl({
            type: controls.BUTTON,
            label: "Reorder",
            icon: "reorder",
            callback: () => {
                ShowDialog(ReorderCarouselDialog, {
                    element: this.element
                });
            }
        });
    }
});

const CarouselList = styled.div`
  display: grid;
  gap: 10px;
`;
const CarouselItemDiv = styled.div`
  width: 100%;
  position: relative;
  border: solid 1px #ccc;

  &:hover {
    border-color: ${themeColors.ui_blue};
  }
  
  .ImageCarouselItem {
    transform: translateY(10px) translateX(10px) scale(0.25);
    transform-origin: 0px 0px;

    // disable certain styling for the popup
    opacity: 1 !important;
    animation-name: none !important
  }
`;

const DraggableItem = ({ index, item, height, onMove, backgroundColor, onDoubleClick, onDelete }) => {
    const ref = useRef(null);

    const [{ handlerId, isDropTarget }, drop] = useDrop({
        accept: "item",
        collect(monitor) {
            return {
                handleId: monitor.getHandlerId(),
                isDropTarget: monitor.isOver()
            };
        },
        hover(item, monitor) {
            if (!ref.current) return;

            let dragIndex = item.index;
            let hoverIndex = index;

            if (dragIndex === hoverIndex) return;

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            onMove(dragIndex, hoverIndex);

            item.index = hoverIndex;
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: "item",
        item: () => {
            return { item, index };
        },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ref));

    let styles = {
        height: height * .25 + 20,
        backgroundColor,
        opacity: 1
    };

    item.calculatedProps.opacity = 1;

    // display the item - use the already generated
    // HTML content to prevent refs from breaking
    const html = extractHtml(item);
    return (<CarouselItemDiv ref={ref} id={item.id} style={styles}>
        <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }} />
        <IconButton onClick={onDelete} style={{ position: "absolute", right: 0 }}><Icon>delete</Icon></IconButton>
    </CarouselItemDiv>);
};

export class ReorderCarouselDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: props.element.itemElements.map(item => item),
            itemHeight: props.element.itemElements[0].bounds.height
        };
    }

    handleReorderItem = (fromIndex, toIndex) => {
        let { items } = this.state;

        let sourceItem = items[fromIndex];
        items.remove(sourceItem);
        items.insert(sourceItem, toIndex);

        this.setState({ items });
    }

    handleDeleteItem = itemId => {
        if (this.props.element.itemCollection.length > this.props.element.minItemCount) {
            this.props.element.deleteItem(itemId);

            this.setState({ items: this.props.element.itemElements.map(item => item) });
        }
    }

    handleDoubleClick = index => {
        this.saveChanges();
        this.props.element.goToItem(index, false);
    }

    saveChanges = () => {
        let { element } = this.props;
        let { items } = this.state;

        element.model.items = items.map(item => item.model);

        this.props.closeDialog();
        element.canvas.updateCanvasModel(false);
    }

    render() {
        let { items, itemHeight } = this.state;
        const bg = this.props.element.getBackgroundColor();
        const backgroundColor = bg.toRgbString();

        return (
            <BeautifulDialog closeDialog={this.props.closeDialog}>
                <DialogTitle>Reorder Carousel Items</DialogTitle>
                <DialogContent>
                    <DndProvider backend={HTML5Backend}>
                        <CarouselList>
                            {items.map((item, index) => (
                                <DraggableItem key={index}
                                    index={index}
                                    height={itemHeight}
                                    item={item}
                                    backgroundColor={backgroundColor}
                                    onMove={this.handleReorderItem}
                                    onDelete={() => this.handleDeleteItem(item.id)}
                                    onDoubleClick={() => this.handleDoubleClick(index)}
                                />))}
                        </CarouselList>
                    </DndProvider>
                </DialogContent>
                <DialogActions>
                    <FlexSpacer />
                    <Button onClick={this.props.closeDialog}>Cancel</Button>
                    <BlueButton onClick={this.saveChanges}>Save</BlueButton>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

const ImageCarouselOptionsMenu = ElementOptionsMenu.extend({

    renderControls: function() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Transition",
            property: "transition",
            items: [{
                value: "slide-left", label: "Slide Left"
            }, {
                value: "slide-up", label: "Slide Up"
            }, {
                value: "fade", label: "Fade"
            }]
        });
    },

});

const ImageCarouselItemSelection = CollectionItemElementSelection.extend({
    canDrag: function() {
        return false;
    },

    getOffset() {
        return 0;
    },

    renderControls() {
        this.renderResizer();

        CreateMediaMenu(this, this.element.content.content);

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Position",
            menuClass: "icon-menu twocol",
            showArrow: true,
            items: [{
                //     value: "background",
                //     label: "Background",
                //     image: getStaticUrl("/images/ui/trays/background_full.png")
                // }, {
                value: "left", label: "Left", image: getStaticUrl("/images/ui/trays/left_tray_full.png")
            }, {
                value: "right", label: "Right", image: getStaticUrl("/images/ui/trays/right_tray_full.png")
                // }, {
                //     value: "top", label: "Top", image: getStaticUrl("/images/ui/trays/top_tray_full.png")
                // }, {
                //     value: "bottom", label: "Bottom", image: getStaticUrl("/images/ui/trays/bottom_tray_full.png")
            }],
            callback: value => {
                this.element.model.layout = value;
                this.element.model.contentSize = null;
                this.element.canvas.updateCanvasModel(false);
                ds.selection.element = null;
            }
        });

        let $frameMenu = this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "filter_frames",
            showArrow: false,
            customMenuClass: "frame-popup",
            menuContents: closeMenu => {
                let $menu = $.div();
                renderReactRoot(ImageFrameMenu, {
                    onSelect: frame => {
                        this.element.model.frameType = frame;
                        this.element.markStylesAsDirty();
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    }
                }, $menu[0]);
                return $menu;
            },
            transitionModel: false
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            showBackgroundColors: true,
            includeAuto: true,
            showDecorationStyles: () => (this.element.content.allowDecorationStyles),
            transitionModel: false
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            icon: "settings",
            showArrow: false,
            items: [{
                value: "copy", label: "Copy Settings to All Items"
            }],
            callback: action => {
                switch (action) {
                    case "copy":
                        for (let item of this.element.parentElement.itemElements) {
                            item.markStylesAsDirty();
                            item.model.contentSize = this.element.model.contentSize;
                            item.model.frameType = this.element.model.frameType;
                            item.model.layout = this.element.model.layout;
                            item.model.color = this.element.model.color;
                        }
                        this.element.canvas.updateCanvasModel(false);
                        break;
                }
            }
        });
    },

    renderResizer: function() {
        let resizerProps = {
            view: this,
            element: this.element.content,
            scaleFromCenter: false,
            offset: 20,
            dividerSize: 300,
            handleType: "tray",
            onResize: value => {
                this.element.model.contentSize = value;
                this.element.canvas.refreshCanvas();
            }
        };

        switch (this.element.layout) {
            case "left":
                resizerProps.position = PositionType.RIGHT;
                resizerProps.minSize = this.element.bounds.width * .25;
                resizerProps.maxSize = this.element.bounds.width * .75;
                break;
            case "right":
                resizerProps.position = PositionType.LEFT;
                resizerProps.minSize = this.element.bounds.width * .25;
                resizerProps.maxSize = this.element.bounds.width * .75;
                break;
            case "top":
                resizerProps.position = PositionType.BOTTOM;
                resizerProps.minSize = this.element.bounds.height * .25;
                resizerProps.maxSize = this.element.bounds.height * .75;
                break;
            case "bottom":
                resizerProps.position = PositionType.TOP;
                resizerProps.minSize = this.element.bounds.height * .25;
                resizerProps.maxSize = this.element.bounds.height * .75;
                break;
        }

        createResizer(resizerProps, this.selectionLayer);
    },
});

// search down a React ref tree to find a DOM element
// and then return the html within
function extractHtml(item) {
    let html = "";
    let check = item;
    while (check?.ref) {
        // this is the DOM node with HTML
        if (check.ref?.current?.outerHTML) {
            html = check.ref.current.outerHTML;
            break;
        }

        // check another level up
        check = check.ref?.current;
    }

    return html;
}

export const editors = {
    ImageCarouselSelection,
    ImageCarouselItemSelection,
    ImageCarouselOptionsMenu,
};
