import { controls } from "js/editor/ui";
import { _ } from "js/vendor";
import { ElementOptionsMenu } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { TextStyleType } from "common/constants";

const PyramidSelection = CollectionElementSelection.extend({
    getAddItemLabel: function() {
        return "Add Section";
    },

    renderControls: function() {
        this.createAddItemButton();

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Styles",
            property: "style",
            items: [{
                label: "Text", value: "text", icon: "text_fields"
            }, {
                label: "Sequence", value: "sequence", icon: "pin"
            }, {
                label: "Icon", value: "icon", icon: "star"
            }],
        });
    }
});

const PyramidOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Show Section Text",
            property: "showDescription",
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Shade Colors",
            value: this.element.model.shadeColors,
            enabled: this.element.itemCollection.filter(m => m.hilited).length == 0,
            callback: value => {
                this.element.markStylesAsDirty();
                this.element.model.shadeColors = value;
                this.element.itemCollection.forEach(item => item.color = null);
                this.element.canvas.updateCanvasModel();
            }
        });

        this.addDivider();

        this.addControl({
            type: controls.TOGGLE,
            label: "Step",
            property: "step"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Inverted",
            property: "inverted"
        });

        this.addControl({
            type: controls.SLIDER,
            label: "Top Width",
            property: "capSize",
            min: 0,
            max: 100
        });

        this.addControl({
            type: controls.SLIDER,
            label: "Base Width",
            property: "baseSize",
            min: 0,
            max: 100
        });
    }
});

const PyramidSectionSelection = CollectionItemElementSelection.extend({
    getDragAxis: function() {
        return "y";
    },

    renderControls: function() {
        this.createEmphasizeWidget(true);

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });
    }
});

export const editors = {
    PyramidSelection,
    PyramidOptionsMenu,
    PyramidSectionSelection,
};
