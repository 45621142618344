import { AssetType, HorizontalAlignType, TrayType, VerticalAlignType, BlockStructureType, AuthoringBlockType, TextStyleType } from "common/constants";
import * as geom from "js/core/utilities/geom";
import { _ } from "js/vendor";
import { detectListContent } from "js/core/services/sharedModelManager";

import { TextElement } from "../../base/Text/TextElement";
import { CollectionElement, CollectionItemElement } from "../../base/CollectionElement";
import { FramedMediaElement } from "../../base/MediaElements/FramedMediaElement";

export class IconTextGrid extends CollectionElement {
    getChildItemType() {
        return IconTextGridItem;
    }

    get maxItemCount() {
        return 12;
    }

    get forceFitLayout() {
        return true;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            body: { text: "" }
        };
    }

    get orientation() {
        return this.model.orientation || this.options.orientation || "auto";
    }

    _calcProps(props, options) {
        let { size } = props;
        let layouter = this.getLayouter(props, this.itemElements, size);

        switch (this.orientation) {
            case "auto":
                this.updateStyles(this.styles.vertical, true);
                layouter.calcRowLayout({
                    itemOptions: {
                        orientation: "vertical"
                    }
                });

                if (!layouter.isFit || !layouter.isTextFit) {
                    this.updateStyles(this.styles.horizontal, true);
                    //special case styling when there is a large tray
                    if (this.canvas.model.layout.trayLayout && this.canvas.model.layout.trayLayout.equalsAnyOf(TrayType.RIGHT_INLINE, TrayType.RIGHT_TRAY, TrayType.LEFT_INLINE, TrayType.LEFT_TRAY) && this.canvas.getTrayElement() && this.canvas.getTrayElement().trayWidth > 300) {
                        this.updateStyles(this.styles.horizontal.withLargeTray, true);
                    }
                    layouter.calcColumnLayout({ itemOptions: { orientation: "horizontal" } });
                    layouter.alignHorizontally(HorizontalAlignType.CENTER);
                }
                break;
            case "horizontal":
                this.updateStyles(this.styles.horizontal, true);
                //special case styling when there is a large tray
                if (this.canvas.model.layout.trayLayout && this.canvas.model.layout.trayLayout.equalsAnyOf(TrayType.RIGHT_INLINE, TrayType.RIGHT_TRAY, TrayType.LEFT_INLINE, TrayType.LEFT_TRAY) && this.canvas.getTrayElement() && this.canvas.getTrayElement().trayWidth > 300) {
                    this.updateStyles(this.styles.horizontal.withLargeTray, true);
                }
                layouter.calcColumnLayout({ itemOptions: { orientation: "horizontal" } });
                layouter.alignHorizontally(HorizontalAlignType.CENTER);
                break;
            case "vertical":
                this.updateStyles(this.styles.vertical, true);
                layouter.calcRowLayout({ itemOptions: { orientation: "vertical" } });
                break;
        }

        layouter.alignVertically(VerticalAlignType.MIDDLE);

        props.isFit = layouter.isFit;

        return { size };
    }

    _exportToSharedModel() {
        const listContent = this.itemElements.map(item => ({
            text: item.text._exportToSharedModel().textContent[0],
            asset: item.media._exportToSharedModel().assets[0]
        }));

        const textContent = this.itemElements.reduce(
            (textContent, itemElement) => ([
                ...textContent, ...itemElement.text._exportToSharedModel().textContent
            ]), []
        );

        const assets = this.itemElements.reduce(
            (assets, itemElement) => ([
                ...assets, ...itemElement.media._exportToSharedModel().assets
            ]), []
        );

        return { listContent, textContent, assets };
    }

    _importFromSharedModel(model) {
        const listContent = detectListContent(model);
        if (!listContent?.length) return;

        const items = listContent.map(({ text, asset }) => ({
            ...(asset ? {
                content_type: asset.type,
                content_value: asset.value,
                assetProps: asset.props,
                assetName: asset.name,
                ...(asset.configProps ?? {}),
            } : {}),
            ...(text ? {
                text: {
                    blocks: [
                        {
                            html: text.mainText.text,
                            textStyle: TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...text.secondaryTexts.map(secondaryText => ({
                            html: secondaryText.text,
                            textStyle: TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        })),
                    ]
                }
            } : {})
        }));

        items.splice(this.maxItemCount);
        return { items };
    }
}

export class IconTextGridItem extends CollectionItemElement {
    get selectionPadding() {
        if (this.calculatedProps.orientation == "horizontal") {
            return 20;
        } else {
            return { left: 34, right: 34, top: 20, bottom: 20 };
        }
    }

    _build() {
        this.media = this.addElement("media", () => FramedMediaElement, {
            defaultAssetType: AssetType.ICON,
            allowUnframedImages: false
        });
        this.text = this.addElement("text", () => TextElement, {
            blockStructure: BlockStructureType.TITLE_AND_BODY,
            autoHeight: true,
            syncFontSizeWithSiblings: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        this.updateStyles(this.styles[options.orientation || "horizontal"], true);

        let layouter = this.getLayouter(props, [this.media, this.text], size);

        if (options.orientation == "vertical") {
            layouter.calcVerticalBlockLayout({
                contentSize: new geom.Size(this.styles.content.width, this.styles.content.height),
                horizontalAlign: layouter.HorizontalAlignType.CENTER,
                itemOptions: {
                    autoWidth: true
                }
            });

            layouter.alignHorizontally(layouter.HorizontalAlignType.CENTER);
        } else {
            if (options.bulletAlign == "right") {
                this.text.styles.textAlign = HorizontalAlignType.RIGHT;
                this.text.styles.marginRight = this.text.styles.marginLeft;
                this.text.styles.marginLeft = 0;
            }

            layouter.calcHorizontalBlockLayout({
                contentSize: new geom.Size(this.styles.content.width, this.styles.content.height),
                verticalAlign: layouter.VerticalBlockAlignType.MIDDLE_OR_TOP,
                reverse: options.bulletAlign == "right"
            });
        }

        return {
            size: layouter.size,
            orientation: options.orientation,
            isTextFit: layouter.isTextFit
        };
    }

    _migrate_10() {
        delete this.model.blocks; // delete any blocks property that was left in this model from switch template
    }
}

export const elements = {
    IconTextGrid,
};
