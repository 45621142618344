import { v4 as uuid } from "uuid";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { Shape } from "js/core/utilities/shapes";
import { BlockStructureType, AuthoringBlockType, TextStyleType } from "common/constants";
import { getValidChartDataFromCsv } from "js/core/utilities/xlsx";
import { detectCompareContent } from "js/core/services/sharedModelManager";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { TextElement } from "../base/Text/TextElement";
import { SVGPolygonElement } from "../base/SVGElement";

class ArrowBars extends CollectionElement {
    static get schema() {
        return {
            matchFontSizes: true
        };
    }

    getChildItemType() {
        return ArrowBarItem;
    }

    get defaultItemData() {
        return {
            value: 75,
            showArrowHead: true
        };
    }

    get maxItemCount() {
        return 5;
    }

    getCanvasMargins() {
        return {
            left: this.model.showFoldEffect ? 0 : 50,
            top: 50,
            right: 50,
            bottom: 50
        };
    }

    _calcProps(props, options) {
        const { size } = props;

        const layouter = this.getLayouter(props, this.itemElements, size);
        layouter.calcVerticalLayout();
        layouter.alignVertically();

        if (this.model.showFoldEffect) {
            for (const item of this.itemElements) {
                const arrowOffset = item.styles.arrow.arrowHeadOffset;
                const topPoint = [item.calculatedProps.foldWidth, item.bounds.top + arrowOffset / 2];
                const bottomPoint = [item.calculatedProps.foldWidth, item.bounds.top + item.bounds.height - arrowOffset / 2];
                const vanishingPoint = [item.styles.arrow.vanishingPointOffsetX, item.styles.arrow.vanishingPointOffsetY + size.height / 2];

                let slope = (vanishingPoint[1] - topPoint[1]) / (vanishingPoint[0] - topPoint[0]);
                const perspectiveTopPoint = [0, vanishingPoint[1] - slope * vanishingPoint[0]];

                slope = (vanishingPoint[1] - bottomPoint[1]) / (vanishingPoint[0] - bottomPoint[0]);
                const perspectiveBottomPoint = [0, vanishingPoint[1] - slope * vanishingPoint[0]];

                const foldPath = [perspectiveTopPoint, topPoint, bottomPoint, perspectiveBottomPoint].map(points => [points[0] - item.calculatedProps.elementPadding, points[1] - item.bounds.top]);

                item.fold.createProps({
                    path: foldPath
                });
            }
        }

        return { size };
    }

    _exportToSharedModel() {
        const values = this.itemElements.map(item => item.model.value);

        const textContent = this.itemElements.map(item => ({
            mainText: {
                text: item.label.blocks[0].textContent,
                textStyle: item.label.blocks[0].textStyle
            },
            secondaryTexts: [{ text: item.model.value }]
        }));

        const compareContent = this.itemElements.map((item, i) => ({
            value: values[i], text: textContent[i],
            format: "percent", emphasized: !!item.model.hilited
        }));

        return { compareContent, textContent };
    }

    _importFromSharedModel(model) {
        const compareContent = detectCompareContent(model);
        if (!compareContent?.length) return;

        const items = compareContent.map(({ text, value, emphasized }) => ({
            value: Math.max(20, value ?? Math.round(Math.random() * (80 - 40) + 40)),
            label: {
                blocks: [{
                    html: text.mainText.text,
                    type: AuthoringBlockType.TEXT,
                    textStyle: text.textStyle
                }]
            },
            hilited: !!emphasized
        }));

        items.splice(this.maxItemCount);
        return { items, format: "percent" };
    }
}

class ArrowBarItem extends CollectionItemElement {
    static get schema() {
        return {
            showArrowHead: true,
            value: 50
        };
    }

    get showIndex() {
        return this.parentElement.model.showIndex;
    }

    get selectionPadding() {
        return 0;
    }

    get rolloverPadding() {
        return 0;
    }

    get MIN_FOLD_WIDTH() {
        return 120;
    }

    get showFold() {
        return this.parentElement.model.showFoldEffect;
    }

    _build() {
        this.arrow = this.addElement("arrow", () => SVGPolygonElement);

        if (this.showFold) {
            this.fold = this.addElement("fold", () => SVGPolygonElement);
        }

        if (this.showIndex) {
            this.index = this.addElement("index", () => TextElement, {
                blockStructure: BlockStructureType.SINGLE_BLOCK,
                html: this.itemIndex + 1,
                canEdit: false,
                isTabbable: false,
                scaleTextToFit: true
            });
        }

        this.label = this.addElement("label", () => TextElement, {
            blockStructure: BlockStructureType.SINGLE_BLOCK,
            syncFontSizeWithSiblings: true,
            scaleTextToFit: true
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const currentValue = this.isAnimating ? (this.animationState.growProgress ?? 1) * this.model.value : this.model.value;

        // Setting minimum value to 11.5%
        const percent = ((currentValue ?? 100) / 100) * 0.885 + 0.115;

        let arrowBounds = new geom.Rect(0, 0, (size.width + this.styles.paddingLeft + this.styles.paddingRight) * percent, size.height).inflate({
            top: this.styles.paddingTop,
            bottom: this.styles.paddingBottom
        }).zeroOffset();
        let availableTextBounds = new geom.Rect(0, 0, size.width * percent, size.height);
        if (this.model.showArrowHead) {
            availableTextBounds.width -= 50;
        }

        if (this.showFold) {
            props.elementPadding = this.parentElement.styles.marginLeft;
            props.foldWidth = Math.max(this.MIN_FOLD_WIDTH, props.elementPadding);
            //offset the arrow bounds
            const offset = props.foldWidth - props.elementPadding - arrowBounds.left;

            arrowBounds = arrowBounds.deflate({ left: offset });
            availableTextBounds = availableTextBounds.deflate({ left: offset });
        }

        const arrowProps = this.arrow.createProps({
            layer: -1
        });

        if (this.model.showArrowHead) {
            arrowProps.path = Shape.drawArrow(arrowBounds, arrowBounds.height - this.styles.arrow.arrowHeadOffset, this.styles.arrow.arrowHeadLength, "right");
        } else {
            arrowBounds.height -= this.styles.arrow.arrowHeadOffset;
            arrowBounds.top += this.styles.arrow.arrowHeadOffset * 0.5;
            arrowProps.path = Shape.drawBounds(arrowBounds);
        }

        if (this.showIndex) {
            const index = this.index.calcProps(new geom.Size(40, availableTextBounds.height));
            index.bounds = new geom.Rect(availableTextBounds.left, 0, index.size);
            availableTextBounds = availableTextBounds.deflate({ left: index.size.width + this.index.styles.marginRight });
        }

        const labelBounds = this.isAnimating ? (this.animationLabelBounds ?? availableTextBounds) : availableTextBounds;
        const labelProps = this.label.calcProps(labelBounds.size, { forceTextScale: this.isAnimating ? this.animationLabelScale : null });
        labelProps.bounds = new geom.Rect(labelBounds.left, 0, labelProps.size);

        if (this.isAnimating) {
            this.label.animationState.fadeInProgress = this.animationState.growProgress;
            this.index && (this.index.animationState.fadeInProgress = this.animationState.growProgress);
        }

        return { size };
    }

    _getBackgroundColor(forElement) {
        if (forElement === this.label || forElement === this.index) {
            return this.getShapeFillColor(this.arrow);
        } else {
            return super._getBackgroundColor(forElement);
        }
    }

    get animationElementName() {
        return `Arrow bar #${this.itemIndex + 1}`;
    }

    get animateChildren() {
        return false;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            easing: "easeOutQuad",
            prepare: () => {
                this.animationLabelBounds = this.label.calculatedProps.bounds;
                this.animationLabelScale = this.label.calculatedProps.textScale;
                this.isLabelFit = false;
                this.animationState.growProgress = 0;
                this.animationState.fadeInProgress = 0;

                this.label.animationState.fadeInProgress = 0;
                if (this.showIndex) {
                    this.index.animationState.fadeInProgress = 0;
                }
            },
            onBeforeAnimationFrame: progress => {
                this.animationState.growProgress = progress;
                this.animationState.fadeInProgress = Math.min(progress * 2, 1);
                return this.parentElement;
            }
        }];
    }
}

export { ArrowBars };

export const elements = {
    ArrowBars,
};
