import { v4 as uuid } from "uuid";

import { ds } from "js/core/models/dataService";
import { findInsertionIndex } from "js/core/utilities/utilities";
import { DragType, NodeType, WidgetPositionType } from "common/constants";
import { app } from "js/namespaces.js";
import { _, $ } from "js/vendor";
import { AnchorType } from "js/core/utilities/geom";
import { getStaticUrl } from "js/config";
import { controls } from "js/editor/ui";
import renderReactRoot from "js/react/renderReactRoot";
import getLogger, { LogGroup } from "js/core/logger";
import * as geom from "js/core/utilities/geom";

import { ElementOptionsMenu, ElementRollover } from "../BaseElementEditor";
import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { ImageFrameMenu } from "../EditorComponents/ImageFrameMenu";
import { ShowDialogAsync, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import { CollectionElement } from "../../elements/base/CollectionElement";

const logger = getLogger(LogGroup.ELEMENTS);

const TimelineSelection = CollectionElementSelection.extend({

    renderControls: function() {
        this.addMilestonePromiseChain = Promise.resolve();

        this.addControl({
            type: controls.BUTTON,
            label: "Add Milestone",
            icon: "add_circle",
            enabled: this.element.annotations.itemCount < this.element.annotations.maxItemCount,
            callback: () => {
                this.addMilestonePromiseChain = this.addMilestonePromiseChain
                    .then(async () => {
                        const annotations = this.element.annotations;

                        const annotationModel = this.element.getDefaultAnnotationModel();
                        annotationModel.x = annotations.model.items.reduce((x, itemModel) => Math.max(x, itemModel.x), 0);
                        annotationModel.x = (1 - annotationModel.x) / 2 + annotationModel.x;

                        const annotation = annotations.addItem(annotationModel);

                        await this.element.canvas.updateCanvasModel(true);

                        ds.selection.element = annotations.getItemElementById(annotation.id);
                    })
                    .catch(err => logger.error(err, "[TimelineSelection] failed to add milestone", { slideId: this.element.canvas.dataModel?.id }));
            }
        });

        this.addControl({
            id: "nodeType",
            type: controls.POPUP_BUTTON,
            icon: "apps",
            showArrow: false,
            menuClass: "icon-menu fivecol",
            items: [{
                label: "Text",
                value: NodeType.TEXT,
                image: getStaticUrl("/images/ui/node_types/node_text.svg")
            }, {
                label: "Bullet",
                value: NodeType.BULLET_TEXT,
                image: getStaticUrl("/images/ui/node_types/node_bullet_text.svg")
            }, {
                label: "Numbered",
                value: NodeType.NUMBERED_TEXT,
                image: getStaticUrl("/images/ui/node_types/node_numbered_text.svg")
            }, {
                label: "Lettered",
                value: NodeType.LETTERED_TEXT,
                image: getStaticUrl("/images/ui/node_types/node_lettered_text.svg")
            }, {
                label: "Media with Text",
                value: NodeType.CONTENT_AND_TEXT,
                image: getStaticUrl("/images/ui/node_types/node_content_and_text.svg")
            }],
            callback: value => {
                ds.selection.element = null;
                this.element.model.annotations.items.forEach(itemModel => itemModel.nodeType = value);
                this.element.canvas.updateCanvasModel(true);
            }
        });

        this.addControl({
            type: controls.SPACER,
            width: 5
        });

        this.addControl({
            type: controls.BUTTON,
            label: "Add Span",
            icon: "add_circle",
            enabled: this.element.spanCount < this.element.maxSpanCount,
            callback: () => {
                if (this.element.spanStyle === "line") {
                    this.element.model.spanStyle = "chevron";
                }
                this.element.model.spans.push({
                    id: uuid()
                });
                // We're removing the decoration from the start/end of the timeline when adding a span
                // we want to be sure the color is set to the default color
                // of the slide and not the background it had before
                this.element.markStylesAsDirty();
                return this.element.canvas.updateCanvasModel(false).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit another task on this chart",
                    });
                });
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "style",
            items: closeMenu => [{
                type: "control",
                view: () => controls.createIconGrid(this, {
                    cols: 2,
                    labelClass: "centered-label",
                    items: [{
                        value: "line",
                        label: "Line",
                        icon: "/images/ui/node_types/node_line.svg"
                    }, {
                        value: "chevron",
                        label: "Arrow",
                        icon: "/images/ui/horizontal-tasks-style-process.svg"
                    }, {
                        value: "slice",
                        label: "Slice",
                        icon: "/images/ui/horizontal-tasks-style-task.svg",
                    }],
                    callback: value => {
                        if (value !== "line" && this.element.model.spans.length === 0) {
                            this.element.model.spans.push({
                                id: uuid()
                            });
                        }

                        this.element.markStylesAsDirty();
                        this.element.model.spanStyle = value;
                        ds.selection.element = null;
                        this.element.canvas.updateCanvasModel(false);
                        closeMenu();
                    }
                })
            }]
        });

        this.addControl({
            type: controls.SPACER,
            width: 5
        });

        const contentAndTextElement = this.element.annotations.itemElements.find(element => element?.nodeType === NodeType.CONTENT_AND_TEXT);
        if (contentAndTextElement && this.element.spanCount === 0) {
            this.addControl({
                type: controls.POPUP_BUTTON,
                icon: "filter_frames",
                showArrow: false,
                customMenuClass: "frame-popup",
                menuContents: closeMenu => {
                    const $menu = $.div();
                    renderReactRoot(
                        ImageFrameMenu,
                        {
                            onSelect: frame => {
                                this.element.annotations.itemElements.forEach(annotation => {
                                    annotation.model.frameType = frame;
                                    annotation.markStylesAsDirty();
                                });
                                this.element.canvas.updateCanvasModel(false);
                                closeMenu();
                            },
                            allowedCategories: contentAndTextElement.options.allowedFrameCategories
                        },
                        $menu[0]
                    );
                    return $menu;
                },
                transitionModel: false
            });
        }

        this.addControl({
            type: controls.BUTTON,
            label: "Auto Arrange",
            icon: "view_column",
            callback: () => {
                (async () => {
                    await this.element.distributeMilestones();
                    await this.element.canvas.updateCanvasModel(true);
                })();
            }
        });
    }
});

const TimelineSpanItemContainerSelection = CollectionItemElementSelection.extend({
    showSelectionBox: true,

    getDragOptions: function() {
        return {
            type: DragType.SWAP,
            transitionOnDrop: false
        };
    },

    onDeleteItem() {
        let containerElement = this.element.parentElement;
        if (containerElement instanceof CollectionElement && (containerElement.itemCollection.length > containerElement.minItemCount)) {
            containerElement.deleteItem(this.element.id);
            this.element.getRootElement().distributeSpans();
            ds.selection.element = null;

            this.element.getRootElement().markStylesAsDirty();
            // note: we need to set the forceRender flag on delete so we can get out of layout does not fit states
            this.element.canvas.updateCanvasModel(false, true);
        } else {
            ShowWarningDialog({
                title: "Sorry, we can't delete this item",
                message: `This smart slide requires at least ${containerElement.minItemCount} ${"item".pluralize(containerElement.minItemCount > 1)}.`,
            });
        }
    },

    getDragAxis: function() {
        return "x";
    },

    getOffset: function() {
        return 10;
    },

    getDragWidgetPosition() {
        return WidgetPositionType.UPPER_LEFT;
    },

    getDeleteButtonPosition() {
        return WidgetPositionType.UPPER_RIGHT;
    },

    createAddSpanWidget: function() {
        const { spanCount, maxSpanCount, model: parentModel } = this.element.parentElement;
        this.addControl({
            type: controls.BUTTON,
            enabled: spanCount < maxSpanCount,
            label: "Add Span",
            icon: "add_circle",
            callback: () => {
                const selectedIndex = parentModel.spans.findIndex(item => item.id === this.model.id);
                parentModel.spans.splice(selectedIndex + 1, 0, {
                    id: uuid()
                });

                return this.element.canvas.updateCanvasModel(false).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit another task on this chart",
                    });
                });
            }
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true,
            includeNone: false,
            includePositiveNegative: true,
            markStylesAsDirty: true,
            showDecorationStyles: () => (true)
        });
    },

    renderControls: function() {
        this.createAddSpanWidget();

        if (this.element.parentElement.itemCount > 1) {
            if (this.element.itemIndex === 0) {
                this.renderWidget({ position: WidgetPositionType.RIGHT });
            } else if (this.element.itemIndex === this.element.itemCount - 1) {
                this.renderWidget({ position: WidgetPositionType.LEFT });
            } else {
                this.renderWidget({ position: WidgetPositionType.RIGHT });
                this.renderWidget({ position: WidgetPositionType.LEFT });
            }
        }
    },

    updateSpans: function(amountMoved, direction) {
        const rootElement = this.element.parentElement;
        const neighbor = this.element.parentElement.itemElements[this.element.itemIndex + direction];

        const lastWidth = this.element.model.width;
        const lastNeighborWidth = neighbor.model.width;

        const totalSliceWidth = _.sum(this.element.parentElement.itemElements.map(item => item.model.width));
        const change = totalSliceWidth * (amountMoved / rootElement.availableWidth);
        const newWidth = lastWidth + direction * change;
        const newNeighborWidth = lastNeighborWidth + -1 * direction * change;

        const minWidth = this.element.styles.minWidth;
        const newPixelWidth = rootElement.getSpanWidth(newWidth);
        const newNeighborPixelWidth = rootElement.getSpanWidth(newNeighborWidth);
        if (newWidth < lastWidth && newPixelWidth < minWidth || newNeighborWidth < lastNeighborWidth && newNeighborPixelWidth < minWidth) {
            return false;
        }

        const changed = lastWidth !== newWidth;
        if (changed) {
            this.element.model.width = newWidth;
            neighbor.model.width = newNeighborWidth;
        }

        return changed;
    },

    renderWidget(config) {
        const $widget = this.$el.addEl($.div("drag-widget horizontal"));
        $widget.addClass(config.position);
        let dragStart;
        const isPositionedRight = config.position === WidgetPositionType.RIGHT;
        const direction = isPositionedRight ? 1 : -1;

        $widget.makeDraggable({
            axis: "x",
            start: event => {
                ds.selection.element = null;
                app.isDraggingItem = true;
                dragStart = event.clientX;
            },
            drag: event => {
                let amountMoved = event.clientX - dragStart;
                const scale = this.element.canvas.canvasScale;
                const changed = this.updateSpans(amountMoved / scale, direction);

                if (changed) {
                    dragStart = event.clientX;
                    this.element.canvas.refreshCanvasAutoRevert();
                }
            },
            stop: () => {
                ds.selection.element = this.element;
                app.isDraggingItem = false;
                this.element.canvas.updateCanvasModel();
            }
        });
    },

    _layout() {
        const selectionBounds = this.getElementSelectionBounds().zeroOffset();

        this.$el.find(".drag-widget.right").center(selectionBounds.getPoint(AnchorType.RIGHT));
        this.$el.find(".drag-widget.left").center(selectionBounds.getPoint(AnchorType.LEFT));
    }

});

const TimelineRollover = ElementRollover.extend({

    captureMouseEvents: true,

    calculateSpanHeight: function(top = true) {
        if (!this.element.elements.spans || this.element.elements.spans.elements.length === 0) {
            return 0;
        }

        const middleLine = (Math.max(...this.element.elements.spans.itemElements.map(element => element.bounds.height)) / 2);
        return top ? -middleLine : middleLine;
    },

    renderTopAddMilestoneButton: function(elementHeight, timelineBounds) {
        const $addMilestoneButton = this.$el.addEl($.div("ui_widget").css("pointer-events", "none"));
        $addMilestoneButton.append($.div("add_component_button button", "Add Milestone").left(-55).top(this.calculateSpanHeight() - 40));
        $addMilestoneButton.top(elementHeight).width(120);
        $addMilestoneButton.append($.div("vertical_line").left(0).top(-30 + this.calculateSpanHeight()));
        $addMilestoneButton.append($.div("add_item_button button").left(-10).top(this.calculateSpanHeight()));
        $addMilestoneButton.on("click", event => {
            const x = this.screenToCanvasCoordinates(new geom.Point(event.pageX, event.pageY)).x - this.element.bounds.left;
            // convert x to percentage
            const percentX = (x - timelineBounds.left) / timelineBounds.width;
            this.addItem(percentX, true);
        });

        // We want users to hover the top line and give them some padding on top of it
        this.registerSubComponent({
            widget: $addMilestoneButton,
            bounds: this.canvasToSelectionCoordinates(new geom.Rect(timelineBounds.left,
                timelineBounds.height / 2 + this.calculateSpanHeight(true) - 15,
                timelineBounds.width, 30)),
            rollover: event => {
                $addMilestoneButton.left(event.pageX - this.$el.offset().left).top(this.elementBounds.height / 2 - 10);
            },
        });

        return $addMilestoneButton;
    },

    renderBottomAddMilestoneButton: function(elementHeight, timelineBounds) {
        const $addBottomMilestoneButton = this.$el.addEl($.div("ui_widget").css("pointer-events", "none"));
        $addBottomMilestoneButton.append($.div("add_component_button button", "Add Milestone").left(-55).top(this.calculateSpanHeight(false) + 40));
        $addBottomMilestoneButton.top(elementHeight).width(120);
        $addBottomMilestoneButton.append($.div("vertical_line").left(0).top(this.calculateSpanHeight(false)));
        $addBottomMilestoneButton.append($.div("add_item_button button").left(-10).top(this.calculateSpanHeight(false)));
        $addBottomMilestoneButton.on("click", event => {
            const x = this.screenToCanvasCoordinates(new geom.Point(event.pageX, event.pageY)).x - this.element.bounds.left;
            // convert x to percentage
            const percentX = (x - timelineBounds.left) / timelineBounds.width;
            this.addItem(percentX);
        });

        this.registerSubComponent({
            widget: $addBottomMilestoneButton,
            bounds: this.canvasToSelectionCoordinates(new geom.Rect(timelineBounds.left,
                timelineBounds.height / 2 + this.calculateSpanHeight(false) - 15,
                timelineBounds.width, 30)),
            rollover: event => {
                $addBottomMilestoneButton.left(event.pageX - this.$el.offset().left).top(this.elementBounds.height / 2 - 10);
            },
        });

        return $addBottomMilestoneButton;
    },

    renderControls: function() {
        if (ds.selection.element || this.element.annotations.itemCount >= this.element.annotations.maxItemCount) {
            return;
        }

        const timelineBounds = this.element.timeline.calculatedProps.bounds;

        let elementHeight = this.calculateSpanHeight();
        if (elementHeight === 0) elementHeight = this.elementBounds.height / 2;

        let $addBottomAddMilestoneButton;
        const $addTopMilestoneButton = this.renderTopAddMilestoneButton(elementHeight, timelineBounds);
        if (this.element.spans.length > 0) {
            $addBottomAddMilestoneButton = this.renderBottomAddMilestoneButton(elementHeight, timelineBounds);
        }

        // these widget subcomponents exist just to hide the $addMilestoneButton widget when rolling over a milestone point
        for (const item of this.element.annotations.itemElements) {
            const $milestoneDrag = this.$el.addEl($.div("ui_widget"));
            this.registerSubComponent({
                widget: $milestoneDrag,
                target: item,
                bounds: new geom.Rect(this.canvasToSelectionCoordinates(item.bounds).left - 15, this.elementBounds.height / 2 - 15, 30, 300),
                rollover: event => {
                    $addTopMilestoneButton.hide();
                    $addBottomAddMilestoneButton?.hide();
                }
            });
        }
    },

    reverseAnnotations: function(annotationModel, isTop) {
        const sortedAnnotations = this.element.annotations.itemCollection.sort((a, b) => a.x - b.x);
        const reversed = this.element.isReversed;

        const addedAnnotationIndex = findInsertionIndex(sortedAnnotations.map(annotation => annotation.x), annotationModel.x);

        if (this.element.spanCount) {
        // first and last elements are special cases
            if (addedAnnotationIndex === 0) {
                this.element.model.reversed = !isTop;
            } else if (addedAnnotationIndex === this.element.annotations.itemCount) {
                const prevElement = sortedAnnotations[addedAnnotationIndex - 1];
                const shouldChange = prevElement.y < 0.5 && isTop || prevElement.y > 0.5 && !isTop;

                // last item
                if (shouldChange) {
                    this.element.model.reversed = !reversed;
                }
            } else {
                const nextElement = sortedAnnotations[addedAnnotationIndex + 1];
                const shouldChange = nextElement.y > 0.5 && isTop || nextElement.y < 0.5 && !isTop;
                // in the middle items
                if (shouldChange) {
                    this.element.model.reversed = reversed ? false : true;
                }
            }
        }
    },

    addItem: function(x, isTop) {
        const annotationModel = this.element.getDefaultAnnotationModel();
        annotationModel.x = x;

        if (this.element.spanCount) {
            // We can reverse ONLY if we didn't drag any milestones yet
            if (!this.element.hasDraggedMilestones) {
                this.reverseAnnotations(annotationModel, isTop);
            } else {
                annotationModel.y = isTop ? 0.25 : 0.75;
            }
        }

        this.element.annotations.addItem(annotationModel);

        this.element.canvas.updateCanvasModel(true).catch(err => {
            ShowWarningDialog({
                title: "Unable to add item",
                message: err.message,
            });
        });
    }
});

const TimelineOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.TOGGLE,
            label: "Start Marker",
            property: "showStartMarker"
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "End Marker",
            property: "showEndMarker"
        });
    }
});

export const editors = {
    TimelineSpanItemContainerSelection,
    TimelineSelection,
    TimelineRollover,
    TimelineOptionsMenu
};
