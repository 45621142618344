import * as geom from "js/core/utilities/geom";
import { HorizontalAlignType, VerticalAlignType, BlockStructureType, AuthoringBlockType, TextStyleType, NodeType } from "common/constants";
import { _ } from "js/vendor";
// TODO: Why this is imported directly instead of from js/vendor?
import * as venn from "vendor/d3.venn";

import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { SVGCircleElement } from "../base/SVGElement";
import { layoutHelper } from "../layouts/LayoutHelper";
import { TextElement } from "../base/Text/TextElement";
import { detectCompareContent } from "js/core/services/sharedModelManager";

class VennDiagram extends CollectionElement {
    static get schema() {
        return {
            modelVersion: 2
        };
    }

    getChildItemType() {
        return VennDiagramItem;
    }

    get maxItemCount() {
        return 20;
    }

    get defaultItemData() {
        return {
            x: parseInt(Math.random() * 100) - 50,
            y: 0,
            radius: 150,
            label: { text: "" },
            labelCenterX: 0,
            labelCenterY: 0
        };
    }

    _build() {
        this.buildItems();
    }

    _calcProps(props, options) {
        let { size } = props;

        if (this.model.isVersion8Model) {
            this._migrationCalc(props);
        }

        let calcLayout = scale => {
            for (let item of this.itemElements) {
                let r = item.currentRadius * scale;
                let itemProps = item.calcProps(new geom.Size(r * 2, r * 2));
                itemProps.bounds = new geom.Rect(item.model.x * scale - r, item.model.y * scale - r, itemProps.size);
            }
        };
        calcLayout(1);

        let totalBounds = layoutHelper.getTotalBounds(this.itemElements.map(item => item.calculatedProps.bounds));

        let scale = Math.min(1, Math.min(size.width / totalBounds.width, size.height / totalBounds.height));

        if (scale < 1) {
            calcLayout(scale);
            totalBounds = layoutHelper.getTotalBounds(this.itemElements.map(item => item.calculatedProps.bounds));
        }

        let offset = layoutHelper.getAlignOffset(totalBounds, size, HorizontalAlignType.CENTER, VerticalAlignType.MIDDLE);
        offset = offset.offset(totalBounds.position.multiply(-1));
        for (let item of this.itemElements) {
            item.calculatedProps.bounds = item.calculatedProps.bounds.offset(offset);
            item.calculatedProps.layer = this.itemElements.indexOf(item);
        }

        return { size, scale, offset };
    }

    beautify() {
        this.calcProps(this.calculatedProps.allowedSize);

        // beautify the labels
        for (let item of this.itemElements) {
            if (!item.model.userPositionedLabel) {
                let itemCenter = item.canvasBounds.center;
                let labelSize = item.text.bounds.size.inflate(10);
                let labelBoundsAtCenter = new geom.Rect(itemCenter.x - labelSize.width / 2, itemCenter.y - labelSize.height / 2, labelSize);

                let otherItems = _.filter(this.itemElements, circle => circle !== item);

                // check against each other venn item if the label bounds when centered intersect with it
                // if so, calculate the optimum text center and use that
                for (let otherItem of otherItems) {
                    let otherItemCenter = otherItem.canvasBounds.center;
                    let closestX = Math.clamp(otherItemCenter.x, labelBoundsAtCenter.left, labelBoundsAtCenter.right);
                    let closestY = Math.clamp(otherItemCenter.y, labelBoundsAtCenter.top, labelBoundsAtCenter.bottom);
                    let distanceX = otherItemCenter.x - closestX;
                    let distanceY = otherItemCenter.y - closestY;

                    let distanceSquared = (distanceX * distanceX) + (distanceY * distanceY);

                    if (distanceSquared < (otherItem.model.radius * otherItem.model.radius)) {
                        // calculate an optimum center for the label
                        let labelCenter = venn.computeTextCentre([{
                            x: item.model.x,
                            y: item.model.y,
                            radius: item.model.radius - 20
                        }], otherItems.map(i => i.model));
                        item.model.labelCenterX = labelCenter.x - item.model.x;
                        item.model.labelCenterY = labelCenter.y - item.model.y;
                        break;
                    } else {
                        // center the label in the bubble
                        item.model.labelCenterX = 0;
                        item.model.labelCenterY = 0;
                    }
                }
            }
        }
    }

    _migrate_9() {
        this.model.isVersion8Model = true; // set flag so we can call _migrationCalc in calcProps
        for (let item of this.itemCollection) {
            item.title = item.label;
            item.label = null;
        }
    }

    _migrationCalc(props) {
        let { size } = props;

        let outerBounds;

        for (let item of this.itemElements) {
            // store props for computeTextCentre function
            item.x = (item.model.x || 20) * size.width / 100;
            item.y = (item.model.y || 20) * size.height / 100;

            item.radius = item.model.radius * (size.height / 2 + size.width / 3.6) / 100;
            // item.radius = this.calcRadius(item.model.radius, size);

            let itemSize = new geom.Size(item.radius * 2, item.radius * 2);

            let labelSize = item.label.calcProps(itemSize).size;
            let otherItems = _.filter(this.itemElements, circle => circle !== item);
            let labelCenter = venn.computeTextCentre([item], otherItems);

            if (labelCenter.margin * -0.5 > labelSize.width) {
                labelCenter.x = item.x;
                labelCenter.y = item.y;
            }
            labelCenter.x -= item.x;
            labelCenter.y -= item.y;

            item.model.labelCenterX = labelCenter.x;
            item.model.labelCenterY = labelCenter.y;

            let itemProps = item.calcProps(new geom.Size(item.radius * 2, item.radius * 2));
            itemProps.bounds = new geom.Rect(item.x - item.radius, item.y - item.radius, itemProps.size);

            if (outerBounds) {
                outerBounds = outerBounds.union(itemProps.bounds);
            } else {
                outerBounds = itemProps.bounds;
            }
        }

        let offsetX = size.width / 2 - outerBounds.width / 2 - outerBounds.left;
        let offsetY = size.height / 2 - outerBounds.height / 2 - outerBounds.top;

        for (let item of this.itemElements) {
            item.model.x = ((item.x + offsetX) - size.width / 2);
            item.model.y = ((item.y + offsetY) - size.height / 2);
            item.model.radius = item.radius;
        }

        this.model.isVersion8Model = null;
    }

    _exportToSharedModel() {
        const textContent = this.itemElements.reduce((textContent, item) => ([
            ...textContent, ...item.text._exportToSharedModel().textContent
        ]), []);

        const compareContent = this.itemElements.map((item, i) => ({
            value: item.model.radius, text: textContent[i],
            format: "number", emphasized: !!item.model.hilited
        }));

        const graphData = [{
            nodes: this.itemElements.map((item, i) => ({
                type: NodeType.CIRCLE,
                size: item.model.radius,
                textContent: textContent[i]
            }))
        }];

        return { textContent, compareContent, graphData };
    }

    _importFromSharedModel(model) {
        const compareContent = detectCompareContent(model);
        if (!compareContent?.length) return;

        const items = compareContent.map(({ text, value, emphasized }) => ({
            radius: value,
            text: {
                blocks: [
                    {
                        html: text.mainText.text,
                        textStyle: TextStyleType.HEADING,
                        type: AuthoringBlockType.TEXT,
                    },
                    ...text.secondaryTexts.map(({ text, textStyle }) => ({
                        html: text,
                        textStyle: textStyle || TextStyleType.BODY,
                        type: AuthoringBlockType.TEXT,
                    }))
                ]
            }
        }));

        items.splice(this.maxItemCount);
        return {
            items,
            postProcessingFunction: canvas => canvas.getPrimaryElement().beautify()
        };
    }
}

class VennDiagramItem extends CollectionItemElement {
    static get schema() {
        return {
            radius: Math.random() * 100 + 50,
            x: Math.random() * 200 - 100,
            y: Math.random() * 200 - 100,
        };
    }

    get selectionPadding() {
        return 0;
    }

    get currentRadius() {
        return this.model.radius;
    }

    get canRefreshElement() {
        return true;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition, true);
    }

    _build() {
        this.circle = this.addElement("circle", () => SVGCircleElement);
        this.text = this.addElement("text", () => VennDiagramLabel, {
            blockStructure: BlockStructureType.HEADER,
            autoWidth: true,
            autoHeight: true,
            scaleTextToFit: true,
            allowAlignment: true,
            syncFontSizeWithSiblings: true
        });
    }

    _calcProps(props, options) {
        let { size } = props;

        let circleProps = this.circle.createProps();
        circleProps.bounds = new geom.Rect(0, 0, size).scale(this.animationState.value ?? 1);

        let labelPositionX = this.model.labelCenterX ?? options.labelCenter?.x ?? 0;
        let labelPositionY = this.model.labelCenterY ?? options.labelCenter?.y ?? 0;

        let textProps = this.text.calcProps(new geom.Size(this.model.textWidth ?? size.width, 1000));
        textProps.bounds = new geom.Rect(size.width / 2 - textProps.size.width / 2 + labelPositionX, size.height / 2 - textProps.size.height / 2 + labelPositionY, textProps.size);

        return { size };
    }

    get animationElementName() {
        return `Bubble #${this.itemIndex + 1}`;
    }

    _getAnimations() {
        return [{
            name: "Grow in",
            prepare: () => {
                this.text.animationState.fadeInProgress = 0;
                this.animationState.fadeInProgress = 0;
                this.animationState.value = 0;
            },
            onBeforeAnimationFrame: progress => {
                // this.text.animationState.fadeInProgress = Math.clamp((progress - 0.7) / 0.3, 0, 1);
                this.text.animationState.fadeInProgress = progress;
                this.animationState.fadeInProgress = Math.min(1, progress * 3);
                this.animationState.value = progress;
                return this.parentElement;
            }
        }];
    }
}

class VennDiagramLabel extends TextElement {
    get requireParentSelection() {
        return false;
    }

    get passThroughSelection() {
        return false;
    }

    get selectionPadding() {
        return { left: 34, right: 34, top: 20, bottom: 20 };
    }

    _migrate_10() {
        delete this.model.titleTextStyle; // prevents convert to header block BA-11706
        super._migrate_10();
    }
}

export const elements = {
    VennDiagram,
};
