import { BaseElement } from "../base/BaseElement";
import { FramedMediaElement } from "../base/MediaElements/FramedMediaElement";
import { AssetType } from "common/constants";

class MediaBlock extends BaseElement {
    get hasValidAsset() {
        return this.media.hasValidAsset;
    }

    get allowDecorationStyles() {
        return this.media.allowDecorationStyles;
    }

    _build() {
        this.media = this.addElement("media", () => FramedMediaElement, {
            preserveAspectRatioForUnframed: true,
            allowUnframedImages: true,
            fitToHeight: true,
            autoWidth: true,
            defaultAssetType: AssetType.ICON
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        const mediaProps = this.media.calcProps(size, { autoHeight: true });

        return { size: mediaProps.size };
    }
}

export const elements = {
    MediaBlock
};

