import React, { Component } from "react";
import styled from "styled-components";

import { FlexBox, ScrollBox } from "js/react/components/LayoutGrid";
import { $ } from "js/vendor";
import { getStaticUrl } from "js/config";
import { themeColors } from "js/react/sharedStyles";

import { DeviceFrames } from "../../elements/base/Frame";
import { Gap10, Gap20 } from "js/react/components/Gap";
import { Divider } from "js/react/components/UiComponents";
import ThemeOption from "js/editor/ThemeEditor/components/ThemeOption";
import { shapeStyle } from "js/editor/ThemeEditor/panes/ThemeDecoration";

const Menu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #eee;
  width: 100%;
`;

const MenuItem = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  border-bottom: ${props => props.selected ? `solid 3px ${themeColors.ui_blue}` : "`solid 3px transparent"};
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export class ImageFrameMenu extends Component {
    state = {
        selectedTab: "shape"
    }

    constructor(props) {
        super(props);

        const category = DeviceFrames.find(frame => frame.id === props.selectedFrame)?.category ?? "Shape";
        let selectedTab = "shape";
        switch (category) {
            case "Shape":
                selectedTab = "shape";
                break;
            case "Decorative":
                selectedTab = "decorative";
                break;
            default:
                selectedTab = "device";
        }

        if (props.allowedCategories && !props.allowedCategories.includes(selectedTab)) {
            selectedTab = props.allowedCategories[0];
        }

        this.state = {
            selectedTab,
            selectedFrame: props.selectedFrame
        };
    }

    handleSelect = frame => {
        const img = $(`#${frame.id}`)[0];
        let aspectRatio = null;
        if (frame.fixedAspectRatio) {
            aspectRatio = img.naturalWidth / img.naturalHeight;
        }
        this.props.onSelect(frame.id, aspectRatio);

        this.setState({ selectedFrame: frame.id });
        if (frame.category !== "Shape" && this.props.closePopup) {
            this.props.closePopup();
        }
    }

    handleSelectStyle = option => {
        this.props.onSelectStyle(option.decorationStyle);
    }

    selectTab = selectedTab => {
        this.setState({ selectedTab }, () => {
            this.props.repositionPopup && this.props.repositionPopup();
        });
    }

    render() {
        const {
            allowedCategories = ["shape", "device", "decorative"],
            selectedStyle,
        } = this.props;
        const {
            selectedTab,
            selectedFrame,
        } = this.state;

        return (
            <FlexBox vertical width={800}>
                {allowedCategories.length > 1 && <Menu>
                    <MenuItem selected={selectedTab === "shape"}
                        onClick={() => this.selectTab("shape")}
                    >Shape Frames</MenuItem>
                    <MenuItem selected={selectedTab === "device"}
                        onClick={() => this.selectTab("device")}
                    >Device Frames</MenuItem>
                    <MenuItem selected={selectedTab === "decorative"}
                        onClick={() => this.selectTab("decorative")}
                    >Decorative Frames</MenuItem>
                </Menu>}

                {
                    selectedTab === "shape" &&
                    <ShapeFrames
                        onSelect={this.handleSelect}
                        onSelectStyle={this.handleSelectStyle}
                        selectedFrame={selectedFrame}
                        selectedStyle={selectedStyle}
                    />
                }
                {
                    selectedTab === "device" &&
                    <DevicesFrames
                        onSelect={this.handleSelect}
                        selectedFrame={selectedFrame}
                    />
                }
                {
                    selectedTab === "decorative" &&
                    <DecorativeFrames
                        onSelect={this.handleSelect}
                        selectedFrame={selectedFrame}
                    />
                }

            </FlexBox>
        );
    }
}

const FrameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  padding: 10px;

  img {
    width: 70px;
    height: 70px;
    object-fit: contain;
  }
`;

const OptionsList = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  padding: 0px 20px;
  div {
    label {
      font-size: 10px;
      text-transform: uppercase;
      font-weight: 600;
      margin-left: 10px;
    }
  }
`;

class ShapeFrames extends Component {
    render() {
        const {
            selectedFrame,
            selectedStyle,
        } = this.props;

        let frames = DeviceFrames.filter(frame => frame.category === "Shape");
        let isShapeFrameSelected = frames.filter(frame => frame.id == selectedFrame && selectedFrame != "none").length > 0;

        return (
            <FlexBox vertical fillWidth left>
                <FrameGrid>
                    {frames.map(frame => <FrameItem key={frame.id} frame={frame} onSelect={this.props.onSelect} selected={selectedFrame == frame.id} />)}
                </FrameGrid>
                {isShapeFrameSelected &&
                    <>
                        <Divider margin={0} />
                        <Gap20 />
                        <OptionsList>
                            {shapeStyle.map(option => (
                                <ThemeOption
                                    key={option.value}
                                    item={option}
                                    selected={selectedStyle === option.value}
                                    update={this.props.onSelectStyle}
                                    property="decorationStyle"
                                />
                            ))}
                        </OptionsList>
                        <Gap20 />
                    </>
                }
            </FlexBox>
        );
    }
}

const CategoryHeader = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 600;
  color: #777;
  padding: 8px 0px;
  border-bottom: solid 1px #ddd;
  margin: 20px 0px 20px;
  &:first-of-type {
    margin-top: 10px;
  }
`;

const DeviceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 0px;
  row-gap: 10px;

  img {
    width: 100%;
    height: 80px;
    object-fit: contain;
  }
`;

class DevicesFrames extends Component {
    render() {
        const { selectedFrame } = this.props;

        return (
            <ScrollBox fillWidth style={{ padding: "0px 20px", maxHeight: 400 }}>
                <CategoryHeader>Computers</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Computers" && !frame.isHidden).map(frame => (<FrameItem key={frame.id} frame={frame}
                        selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <CategoryHeader>Displays</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Displays" && !frame.isHidden).map(frame => (<FrameItem key={frame.id} frame={frame}
                        selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <CategoryHeader>Phones</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Phones" && !frame.isHidden).map(frame => (<FrameItem key={frame.id} frame={frame}
                        selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <CategoryHeader>Cisco Devices</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Cisco" && !frame.isHidden).map(frame => (<FrameItem key={frame.id} frame={frame}
                        selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <CategoryHeader>Other</CategoryHeader>
                <DeviceGrid>
                    {DeviceFrames.filter(frame => frame.category == "Other" && !frame.isHidden).map(frame => (<FrameItem key={frame.id} frame={frame}
                        selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />))}
                </DeviceGrid>
                <Gap20 />
            </ScrollBox>
        );
    }
}

class DecorativeFrames extends Component {
    render() {
        const { selectedFrame } = this.props;

        let frames = DeviceFrames.filter(frame => frame.category === "Decorative");

        return (
            <FlexBox vertical fillWidth left>
                <FrameGrid>
                    {frames.map(frame => <FrameItem key={frame.id} frame={frame} selected={selectedFrame == frame.id} onSelect={this.props.onSelect} />)}
                </FrameGrid>
            </FlexBox>
        );
    }
}

const FrameItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  padding: 20px;
  &:hover {
    background: #f1f1f1;
  }
  background: ${props => props.selected ? themeColors.selection : null}
`;

class FrameItem extends Component {
    render() {
        const { frame, onSelect, selected } = this.props;

        return (
            <FrameItemContainer onClick={() => onSelect(frame)} selected={selected}>
                <img id={frame.id} src={getStaticUrl("/images/frames/thumbnails/" + frame.thumbnail)} />
                <Gap10 />
                <label>{frame.name}</label>
            </FrameItemContainer>
        );
    }
}
