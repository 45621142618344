import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { csvDataToChartData, getSelectedSpreadsheetData } from "js/core/utilities/xlsx";
import { BlockStructureType, PositionType } from "common/constants";

import { TextElement } from "../base/Text/TextElement";
import { CollectionElement, CollectionItemElement } from "../base/CollectionElement";
import { Chart } from "./Chart";
import { PieChart } from "./PieChart";
import { chartUtils } from "../../slideTemplates/slideTemplates";

class Dashboard extends CollectionElement {
    getCanvasMargins() {
        // We don't want charts to be overlapped by the branding, so we limit
        // margin bottom to 60px
        const margins = super.getCanvasMargins();
        margins.bottom = Math.max(margins.bottom, 60);
        return margins;
    }

    get maxItemCount() {
        return 4;
    }

    getChildItemType() {
        return DashboardItem;
    }

    get defaultItemData() {
        return {
            elementType: "chart",
            chart: chartUtils.getDefaultChartModel("line")
        };
    }

    get showTitle() {
        return this.model.showTitle ?? false;
    }

    get preventTransitionDuringRender() {
        return true;
    }

    _build() {
        if (this.model.items?.length > 1) {
            if (this.model.showTitle === undefined) {
                this.model.showTitle = true;
            }
        }

        this.model.items?.forEach(item => {
            // We do not enable legend for "piechart" chart type
            if (item.elementType === "chart") {
                if (item[item.elementType].legendPosition === undefined && item[item.elementType].chartData.series.length > 1) {
                    item[item.elementType].legendPosition = PositionType.BOTTOM;
                }
            }
        });

        super._build();
    }

    _calcProps(props, options) {
        let { size } = props;

        var itemBounds = [];
        switch (this.itemElements.length) {
            case 1:
                itemBounds.push(new geom.Rect(0, 0, size));
                break;
            case 2:
                switch (this.model.layout) {
                    case "2-charts-vertical":
                        itemBounds.push(new geom.Rect(0, 0, size.width, size.height / 2 - this.styles.vGap / 2));
                        itemBounds.push(new geom.Rect(0, size.height / 2 + this.styles.vGap / 2, size.width, size.height / 2 - this.styles.vGap / 2));
                        break;
                    case "2-charts-horizontal":
                    default:
                        itemBounds.push(new geom.Rect(0, 0, size.width / 2 - this.styles.hGap / 2, size.height));
                        itemBounds.push(new geom.Rect(size.width / 2 + this.styles.hGap / 2, 0, size.width / 2 - this.styles.hGap / 2, size.height));
                        break;
                }
                break;
            case 3:
                switch (this.model.layout) {
                    case "3-charts-horizontal":
                        let thirdWidth = (size.width - this.styles.hGap * 2) / 3;
                        itemBounds.push(new geom.Rect(0, 0, thirdWidth, size.height));
                        itemBounds.push(new geom.Rect(thirdWidth + this.styles.hGap, 0, thirdWidth, size.height));
                        itemBounds.push(new geom.Rect(size.width - thirdWidth, 0, thirdWidth, size.height));
                        break;
                    case "3-charts-right":
                        itemBounds.push(new geom.Rect(size.width / 2 + this.styles.hGap / 2, 0, size.width / 2 - this.styles.hGap / 2, size.height));
                        itemBounds.push(new geom.Rect(0, 0, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                        itemBounds.push(new geom.Rect(0, 0 + size.height / 2 + this.styles.vGap / 2, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                        break;
                    case "3-charts-top":
                        itemBounds.push(new geom.Rect(0, 0, size.width, size.height / 2 - this.styles.vGap / 2));
                        itemBounds.push(new geom.Rect(0, size.height / 2 + this.styles.vGap / 2, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                        itemBounds.push(new geom.Rect(size.width / 2 + this.styles.hGap / 2, size.height / 2 + this.styles.vGap / 2, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                        break;
                    case "3-charts-bottom":
                        itemBounds.push(new geom.Rect(0, 0, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                        itemBounds.push(new geom.Rect(size.width / 2 + this.styles.hGap / 2, 0, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                        itemBounds.push(new geom.Rect(0, size.height / 2 + this.styles.vGap / 2, size.width, size.height / 2 - this.styles.vGap / 2));
                        break;
                    case "3-charts-left":
                    default:
                        itemBounds.push(new geom.Rect(0, 0, size.width / 2 - this.styles.hGap / 2, size.height));
                        itemBounds.push(new geom.Rect(size.width / 2 + this.styles.hGap / 2, 0, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                        itemBounds.push(new geom.Rect(size.width / 2 + this.styles.hGap / 2, 0 + size.height / 2 + this.styles.vGap / 2, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                        break;
                }
                break;
            case 4:
            default:
                itemBounds.push(new geom.Rect(0, 0, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                itemBounds.push(new geom.Rect(0, size.height / 2 + this.styles.vGap / 2, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                itemBounds.push(new geom.Rect(size.width / 2 + this.styles.hGap / 2, 0, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                itemBounds.push(new geom.Rect(size.width / 2 + this.styles.hGap / 2, 0 + size.height / 2 + this.styles.vGap / 2, size.width / 2 - this.styles.hGap / 2, size.height / 2 - this.styles.vGap / 2));
                break;
        }

        let maxTitleHeight = 0;
        if (this.showTitle) {
            for (let i = 0; i < this.itemElements.length; i++) {
                let titleSize = this.itemElements[i].calcTitleSize(this.styles.DashboardItem.chartTitle, itemBounds[i].size);
                maxTitleHeight = Math.max(titleSize.height, maxTitleHeight);
            }
        }

        for (let i = 0; i < this.itemElements.length; i++) {
            let itemProps = this.itemElements[i].calcProps(itemBounds[i].size, { titleHeight: maxTitleHeight });
            itemProps.bounds = itemBounds[i];
        }
        return { size };
    }

    setItemCount(count) {
        if (count > this.itemElements.length) {
            for (let i = this.itemElements.length; i < count; i++) {
                this.addItem(this.defaultItemData);
            }
        } else if (count < this.itemElements.length) {
            for (let i = this.itemElements.length; i > count; i--) {
                this.deleteItem(this.itemElements[i - 1].id);
            }
        }
    }

    _migrate_8() {
        // migrate layout because default value has changed
        if (this.model.items.length == 2 && this.model.layout == null) {
            this.model.layout = "2-charts-vertical";
        }
    }

    _exportToSharedModel() {
        return this.itemElements.reduce(
            (model, itemElement) => _.mergeWith(model, itemElement.infographic._exportToSharedModel(), (a, b) => {
                if (_.isArray(a)) return a.concat(b);
            }), {});
    }

    _importFromSharedModel(model) {
        const items = this.itemElements.map(itemElement => {
            const { dataSourceLink, ...chart } = itemElement.infographic._importFromSharedModel(model);
            return { ...itemElement.model, chart, dataSourceLink };
        });

        const itemsWithPostProcess = items.filter(item => !!item.chart?.postProcessingFunction);
        const postProcessingFunction = itemsWithPostProcess.length
            ? canvas => itemsWithPostProcess.forEach(item => item.chart.postProcessingFunction(canvas)) : null;

        return { items, postProcessingFunction };
    }
}

class DashboardItem extends CollectionItemElement {
    get showTitle() {
        return this.parentElement.showTitle;
    }

    get selectionPadding() {
        return 10;
    }

    get canRollover() {
        return true;
    }

    get selectionUIType() {
        switch (this.infographic.type) {
            case "piechart":
                break;
            default:
                return "ChartSelection";
        }
    }

    get rolloverPadding() {
        return { left: 0, right: 0, top: 0, bottom: 15 };  // allows extra space for category axis title clicking
    }

    _build() {
        if (this.showTitle) {
            if (!this.model.chartTitle) {
                this.model.chartTitle = {};
            }
            this.chartTitle = this.addElement("chartTitle", () => TextElement, {
                blockStructure: BlockStructureType.TITLE_AND_BODY,
                model: this.model.chartTitle,
                autoHeight: true,
            });
        }

        switch (this.model.elementType) {
            case "chart": {
                let firstSeriesType = this.model.chart.chartData.series[0].type;
                this.infographic = this.addElement("infographic", () => Chart, {
                    model: this.model.chart,
                    rollover: firstSeriesType === "waterfall" ? "WaterfallChartRollover" : "ChartRollover"
                });
                break;
            }
            case "piechart":
                this.infographic = this.addElement("infographic", () => PieChart, {
                    model: this.model.piechart,
                });
                break;
        }
    }

    calcTitleSize(styles, size) {
        if (this.showTitle) {
            switch (this.model.elementType) {
                case "chart":
                    if (this.showTitle == "below") {
                        this.chartTitle.styles.marginTop = 35;
                    } else {
                        this.chartTitle.styles.marginBottom = 15;
                    }
                    break;
                case "piechart":
                    if (this.showTitle == "below") {
                        this.chartTitle.styles.marginTop = 20;
                    } else {
                        this.chartTitle.styles.marginBottom = 20;
                    }
                    break;
            }
            return this.chartTitle.calcProps(size).size;
        } else {
            return new geom.Size(0, 0);
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        this.titleHeight = options.titleHeight;
        let y = 0;
        if (this.showTitle) {
            let titleProps = this.chartTitle.calcProps(new geom.Size(size.width, options.titleHeight));
            if (this.showTitle === "below") {
                titleProps.bounds = new geom.Rect(0, size.height - titleProps.size.height, titleProps.size);
                y = 0;
            } else {
                titleProps.bounds = new geom.Rect(0, 0, titleProps.size);
                y = options.titleHeight;
            }
        }

        let availableHeight = size.height - options.titleHeight;

        let infographicProps = this.infographic.calcProps(new geom.Size(size.width, availableHeight));
        infographicProps.bounds = new geom.Rect(0, y, infographicProps.size);

        return { size };
    }

    getAnimations() {
        return Object.values(this.elements)
            // Sorting in order to put chart title animations in the front
            .sort((a, b) => b.type.localeCompare(a.type))
            .reduce((animations, element) => ([...animations, ...element.getAnimations()]), []);
    }

    _useUpdatedDataSource(dataSourceEntry) {
        let chartData = dataSourceEntry.validatedData, initialImport = dataSourceEntry.initialImport;

        if (this.hasDataSourceLink() && dataSourceEntry.id) {
            const spreadsheetData = dataSourceEntry.spreadsheetData;

            const { selectedSheetIndex, selectedCellRange, isDataTransposed, useFirstRowAsCategory, useFirstColAsLegend } = this.model.dataSourceLink;
            const selectedData = getSelectedSpreadsheetData({ sheets: spreadsheetData }, selectedSheetIndex, selectedCellRange);

            if (isDataTransposed) selectedData.csvData = _.unzip(selectedData.csvData);

            const isPieChart = this.infographic.getChartType?.() === "pie";
            const isWaterfall = this.infographic.getChartType?.() === "waterfall";
            chartData = csvDataToChartData(selectedData.csvData, useFirstRowAsCategory, useFirstColAsLegend, isPieChart, isWaterfall);
        }

        this.infographic.updateChartData(chartData, initialImport);
        this.canvas.updateCanvasModel(false);
    }
}

export { Dashboard, DashboardItem };

export const elements = {
    Dashboard
};
