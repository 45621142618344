import React, { Component } from "react";
import { Icon } from "../../../base/MediaElements/IconElement";
import { DecorationElement } from "../../../base/DecorationElement";
import { BaseElement } from "../../../base/BaseElement";
import { SVGCircleElement } from "../../../base/SVGElement";
import { TextElement } from "../../../base/Text/TextElement";
import * as geom from "js/core/utilities/geom";
import { HorizontalAlignType, VerticalAlignType } from "common/constants";
import styled from "styled-components";

class ListDecoration extends BaseElement {
    get itemIndex() {
        return this.model.bulletIndex ?? 0;
    }

    get canvasBounds() {
        const bounds = super.canvasBounds;

        if (this.parentElement.calculatedProps) {
            const blockProps = this.parentElement.calculatedProps.blockProps.findById(this.blockModel.id);
            return bounds
                .offset(0, this.parentElement.textBounds.top)
                .offset(0, -blockProps.normalizedTopSpace)
                .offset(0, blockProps.textBounds.top);
        }

        return bounds;
    }

    getSize(fontHeight) {
        return fontHeight * 2;
    }
}

export class BulletDecoration extends ListDecoration {
    _build() {
        this.bullet = this.addElement("bullet", () => SVGCircleElement);
    }

    _loadStyles(styles) {
        if (this.model.indent > 0) {
            if (this.model.color && this.model.color != "auto") {
                styles.bullet.fillColor = this.model.color;
            } else {
                styles.bullet.fillColor = "primary";
            }
        }
        this.markStylesAsDirty();
    }

    _calcProps(props, options) {
        const { size } = props;

        const SCALE = .3;

        this.bullet.styles.fillColor = options.textStyles.bulletColor ?? this.bullet.styles.fillColor;

        let bulletProps = this.bullet.calcProps(new geom.Size(size.height * SCALE, size.height * SCALE));

        if (options.textStyles.textAlign == HorizontalAlignType.RIGHT) {
            bulletProps.bounds = new geom.Rect(size.width - bulletProps.size.width, size.height / 2 - bulletProps.size.height / 2, bulletProps.size);
        } else {
            bulletProps.bounds = new geom.Rect(0, size.height / 2 - bulletProps.size.height / 2, bulletProps.size);
        }

        return { size };
    }
}

export class NumberedListDecoration extends ListDecoration {
    _build() {
        this.index = this.addElement("index", () => TextElement, {
            html: "",
            verticalAlign: VerticalAlignType.MIDDLE,
            scaleTextToFit: false,
            canEdit: false
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        let label;
        switch (this.options.listDecorationStyle) {
            case "letters":
                label = String.fromCharCode(64 + options.sequenceNum);
                break;
            case "numbers":
            default:
                label = options.sequenceNum;
                break;
        }

        if (this.model.indent > 0) {
            label += ".";
        }

        this.index.styles.textAlign = HorizontalAlignType.CENTER;
        this.index.styles.fontSize = options.textStyles.fontSize * 0.75;

        this.index.options.html = label;
        let indexProps = this.index.calcProps(size);
        indexProps.bounds = new geom.Rect(0, 0, indexProps.size);

        return { size };
    }

    _renderElement(transition, renderProps = { animationName: null }) {
        // Force number text element to be on top of the main text element so its cursor style gets respected
        return super._renderElement(transition, { ...renderProps, zIndex: 10 });
    }
}

const SimpleNumberedListItem = styled.div`
  position: absolute;
  height: 100%;
  font-family: ${props => props.textStyles.fontFamily};
  font-size: ${props => props.textStyles.fontSize}px;
  color: ${props => props.textStyles.color};
  line-height: ${props => props.textStyles.lineHeight};
`;

export class SimpleNumberedDecoration extends Component {
    render() {
        const { style, textStyles, sequenceNum, indentSize, textBounds } = this.props;

        let label;
        switch (style) {
            case "letters":
                label = `${String.fromCharCode(64 + sequenceNum)}`;
                break;
            case "numbers":
            default:
                label = `${sequenceNum}`;
                break;
        }

        let styles = {
            width: indentSize,
        };

        if (textStyles.textAlign === HorizontalAlignType.RIGHT) {
            styles.left = textBounds.right - indentSize;
            styles.textAlign = HorizontalAlignType.LEFT;
            styles.paddingLeft = "0.6em";
            label = "." + label;
        } else {
            styles.left = 0;
            styles.textAlign = HorizontalAlignType.RIGHT;
            styles.paddingRight = "0.6em";
            label = label + ".";
        }

        return (
            <SimpleNumberedListItem
                textStyles={textStyles}
                style={styles}
            >
                {label}
            </SimpleNumberedListItem>
        );
    }
}

export class IconListDecoration extends ListDecoration {
    get iconId() {
        return this.model.iconId ?? "star";
    }

    getSize(fontHeight) {
        return fontHeight * 2;
    }

    _build() {
        this.icon = this.addElement("icon", () => Icon, {
            icon: this.iconId
        });
    }

    _calcProps(props, options) {
        const { size } = props;

        let iconProps = this.icon.calcProps(size);
        iconProps.bounds = new geom.Rect(0, 0, iconProps.size);

        return { size };
    }
}

export class CheckListDecoration extends ListDecoration {
    get _canRollover() {
        return true;
    }

    get iconId() {
        switch (this.model.checkState) {
            case "unchecked":
                return "check-yes";
            case "crossed-out":
                return "x";
            case "checked":
            default:
                return "check-yes";
        }
    }

    _loadStyles(styles) {
        switch (this.model.checkState) {
            case "unchecked":
                styles.icon.fillColor = "primary";
                styles.icon.opacity = 0.5;
                break;
            case "crossed-out":
                styles.icon.fillColor = "negative";
                break;
            case "checked":
            default:
                styles.icon.fillColor = "positive";
                break;
        }
    }

    _build() {
        this.icon = this.addElement("icon", () => Icon, {
            icon: this.iconId
        });
    }
}
