import {
    CollectionElementSelection,
    CollectionItemElementSelection
} from "../CollectionElementEditor";
import { DragType } from "common/constants";
import { controls } from "js/editor/ui";
import { $ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { app } from "js/namespaces";

const CardsSelection = CollectionElementSelection.extend({

});

const CardSelection = CollectionItemElementSelection.extend({

    getWidgetPosition() {
        return "inner";
    },

    renderControls() {
        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            showBackgroundColors: true,
            showNone: true,
            showAuto: true
        });
    },

    getDragOptions: function() {
        return {
            type: DragType.INSERT_HORIZONTAL,
            multipleRows: true,
            insertGap: this.element.parentElement.styles.gap
        };
    },

    getDropTargets: function(containerElement) {
        let targets = containerElement.itemElements;

        $(".add-row-indicator").remove();

        if (containerElement.rows < 3) {
            let canvasBounds = containerElement.canvasBounds;
            let targetBounds = new geom.Rect(canvasBounds.left, canvasBounds.bottom - 50, canvasBounds.width, 50);

            targets.push({
                id: "add-row",
                itemIndex: 0,
                canvasBounds: targetBounds
            });

            let $dropIndicator = this.selectionLayer.$el.addEl($.div("add-row-indicator", "Add Row"));
            $dropIndicator.setBounds(this.canvasToSelectionCoordinates(targetBounds));
        }
        return targets;
    },

    renderInsertDropIndicator: function(dropTarget, dropEdge) {
        $(".drop-target-indicator").remove();

        if (dropTarget) {
            if (dropTarget.id === "add-row") {
                $(".add-row-indicator").addClass("hover");
                return;
            }
            $(".add-row-indicator").removeClass("hover");

            CollectionItemElementSelection.prototype.renderInsertDropIndicator.apply(this, arguments);
        }
    },

    updateModelFromDropAction: async function(dropTarget, dragProps, insertBeforeTarget) {
        let containerElement = this.element.parentElement;

        if (dropTarget && dropTarget.id === "add-row") {
            // add the dragItem into a new row
            // containerElement.model.rows += 1;
            dragProps.dragItem.model.row = containerElement.rows + 1;
        } else if (dropTarget && dropTarget.id === "row") {
            // update the dragItem's row to match the dropTarget's row
            dragProps.dragItem.model.row = dropTarget.row;
        } else {
            // update the dragItem's row to match the dropTarget's row
            dragProps.dragItem.model.row = dropTarget.model.row;

            let rows = containerElement.model.rows;
            let emptyRows = 0;
            let itemsInRows = containerElement.getItemsInRows();
            for (let row = 0; row < itemsInRows.length; row++) {
                if (itemsInRows[row].length === 0) {
                    emptyRows++;
                }
                for (let item of itemsInRows[row]) {
                    item.model.row -= emptyRows;
                }
            }
            containerElement.model.rows = rows - emptyRows;

            CollectionItemElementSelection.prototype.updateModelFromDropAction.apply(this, arguments);
        }
    },

    onStopDrag: function(event, position, dragProps, options = { forceRender: false }) {
        CollectionItemElementSelection.prototype.onStopDrag.apply(this, arguments);
        $(".add-row-indicator").remove();
    },

});

export const editors = {
    CardsSelection,
    CardSelection
};
