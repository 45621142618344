import React, { Component } from "react";
import styled from "styled-components";
import { Button, DialogActions, DialogTitle, MenuItem, Select, TextField } from "@material-ui/core";

import { Key } from "js/core/utilities/keys";
import { BeautifulDialog, DialogContent, ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { FlexSpacer, Gap20 } from "js/react/components/Gap";
import { ColGrid } from "js/react/components/LayoutGrid";
import { ds } from "js/core/models/dataService";
import Spinner from "js/react/components/Spinner";
import { themeColors } from "js/react/sharedStyles";
import { emailRegex } from "js/core/utilities/regex";

import { stopEventPropagation } from "../AuthoringHelpers";

const Scroller = styled.div`
  overflow-x: scroll;
  padding: 3px 3px 10px 3px;
`;

const ThumbnailContainer = styled.div`
  position: relative;
  width: 256px;
  height: 144px;
  border:  solid 1px #ccc;
  outline: ${props => props.selected ? `solid 3px ${themeColors.ui_blue}` : "none"};


  img {
    width: 100%;
    height: 100%;
  }
`;

export class AddLinkDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            linkType: props.selectedSlide ? "slide" : "url",
            selectedSlide: props.selectedSlide
        };
    }

    handleChangeUrl = event => {
        this.setState({ value: event.target.value });
    }

    handleSetLink = () => {
        const { onSetLink } = this.props;
        let { value } = this.state;
        if (
            !value.startsWith("http") &&
            !value.startsWith("mailto")
        ) {
            if (value.match(emailRegex)) {
                value = `mailto:${value}`;
            } else {
                value = `https://${value}`;
            }
        }
        onSetLink(value);
    }

    handleKeyDown = event => {
        const { closeDialog } = this.props;
        event.stopPropagation();

        if (event.which == Key.ENTER) {
            event.preventDefault();
            closeDialog();
            this.handleSetLink();
        }
    }

    handleChangeLinkType = event => {
        this.setState({
            linkType: event.target.value
        });
    }

    handlePickSlide = slideId => {
        this.setState({ selectedSlide: slideId });
    }

    handleOk = () => {
        const { onSetLink, onSetSlide, closeDialog, linkToSlideAsLink } = this.props;
        const { linkType, selectedSlide } = this.state;

        if (linkType == "url") {
            this.handleSetLink();
        } else {
            if (selectedSlide) {
                if (linkToSlideAsLink) {
                    onSetLink(`bai://${selectedSlide}`);
                } else {
                    onSetSlide(selectedSlide);
                }
            } else {
                return ShowErrorDialog({ title: "Please select a slide to link to" });
            }
        }
        closeDialog();
    }

    handleRemove = () => {
        const { onRemoveLink, closeDialog } = this.props;
        closeDialog();
        onRemoveLink();
    }

    render() {
        const { closeDialog } = this.props;
        const { value, linkType, selectedSlide } = this.state;

        const okIsDisabled = (linkType === "url" && !value?.length) || (linkType === "slide" && !selectedSlide);

        return (
            <BeautifulDialog
                closeDialog={closeDialog}
                maxWidth="md"
                {...stopEventPropagation}
            >
                <DialogTitle>Add Link</DialogTitle>
                <DialogContent>
                    <Select variant="outlined" value={linkType} onChange={this.handleChangeLinkType}>
                        <MenuItem value="url">Link to URL</MenuItem>
                        <MenuItem value="slide">Link to Slide</MenuItem>
                    </Select>
                    <Gap20 />
                    {linkType === "url" &&
                        <TextField
                            autoFocus={true}
                            placeholder="Type url"
                            value={value}
                            fullWidth={true}
                            type="text"
                            onChange={this.handleChangeUrl}
                            onKeyDown={this.handleKeyDown}
                        />
                    }
                    {linkType === "slide" &&
                        <Scroller>
                            <ColGrid gap={10}>
                                {
                                    ds.selection.presentation.slides.map(slide => (
                                        <SlideThumbnail
                                            key={slide.id}
                                            slide={slide}
                                            selected={selectedSlide === slide.id}
                                            onClick={() => this.handlePickSlide(slide.id)}
                                            presentation={ds.selection.presentation}
                                        />
                                    ))
                                }
                            </ColGrid>
                        </Scroller>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleRemove}>Remove Link</Button>
                    <FlexSpacer />
                    <Button onClick={() => closeDialog()}>
                        Cancel
                    </Button>
                    <Button disabled={okIsDisabled} onClick={this.handleOk}>Ok</Button>
                </DialogActions>
            </BeautifulDialog>
        );
    }
}

class SlideThumbnail extends Component {
    state = {
        thumbnailUrl: null
    }

    componentDidMount() {
        const { presentation, slide } = this.props;

        slide.getThumbnailUrl(presentation.id).then(url => {
            this.setState({ thumbnailUrl: url });
        });
    }

    render() {
        let { thumbnailUrl } = this.state;
        let { selected } = this.props;

        return (
            <ThumbnailContainer selected={selected} onClick={this.props.onClick}>
                {thumbnailUrl && <img src={thumbnailUrl} />}
                {!thumbnailUrl && <Spinner />}
            </ThumbnailContainer>
        );
    }
}
