import { isArray } from "lodash-es";
import { v4 as uuid } from "uuid";
import { ds } from "js/core/models/dataService";

import {
    AuthoringElementType,
    AuthoringBlockType,
    TextStyleType,
    AuthoringShapeType,
    VerticalAlignType,
    AssetType
} from "common/constants";

import { app } from "js/namespaces";
import * as geom from "js/core/utilities/geom";
import { sanitizeHtml } from "js/core/utilities/dompurify";
import { _, tinycolor } from "js/vendor";
import { DecorationType } from "common/constants";

import { TextElement, USE_STYLESHEET_FOR_TEXT_STYLES } from "../../../elements/base/Text/TextElement";
import { ContentElement } from "../../../elements/base/ContentElement";
import { SVGElement } from "../../../elements/base/SVGElement";
import ConnectorItem from "../../../elements/elements/connectors/ConnectorItem";
import { trackActivity } from "js/core/utilities/utilities";
import { StreetMap } from "../../../elements/elements/infographics/StreetMap";
import { Video } from "../../../elements/elements/Video";
import { Icon } from "../../../elements/base/MediaElements/IconElement";
import { FramedMediaElement } from "../../../elements/base/MediaElements/FramedMediaElement";

export async function ConvertSlideToAuthoring(canvas) {
    // check for specific export scenarios
    switch (canvas.model.template_id) {
        case "textgrid_carousel":
            return exportMessageCarousel(canvas);

        case "authoring":
            return;
    }

    if (canvas.showSmartSlideWatermark()) {
        return;
    }

    const converted = [];
    await convertElementToAuthoring(canvas.layouter.canvasElement, converted);

    if (converted.length == 0) {
        return;
    }

    // update for the new canvas
    canvas.model = createAuthoringModel(canvas, converted);
    canvas.updateTemplate("authoring");
    await canvas.updateCanvasModel();
}

function createAuthoringModel(canvas, converted) {
    let userFontScale = {};
    for (let convertedModel of converted) {
        // move any userFontScale from converted elements
        if (convertedModel.userFontScale) {
            userFontScale = { ...convertedModel.userFontScale };
            delete convertedModel.userFontScale;
        }
    }

    trackActivity("Slide", "ConvertedToClassic", null, null, { slide_id: canvas.dataModel.id, template: canvas.model.template_id }, { audit: true });

    // check for background conversions
    let background = canvas.getCanvasElement().elements.background;

    let backgroundColor;
    let backgroundStyle = background.canvasBackgroundStyle;
    if (backgroundStyle == "backgroundImage") {
        backgroundColor = background.model.backgroundColor;
    } else {
        backgroundColor = background.canvasBackgroundColor.toRgbString();
    }

    let videoOverlay = canvas.getCanvasElement().elements.annotations.model.videoOverlay;

    return {
        template_id: "authoring",
        elements: {
            primary: {
                elements: converted,
                userFontScale
            },
            annotations: {
                videoOverlay
            }
        },
        layout: {
            headerPosition: "none",
            showFooter: "off",
            slideColor: canvas.model.layout.slideColor,
            trayLayout: "none",
            elementTextBlockPosition: "none",
            backgroundColor,
            backgroundStyle
        },
        animations: {},
        version: canvas.bundleVersion
    };
}

async function exportCustomAsGroup(element, converted) {
    const groupElement = target => exportSelfAsGroup(target, converted);
    const groupChildren = target => exportChildrenAsGroup(target, converted);
    const exportAll = async target => {
        target.isExporting = true;
        const children = [];
        await convertElementToAuthoring(target, children, true);
        converted.push(...children);
        return children;
    };
    await element.convertToAuthoring(groupElement, groupChildren, exportAll);
}

async function exportSelfAsGroup(element, converted, allowGrouping = false) {
    const convert = isArray(element) ? { elements: element } : element;
    const children = [];
    await convertElementToAuthoring(convert, children, allowGrouping);
    groupAll(children, converted);
}

async function exportChildrenAsGroup(element, converted, allowGrouping = false) {
    const convert = isArray(element) ? { elements: element } : element;
    const children = [];
    await exportElementChildren(convert, children, allowGrouping);
    groupAll(children, converted);
}

function groupAll(elements, appendTo) {
    const groupId = uuid();
    for (const element of elements) {
        element.groupIds = [groupId];
        appendTo?.push(element);
    }
}

function isInsideElementType(element, type) {
    let check = element;
    while (check.parentElement) {
        if (check.parentElement.type === type) {
            return true;
        }

        check = check.parentElement;
    }

    return false;
}

async function convertElementToAuthoring(element, converted, allowGrouping = true) {
    if (element.options.preventExport) return;
    if (!element.DOMNode || element.DOMNode.style.display == "none") return;

    // turning off group per BA-11961
    allowGrouping = false;
    // allow for grouping
    if (allowGrouping) {
        switch (element.type) {
            case "AgendaItem":
            case "AgendaSection":
            case "RadialBarChartItem":
            case "LogoGridItem":
            case "TextListItem":
            case "GanttChartTask":
            case "TextBoxGridItem":
            case "StackDiagramItem":
            case "UserTestimonialItem":
            case "ProcessDiagramBoxItem":
            case "SwotDiagramItem":
            case "BoxOrgChartNode":
            case "TimelineItem":
            case "TimelineMarker":
            case "ContactItemIcon":
            case "CircleOrgChartNode":
            case "BigNumberItem":
            case "TextInShapeContentItem":
            case "NumberedTextContentItem":
            case "MonthCalendar":
            case "PictureOrgChartNode":
            case "HorizontalTaskElement":
            case "ProductScreenshotTextListItem":
            case "JourneyStart":
            case "JourneyEnd":
            case "MinimalOrgChartNode":
            // case "VerticalTaskListsColumn":
            case "VerticalTaskElement":
            case "GridComponent":
                return exportSelfAsGroup(element, converted);

            case "VerticalTaskList":
                return exportChildrenAsGroup(element, converted, true);

            case "BulletTextContentItem":
            case "FramedMediaElement":
            case "TextContentItem":
            case "TargetBandSegment":
            case "SliceChartItem":
            case "MediaAndTextContentItem":
            // case "VerticalTaskElement":
            case "TeamItem":
            case "CompareVerticalBarItem":
            case "ProcessDiagramChevronItem":
            case "ArrowBarItem":
            case "PhotoTextListItem":
            case "IconTextGridItem":
            case "CompareBubbleItem":
            case "CompareHorizontalBarItem":
            case "CompareVerticalPictographItem":
            case "VennDiagramItem":
            case "FlexCircleContentItem":
            case "PictorialChartItem":
                return exportChildrenAsGroup(element, converted);

            // photo collages are typically used within
            // tray containers and should only be exported as
            // a group when inside of an actual PhotoCollage
            case "PhotoCollageItem":
                if (isInsideElementType(element, "PhotoCollage")) {
                    return exportSelfAsGroup(element, converted);
                }

            // if a layer provides its own conversion process
            default:
                if (element.convertToAuthoring && !element.isExporting) {
                    return exportCustomAsGroup(element, converted);
                }
        }
    }

    switch (element.type) {
        case "Header":
        case "Footer":
            return exportLayoutComponent(element, converted);
        case "TableFrame":
            return exportTable(element, converted);
        case "ProductScreenshotImage":
            return exportContent(element, converted);
        case "OrgChartConnectors":
        case "GanttChartGridLines":
        case "UserTestimonialDividers":
        case "UserTestimonialAttributionDivider":
        case "GridComponent":
            return exportComplexSVGLines(element, converted);

        case "UserTestimonialItemDesignQuote":
            return exportUserTestimonialQuote(element, converted);

        case "ImageCarouselItem":
            return exportImageCarouselItem(element, converted);

        case "Icon":
            return exportContent(element, converted);

        case "GanttChartGridLabels":
            return exportSVGTextNodes(element, converted);

        case "BigNumberChangeInValueIcon":
        case "PictographIcons":
        case "PictorialChartItemIcons":
        case "WordCloudElement":
            return exportSVG(element, converted);

        // for the journey slide, it doesn't know how to
        // determine the stroke for the road, so just assign
        // a single stroke value
        // we might need to refactor the Journey slide to break
        // up the road graphic into mulitple SVGs
        case "JourneySvg":
            const journey = exportSVG(element, converted);
            journey.strokeWidth = 2;
            return journey;

        case "Dashboard":
            return exportDashboard(element, converted);
        case "Thermometer":
        case "Countdown":
        case "Timer":
            // case "RadialBarGraph":
            return exportElementComponent(element, converted);
    }

    let shouldExportDecoration = true;

    if (element.findClosestOfType(FramedMediaElement)) {
        shouldExportDecoration = false;
    }

    if (element instanceof TextElement && element.decoration && element.styles.decoration?.type !== "svg") {
        shouldExportDecoration = false; // decoration will be exported as shape fill
    }

    if (element.decoration && element.decoration.hasBackground && shouldExportDecoration) {
        exportDecoration(element, converted, "background");
    }

    if (element instanceof TextElement) {
        return exportText(element, converted);
    } else if (element instanceof ContentElement) {
        exportContent(element, converted);
    } else if (element instanceof Icon) {
        return exportContent(element, converted);
    } else if (element instanceof Video) {
        return exportVideo(element, converted);
    } else if (element instanceof ConnectorItem) {
        return exportConnectorItem(element, converted);
    } else if (element instanceof SVGElement) {
        switch (element.type) {
            case "SVGPathElement":
                exportSVG(element, converted);
                break;
            case "SVGPolygonElement":
            case "SVGPolylineElement":
                exportPath(element, converted);
                break;
            case "SVGRectElement":
                exportRect(element, converted);
                break;
            case "SVGCircleElement":
                exportCircle(element, converted);
                break;
            default:
                exportSVG(element, converted);
        }
        return;
    } else if (element instanceof StreetMap) {
        return exportStreetMap(element, converted);
    }

    await exportElementChildren(element, converted);

    if (!element.convertDecorationToAuthoringBeforeChildren && shouldExportDecoration) {
        exportForegroundDecoration(element, converted);
    }
}

async function exportElementChildren(element, converted) {
    let children;

    // check for a manual item return
    if (element.getElementsForAuthoringConversion) {
        children = element.getElementsForAuthoringConversion();
    } else {
        children = _.sortBy(element.elements, element => element.calculatedProps ? element.calculatedProps.layer : null);
    }

    if (element.convertDecorationToAuthoringBeforeChildren) {
        exportForegroundDecoration(element, converted);
    }

    if (children?.length) {
        for (const childElement of children) {
            if (childElement) {
                await convertElementToAuthoring(childElement, converted);
            }
        }
    }
}

function exportForegroundDecoration(element, converted) {
    if (element.decoration?.hasForeground) {
        exportDecoration(element, converted, "foreground");
    }
}

function convertDOMNodeBounds(domNode) {
    const canvasBounds = geom.Rect.FromBoundingClientRect(app.currentCanvas.el.getBoundingClientRect());

    const bounds = geom.Rect.FromBoundingClientRect(domNode.getBoundingClientRect())
        .offset(-canvasBounds.left, -canvasBounds.top)
        .multiply(1 / app.currentCanvas.canvasScale);

    return bounds;
}

function fitBoundsToPoints(bounds, points) {
    bounds = bounds.clone();

    let left = _.minBy(points, pt => pt.x).x;
    const width = _.maxBy(points, pt => pt.x).x - left;
    let top = _.minBy(points, pt => pt.y).y;
    const height = _.maxBy(points, pt => pt.y).y - top;

    left += bounds.x;
    top += bounds.y;

    // Normalize path points coordinates
    for (const point of points) {
        point.x += bounds.left - left;
        point.y += bounds.top - top;
    }

    bounds.left = left;
    bounds.top = top;
    bounds.width = width;
    bounds.height = height;

    const minSize = 10;
    // We don't want the shape to have width or height less than minSize
    // which is possible for vertical or horizontal lines
    if (bounds.width < minSize) {
        const diff = (minSize - bounds.width) / 2;
        bounds.left -= diff;
        points.forEach(point => point.x += diff);
        bounds.width = minSize;
    }
    if (bounds.height < minSize) {
        const diff = (minSize - bounds.height) / 2;
        bounds.top -= diff;
        points.forEach(point => point.y += diff);
        bounds.height = minSize;
    }

    return bounds;
}

function convertColor(color, opacity) {
    if (color && color != "none") {
        color = tinycolor(color);

        color.setAlpha(color.getAlpha() * (opacity || 1));
        return color.toRgbString();
    } else {
        return "none";
    }
}

function getStylesFromNode(node) {
    let fill, stroke, strokeWidth;

    while (node.getAttribute("fill") == null && node.getAttribute("style") == null) {
        if (node.parentElement) {
            node = node.parentElement;
        } else {
            break;
        }
    }

    if (node && node.getAttribute("fill")) {
        fill = convertColor(node.getAttribute("fill"), node.getAttribute("fill-opacity"));
        stroke = convertColor(node.getAttribute("stroke"), node.getAttribute("stroke-opacity"));
        strokeWidth = parseFloat(node.getAttribute("stroke-width"));
    } else if (node && node.getAttribute("style")) {
        let style = node.getAttribute("style");
        fill = convertColor(style.match(/fill: (.*?);/)?.[1], style.match(/fill-opacity: (.*?);/)?.[1]);
        stroke = convertColor(style.match(/stroke: (.*?);/)?.[1], style.match(/stroke-opacity: (.*?);/)?.[1]);
        strokeWidth = parseFloat(style.match(/stroke-width: (.*?);/)?.[1]);
    }

    return { fill, stroke, strokeWidth };
}

function exportUserTestimonialQuote(element, converted) {
    exportSVG(element, converted, {
        ignoreOffset: true,
        transformSVG: svg => {
            // remove the styling transforms
            const container = svg.querySelector("g");
            container.style.transform = "";
            container.style.position = "";
        }
    });
}

function exportText(element, converted) {
    let bounds = element.canvasBounds;
    let isShape = element.decoration?.calculatedStyles.type === "frame" && element.decoration?.calculatedStyles.shape != "none";

    if (!isShape) {
        bounds = bounds.deflate(element.styles.padding);
    }

    let blocksModel = element.textModel;

    delete blocksModel.blockFontScales;

    let baseBlockSpacing = element.canvas.styleSheet.TextFrameBox?.text?.blockSpacing;

    for (let blockModel of blocksModel.blocks) {
        if (blockModel.type == AuthoringBlockType.TEXT) {
            let blockProps = element.calculatedProps.blockProps.findById(blockModel.id);

            blockModel.fontSize = blockProps.textStyles.fontSize;

            blockModel.fontWeight = blockProps.textStyles.fontWeight;

            blockModel.fontColor = blockProps.textStyles.color;
            blockModel.bulletColor = blockProps.listDecorationElement?.bullet?.styles.resolved_fillColor.toRgbString();

            blockModel.textAlign = blockProps.textStyles.textAlign;
            blockModel.letterSpacing = parseFloat(blockProps.textStyles.letterSpacing);

            blockModel.evenBreak = blockProps.textStyles.evenBreak;

            if (!blockModel.textStyle || blockModel.textStyle === USE_STYLESHEET_FOR_TEXT_STYLES) {
                // if this text had it's fontStyle set in BSS, it may not have a textStyle model property
                blockModel.textStyle = element.styles.textStyle;
            }

            let textFrameBoxBlockSpacing = element.canvas.styleSheet.TextFrameBox.text[blockModel.textStyle].blockSpacing ?? baseBlockSpacing;
            if (blockModel.textStyle == TextStyleType.BULLET_LIST) {
                if (blockModel.indent == 0) {
                    textFrameBoxBlockSpacing = element.canvas.styleSheet.TextFrameBox.text.bulleted.indent_0.blockSpacing ?? textFrameBoxBlockSpacing;
                } else {
                    textFrameBoxBlockSpacing = element.canvas.styleSheet.TextFrameBox.text.bulleted.indent_1.blockSpacing ?? textFrameBoxBlockSpacing;
                }

                if (blockProps.textStyles.allowFancyNumberedDecorations == false) {
                    blockModel.useThemedListDecoration = false;
                }
            }

            blockModel.spaceAbove = (blockProps.blockSpacing ?? 0) + (blockProps.additionalBlockSpacing ?? 0) - textFrameBoxBlockSpacing;

            delete blockModel.fontScale;

            if (blockModel.emphasized || blockProps.textStyles.hasEmphasized) {
                const block = element.getBlockRef(blockModel.id);

                const contentEditableComputedStyle = window.getComputedStyle(block.ref.current);
                blockModel.fontColor = contentEditableComputedStyle.getPropertyValue("color");
                blockModel.fontWeight = contentEditableComputedStyle.getPropertyValue("font-weight");

                block.ref.current.querySelectorAll("*").forEach(element => {
                    if (element.getAttribute && element.getAttribute("class") === "emphasized") {
                        const elementComputedStyle = window.getComputedStyle(element);
                        element.style.setProperty("color", elementComputedStyle.getPropertyValue("color"));
                        element.style.setProperty("font-weight", elementComputedStyle.getPropertyValue("font-weight"));
                        element.removeAttribute("class");
                    }
                });

                blockModel.html = sanitizeHtml(block.ref.current.innerHTML);
            }
            delete blockModel.emphasized;

            switch (blockProps.textStyles.textTransform) {
                case "uppercase":
                    blockModel.html = blockModel.html?.toUpperCase();
                    break;
                case "lowercase":
                    blockModel.html = blockModel.html?.toLowerCase();
                    break;
            }
        } else if (blockModel.type == AuthoringBlockType.MEDIA) {
            let frameStyles = element.blockElements[blockModel.id]?.media?.content?.decoration?.styles;
            if (frameStyles) {
                blockModel.elementModel.color = frameStyles.resolved_fillColor.toRgbString();
            }
        }
    }

    let shapeProps = {};
    if (isShape) {
        switch (element.decoration.styles.shape) {
            case "circle":
                shapeProps.shape = AuthoringShapeType.ELLIPSE;
                bounds = bounds.square().centerInRect(bounds);
                break;
            case "octagon":
                shapeProps.shape = AuthoringShapeType.POLYGON;
                bounds = bounds.square().centerInRect(bounds);
                break;
            default:
                shapeProps.shape = AuthoringShapeType.RECT;
        }

        shapeProps.fitToText = false;
        shapeProps.fill = element.decoration.styles.resolved_fillColor?.toRgbString();
        shapeProps.stroke = element.decoration.styles.resolved_strokeColor?.toRgbString();
        shapeProps.strokeWidth = element.decoration.styles.strokeWidth;
        shapeProps.adj1 = element.decoration.styles.cornerRadius;
        shapeProps.verticalAlign = VerticalAlignType.MIDDLE;
        shapeProps.textInset = element.styles.paddingLeft ?? 0;
    }

    let model = {
        type: AuthoringElementType.SHAPE,
        shape: AuthoringShapeType.RECT,
        ...bounds.toXYObject(),
        fill: "none",
        stroke: "none",
        strokeWidth: 0,
        textAlign: element.calculatedProps.textAlign,
        text: blocksModel,
        blockSpacing: element.model.blockSpacing ?? element.calculatedStyles.blockSpacing,
        // these can be overridden by shapeProps
        verticalAlign: element.calculatedProps.verticalAlign,
        fitToText: ![VerticalAlignType.MIDDLE, VerticalAlignType.BOTTOM].includes(element.calculatedProps.verticalAlign),
        textInset: 0,
        // merge any shape-specific props
        ...shapeProps
    };

    converted.push(model);
}

function createTextBlock(textProps, lines) {
    const htmlLines = [];
    for (const textRuns of lines) {
        let lineHtml = "";
        for (const run of textRuns) {
            let htmlRun = run.text;
            if (run.options.italic) {
                htmlRun = `<i>${htmlRun}</i>`;
            }
            if (run.options.bold) {
                htmlRun = `<b>${htmlRun}</b>`;
            }
            if (run.options.color) {
                htmlRun = `<font style="color: ${tinycolor(run.options.color).toHexString()}">${htmlRun}</font>`;
            }
            if (run.options.hyperlink) {
                htmlRun = `<a href="${run.options.hyperlink.url}">${htmlRun}</a>`;
            }
            lineHtml += htmlRun;
        }
        htmlLines.push(lineHtml);
    }

    let html = htmlLines.join("<br>");
    if (html.endsWith("<br>")) {
        html += "<br>";
    }

    return {
        id: uuid(),
        type: AuthoringBlockType.TEXT,
        textStyle: textProps.textStyle,
        html,
        fontSize: textProps.fontSize,
        fontColor: textProps.fontColor,
        fontWeight: textProps.fontWeight,
        lineHeight: textProps.lineHeight,
        letterSpacing: textProps.letterSpacing
    };
}

function exportLayoutComponent(element, converted) {
    let model = {
        ...convertDOMNodeBounds(element.DOMNode).toXYObject(),
        ...element.model
    };

    switch (element.type) {
        case "Header":
            model.type = AuthoringElementType.HEADER;
            model.headerPosition = element.position;
            if (element.model.userFontScale) {
                model.userFontScale = {};
                for (let key of Object.keys(element.model.userFontScale)) {
                    model.userFontScale[key.replace(/Header/, "AuthoringCanvas/AuthoringElementContainer/Header")] = element.model.userFontScale[key];
                }
            }
            break;
        case "Footer":
            model.type = AuthoringElementType.FOOTER;
            break;
    }

    converted.push(model);
}

// checks if the converted list already contains an ID
// meaning that element has already been converted - helps
// to prevent duplicate conversions
function hasAlreadyConverted(id, converted) {
    return !!converted.find(el =>
        el.id === id ||
        el.model?.id === id ||
        el.element?.id === id
    );
}

// rebases targets and sources to a new container
function rebasePaths(authoringContainerId, model) {
    // is a collection of items
    if (isArray(model)) {
        return model.forEach(value => rebasePaths(authoringContainerId, value));
    }

    // Since the converted chart will have a new path (unique id) we have to update all
    // references to it from its annotations
    const keysContainingElementId = ["elementId", "source", "target"];
    Object.entries(model).forEach(([key, value]) => {
        if (keysContainingElementId.includes(key) && typeof value === "string" && value.startsWith("/primary/")) {
            model[key] = value.replace(/^\/primary\//, `/primary/${authoringContainerId}/element/`);
        } else if (value && typeof value === "object") {
            rebasePaths(authoringContainerId, value);
        }
    });
}

// extract opacity and verifies the value
function exportOpacity(opacity) {
    const converted = opacity * 100;
    return isNaN(converted) ? 100 : converted;
}

function exportDashboard(element, converted) {
    const convertedDashboardModel = _.cloneDeep(element.model);
    convertedDashboardModel.items
        .forEach(dashboardItem => {
            const authoringContainerId = uuid();

            // charts have annotations that need to be converted
            if (dashboardItem.elementType === "chart") {
                const chartAnnotationsModel = dashboardItem.chart.chartAnnotations;
                if (chartAnnotationsModel) {
                    rebasePaths(authoringContainerId, chartAnnotationsModel);
                }
            }

            // get the bounds of the child element so it can be
            // used to create the new mini-dashboard
            const childElement = element.elements[dashboardItem.id];
            const bounds = convertDOMNodeBounds(childElement.DOMNode).toXYObject();

            // save each as a separate dashboard
            converted.push({
                id: authoringContainerId,
                type: AuthoringElementType.COMPONENT,
                componentType: element.type,
                ...bounds,
                element: {
                    ...convertedDashboardModel,
                    id: authoringContainerId,
                    items: [dashboardItem]
                },
            });
        });
}

function exportElementComponent(element, converted) {
    const clone = {
        id: uuid(),
        type: AuthoringElementType.COMPONENT,
        componentType: element.type,
        ...convertDOMNodeBounds(element.DOMNode).toXYObject(),
        element: { ...element.model }
    };

    // add arrays that require conversions to
    // include with rebase paths
    [
        // Thermometer
        clone.element.annotations?.connections?.items

    ].forEach(collection => {
        if (collection) {
            rebasePaths(clone.id, collection);
        }
    });

    converted.push(clone);
}

function exportTable(element, converted) {
    let bounds = convertDOMNodeBounds(element.table.DOMNode);
    element.model.tableHeight = 1;
    converted.push({
        type: AuthoringElementType.COMPONENT,
        componentType: element.type,
        ...bounds.toXYObject(),
        element: { ...element.model }
    });
}

function exportWordCloud(element, converted) {
    // let bounds = convertDOMNodeBounds(element.DOMNode);
    //
    // element.DOMNode.querySelectorAll("path").forEach(pathNode => {
    //     let style = pathNode.getAttribute("style");
    //     let transformX = parseFloat(style.match(/translateX\((.*?)\)/)[1]) / element.canvas.getScale();
    //     let transformY = parseFloat(style.match(/translateY\((.*?)\)/)[1]) / element.canvas.getScale();
    //
    //     let pathBounds = convertDOMNodeBounds(pathNode).offset(bounds.left, bounds.top);
    //     pathBounds.left = transformX;
    //     pathBounds.top = transformY;
    //     // pathBounds.width += 100;
    //
    //     // pathNode.style.transform = "";
    //
    //     converted.push({
    //         type: AuthoringElementType.SHAPE,
    //         shape: AuthoringShapeType.RAW_SVG,
    //         svgHTML: pathNode.outerHTML,
    //         ...getStylesFromNode(pathNode),
    //         ...pathBounds.toXYObject()
    //     });

    // let color = pathNode.style.fill;
    //
    // let word = pathNode.getAttribute("data-word");
    // let fontWeight = parseInt(pathNode.getAttribute("data-font-weight"));
    // let fontSize = parseInt(pathNode.getAttribute("data-font-size"));
    //
    // fontWeight = 700;
    // converted.push({
    //     type: AuthoringElementType.SHAPE,
    //     shape: AuthoringShapeType.RECT,
    //     fill: "#eeeeee",
    //     textInset: 0,
    //     text: {
    //         blocks: [{
    //             type: AuthoringBlockType.TEXT,
    //             textStyle: TextStyleType.HEADING,
    //             html: word,
    //             fontColor: color,
    //             fontFamily: element.fontId,
    //             fontWeight,
    //             fontSize
    //         }]
    //     },
    //     ...pathBounds.toXYObject()
    // });
    // });
}

function exportContent(element, converted) {
    let isFramed = element.parentElement instanceof FramedMediaElement && (element.parentElement.frameType != "none" && element.parentElement.frameType != "unframedRect");

    let bounds;

    if (isFramed) {
        bounds = convertDOMNodeBounds(element.parentElement.DOMNode);
    } else {
        bounds = convertDOMNodeBounds(element.DOMNode).deflate(element.styles.padding);
    }

    let elementModel = { ...element.model };

    if (isFramed) {
        elementModel.frameType = element.parentElement.frameType; // update the model frameType to reflect the frameType getter which may differ from the model.frameType
        if (!elementModel.color || elementModel.color === "auto" || element.bakeColorsWhenConvertedToClassic) {
            // we need to use the theme-driven colors here because there is no user-defined color
            if (element.decoration.styles.resolved_fillColor.name === "none") {
                elementModel.color = element.decoration.styles.resolved_strokeColor.name;
            } else {
                elementModel.color = element.decoration.styles.resolved_fillColor.name;
            }
        }
    }

    // elementModel.frameType = elementModel.frame;
    // delete elementModel.frame;

    // if an icon is directly passed in then we need
    // to determine which properties to use to convert
    // it into a content element
    if (element instanceof Icon) {
        // this content has already been converted by a previous attempt
        if (hasAlreadyConverted(element.model.id, converted)) {
            return;
        }

        let iconColor = element.styles.resolved_fillColor.name;
        // classic slide icons only allow background dark and light - so this converts primary to background
        if (iconColor == "primary_dark") {
            iconColor = "background_dark";
        }
        if (iconColor == "primary_light") {
            iconColor = "background_light";
        }

        elementModel = {
            id: element.model.id || uuid(),
            color: iconColor,
            content_type: AssetType.ICON,

            // TODO: are there more places where the "Icon" might not
            // be this property?
            content_value: element.iconId
        };

        bounds = bounds.scale(element.calculatedProps.scale);
    } else if (element.assetType == AssetType.ICON) {
        if (!isFramed) {
            elementModel.frameType = "none";
            elementModel.color = element.assetElement.styles.resolved_fillColor.name;
            bounds = bounds.scale(element.assetElement.calculatedProps.scale);
        }
    }
    if (!isFramed && element.assetType == AssetType.IMAGE) {
        if (element.decoration?.styles?.shape == "circle" || element.assetElement.calculatedProps.clipPath?.startsWith("circle")) {
            if (element.assetElement.calculatedProps.clipPath) {
                let r = parseFloat(element.assetElement.calculatedProps.clipPath.split(" ")[2]);
                bounds = bounds.deflate((bounds.width - r * 2) / 2);
            }
            elementModel.frameType = "circle";
        }
    }

    converted.push({
        type: AuthoringElementType.CONTENT,
        ...bounds.toXYObject(),
        element: elementModel,
        opacity: exportOpacity(element.styles.opacity)
    });
}

function exportSVGTextNodes(element, converted) {
    // create the default text props
    const { styles } = element;
    const textProps = {
        fontColor: styles.resolved_fontColor.toRgbString(),
        // fontFamily: styles.fontId,
        fontScaling: styles.fontScaling,
        fontSize: styles.fontSize,
        textStyle: styles.fontStyle,
        fontWeight: styles.fontWeight,
        lineHeight: styles.lineHeight,
        letterSpacing: styles.letterSpacing
    };

    // generate a text object for each node
    const textNodes = element.DOMNode.querySelectorAll("text");
    for (const textNode of textNodes) {
        const content = textNode.textContent;
        const bounds = convertDOMNodeBounds(textNode).inflate({ left: 2, right: 2 });
        const run = [{ text: content, options: {} }];
        const block = createTextBlock(textProps, [run]);

        // save the text node
        converted.push({
            type: AuthoringElementType.SHAPE,
            shape: AuthoringShapeType.RECT,
            ...bounds.toXYObject(),
            fill: "none",
            stroke: "none",
            strokeWidth: 0,
            textAlign: element.calculatedProps.textAlign,
            verticalAlign: VerticalAlignType.MIDDLE,
            textInset: 0,
            fitToText: false,
            text: {
                blocks: [block]
            },
            // blockGap: 15
        });
    }
}

function exportVideo(element, converted) {
    const bounds = convertDOMNodeBounds(element.DOMNode).deflate(element.styles.padding);
    const elementModel = {
        ...element.model,
        fullBleed: false,
    };

    const model = {
        type: AuthoringElementType.VIDEO,
        x: bounds.x,
        y: bounds.y,
        width: bounds.width,
        height: bounds.height,
        element: elementModel,
    };

    converted.push(model);
}

function exportStreetMap(element, converted) {
    converted.push({
        type: AuthoringElementType.CONTENT,
        ...convertDOMNodeBounds(element.DOMNode).toXYObject(),
        element: {
            content_type: "image",
            content_value: element.model.assetId
        }
    });
}

async function exportConnectorItem(connectorItemElement, converted) {
    if (connectorItemElement.convertToAuthoringAsSVG) {
        return exportSVG(connectorItemElement, converted, { svgNode: connectorItemElement.DOMNode.firstElementChild.firstElementChild });
    }

    const connectorBounds = convertDOMNodeBounds(connectorItemElement.DOMNode);

    // Stripping out adjustments
    const points = connectorItemElement.fullConnectorPath.points.map(({ x, y }) => ({ x, y }));
    // Adjusting bounds
    {
        let left = _.minBy(points, pt => pt.x).x;
        let width = _.maxBy(points, pt => pt.x).x - left;
        let top = _.minBy(points, pt => pt.y).y;
        let height = _.maxBy(points, pt => pt.y).y - top;

        left += connectorBounds.left;
        top += connectorBounds.top;
        width = Math.max(width, 1);
        height = Math.max(height, 1);

        // Normalize path points coordinates
        for (const point of points) {
            point.x += connectorBounds.left - left;
            point.y += connectorBounds.top - top;
        }

        connectorBounds.left = left;
        connectorBounds.top = top;
        connectorBounds.width = width;
        connectorBounds.height = height;
    }

    let strokeStyle = "solid";
    if (connectorItemElement.model.lineStyle === "dotted") {
        strokeStyle = "dotted";
    } else if (connectorItemElement.model.lineStyle === "dashed" || connectorItemElement.model.lineStyle === "animate_dash") {
        strokeStyle = "dashed";
    }

    let strokeWidth = connectorItemElement.model.lineWeight || connectorItemElement.calculatedProps.strokeWidth;
    if (strokeWidth == "bold") {
        strokeWidth = 10;
    }

    const model = {
        ...connectorBounds.toXYObject(),
        type: AuthoringElementType.PATH,
        points,
        fill: "none",
        stroke: connectorItemElement.getConnectorColor(),
        strokeWidth,
        strokeStyle,
        startDecoration: connectorItemElement.model.startDecoration || "none",
        endDecoration: connectorItemElement.model.endDecoration || "none",
        arrowHeads: connectorItemElement.styles.arrowHeads,
        opacity: connectorItemElement.styles.connectorOpacity * 100
    };

    converted.push(model);

    // convert the labels, if any
    return await convertElementToAuthoring(connectorItemElement.labels, converted);
}

function exportPath(element, converted) {
    let bounds = convertDOMNodeBounds(element.DOMNode);
    let points = element.calculatedProps.path.map(pt => ({ x: pt[0], y: pt[1] }));

    bounds = fitBoundsToPoints(bounds, points);

    let fillColor = convertColor(element.styles.resolved_fillColor?.toRgbString() ?? "none", element.styles.fillOpacity || 1);
    let strokeColor = convertColor(element.styles.resolved_strokeColor?.toRgbString() ?? "none", element.styles.strokeOpacity || 1);

    converted.push({
        type: AuthoringElementType.PATH,
        ...bounds.toXYObject(),
        points,
        fill: fillColor,
        stroke: strokeColor,
        strokeWidth: element.styles.strokeWidth,
        opacity: exportOpacity(element.styles.opacity)
    });
}

function exportComplexSVGLines(element, converted) {
    let bounds = convertDOMNodeBounds(element.DOMNode);

    element.DOMNode.querySelectorAll("polyline, line").forEach(node => {
        let points;

        if (node.getAttribute("points")) {
            points = node.getAttribute("points").split(" ").filter(o => o !== "").map(o => o.split(",").map(o => parseFloat(o)));
            points = points.map(arr => ({ x: arr[0], y: arr[1] }));
        } else if (node.getAttribute("x1")) {
            points = [
                {
                    x: parseFloat(node.getAttribute("x1")),
                    y: parseFloat(node.getAttribute("y1"))
                }, {
                    x: parseFloat(node.getAttribute("x2")),
                    y: parseFloat(node.getAttribute("y2"))
                }
            ];
        }

        let pathBounds = fitBoundsToPoints(bounds, points);
        let styles = getStylesFromNode(node);

        converted.push({
            type: AuthoringElementType.PATH,
            ...pathBounds.toXYObject(),
            points,
            ...styles
        });
    });
}

function exportSVG(element, converted, { ignoreOffset, transformSVG, svgNode = element.DOMNode.firstElementChild } = {}) {
    const elementBounds = convertDOMNodeBounds(element.DOMNode);
    const svgBounds = convertDOMNodeBounds(svgNode);

    let {
        preserveFill,
        preserveStroke,
    } = element.options;

    // check for the offset required
    let offsetX = 0;
    let offsetY = 0;
    if (!ignoreOffset) {
        offsetX = svgBounds.left - elementBounds.left;
        offsetY = svgBounds.top - elementBounds.top;
    }

    const nodeStyles = getStylesFromNode(svgNode);
    const normalizedSvgNode = svgNode.cloneNode(true);

    // check for a custom function to normalize the SVG contents
    if (transformSVG) {
        transformSVG(normalizedSvgNode, nodeStyles);
    }

    const styledNodes = normalizedSvgNode.querySelectorAll("[fill], [fill-opacity], [stroke], [stroke-width], [stroke-dasharray], [style]");
    const allNodes = [...styledNodes, normalizedSvgNode];
    const styles = allNodes.map(getStylesFromNode);
    const fills = new Set(styles.filter(style => style.fill && style.fill !== "none").map(style => style.fill));
    const strokes = new Set(styles.filter(style => style.stroke && style.stroke !== "none").map(style => style.stroke));

    // find all nodes that have assigned fills and strokes
    for (const target of allNodes) {
        // check each child node for styles and merge them into
        // the root styles
        const localStyles = getStylesFromNode(target);

        if (isNaN(nodeStyles.strokeWidth) && !isNaN(localStyles.strokeWidth)) {
            nodeStyles.strokeWidth = localStyles.strokeWidth;
        }

        if (!preserveFill && fills.size === 1) {
            if (nodeStyles.fill === "none" && localStyles.fill && localStyles.fill !== "none") {
                nodeStyles.fill = localStyles.fill;
            }

            target.style.removeProperty("fill");

            if (!localStyles.fill || localStyles.fill === "none") {
                // without a fill, or assigned to none
                target.setAttribute("fill", "none");
            } else {
                target.setAttribute("fill", "@fill");
            }
        }

        if (!preserveStroke && strokes.size === 1) {
            if (nodeStyles.stroke === "none" && localStyles.stroke && localStyles.stroke !== "none") {
                nodeStyles.stroke = localStyles.stroke;
            }

            target.style.removeProperty("stroke");

            if (!localStyles.stroke || localStyles.stroke === "none") {
                // without a stroke, or assigned to none
                target.setAttribute("stroke", "none");
            } else {
                target.setAttribute("stroke", "@stroke");
            }
        }
    }

    // just in case
    if (isNaN(nodeStyles.strokeWidth) || !_.isNumber(nodeStyles.strokeWidth)) {
        nodeStyles.strokeWidth = 0;
    }

    // create the final svg markup
    let svgHTML;
    svgHTML = new XMLSerializer().serializeToString(normalizedSvgNode);
    svgHTML = `<g transform="translate(${-offsetX} ${-offsetY})">${svgHTML}</g>`;

    const model = {
        type: AuthoringElementType.SHAPE,
        shape: AuthoringShapeType.RAW_SVG,
        svgHTML,
        canChangeFill: true,
        canChangeStroke: true,
        viewBox: svgBounds.zeroOffset().toXYObject(),
        ...nodeStyles,
        ...svgBounds.toXYObject()
    };

    converted.push(model);
    return model;
}

function exportRect(element, converted) {
    const svgNode = element.DOMNode.firstElementChild;

    const model = {
        type: AuthoringElementType.SHAPE,
        shape: AuthoringShapeType.RECT,
        ...getStylesFromNode(svgNode),
        strokeStyle: "solid",
        opacity: exportOpacity(element.styles.opacity),
        ...convertDOMNodeBounds(svgNode).toXYObject()
    };

    converted.push(model);
}

function exportCircle(element, converted) {
    const svgNode = element.DOMNode.firstElementChild;

    const model = {
        type: AuthoringElementType.SHAPE,
        shape: AuthoringShapeType.ELLIPSE,
        ...getStylesFromNode(svgNode),
        strokeStyle: "solid",
        opacity: exportOpacity(element.styles.opacity),
        ...convertDOMNodeBounds(svgNode).toXYObject()
    };

    converted.push(model);
}

function getColorFromAttributes(node, property) {
    let color = tinycolor(node.getAttribute(property) || "rgb(0,0,0)");
    color.setAlpha((parseFloat(node.getAttribute(`${property}-opacity`) || 1)) * color.getAlpha());
    return color.toRgbString();
}

function exportDecoration(element, converted, position) {
    const decorationElement = element.decoration;
    const ref = position === "background" ? decorationElement.backgroundRef : decorationElement.foregroundRef;
    const domNode = ref.current;
    if (!domNode) {
        return;
    }

    const { type, shape, cornerRadius } = decorationElement.styles;

    if (type === "svg") {
        if (element.styles.decoration.def.startsWith("<rect")) {
            for (let svg of element.DOMNode.querySelectorAll("rect")) {
                let model = {
                    type: AuthoringElementType.SHAPE,
                    shape: AuthoringShapeType.RECT,
                    ...convertDOMNodeBounds(domNode).toXYObject()
                };

                model.fill = getColorFromAttributes(svg, "fill");
                model.stroke = getColorFromAttributes(svg, "stroke");
                model.strokeWidth = parseFloat(svg.getAttribute("stroke-width")) || 0;
                model.width = parseInt(svg.getAttribute("width"));
                model.height = parseInt(svg.getAttribute("height"));

                converted.push(model);
            }
        } else if (element.styles.decoration.def.startsWith("<line")) {
            for (let svg of element.DOMNode.querySelectorAll("line")) {
                let model = {
                    type: AuthoringElementType.PATH,
                };
                model.fill = getColorFromAttributes(svg, "fill");
                model.stroke = getColorFromAttributes(svg, "stroke");
                model.strokeWidth = parseFloat(svg.getAttribute("stroke-width")) || 0;

                model.points = [{ x: parseFloat(svg.getAttribute("x1")), y: parseFloat(svg.getAttribute("y1")) }, { x: parseFloat(svg.getAttribute("x2")), y: parseFloat(svg.getAttribute("y2")) }];

                let bounds = new geom.Rect(
                    Math.min(model.points[0].x, model.points[1].x),
                    Math.min(model.points[0].y, model.points[1].y),
                    model.points[1].x - model.points[0].x,
                    model.points[1].y - model.points[0].y
                );

                for (let pt of model.points) {
                    pt.x -= bounds.left;
                    pt.y -= bounds.top;
                }

                bounds = bounds.offset(element.canvasBounds.position);

                const minSize = 10;
                // We don't want the shape to have width or height less than minSize
                // which is possible for vertical or horizontal lines
                if (bounds.width < minSize) {
                    const diff = (minSize - bounds.width) / 2;
                    bounds.left -= diff;
                    model.points.forEach(point => point.x += diff);
                    bounds.width = minSize;
                }
                if (bounds.height < minSize) {
                    const diff = (minSize - bounds.height) / 2;
                    bounds.top -= diff;
                    model.points.forEach(point => point.y += diff);
                    bounds.height = minSize;
                }

                model = { ...model, ...bounds.toXYObject() };

                converted.push(model);
            }
        } else {
            exportSVG(element, converted);
        }
    } else if (shape === DecorationType.OCTAGON) {
        exportSVG(element, converted);
    } else if (shape === DecorationType.RECT || shape === DecorationType.CIRCLE) {
        let bounds = convertDOMNodeBounds(domNode);
        let model = {
            ...bounds.toXYObject(),
            type: AuthoringElementType.SHAPE
        };

        switch (shape) {
            case DecorationType.RECT:
                model.shape = AuthoringShapeType.RECT;
                model.adj1 = parseInt(domNode.style.cornerRadius || 0);
                break;
            case DecorationType.CIRCLE:
                model.shape = AuthoringShapeType.ELLIPSE;
                break;
        }

        if (position == "background" && domNode.style.backgroundColor && domNode.style.backgroundColor !== "none") {
            model.fill = domNode.style.backgroundColor;
            // if (element.parentElement?.styles?.shadow) {
            //     // special case for shadow on PhotoCollage
            //     let [color, x, y, blur] = element.parentElement.styles.replace(/, /g, ",").split(" ");
            //     model.shadow = { color, x: parseInt(x), y: parseInt(y), blur: parseInt(blur) };
            // }
            if (domNode.style.boxShadow) {
                let [color, x, y, blur] = domNode.style.boxShadow.replace(/, /g, ",").split(" ");
                model.shadow = { color, x: parseInt(x), y: parseInt(y), blur: parseInt(blur) / 2 };
            }
        } else {
            model.fill = "none";
        }

        if (position == "foreground" && domNode.style.borderColor && domNode.style.borderColor !== "none" && parseFloat(domNode.style.borderWidth) > 0) {
            model.stroke = domNode.style.borderColor;
            model.strokeWidth = parseFloat(domNode.style.borderWidth) || 0;
            model = { ...model, ...bounds.deflate(model.strokeWidth / 2).toXYObject() };
        } else {
            model.stroke = "none";
        }

        if (model.fill == "none" && model.stroke == "none") {
            return;
        }

        converted.push(model);
    }
}

async function exportMessageCarousel(canvas) {
    const models = [];

    // perform normal conversions
    const converted = [];
    await convertElementToAuthoring(canvas.layouter.canvasElement, converted);

    // gather up non-stage elements
    const common = converted.filter(model => !("stageIndex" in model));

    // capture individual stage elements
    for (let i = 0; i < canvas.playbackStages.length; i++) {
        // start with an array of just the non-stage elements
        const elements = [
            ...common,
            ...converted.filter(model => model.stageIndex === i)
        ];

        // create each model
        const model = createAuthoringModel(canvas, elements);
        models.push(model);
    }

    // delete the current slide
    app.undoManager.openGroup();

    // the first slide replaces the others
    const created = models.map(model => ({
        template_id: "authoring",
        ...model,
        states: [{
            primary: model.elements.primary
        }]
    }));

    // replace the slide with new individual slides
    const index = canvas.slide.getIndex();

    // screen bounces around
    await ds.selection.presentation.replaceSlide(canvas.slide, created, { isSlideCreatedFromUserAction: true });
    const goto = ds.selection.presentation.slides.models[index];
    ds.selection.set("slide", goto, {});

    app.undoManager.closeGroup();
}

// when exporting a message carousel, each stage is exported as a
// separate slide that uses the stage content along with common
// slide elements like the title or tray. This code performs a normal
// export, but tracks the last known index of converted elements to
// assign a stage index for each child element so it's easier to
// map each bit of content back to the correct new slide
async function exportImageCarouselItem(element, converted) {
    const startingIndex = converted.length;

    // get the index being exported
    element.parentElement.exportingStageIndex = (element.parentElement.exportingStageIndex + 1) || 0;

    // convert normally
    await exportElementChildren(element, converted);

    // append the stage index to all newly created slides
    for (let i = startingIndex; i < converted.length; i++) {
        converted[i].stageIndex = element.parentElement.exportingStageIndex;
    }
}

async function exportFooter(element, converted) {
    await exportElementChildren(element, converted);
}
