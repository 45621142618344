import { v4 as uuid } from "uuid";
import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { DegreesToRadians } from "js/core/utilities/geom";
import { Ellipse } from "js/core/utilities/ellipse";
import { ConnectorType, DirectionType, NodeType, ShapeType, VerticalAlignType, AuthoringBlockType, TextStyleType } from "common/constants";

import { CollectionElement } from "../../base/CollectionElement";
import ConnectorGroup from "../../elements/connectors/ConnectorGroup";
import { getContentItemFromType } from "../../base/ContentItem";
import { layoutHelper } from "../../layouts/LayoutHelper";

class HubAndSpoke extends CollectionElement {
    static get schema() {
        return {
            connectorStyle: "hub",
            reverseArrowDirection: false,
            useAlternateRotation: true
        };
    }

    get maxItemCount() {
        return 8;
    }

    getChildItemType(model) {
        return getContentItemFromType(model.nodeType || NodeType.CONTENT_AND_TEXT);
    }

    getChildOptions(model) {
        return {
            canChangeTextDirection: false,
            frameType: ShapeType.CIRCLE,
            syncFontSizeWithSiblings: true
        };
    }

    getItemSelectionUIType(node) {
        if (node.id == "hub") {
            return "HubSelection";
        } else {
            return "HubSpokeSelection";
        }
    }

    getAllowedNodeTypes(node) {
        if (node.id === "hub") {
            return [NodeType.CIRCLE, NodeType.BOX, NodeType.DIAMOND, NodeType.TEXT, NodeType.CONTENT];
        } else {
            return [];
        }
    }

    get showHub() {
        return this.connectorStyle === "hub" || this.model.showHub;
    }

    get hubLayout() {
        return this.model.layout || "center";
    }

    get hubNode() {
        return this.itemElements.find(node => node.id === "hub");
    }

    get spokeNodes() {
        return this.itemElements.filter(node => node.id !== "hub");
    }

    get ellipseScale() {
        return (this.model.ellipse_size || 1) - 1;
    }

    get connectorDefaultProperties() {
        return {
            startDecoration: this.model.startDecoration,
            endDecoration: this.model.endDecoration,
            lineStyle: this.model.lineStyle,
            lineWeight: this.model.lineWeight
        };
    }

    get connectorStyle() {
        return this.model.connectorStyle;
    }

    buildConnectorsModel() {
        if (!this.model.connectors) {
            this.model.connectors = {
                items: []
            };
        }

        let activeConnectors = [];
        this.itemElements.forEach((node, idx) => {
            const source = node.id;

            let connector = this.model.connectors.items.find(m => m.source == source);
            if (!connector) {
                connector = { ...this.defaultConnectorData };
                this.model.connectors.items.push(connector);
            }

            activeConnectors.push(connector);

            if (this.connectorStyle === "hub") {
                // hub connector
                connector.source = source;
                connector.target = "hub";
                connector.connectorType = ConnectorType.STRAIGHT;
            } else {
                // item connector
                const target = this.itemElements[(idx + 1) % this.itemElements.length].id;
                if (source !== target) {
                    connector.source = source;
                    connector.target = target;
                    connector.connectorType = this.connectorStyle === "arc" ? ConnectorType.ARC : ConnectorType.STRAIGHT;
                }
            }
        });

        this.model.connectors.items = activeConnectors;
    }

    get defaultItemData() {
        return {
            title: { text: "" },
            nodeType: _.last(this.itemCollection)?.nodeType || NodeType.CONTENT_AND_TEXT,
            frameType: _.last(this.itemCollection)?.frameType || ShapeType.NONE,
            decorationStyle: _.last(this.itemCollection)?.decorationStyle ?? null
        };
    }

    get defaultConnectorData() {
        let startDecoration = "none";
        let endDecoration = "none";
        // migration from v5
        if (this.model.show_arrows == null || this.model.show_arrows !== false) {
            if (this.model.pointing_in === false) {
                endDecoration = "arrow";
            } else {
                startDecoration = "arrow";
            }
        }
        return {
            source: "hub",
            startDecoration,
            endDecoration,
            lineStyle: "solid",
            lineWeight: 2
        };
    }

    addItem(props, index) {
        const item = super.addItem(props, index);
        this.model.connectors.items.push({
            ...this.defaultConnectorData,
            id: null,
            target: item.id
        });
        return item;
    }

    deleteItem(itemId) {
        super.deleteItem(itemId);
        _.remove(this.model.connectors.items, connector => connector.target === itemId);
    }

    resetUserColors() {
        _.each(this.itemElements, element => {
            element.resetUserColors();
        });
    }

    _build() {
        if (this.showHub) {
            if (!this.model.hub) {
                this.model.hub = {
                    id: "hub",
                    nodeType: NodeType.CIRCLE,
                    userSize: 100
                };
            }

            this.hub = this.addElement("hub", () => this.getChildItemType(this.model.hub), {
                model: this.model.hub,
            });
        }

        this.buildItems();

        this.buildConnectorsModel();

        this.connectors = this.addElement("connectors", () => ConnectorGroup, {
            model: this.model.connectors,
            containerElement: this,
            startPointsAreLocked: true,
            endPointsAreLocked: true,
            canDeleteConnectors: false,
            defaultProps: () => {
                return this.connectorDefaultProperties;
            }
        });
        this.connectors.layer = -1;
    }

    _calcProps(props, options) {
        const { size } = props;

        let arcStart, arcAngle, rx, ry, flip;

        const nodeSize = new geom.Size(300, 200);

        const minRx = 150;
        const maxRx = size.width / 2 - nodeSize.width / 2;

        let cx, cy;
        rx = minRx + (this.ellipseScale * (maxRx - minRx));

        let verticalAlign;
        switch (this.hubLayout) {
            case "center":
                if (this.model.useAlternateRotation) {
                    switch (this.spokeNodes.length) {
                        case 2:
                            arcStart = DegreesToRadians(-180);
                            break;
                        case 4:
                            arcStart = DegreesToRadians(-135);
                            break;
                        case 6:
                            arcStart = DegreesToRadians(-120);
                            break;
                        default:
                            arcStart = DegreesToRadians(-90);
                    }
                } else {
                    arcStart = DegreesToRadians(-90);
                }

                arcAngle = Math.PI * 2;
                ry = Math.min(size.height / 2 - 30, rx);
                flip = false;
                cx = size.width / 2;
                cy = size.height / 2;
                verticalAlign = VerticalAlignType.MIDDLE;
                break;
            case "bottom":
                arcStart = -Math.PI;
                arcAngle = Math.PI;
                ry = Math.min(size.height, rx);
                flip = false;
                cx = size.width / 2;
                cy = size.height / 2;
                verticalAlign = VerticalAlignType.BOTTOM;
                break;
            case "top":
                arcStart = -Math.PI;
                arcAngle = Math.PI;
                ry = Math.min(size.height, rx);
                flip = true;
                cx = size.width / 2;
                cy = size.height / 2;
                verticalAlign = VerticalAlignType.TOP;
                break;
        }

        cy = 0;

        if (this.showHub) {
            let hubProps = this.hub.calcProps(size, { maxSize: Math.min(rx, ry) - 20 });
            hubProps.bounds = new geom.Rect(cx, cy, hubProps.size);
            this.hub.model.x = cx;
            this.hub.model.y = cy;
        }

        let shape = this.layoutNodes(props, cx, cy, rx, ry, arcStart, arcAngle, flip);

        // readjust node's to ensure that they all fit
        let nodesBounds = this.itemElements.map(node => node.bounds).reduce((acc, cur) => acc.union(cur));
        if (this.showHub) {
            nodesBounds = nodesBounds.union(this.hub.bounds);
        }

        // const center = new geom.Point(cx, cy);
        // const width = Math.max(
        //     center.distance(new geom.Point(nodesBounds.left, center.y)),
        //     center.distance(new geom.Point(nodesBounds.right, center.y))
        // ) * 2;
        // const height = Math.max(
        //     center.distance(new geom.Point(center.x, nodesBounds.top)),
        //     center.distance(new geom.Point(center.x, nodesBounds.bottom))
        // ) * 2;
        //
        // const overflowWidth = Math.max(0, width - size.width);
        // const overflowHeight = Math.max(0, height - size.height);
        //
        // if (overflowWidth > 0 || overflowHeight > 0) {
        //     rx -= overflowWidth / 2;
        //     ry -= overflowHeight / 2;
        //     shape = this.layoutNodes(props, cx, cy, rx, ry, arcStart, arcAngle, flip);
        // }

        let totalHeight = nodesBounds.height;
        if (this.showHub && this.hubLayout !== "center") {
            //   totalHeight += this.hub.calculatedProps.bounds.height / 2;
        }

        let offsetY = layoutHelper.getVerticalAlignOffset(totalHeight, size.height, VerticalAlignType.MIDDLE);
        offsetY -= nodesBounds.top;

        for (let item of this.itemElements) {
            item.calculatedProps.bounds.top += offsetY;
            item.model.y += offsetY;
        }
        if (this.showHub) {
            this.hub.calculatedProps.bounds.top += offsetY;
            this.hub.model.y += offsetY;
        }

        shape.y = offsetY;

        this.connectors.calcProps(size, { shape: shape });

        return {
            size: size,
            rx, ry, cx, cy, arcStart, arcAngle
        };
    }

    renderChildren(transition) {
        let props = this.calculatedProps;
        let children = super.renderChildren(transition);

        return children;
    }

    layoutNodes(props, cx, cy, rx, ry, arcStart, arcLength, flip) {
        const { children } = props;

        const ellipse = new Ellipse(cx, cy, rx, ry, false);

        const angles = [];
        let angle = arcStart;

        for (let i = 0; i < this.itemElements.length; i++) {
            angles.push(angle);

            let delta = arcLength / (this.itemElements.length - (this.hubLayout == "center" ? 0 : 1));

            if (flip) {
                angle -= delta;
            } else {
                angle += delta;
            }
        }

        // // One and two items use a square in polygon mode
        // let corners = itemCount < 3 ? 4 : itemCount;
        // // All shapes but the square have the first corner at the top; square has it at the top-left
        // let firstCorner = ccw_sgn * (corners === 4 ? Math.PI * 3 / 4 : Math.PI / 2);
        //
        // // For one or two items, we place the first item on the left; with more items, the first item starts at the top.
        // let startAngle = ccw_sgn * (itemCount < 3 ? Math.PI : Math.PI / 2);
        //
        // // if (items.length == 4) {
        // //     startAngle = Math.PI / items.length;
        // // }

        // special case for square, where the first item should coincide with the top-left corner.
        // if (shape_is_polygon && itemCount === 4) startAngle = ccw_sgn * Math.PI * 3 / 4;

        for (const node of this.itemElements) {
            let index = this.itemElements.indexOf(node);

            const angle = angles[index];
            const point = ellipse.point(angle);

            node.model.x = point.x;
            node.model.y = point.y;

            let nodeOptions = {};

            // if (this.parentElement.connectorStyle != "hub") {
            //     nodeOptions = {
            //         textDirection: this.getCircleTextDirection(-1 * -1 * angle)
            //     };
            // }

            const nodeProps = node.calcProps(new geom.Size(300, 200), nodeOptions);
            nodeProps.bounds = new geom.Rect(point.x, point.y, nodeProps.size);
            nodeProps.angle = angle;
        }

        return ellipse;
    }

    getCircleTextDirection(angle) {
        let bottomTextBelow = true;
        let topTextAbove = true;

        while (angle < 0) angle += 2 * Math.PI;
        while (angle > 2 * Math.PI) angle -= 2 * Math.PI;
        if (angle < 0.01) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 - 0.01) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 + 0.01 && !bottomTextBelow) return DirectionType.RIGHT;
        if (angle < Math.PI / 2 + 0.01 && bottomTextBelow) return DirectionType.BOTTOM;
        if (angle < Math.PI - 0.01) return DirectionType.LEFT;
        if (angle < Math.PI + 0.01) return DirectionType.LEFT;
        if (angle < 3 * Math.PI / 2 - 0.01) return DirectionType.LEFT;
        if (angle < 3 * Math.PI / 2 + 0.01 && !topTextAbove) return DirectionType.RIGHT;
        if (angle < 3 * Math.PI / 2 + 0.01 && topTextAbove) return DirectionType.TOP;
        if (angle < 2 * Math.PI - 0.01) return DirectionType.RIGHT;
        return DirectionType.RIGHT;
    }

    getAnimations() {
        const animations = [];
        this.itemElements
            .forEach(node => {
                animations.push(...node.getAnimations());

                this.connectors.itemElements
                    .filter(({ model }) => model.source === node.id)
                    .forEach(connector => {
                        animations.push(...connector.getAnimations());
                    });
            });
        return animations;
    }

    _exportToSharedModel() {
        const assets = this.itemElements.map(itemElement => itemElement._exportToSharedModel().assets[0]);
        const textContent = this.itemElements.map(itemElement => itemElement._exportToSharedModel().textContent[0]);

        const graphData = [{
            nodes: [
                ...(this.hub ? this.hub._exportToSharedModel().graphData[0].nodes : []),
                ...this.itemElements.map(itemElement => itemElement._exportToSharedModel().graphData[0].nodes[0])
            ].map(node => ({ ...node, x: node.x / 1000 + 0.15, y: node.y / 1000 + 0.15 })),
            connectors: this.connectors._exportToSharedModel().graphData[0].connectors
        }];

        return { graphData, textContent, assets };
    }

    _importFromSharedModel(model) {
        const { graphData, props = {} } = model;
        if (!graphData?.length) return;

        const items = graphData[0].nodes.map(node => ({
            id: node.id,
            nodeType: node.type,
            ...(node.asset ? {
                id: uuid(),
                content_type: node.asset.type,
                content_value: node.asset.value,
                assetName: node.asset.name,
                assetProps: node.asset.props,
            } : {}),
            ...(node.textContent ? {
                text: {
                    blocks: [
                        {
                            html: node.textContent.mainText.text,
                            textStyle: node.textContent.mainText.textStyle || TextStyleType.TITLE,
                            type: AuthoringBlockType.TEXT,
                        },
                        ...node.textContent.secondaryTexts.map(({ text, textStyle }) => ({
                            html: text,
                            textStyle: textStyle || TextStyleType.BODY,
                            type: AuthoringBlockType.TEXT,
                        }))
                    ]
                }
            } : {}),
        }));

        const connectors = {
            items: graphData[0].connectors.map(connector => ({
                id: connector.id,
                connectorType: connector.type,
                source: connector.source,
                target: connector.target,
                ...(connector.labelText ? {
                    labels: [{
                        text: {
                            blocks: [{
                                html: connector.labelText,
                                textStyle: TextStyleType.TITLE,
                                type: AuthoringBlockType.TEXT,
                            }]
                        }
                    }]
                } : {})
            }))
        };

        const hub = items.splice(0, 1);

        return { items, connectors, hub, ...props };
    }
}

export const elements = {
    HubAndSpoke
};
