import React, { Component } from "reactn";
import styled from "styled-components";

import { _ } from "js/vendor";
import {
    AuthoringBlockType,
    BlockStyleType,
    BLOCK_INSET_GAP,
    TextStyleType,
    HorizontalAlignType, ListStyleType,
} from "common/constants";

import { ElementBlock } from "./AuthoringBlocks/ElementBlock";
import { MediaElementBlock } from "./AuthoringBlocks/MediaElementBlock";
import { CodeBlock } from "./AuthoringBlocks/CodeBlock";
import { DividerBlock } from "./AuthoringBlocks/DividerBlock";
import { getListDecorationSpacing, TextBlock } from "./AuthoringBlocks/TextBlock";
import { EquationBlock } from "./AuthoringBlocks/EquationBlock";
import { CardsBlock } from "./AuthoringBlocks/CardsBlock";

const BlockContainer = styled.div`
  width: 100%;
  pointer-events: auto;
  z-index: 0; // necessary because TextBlock's have z-index set and would be over the annotationlayer without this
`;

const BlockPositionedBlockFrame = styled.div.attrs(({ marginTop = 0, marginBottom = 0, marginLeft = 0, marginRight = 0, align = "center" }) => ({
    style: {
        marginTop: `${marginTop}px`,
        marginBottom: `${marginBottom}px`,
        marginLeft: `${marginLeft}px`,
        marginRight: `${marginRight}px`,
        display: "flex",
        justifyContent: align
    }
}))``;

const AbsolutePositionedBlockFrame = styled.div.attrs(({ bounds, zIndex, opacity }) => ({
    style: {
        zIndex,
        opacity,
        ...bounds.toObject()
    }
}))`
  position: absolute;
  justify-content: center;
  align-items: flex-start;
  display: flex;
`;

const BlockStyleFrame = styled.div.attrs(({ bounds, color, showBorder }) => ({
    style: {
        ...bounds.toObject(),
        background: color,
        border: showBorder ? "solid 1px #ccc" : "none"
    }
}))`
  position: absolute;
  z-index: 0;
`;

export class AuthoringBlockContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editingBlock: null
        };

        this.ref = React.createRef();
        this.blockRefs = {};
    }

    get blocks() {
        const { model } = this.props;
        return model.blocks.map(blockModel => this.blockRefs[blockModel.id] ? this.blockRefs[blockModel.id].current : null);
    }

    get isTextBox() {
        const { model } = this.props;
        return model.fill == "none" && (model.stroke == "none" || model.strokeWidth == 0);
    }

    get model() {
        const { model } = this.props;
        return model;
    }

    handleStartEditingBlock = block => {
        this.setState({ editingBlock: block });
    }

    handleStopEditingBlock = block => {
        this.setState({ editingBlock: null });
    }

    render() {
        const {
            element,
            canvas,
            blockProps,
            textAlign,
            columns,
            columnGap,
            isCalculatingLayout,
            slideColor,
            backgroundColor,
            allowDirectSelection = true,
            isAnimating,
            animationState,
            ignoreSpellcheck
        } = this.props;

        const { canSelect } = this.state;

        const getBlockStyle = block => block.model.blockStyle ?? BlockStyleType.NONE;

        this.blockRefs = {};
        const renderProps = blockProps.map((block, index) => {
            this.blockRefs[block.id] = React.createRef();

            const props = {
                ...block,
                containerRef: this.ref,
                ref: this.blockRefs[block.id],
                index,
                element,
                canvas,
                canEdit: element.canEdit && !element.hasCustomEditor && !canvas?.isPlayback,
                canSelect: element.canEdit && (allowDirectSelection || canSelect) && !canvas?.isPlayback,
                textAlign,
                isCalculatingLayout,
                onStartEditing: this.handleStartEditingBlock,
                onStopEditing: this.handleStopEditingBlock,
                slideColor,
                backgroundColor,
                ignoreSpellcheck
            };

            if (isCalculatingLayout) {
                props.renderProps = { margin: props.blockMargin };
            } else {
                props.renderProps = { bounds: props.renderBounds ?? props.containerBounds };
            }

            return props;
        });

        const Blocks = renderProps.map((props, index) => {
            let Block;
            switch (props.type) {
                case AuthoringBlockType.MEDIA:
                    Block = <MediaElementBlock key={props.id} {...props} />;
                    break;
                case AuthoringBlockType.EQUATION:
                    Block = <EquationBlock key={props.id} {...props} />;
                    break;
                case AuthoringBlockType.CODE:
                    Block = <CodeBlock key={props.id} {...props} />;
                    break;
                case AuthoringBlockType.DIVIDER:
                    Block = <DividerBlock key={props.id} {...props} />;
                    break;
                case AuthoringBlockType.TEXT:
                    Block = <TextBlock key={props.id} {...props} />;
                    break;
                case AuthoringBlockType.ELEMENT:
                    Block = <ElementBlock key={props.id} {...props} />;
                    break;
                case AuthoringBlockType.CARDS:
                    Block = <CardsBlock key={props.id} {...props} />;
                    break;
                default:
                    throw new Error(`Unknown block type ${props.type}`);
            }

            if (isCalculatingLayout) {
                let align;
                switch (textAlign) {
                    case HorizontalAlignType.RIGHT:
                        align = "flex-end";
                        break;
                    case HorizontalAlignType.CENTER:
                        align = "center";
                        break;
                    case HorizontalAlignType.LEFT:
                    default:
                        align = "flex-start";
                }

                return (<BlockPositionedBlockFrame
                    key={`frame${index}`}
                    marginTop={props.renderProps.margin.top}
                    marginBottom={props.renderProps.margin.bottom}
                    marginLeft={props.renderProps.margin.left}
                    marginRight={props.renderProps.margin.right}
                    align={align}
                >
                    {Block}
                </BlockPositionedBlockFrame>);
            }

            let opacity = null;
            if (isAnimating && animationState.blocks) {
                opacity = animationState.blocks[props.id].fadeInProgress;
            }

            return (<AbsolutePositionedBlockFrame
                key={`frame${index}`}
                bounds={props.renderProps.bounds}
                zIndex={renderProps.length > 1 ? (1000 - (props.textStyles?.fontSize ?? 0)) : null}
                opacity={opacity}
            >
                {Block}
            </AbsolutePositionedBlockFrame>);
        });

        const BlockStyleFrames = [];
        if (!isCalculatingLayout) {
            let currentBlockStyle;
            let currentBlockStyleBounds;
            for (const props of renderProps) {
                const index = renderProps.indexOf(props);

                const blockStyle = getBlockStyle(props);
                const nextBlock = renderProps[index + 1];

                const resetCurrentBlockStyle = () => {
                    currentBlockStyle = blockStyle;
                    currentBlockStyleBounds = props.renderProps.bounds;
                };

                const terminateCurrentBlockStyle = () => {
                    if (currentBlockStyle !== BlockStyleType.NONE) {
                        const color = currentBlockStyle === BlockStyleType.INSET ? "white" : canvas.getTheme().palette.getColor(props.model.blockColor ?? "#f1f1f1").toRgbString();
                        BlockStyleFrames.push(<BlockStyleFrame
                            bounds={currentBlockStyleBounds.inflate(BLOCK_INSET_GAP)}
                            color={color}
                            showBorder={currentBlockStyle === BlockStyleType.INSET}
                        />);
                    }
                    resetCurrentBlockStyle();
                };

                if (currentBlockStyle && blockStyle !== currentBlockStyle) {
                    terminateCurrentBlockStyle();
                } else if (!currentBlockStyle) {
                    resetCurrentBlockStyle();
                }

                currentBlockStyleBounds = currentBlockStyleBounds.union(props.renderProps.bounds);

                if (!nextBlock) {
                    terminateCurrentBlockStyle();
                }
            }
        }

        const containerStyles = {
            textAlign: textAlign // needed for calcing layout
        };

        if (columns > 1) {
            containerStyles.columnCount = columns;
            containerStyles.columnFill = "auto";
            containerStyles.columnGap = columnGap;
        }

        if (!isCalculatingLayout) {
            containerStyles.position = "relative";
        }

        return (
            <BlockContainer ref={this.ref} className="authoring-block-container" style={containerStyles}>
                {BlockStyleFrames}
                {Blocks}
            </BlockContainer>
        );
    }
}
