import styled from "styled-components";
import React from "react";
import { AuthoringBlockType } from "common/constants";

import { AuthoringBlock } from "./AuthoringBlock";

const Container = styled.div`
  //overflow: hidden;
  position: relative;
`;

const ElementBox = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  //overflow: hidden;     // disabling because it clips dropshadow theme effect but this may have issues down the road when we introduce other elements than Media
  top: 0px;
`;

export class ElementBlock extends AuthoringBlock {
    get type() {
        return AuthoringBlockType.ELEMENT;
    }

    get blockMargin() {
        return {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };
    }

    get elementType() {
        return this.model.type;
    }

    get element() {
        const { blockElement } = this.props;
        return blockElement;
    }

    constructor() {
        super();

        this.state = {
            element: null,
            isEditing: false,
            isDragging: false
        };

        this.containerRef = React.createRef();
    }

    render() {
        const { model, blockElement, canvas, isCalculatingLayout } = this.props;
        const { isEditing, isDragging } = this.state;

        if (!blockElement.calculatedProps) return null; // not sure why this is happening but it happens if you have a media block and a text block and delete the text block

        const styles = {
            width: model.autoWidth ? blockElement.calculatedProps.size.width : "100%",
            height: model.autoHeight ? blockElement.calculatedProps.size.height : model.blockHeight,
            pointerEvents: isEditing ? "none" : "auto"
        };

        if (model.backgroundColor) {
            styles.background = canvas.getTheme().palette.getColor(model.backgroundColor).toRgbString();
        }

        if (isDragging) {
            styles.opacity = 0.3;
        }

        return (
            <Container
                id={model.id}
                ref={this.containerRef}
                style={styles}
            >
                <ElementBox ref={this.ref}>
                    {!isCalculatingLayout && blockElement.renderElement(false, { isDocumentView: true })}
                </ElementBox>
            </Container>
        );
    }
}
