import React from "react";
import equal from "fast-deep-equal/es6";

import { ConnectorType, ForeColorType, FormatType } from "common/constants";
import { fontManager } from "js/core/services/fonts";
import { getDataSourceManagerInstance, DataState, DataSourceManager } from "js/core/services/dataSourceManager";
import { ElementStyles } from "js/core/styleSheet";
import * as geom from "js/core/utilities/geom";
import { AnchorType } from "js/core/utilities/geom";
import { blendColors, uuid } from "js/core/utilities/utilities";
import { app } from "js/namespaces";
import { _ } from "js/vendor";
import { getValueOrDefault } from "js/core/utilities/extensions";
import { MigrationsHelper } from "js/core/utilities/migrationsHelper";
import { DivGroup } from "js/core/utilities/svgHelpers";
import { ds } from "js/core/models/dataService";
import { isRenderer } from "js/config";
import getObjectHash from "common/utils/getObjectHash";
import perf from "js/core/utilities/perf";
import FetchingClickShield from "js/react/components/FetchingClickShield";

import { ElementLayouter } from "../layouts/ElementLayouter";
import { layoutHelper } from "../layouts/LayoutHelper";

const DEBUG_STYLES = false;
const DEBUG_ELEMENT_PERFORMANCE = false;

class BaseElement {
    static get isElement() {
        return true;
    }

    static get schema() {
        return {};
    }

    get type() {
        return this.constructor.name;
    }

    get slide() {
        return this.canvas.slide;
    }

    get name() {
        return this.type;
    }

    get isCollectionItem() {
        if (this.parentElement) {
            return this.parentElement.isCollectionItem;
        } else {
            return false;
        }
    }

    get isOnAuthoringCanvas() {
        if (this.parentElement) {
            return this.parentElement.isOnAuthoringCanvas;
        } else {
            return false;
        }
    }

    get authoringCanvas() {
        if (!this.isOnAuthoringCanvas) {
            return null;
        } else {
            let elem = this;
            while (elem.parentElement.isOnAuthoringCanvas) {
                elem = elem.parentElement;
            }
            return elem;
        }
    }

    get isOnDocumentCanvas() {
        return this.findClosestOfType("DocContainerElement") != null;
    }

    get formatOptions() {
        const formatOptions = this._formatOptions;
        if (!formatOptions) return null;
        // This is a hack to prevent the format options from being decimal value while animating
        // and making sure we present the percentage value as a whole number and not a decimal
        if (this.isAnimating) {
            return {
                ...formatOptions,
                decimal: 0
            };
        }

        // We set "auto" to be 0.00, but if we are presenting the values as percentages, we want to show them as whole numbers
        // Alternatively we should consider set decimal to be 0 when we are presenting percentages
        return {
            ...formatOptions,
            decimal: this.format == FormatType.PERCENT && formatOptions.decimal === "auto" ? 0 : formatOptions.decimal
        };
    }

    get isBlockElement() {
        // return this.parentElement.findClosestOfType("TextElement") != null;
        return this.parentElement?.blockElements && Object.values(this.parentElement.blockElements).includes(this);
    }

    get parentBlockContainer() {
        return this.parentElement.findClosestOfType("TextElement");
    }

    get rootBlockElement() {
        if (this.isBlockElement) {
            return this;
        } else if (this.parentElement) {
            return this.parentElement.rootBlockElement;
        } else {
            return null;
        }
    }

    get isTabbable() {
        return false;
    }

    // return either this or a child element that supports clipboard copy/paste actions. Returns null if no clipboard support
    get clipboardElement() {
        return null;
    }

    get canPasteImage() {
        return false;
    }

    get canPasteNewElement() {
        return false;
    }

    get canDropImage() {
        return false;
    }

    get layer() {
        return this._layer || 0;
    }

    set layer(value) {
        this._layer = value;
    }

    get isRTL() {
        return this.canvas.getTheme().get("isRTL");
    }

    get minWidth() {
        return this.canvas.CANVAS_WIDTH / 2;
    }

    get minHeight() {
        return this.canvas.CANVAS_HEIGHT / 3;
    }

    get reserveFooterSpace() {
        return true; // when true, the layouter will reserve space for the footer and decrease the available space for the primary elemnet - when false, the footer will overlay this element
    }

    get imagesLoadPromise() {
        if (!isRenderer) {
            return Promise.reject(new Error("Supported only in renderer"));
        }

        return Promise.all([
            ...Object.values(this.imageOnLoadPromises),
            ...Object.values(this.elements).map(element => element.imagesLoadPromise)
        ]);
    }

    get bakeColorsWhenConvertedToClassic() {
        if (this.parentElement) {
            return this.parentElement.bakeColorsWhenConvertedToClassic;
        }

        return false;
    }

    get animators() {
        const animators = [this.animator];
        Object.values(this.elements).forEach(element => {
            animators.push(...element.animators);
        });
        return animators.filter(Boolean);
    }

    /**
     * Returns a function that resolves the corresponding image load promise,
     * to be used as an onLoad callback for an image element
     */
    getImageOnLoadPromiseResolver(imageUrl) {
        if (!isRenderer) {
            return () => {
            };
        }

        if (!this.imageOnLoadPromises[imageUrl]) {
            this.imageOnLoadPromises[imageUrl] = new Promise(resolve => {
                // Stripping out the args which can be an Event when passed directly as
                // an onLoad callback
                this.imageOnLoadResolvers[imageUrl] = () => resolve();
            });
        }

        return this.imageOnLoadResolvers[imageUrl];
    }

    getClipboardData() {
        return null;
    }

    isInstanceOf(elementTypeName) {
        let element = this;
        while (element?.constructor) {
            if (element.constructor.name === elementTypeName) {
                return true;
            }
            element = element.__proto__;
        }
        return false;
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // constructor
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_

    constructor(props) {
        this.id = props.id;

        this.uniqueId = _.uniqueId();

        this.parentElement = props.parentElement;
        this.options = props.options || {};
        if (this.parentElement) {
            this.canvas = this.parentElement.canvas;
            // this.svg = this.parentElement.svg.group();
        } else {
            this.canvas = props.canvas;
            // this.svg = this.canvas.svg.group();
        }
        // this.svg.attr("id", this.id).addClass(this.type);
        // this.svg.view = this;
        this.fastLoad = this.canvas.fastLoad;

        // if (this.isTabbable) {
        //     this.svg.addClass("isTabbable");
        // }

        // this.options = {};
        this.elements = {};

        this.setupElement();

        this.isDragging = false;

        this.ref = React.createRef();

        // Custom animation state, used by inheriting elements for storing values describing
        // current animation state, i.e. current value during an "animate to value" animation
        this.animationState = {};

        // Resolved colors cache, reset only when explicitly requested by markStylesAsDirty()
        this.resolvedColorsCache = {};

        // Element colors cache (e.g. background color), reset on every build() and calcProps()
        this.elementColorsCache = {};

        if (isRenderer) {
            // Used for storing and managing promises that get resolved when the image loads
            // Used when we're waiting for images to load before taking a screenshot
            this.imageOnLoadPromises = {};
            this.imageOnLoadResolvers = {};
        }

        // Data source related
        this.currDataSourceId = null;
        this.currDataSourceState = null;
        this.currDataSourceManager = null;
        this.onAfterDataSourceUpdatedCallbacks = [];
        this.currDataSourceStateChangedCb = (state, data, error) => this.useUpdatedDataSource(state, data, error);

        if (import.meta.webpackHot) {
            const onWebpackReload = status => {
                if (status === "prepare") {
                    this.resolvedColorsCache = {};
                    this.elementColorsCache = {};
                }
            };

            import.meta.webpackHot.addStatusHandler(onWebpackReload);
        }
    }

    perfStart(type) {
        if (DEBUG_ELEMENT_PERFORMANCE) {
            perf.start(`${this.type}:${this.pathByElementIndexes}:${type}`);
        }
    }

    perfStop(type) {
        if (DEBUG_ELEMENT_PERFORMANCE) {
            perf.stop(`${this.type}:${this.pathByElementIndexes}:${type}`);
        }
    }

    setupElement() {

    }

    beforeShowElement() {
        this._beforeShowElement();
        _.each(this.elements, childElement => childElement.beforeShowElement && childElement.beforeShowElement());
    }

    _beforeShowElement() {

    }

    prepareToShowElement() {
        this._prepareToShowElement();
        _.each(this.elements, childElement => childElement.prepareToShowElement && childElement.prepareToShowElement());
        this.loadDataSource();
    }

    _prepareToShowElement() {
        // can be overridden to do any setup needed before a slide is shown during playback
    }

    stopElement() {
        this._stopElement();
        Object.values(this.elements).forEach(childElement => childElement?.stopElement?.());
        this.removeDataSource();
    }

    _stopElement() {
        // can be overridden to do any cleanup needed when a slide is left during playback
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // Element hierarchy functions
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_

    getCanvasElement() {
        // if (this.type == "DocumentElement") {
        if (!this.parentElement) {
            return this;
        } else {
            return this.parentElement.getCanvasElement();
        }
    }

    // get the top most parent element
    getRootElement() {
        if (this.parentElement && this.parentElement.type != "CanvasElement") {
            return this.parentElement.getRootElement();
        } else {
            return this;
        }
    }

    // check if this element is the top-most parent element
    isRootElement() {
        return this.parentElement == null || this.parentElement.type == "CanvasElement";
    }

    getElementPath() {
        var path = [this];
        var element = this;
        while (element.parentElement && element.parentElement.type != "CanvasElement") {
            element = element.parentElement;
            path.push(element);
        }
        return path;
    }

    getElementTreeType() {
        return this.type;
    }

    getElementTreePath() {
        let path = this.getElementTreeType();
        let element = this;
        while (element.parentElement) {
            element = element.parentElement;
            path = element.getElementTreeType() + "/" + path;
        }
        return path;
    }

    isChildOf(element) {
        if (this.parentElement === element) {
            return true;
        }

        if (this.parentElement?.isChildOf) {
            return this.parentElement.isChildOf(element);
        }

        return false;
    }

    findClosestOfType(type) {
        if (typeof (type) === "string" && this.type === type) {
            return this;
        } else if (typeof (type) === "string" && super.type === type) {
            return this;
        } else if (typeof (type) === "function" && this instanceof type) {
            return this;
        } else if (this.parentElement) {
            return this.parentElement.findClosestOfType(type);
        } else {
            return null;
        }
    }

    toString(includePath = false) {
        var element = this;
        var path = this.type + "[" + this.id + "]";
        if (includePath) {
            while (element.parentElement) {
                element = element.parentElement;
                path = element.type + "[" + element.id + "]" + " > " + path;
            }
        }
        return path;
        // var slideNum = ds.selection.presentation.getSlideIndex(this.canvas.model.id);
        // return "slide " + slideNum + ": " + path;
    }

    getInheritancePath(list = []) {
        list.insert(this.__proto__.constructor.name, 0);
        if (this.__proto__.type != "BaseElement" && this.__proto__.getInheritancePath) {
            return this.__proto__.getInheritancePath(list);
        } else {
            return list;
        }
    }

    get isInActiveSlide() {
        return this.slide.id === app.currentCanvas.slide.id;
    }

    get uniquePath() {
        let path = this.id;
        let element = this;
        while (element.parentElement && element.parentElement.type != "CanvasElement") {
            element = element.parentElement;
            path = element.id + "/" + path;
        }
        return "/" + path;
    }

    get pathByElementIndexes() {
        let element = this;
        let path = element.collectionItemIndex ?? element.id;
        while (element.parentElement && element.parentElement.type !== "CanvasElement") {
            element = element.parentElement;
            path = `${element.collectionItemIndex ?? element.id}/${path}`;
        }
        return `/${path}`;
    }

    get collectionItemIndex() {
        return null;
    }

    get itemIndex() {
        if (this.parentElement) {
            return this.parentElement.itemIndex;
        } else {
            return 0;
        }
    }

    get isLast() {
        return this.itemIndex === this.itemCount - 1;
    }

    get itemCount() {
        return 1;
    }

    getChildAt(index) {
        for (const id in this.elements) {
            const el = this.elements[id];
            if (el.itemIndex == index) {
                return el;
            }
        }
        throw new Error("no such index");
    }

    findChildElements(elementType, foundElements = []) {
        for (const element of _.sortBy(Object.values(this.elements), "itemIndex")) {
            if (element.type === elementType || element.isInstanceOf(elementType)) {
                foundElements.push(element);
            }
            element.findChildElements(elementType, foundElements);
        }
        return foundElements;
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // Element bounds
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    getCanvasMargins() {
        return {
            left: this.canvas.styleSheet.ElementMargin.marginLeft,
            top: this.canvas.styleSheet.ElementMargin.marginTop,
            right: this.canvas.styleSheet.ElementMargin.marginRight,
            bottom: this.canvas.styleSheet.ElementMargin.marginBottom
        };
    }

    get centerPoint() {
        if (this.calculatedSize) {
            return new geom.Point(this.calculatedSize.width / 2, this.calculatedSize.height / 2);
        } else {
            return new geom.Point(0, 0);
        }
    }

    get registrationPoint() {
        return this._registrationPoint || new geom.Point(0, 0);
    }

    set registrationPoint(value) {
        this._registrationPoint = value;
    }

    get connectionShape() {
        return null;
    }

    // bounds of element positioned relative to entire canvas
    get canvasBounds() {
        // canvasBounds is the margin bounds of this element offset by the parent's innerBounds
        let bounds = this.marginBounds;

        if (this.parentElement) {
            let parentCanvasBounds = this.parentElement.canvasBounds.deflate(this.parentElement.styles.padding ?? 0);
            return bounds.offset(parentCanvasBounds.left, parentCanvasBounds.top);
        }

        return bounds;
    }

    // the bounds of the element relative to it's parent and inclusive of any margins and padding
    get bounds() {
        if (this.calculatedProps && this.calculatedProps.bounds) {
            return this.calculatedProps.bounds;
        }

        return null;
    }

    get marginBounds() {
        return this.bounds.deflate(this.styles.margins ?? 0);
    }

    get paddedBounds() {
        return this.marginBounds.deflate(this.styles.padding ?? 0);
    }

    getCanvasBounds(bounds = this.paddedBounds) {
        if (this.parentElement) {
            let parentCanvasBounds = this.parentElement.canvasBounds;
            return bounds.offset(parentCanvasBounds.left, parentCanvasBounds.top);
        }
    }

    // the bounds of the element minus any margins relative to bounds
    get elementBounds() {
        if (!this.bounds) return new geom.Rect(0, 0, 0, 0);
        return this.bounds.zeroOffset().deflate(this.styles.margins || 0);
    }

    // the bounds of the element minus any padding relative to elementBounds
    get innerBounds() {
        return this.elementBounds.deflate(this.styles.padding || 0);
    }

    set bounds(value) {
        throw new Error("bounds can not be set");
        // this._bounds = value;
    }

    get screenBounds() {
        return this.canvasBounds.multiply(this.canvas.getScale()).offset(this.canvas.$el.offset().left, this.canvas.$el.offset().top);
    }

    getScreenBounds() {
        if (this.bounds) {
            return this.canvasBounds.multiply(this.canvas.getScale() || 1).offset(this.canvas.$el.offset().left, this.canvas.$el.offset().top);
        }
    }

    // the bounds of the element offset by the parents margin and padding
    get offsetBounds() {
        if (this.parentElement) {
            // return this.bounds.deflate(this.parentElement.styles.margins);
            return this.bounds.offset(this.parentElement.styles.marginLeft || 0, this.parentElement.styles.marginTop || 0).offset(this.parentElement.styles.paddingLeft || 0, this.parentElement.styles.paddingTop || 0);
        } else {
            return this.bounds;
        }
    }

    // bounds used to determine selectable area of the element - this can be overridden
    get selectionBounds() {
        // if (this.isOnDocumentCanvas) {
        //     return this.documentCanvasBounds;
        // } else {
        return this.canvasBounds.inflate(this.selectionPadding || 0);
        // }
    }

    get anchorBounds() {
        return this.bounds || new geom.Rect(0, 0, 10, 10);
    }

    get availableAnchorPoints() {
        return [AnchorType.FREE, AnchorType.LEFT, AnchorType.RIGHT, AnchorType.TOP, AnchorType.BOTTOM];
    }

    get circleLayoutAngle() {
        if (this.calculatedProps) {
            return this.calculatedProps.angle;
        }

        return null;
    }

    get renderAllDecorationBehind() {
        return false;
    }

    getAnchorPoint(connector, anchor) {
        return this.anchorBounds.getPoint(anchor);
    }

    getAnchorPointType(connector, connectorPoint, anchorPointType, connectorType) {
        if (anchorPointType == AnchorType.FREE || !this.availableAnchorPoints.contains(anchorPointType)) {
            if (connectorType == ConnectorType.STRAIGHT) {
                return AnchorType.CENTER;
            } else {
                return _.minBy([AnchorType.CENTER, AnchorType.TOP, AnchorType.BOTTOM, AnchorType.LEFT, AnchorType.RIGHT], anchorType => {
                    if (this.availableAnchorPoints.includes(anchorType)) {
                        return this.getAnchorPoint(connector, anchorType).distance(connectorPoint);
                    } else {
                        return 999999;
                    }
                });
            }
        } else {
            return anchorPointType;
        }
    }

    getDynamicValue(dataSource) {
        throw new Error("Not implemented");
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // the selection bounds is used by the selection layer to position rollover and selection overlays
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    get selectionPadding() {
        return 0;
    }

    get showSelectionUI() {
        return true;
    }

    get editableOnParentSelect() {
        return this.options.editableOnParentSelect || false;
    }

    enableEditing() {
        // can be overridden to enable editing
    }

    disableEditing() {
        // can be overridden to disable editing
    }

    get rolloverPadding() {
        return 0; // extra padding to bounds used when detecting if mouse is rolled over this element
    }

    containsPoint(pt) {
        if (this.bounds) {
            return this.selectionBounds.inflate(this.rolloverPadding).contains(pt);
        } else {
            return false;
        }
    }

    get canEdit() {
        if (this.options.canEdit == undefined) {
            return true;
        } else {
            return this.options.canEdit;
        }
    }

    get _canSelect() {
        return false;
    }

    get _canRollover() {
        return false;
    }

    get isSelected() {
        return ds.selection.element == this;
    }

    get requireParentSelection() {
        return this.options.requireParentSelection ?? false;
    }

    get passThroughSelection() {
        return this.options.passThroughSelection ?? true;
    }

    get isTryingLayout() {
        return this.options.isTryingLayout ?? this.parentElement?.isTryingLayout ?? false;
    }

    getParentOfType(type) {
        let parent = this.parentElement;
        while (parent) {
            if (parent.type === type) {
                return parent;
            }

            parent = parent.parentElement;
        }
    }

    getSelectableParent() {
        if (this.parentElement.canSelect) {
            return this.parentElement;
        } else {
            return this.parentElement.getSelectableParent();
        }
    }

    get canSelect() {
        if (this.isSelected) return true;

        // this allows two siblings (like two text boxes in a TextGroup) to be directly selected from one to the other without having to select the parent container again
        let isSiblingSelected = Object.values(this.parentElement.elements).find(sibling => sibling.isSelected && sibling.type == this.type);
        if (isSiblingSelected) return true;

        if (!this.requireParentSelection || this.getSelectableParent().isSelected || this.parentElement.type == "CanvasElement" || this.parentElement.parentElement.type == "CanvasElement") {
            return getValueOrDefault(this.options.canSelect, this._canSelect);
        } else {
            return false;
        }
    }

    get canRollover() {
        // if (this.canSelect) {
        return getValueOrDefault(this.options.canRollover, this._canRollover);
        // } else {
        //     return false;
        // }
    }

    allowRollover(currentSelectedElement) {
        return this._canRollover;
    }

    get isInteractive() {
        return false;
    }

    get canSelectChildElements() {
        return true;
    }

    get _doubleClickToSelect() {
        return false;
    }

    get doubleClickToSelect() {
        if (this.options.doubleClickToSelect == undefined) {
            return this._doubleClickToSelect;
        } else {
            return this.options.doubleClickToSelect;
        }
    }

    get selectionUIType() {
        if (this.options.selection) {
            return this.options.selection;
        } else {
            return this.type + "Selection";
        }
    }

    get rolloverUIType() {
        if (this.options.rollover) {
            return this.options.rollover;
        } else {
            return this.type + "Rollover";
        }
    }

    get defaultOverlayUIType() {
        if (this.options.defaultOverlay) {
            return this.options.defaultOverlay;
        } else {
            return this.type + "DefaultOverlay";
        }
    }

    getSelectionElement() {
        return this;
    }

    get canDrag() {
        return false;
    }

    _getCustomBlocksDropTargets(sourceElement, sourceBlocks) {
        return [];
    }

    getCustomBlocksDropTargets(sourceElement, sourceBlocks) {
        return [
            ...this._getCustomBlocksDropTargets(sourceElement, sourceBlocks).map(target => ({ ...target, id: uuid() })),
            ...Object.values(this.elements).reduce((dropTargets, element) => ([...dropTargets, ...element.getCustomBlocksDropTargets(sourceElement, sourceBlocks)]), [])
        ];
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // the default overlay is a UI overlay that can be displayed in the selection layer for unset elements (like images without assets) or for elements in an error state (like text not fitting)
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_

    get showDefaultOverlay() {
        return false;
    }

    get defaultOverlayType() {
        return this.type + "DefaultOverlay";
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // the rollover overlay is a UI overlay that is displayed in the selection layer when the mouse is over the element's rollover rect
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_

    getContextMenu() {
        return null;
    }

    refreshElement(transition, suppressRefreshSelectionLayer) {
        // Going up through the tree, but it has to be processed on some level until the top is reached
        return this.parentElement.refreshElement(transition, suppressRefreshSelectionLayer);
    }

    get canRefreshElement() {
        // Going up until an element that has this property overrided to true or false
        return this.parentElement.canRefreshElement;
    }

    /**
     * Returns a list of font ids used by the element (based on its styles)
     */
    get usedFontIds() {
        const fontIds = new Set();
        const processStyles = styles => {
            Object.entries(styles)
                .forEach(([key, value]) => {
                    if (key === "fontId" && typeof value === "string") {
                        fontIds.add(value);
                    } else if (value && typeof value === "object") {
                        processStyles(value);
                    }
                });
        };

        processStyles(this.styles);

        return [...fontIds];
    }

    get shouldLoadOpentypeFonts() {
        return false;
    }

    // Load css fonts by default
    get shouldLoadCssFonts() {
        return true;
    }

    get allChildElements() {
        return _.flattenDeep(Object.values(this.elements ?? {})
            .filter(child => child instanceof BaseElement)
            .map(child => ([child, child.allChildElements])));
    }

    refreshRender() {
        return this.canvas.layouter.refreshRender();
    }

    replaceModel(newModel) {
        Object.keys(this.model).forEach(key => delete this.model[key]);
        Object.assign(this.model, newModel);
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // build() is called to build each element and any child elements it may require depending on the model state
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    build(model, generationKey, lowerBoundInclusiveVersion, upperBoundInclusiveVersion) {
        this.perfStart("build");

        // Reset calculated props to make sure the element is in correct "zero" state
        // NOTE: we won't reset props on children to keep their calculated props if they got removed
        this.resetCalculatedProps(false);

        // Clear loaded styles
        delete this._loadedStyles;

        this.model = model ?? {};

        // Migrate old models
        this.lowerBoundInclusiveVersion = lowerBoundInclusiveVersion;
        this.upperBoundInclusiveVersion = upperBoundInclusiveVersion;
        this.migrate();

        // Use schema for default model values
        _.defaults(this.model, this.constructor.schema);

        this.generationKey = generationKey;

        this._loadedStyles = this.loadStyles();

        if (this.loadedStyles.decoration && this.loadedStyles.decoration.type !== "none") {
            this.createDecoration();
        } else {
            this.removeDecoration();
        }

        this._build();

        // Saving current model in order to reuse it when the element is replaced
        this.lastBuildModel = _.cloneDeep(this.model);

        // Marking deleted elements
        this.allChildElements.forEach(element => {
            element.isDeleted = element.isDeleted || element.generationKey !== this.generationKey;
        });

        this.perfStop("build");
    }

    createDecoration(styles) {
        this.decoration = this.addElement("decoration", () => this.canvas.elementManager.get("DecorationElement"));
        this.decoration.updateStyles(styles);
    }

    removeDecoration() {
        if (this.decoration) {
            this.removeElement(this.decoration);
            this.decoration = null;
        }
    }

    migrate() {
        if (!this.canvas.canMigrate()) {
            return;
        }

        MigrationsHelper.migrate(this, this.lowerBoundInclusiveVersion, this.upperBoundInclusiveVersion);
    }

    _migrate() {
    }

    _migrate_8() {
        if (this.model.userFontScale) {
            let newObj = {};
            for (let key of Object.keys(this.model.userFontScale)) {
                let value = this.model.userFontScale[key];

                let newKey = key;
                if (!newKey.startsWith("CanvasElement/")) {
                    newKey = "CanvasElement/" + newKey;
                    newKey = newKey.replace("ContentBlockContainer", "ContentBlockContainer/ContentBlockFrame/ContentBlockCollection");
                    newKey = newKey.replace("NodeContentBlockContainer/", "");
                    newKey = newKey.replace("HeadlineContentBlock", "HeadlineContentBlock/ContentBlockFrame/ContentBlockCollection");
                }
                newObj[newKey] = value;
            }
            this.model.userFontScale = newObj;
        }
    }

    _build() {
        // should be overridden by extending class
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // addElement() will add a new element during the build() step
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    addElement(id, elementFactory, options = {}) {
        let elementObj = elementFactory();

        let isReplacingElement = false;
        // if an element with the provided id does not exist, call the elementFactory function to create it and store in elements hash
        // if (!this.elements[id] || ((elementObj.isElement || elementObj.isSVG) && this.elements[id].type !== elementObj.name) || (elementObj instanceof SVG.Element && this.elements[id].type !== elementObj.type)) {
        if (!this.elements[id] || this.elements[id].type !== elementObj.name) {
            if (this.elements[id] && this.elements[id].type !== elementObj.name && this.elements[id].calculatedProps) {
                // if this element id exists already but the type has changed, we are replacing an element with a different type
                // in this case, we dont want to do the normal transition because it results in odd behavior (popping and transitioning)
                isReplacingElement = true;
                let replacedId = "replaced" + id;

                // make a copy of the replace element with a new id so we can fade it out and fade the replacing element in
                this.elements[replacedId] = this.elements[id];
                this.elements[replacedId].id = replacedId;
                this.elements[replacedId].calculatedProps.id = replacedId;
                // Using lastBuildModel becuase the old element may be not compatible with the updated model
                this.elements[replacedId].model = this.elements[replacedId].lastBuildModel;
                this.elements[replacedId].isReplaced = true;
            }

            // create the element from the class
            this.elements[id] = new elementObj({
                id: id,
                parentElement: this,
                options
            });
        }

        this.elements[id]._isReplacing = isReplacingElement;

        this.elements[id].options = options;
        // let the element build any child elements or svg nodes
        this.elements[id].build(options.model || this.model, this.generationKey, this.lowerBoundInclusiveVersion, this.upperBoundInclusiveVersion);

        return this.elements[id];
    }

    get isReplacing() {
        if (this._isReplacing) return this._isReplacing;
        return this.parentElement?.isReplacing;
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // load() is called after build() to allow the element to perform any necessary asychronous loading (like pictures or icons)
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    async load() {
        this.perfStart("load");

        if (isRenderer) {
            // Force reset on load promises
            this.imageOnLoadPromises = {};
            this.imageOnLoadResolvers = {};
        }

        // Loading fonts if needed
        if (!equal(this.usedFontIds, this.prevUsedFontIds)) {
            this.fonts = {};

            if (this.shouldLoadOpentypeFonts || this.shouldLoadCssFonts) {
                await Promise.all(this.usedFontIds.map(async fontId => {
                    const font = await fontManager.loadFont(fontId);

                    // Loading opentype/css fonts
                    if (this.shouldLoadOpentypeFonts) {
                        await Promise.all(font.styles.map(style => style.loadOpentypeFont()));
                    }
                    if (this.shouldLoadCssFonts) {
                        await Promise.all(font.styles.map(style => style.loadCssFont()));
                    }

                    this.fonts[fontId] = font;
                }));
            }

            this.prevUsedFontIds = _.cloneDeep(this.usedFontIds);
        }

        // Loading fallback CJK fonts when in renderer
        // (the renderer env has much less fonts installed than
        // any regular OS, so we need to load fallback CJK fonts
        // and reference them manually)
        if (isRenderer) {
            await fontManager.loadCJKFallbackCSSFonts();
        }

        // Loading datasource linked to element if exists
        if (this.hasDataSourceLink()) {
            await this.loadDataSource();
        }

        // Loading self and children
        await Promise.all([
            this._load(),
            ...Object.values(this.elements)
                .filter(element => element instanceof BaseElement)
                .map(element => element.load())
        ]);

        this.perfStop("load");
    }

    /**
     * Should be overridden by extending class
     */
    async _load() {
    }

    /**
     * Should be overridden by extending class
     */
    get shouldReloadOnPresenterToggle() {
        return false;
    }

    // Reloads the element if it's flagged to do so, and the children as well
    async reloadOnPresenterToggle() {
        // Reloading self and children
        await Promise.all([
            this.shouldReloadOnPresenterToggle && this._load(),
            ...Object.values(this.elements)
                .filter(element => element instanceof BaseElement)
                .map(element => element.reloadOnPresenterToggle())
        ]);
    }

    /**
     * Loads bound data if element has datasource configured
     */
    async loadDataSource() {
        if (!this.canvas.isCurrentCanvas) return;

        const linkedDataSource = this.model.dataSourceLink;
        if (linkedDataSource?.dataSourceId && this.currDataSourceId !== linkedDataSource.dataSourceId) {
            if (!linkedDataSource.ownerIds) {
                linkedDataSource.ownerIds = await DataSourceManager.fetchOwnerIds(linkedDataSource.dataSourceId);
                this.canvas.refreshCanvas();
                return;
            }

            if (!linkedDataSource.ownerIds.includes(app.user?.id)) return;

            this.removeDataSource();
            this.getDataSourceManager(linkedDataSource.dataSourceId);
        }
    }

    /**
     * Getter for current DataSourceManager instance
     */
    getDataSourceManager(dataSourceId) {
        if (!this.currDataSourceId || !this.currDataSourceManager) {
            this.currDataSourceId = dataSourceId;
            this.currDataSourceManager = getDataSourceManagerInstance({
                dataSourceId, dataStateChangedCb: this.currDataSourceStateChangedCb
            });
        }

        return this.currDataSourceManager;
    }

    /**
     * Checks if element has datasource binding
     */
    hasDataSourceLink() {
        const dataSourceLink = this.model.dataSourceLink;
        return !!dataSourceLink?.dataSourceId && dataSourceLink?.createdBy === app.user?.id;
    }

    /**
     * Callback function to be called from DataSourceManager when underlying data state updates
     */
    async useUpdatedDataSource(state, data, error) {
        this.currDataSourceState = state;

        if (state === DataState.Disconnected) {
            this.removeDataSource();
            return;
        }

        if (data) {
            if (!this.model.dataSourceLink?.createdBy) {
                this.model.dataSourceLink.createdBy = data.createdBy;
            }

            await this._useUpdatedDataSource(data);
        } else {
            if (this.canvas.isRendered && !this.canvas.layouter.isGenerating) {
                this.canvas.layouter.refreshRender(false);
            }
        }

        this.onAfterDataSourceUpdatedCallbacks.forEach(cb => cb(state, data, error));
    }

    /**
     * Should be overridden by extending class
     */
    async _useUpdatedDataSource(data) {
    }

    onAfterDataSourceUpdated(callback) {
        this.onAfterDataSourceUpdatedCallbacks.push(callback);
    }

    offAfterDataSourceUpdated(callback) {
        this.onAfterDataSourceUpdatedCallbacks = this.onAfterDataSourceUpdatedCallbacks.filter(cb => cb !== callback);
    }

    /**
     * Exports element's current model to shared model structure by using the element's
     * custom implementation and save it into slide's model
     */
    exportToSharedModel() {
        const exportedSharedModel = this._exportToSharedModel();
        if (!exportedSharedModel) return;

        // add the current element model to shared model so it can be used during importing
        this.slide.set("sharedModel", {
            ...(this.slide.get("sharedModel") || {}),
            _rawElementModels: {
                ...(this.slide.get("sharedModel")?._rawElementModels || {}),
                [this.constructor.name]: this.model
            }
        });

        return exportedSharedModel;
    }

    /**
     * Should be overridden by extending class
     */
    _exportToSharedModel() {
    }

    /**
     * Imports's shared model structure into element's own model
     */
    importFromSharedModel(model = {}) {
        const { postProcessingFunction, ...modelUpdates } = this._importFromSharedModel(model) || {};

        // update the element's model by merging the new data with element's default/latest model
        this.replaceModel({ ...this.model, ...modelUpdates });

        return { ...this.model, postProcessingFunction };
    }

    /**
     * Should be overridden by extending class
     */
    _importFromSharedModel(model) {
    }

    /**
     * Compares the currently stored value of a named prop with the supplied value,
     * replaces the current value.
     * This is supposed be used for storing non-idempotent values (i.e. element state)
     * in order to detect changes between calcProps cycles.
     * @param {string} propName prop name
     * @param {*} propValue prop value (new)
     * @param {boolean} treatNewPropsAsChanged return true if the prop hasn't been stored yet
     * @returns {boolean} true if the current value matches the supplied value
     */
    hasStoredPropChanged(propName, propValue, treatNewPropsAsChanged = false) {
        if (!this.storedProps) {
            this.storedProps = {};
        }

        const wasStored = propName in this.storedProps;
        const hasChanged = !_.isEqual(this.storedProps[propName], propValue);

        this.storedProps[propName] = _.cloneDeep(propValue);

        if (wasStored) {
            return hasChanged;
        } else {
            return treatNewPropsAsChanged;
        }
    }

    createProps(props = {}) {
        // Reset the state of the element and children to ensure idempotency of calcProps()
        // in cases when we recalc props multiple times withing a single generate() loop
        this.resetCalculatedProps();

        // Initialize calculated styles here (because calcProps() won't be called)
        this._calculatedStyles = _.cloneDeep(this.loadedStyles);

        this.calculatedProps = {
            id: this.id,
            type: this.type,
            ...props,
            layer: props.layer ?? this.layer
        };

        return this.calculatedProps;
    }

    getTreeProps(treeProps = {}) {
        treeProps.id = this.id;
        treeProps.calculatedProps = _.clone(this.calculatedProps);
        treeProps.children = [];
        _.each(this.elements, element => {
            treeProps.children.push(element.getTreeProps());
        });
        return treeProps;
    }

    assignPropsToTree(treeProps) {
        this.createProps(treeProps.calculatedProps);
        _.each(this.elements, element => {
            element.assignPropsToTree(treeProps.children.findById(element.id));
        });
    }

    /**
     * Resets the state of the element and its children to the state they were after the previous build() was called
     */
    resetCalculatedProps(resetChildren = true) {
        // Skip deleted elements, let them render with old calculated props
        if (this.calculatedProps && !this.isDeleted) {
            // Reset calculated props
            delete this.calculatedProps;

            // Reset element colors cache
            this.elementColorsCache = {};

            // Reset calculated styles
            delete this._calculatedStyles;

            if (resetChildren) {
                // Reset children
                Object.values(this.elements ?? {})
                    .filter(element => element instanceof BaseElement)
                    .forEach(element => element.resetCalculatedProps(resetChildren));
            }
        }
    }

    calcProps(size, options = {}) {
        this.perfStart("calcProps");

        // Reset the state of the element and children to ensure idempotency of calcProps()
        // in cases when we recalc props multiple times withing a single generate() loop
        this.resetCalculatedProps();

        // Reset element colors cache (even if running calcProps() for the first time)
        this.elementColorsCache = {};

        // Initialize calculated styles
        this._calculatedStyles = _.cloneDeep(this.loadedStyles);

        if (options.stylesScale) {
            // Scale styles if requested
            this.scaleStyleValues(options.stylesScale);
        }

        let availableSize;
        if (this.calculatedStyles.width || this.calculatedStyles.height) {
            availableSize = new geom.Size(this.calculatedStyles.width || size.width, this.calculatedStyles.height || size.height);
            availableSize = availableSize.deflate(this.calculatedStyles.padding || 0);
        } else {
            if (size instanceof geom.Size) {
                availableSize = size.clone();
            } else {
                throw new Error(this.toString(true), "Invalid size parameter passed to calcProps", size);
            }
            availableSize = availableSize.deflate(this.calculatedStyles.margins || 0).deflate(this.calculatedStyles.padding || 0);
        }

        // create the calculatedProps for this element which will get returned up the tree to build the layout object that the layouter passes to render()
        // note: we don't want to recreate calculatedProps on every calcProps() call because the BaseElement.refresh() function can be used to
        // spot recalc and render a node within the tree without recalcing the entire tree which is important for things like dragging performance
        let calculatedProps = {
            id: this.id,
            type: this.type,
            // This can be owerwritten by updating calculatedProps directly
            layer: this.layer
        };

        calculatedProps.allowedSize = size;
        calculatedProps.options = options;

        calculatedProps.size = availableSize;
        calculatedProps.isFit = true;

        // store the model used for calculating these props
        calculatedProps.model = _.cloneDeep(this.model);

        // merge passed in options with options set with addElement
        this.calculatedOptions = _.merge({}, this.options, options);

        // let the elememt
        let layoutProps = this._calcProps(calculatedProps, this.calculatedOptions);

        if (!layoutProps.size || !(layoutProps.size instanceof geom.Size)) {
            throw new Error(`${this.toString(true)}: Element size not defined!`);
        }

        // merge any returned additional props from _calcProps to the layoutObj
        Object.assign(calculatedProps, layoutProps);

        calculatedProps.innerSize = calculatedProps.size;
        calculatedProps.paddedSize = calculatedProps.innerSize.inflate(this.calculatedStyles.padding || 0);
        calculatedProps.size = calculatedProps.paddedSize.inflate(this.calculatedStyles.margins || 0);

        if (this.elements.decoration) {
            let decorationProps = this.elements.decoration.calcProps(calculatedProps.paddedSize);
            decorationProps.bounds = new geom.Rect(0, 0, calculatedProps.paddedSize);
            decorationProps.layer = -99;
        }

        // Set default bounds
        calculatedProps.bounds = new geom.Rect(0, 0, calculatedProps.size);

        this.calculatedProps = calculatedProps;

        this.perfStop("calcProps");

        return calculatedProps;
    }

    _calcProps(props, options) {
        if (Object.keys(this.elements).length == 0) {
            return { size: props.size };
        } else {
            for (let element of Object.values(this.elements)) {
                element.calcProps(props.size, options);
            }
            return { size: layoutHelper.getTotalBoundsOfItems(Object.values(this.elements)).size };
        }
    }

    postCalcProps() {
        // If elements were not calculated, we don't need to postCalcProps
        if (!this.calculatedProps) {
            return;
        }

        // if any of our child textElements have called registerTextElementForMatchScale with a textScale, we need to
        // recalc ourselves so all the textElements can calc using the matched text scale
        if (this.matchedTextElements && Object.values(this.matchedTextElements).some(({ elementUniquePaths }) => elementUniquePaths.size > 1)) {
            this.recalcProps();
        }

        this.matchedTextElements = null;

        this._postCalcProps();
        for (let element of Object.values(this.elements)) {
            element.postCalcProps();
        }
    }

    _postCalcProps() {

    }

    registerTextElementForMatchScale(textElement, textScale) {
        if (!this.matchedTextElements) {
            this.matchedTextElements = {};
        }
        const elementId = textElement.matchTextScaleId;
        if (!this.matchedTextElements[elementId]) {
            this.matchedTextElements[elementId] = { textScale: 1, elementUniquePaths: new Set() };
        }
        this.matchedTextElements[elementId].textScale = Math.min(textScale, this.matchedTextElements[elementId].textScale);
        this.matchedTextElements[elementId].elementUniquePaths.add(textElement.uniquePath);
    }

    recalcProps(recalcOptions) {
        // some calculatedProps are set externally from the calcProps function - we need to save and restore those to the newProps after calcProps()
        let currentBounds = this.calculatedProps.bounds;
        let layer = this.calculatedProps.layer;

        let newProps = this.calcProps(this.calculatedProps.allowedSize, { ...this.calculatedProps.options, ...recalcOptions, recalc: true });

        newProps.bounds = currentBounds;
        newProps.layer = layer;

        return newProps;
    }

    get shouldTransitionWhenNew() {
        return true;
    }

    get clipId() {
        return this.uniqueId + "-clip-path";
    }

    get filterId() {
        {
            return this.uniqueId + "-filter";
        }
    }

    renderFilter(filter) {
        switch (filter) {
            case "shadow":
                return (
                    <filter id={this.filterId} width="300%" height="300%" x="-100%" y="-100%">
                        <feOffset dx="0" dy="0" result="offset" in="SourceAlpha" />
                        <feGaussianBlur stdDeviation="13 13" result="blur" in="offset" />
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" result="matrix"
                            in="blur" />
                        <feBlend in="SourceGraphic" in2="matrix" mode="normal" result="blend" />
                    </filter>
                );
        }
    }

    getHTMLFilter() {
        return null;
    }

    get DOMNode() {
        if (this.ref.current) {
            return this.ref.current.ref.current;
        }
    }

    addRenderUIFunction(fn) {
        if (!this.renderUIFunctions) {
            this.renderUIFunctions = [];
        }
        if (!this.renderUIFunctions.contains(fn)) {
            this.renderUIFunctions.push(fn);
        }
    }

    removeRenderUIFunction(fn) {
        if (this.renderUIFunctions) {
            this.renderUIFunctions.remove(fn);
        }
    }

    // the default BaseElement behavior is to render all the children sorted by their layer and return an array
    // elements can override this behavior to custom render their children if necessary
    renderChildren(transition) {
        let renderChildren = [];

        // we need to render the child elements in the correct order based on build order but respecting any
        // layer properties to override the implied order.
        // However, when collectionItem nodes are reordered,  react doesn't seem to deal always correctly with the reordering of
        // sibling nodes which results in the regeneration of the entire item node causing transitions to not play. So we need
        // to preserve a fixed order for CollectionItem nodes based on their id - so their DOM order never changes (even if their visual order does)

        let bottomLayer = [];
        let topLayer = [];
        let middleLayer = [];
        let dragLayer = [];

        for (let childElement of Object.values(this.elements)) {
            let childProps = childElement.calculatedProps;
            if (childProps) {
                if (childElement.isDragging) {
                    dragLayer.push(childElement);
                } else if (childProps.layer < 0) {
                    bottomLayer.push(childElement);
                } else if (childProps.layer > 0) {
                    topLayer.push(childElement);
                } else {
                    middleLayer.push(childElement);
                }
            }
        }

        bottomLayer = _.sortBy(bottomLayer, child => child.calculatedProps.layer);
        middleLayer = _.sortBy(middleLayer, child => {
            if (child.isCollectionItem) {
                return child.id;    // this forces the DOM ordering to be fixed for collection items regardless of visual order
            } else {
                return child.layer;
            }
        });
        topLayer = _.sortBy(topLayer, child => child.calculatedProps.layer);

        let renderOrder = [...bottomLayer, ...middleLayer, ...topLayer, ...dragLayer];
        // for (let childProps of _.sortBy(props.children, child => child.renderIndex || 0)) {
        for (let childElement of renderOrder) {
            if (childElement.id === "decoration") continue; // decoration was rendered in renderElement and doesn't obey padding

            let childProps = childElement.calculatedProps;
            childProps.key = childProps.id;

            if (childProps.bounds) {
                // important: we need to apply the parent's padding to all the child bounds
                childProps.paddedBoundsOffset = new geom.Point(this.calculatedStyles.paddingLeft || 0, this.calculatedStyles.paddingTop || 0);
                childProps.paddedBounds = childProps.bounds.offset(childProps.paddedBoundsOffset);
            }

            if (childElement && (!childElement.options.preventRender && this.id != "drop-placeholder") && (!childElement.isDeleted || transition)) {
                renderChildren.push(childElement.renderElement(transition));
            } else if (childElement && !childElement.isDeleted) {
                // Update isRendered to false on the child and all its children so they
                // can correctly resolve colors if next time they get rendered with refreshElement()
                const setIsRendered = element => {
                    if (!element) {
                        return;
                    }

                    element.isRendered = false;
                    Object.values(element.elements || {}).forEach(setIsRendered);
                };
                setIsRendered(childElement);
            }

            if (childElement && childElement.showAsDragDropPlaceholder && childProps.paddedBounds) {
                renderChildren.insert(
                    <div
                        key={`${childProps.key}-dragdrop-target-box`}
                        className="dragdrop-target-box"
                        style={childProps.paddedBounds.offset(-childElement.registrationPoint.x, -childElement.registrationPoint.y).toObject()}
                    />,
                    0
                );
            }
        }

        if (this.currDataSourceState === DataState.Loading) {
            renderChildren.push(
                <FetchingClickShield
                    key={`${this.id}-datasource-loading`}
                    visible={true} backgroundColor="transparent" iconStyle={{ color: "#11a9e2" }}
                />
            );
        }

        return renderChildren;
    }

    renderElement(transition, renderProps = { animationName: null }) {
        this.perfStart("renderElement");

        // let start = performance.now();
        // if (Object.keys(this.elements).length == 0) {
        //     perf.start("renderLeafElement");
        // }

        if (this.isDragging) {
            transition = false;
        }

        if (this.preventTransitionDuringRender) {
            transition = false;
        }

        // mark any new child elements from lastProps as isNew
        if (this.previousRenderedChildIds) {
            for (let element of Object.values(this.elements)) {
                element.isNew = !this.previousRenderedChildIds.includes(element.id);
            }
        }
        this.previousRenderedChildIds = _.map(this.elements, element => element.id);

        // render the element
        // perf.start("render " + this.type);
        let node = this._renderElement(transition, renderProps);
        // perf.stop("render " + this.type);

        // remove any child elements that are no longer in the model (ie their generationKey doesn't match the current generationKey)
        for (let element of Object.values(this.elements)) {
            if (element.generationKey !== this.generationKey) {
                this.removeElement(element);
            }
        }

        this.isRendered = true;

        this.perfStop("renderElement");

        return node;
    }

    _renderElement(transition, renderProps = { animationName: null }) {
        let renderChildren = [];

        let decorationBackground, decorationForeground;
        if (this.decoration) {
            [decorationBackground, decorationForeground] = this.decoration.renderDecorationElement(this.decoration.calculatedProps, transition);
        }

        if (decorationBackground) {
            renderChildren.push(decorationBackground);
        }

        if (decorationForeground && this.renderAllDecorationBehind) {
            renderChildren.push(decorationForeground);
        }

        // render any UI functions from attached editors
        if (this.renderUIFunctions) {
            for (let fn of this.renderUIFunctions) {
                renderChildren.push(fn(this));
            }
        }

        // recursively render all our children
        renderChildren = renderChildren.concat(this.renderChildren(transition));

        if (decorationForeground && !this.renderAllDecorationBehind) {
            renderChildren.push(decorationForeground);
        }

        let renderBounds = (this.calculatedProps.paddedBounds || this.calculatedProps.bounds).clone();

        if (this.isDragging && this.dragPosition) {
            renderBounds.left = this.dragPosition.x;
            renderBounds.top = this.dragPosition.y;
            // we have to offset dragging bounds because dragPosition doesn't respect padding
            if (this.calculatedProps.paddedBoundsOffset) {
                renderBounds = renderBounds.offset(this.calculatedProps.paddedBoundsOffset);
            }
            renderProps.zIndex = 9999;
        }

        renderBounds.left -= this.registrationPoint.x;
        renderBounds.top -= this.registrationPoint.y;

        if ((this.isNew || this.isReplacing) && this.shouldTransitionWhenNew && !this.isReplaced) {
            renderProps.fadeIn = true;
            transition = false; // don't transition values - just do the fadeIn so we don't pop and transition
        }

        if (this._isReplacing) {
            this._isReplacing = null;
        }

        if (this.isReplaced) {
            renderProps.fadeOut = true;
            transition = false; // don't transition values - just do the fadeIn so we don't pop and transition
        } else if (this.isDeleted || this.isHidden) {
            renderProps.opacity = 0;
        }

        let style = {};
        if (this.calculatedStyles.shadow) {
            style.shadow = this.calculatedStyles.shadow;
        }
        if (this.parentElement?.type == "CanvasElement") {
            style.shadow = this.canvas.getTheme().get("styleEffect") == "shadow";
        }

        style.filter = this.calculatedStyles.filter || this.getHTMLFilter();

        if (this.calculatedStyles.pointerEvents) {
            style.pointerEvents = this.calculatedStyles.pointerEvents;
        }

        if (this.calculatedStyles.animation && !renderProps.animationName) {
            style.animation = this.calculatedStyles.animation;
        }

        let opacity = this.calculatedProps.opacity ?? 1;
        if (this.isAnimating && "fadeInProgress" in this.animationState) {
            opacity = opacity * this.animationState.fadeInProgress;
        } else if (this.isDimmed) {
            opacity = opacity * 0.6;
        }

        return (
            <DivGroup
                id={this.id}
                key={this.id}
                ref={this.ref}
                className={this.type}
                bounds={renderBounds}
                transition={transition}
                clipPath={this.calculatedProps.clipPath}
                margins={this.calculatedStyles.margins}
                padding={this.calculatedStyles.padding}
                rotate={this.calculatedProps.rotate}
                opacity={opacity}
                styles={style}
                {...renderProps}
            >
                {renderChildren}
            </DivGroup>
        );
    }

    shouldTransition() {
        return true;
    }

    doesLayoutFit() {
        if (this.calculatedProps.isFit === false) {
            return false;
        }

        let childrenFit = true;
        _.each(this.elements, element => {
            if (element instanceof BaseElement) {
                if (element.doesLayoutFit() == false && !element.toBeDeleted) {
                    childrenFit = false;
                }
            }
        });
        return childrenFit;
    }

    getLayouter(props, items, containerSize) {
        return new ElementLayouter(this, props, items, containerSize);
    }

    removeElement(element) {
        if (!element) return;

        _.each(element.elements, el => element.removeElement(el));
        delete this.elements[element.id];

        if (element.dragTargetBox) {
            element.dragTargetBox.remove();
            element.dragTargetBox = null;
        }

        element.isDeleted = true;

        // Reporting removed element to the layouter to let it know it
        // needs to clean up dom after the next render pass so the deleted elements
        // get removed from dom after transition (only done if the next render
        // has transition)
        this.canvas.layouter.reportRemovedElement(element);
    }

    get showAsDragDropPlaceholder() {
        return (this.isDragging === true || this.id === "drop-placeholder") && this.showDragDropTarget !== false;
    }

    get preventTransitionDuringRender() {
        return false;
    }

    layerChildren() {
        this.decoration && this.decoration.updateLayers();
        this._layerChildren();
    }

    _layerChildren() {
        // can be overridden to provide any additional layering of svg nodes
    }

    _render(transition) {
        // can be overridden to provide any element-specific rendering
    }

    getChild(id) {
        let child = this.elements[id];
        if (!child) {
            child = this.elements[Object.keys(this.elements)[0]];
        }
        return child;
    }

    findChildById(id) {
        if (this.elements[id]) {
            return this.elements[id];
        } else {
            let foundElement;
            for (let element of Object.values(this.elements)) {
                foundElement = element.findChildById(id);
                if (foundElement) break;
            }
            if (foundElement) {
                return foundElement;
            }
        }
    }

    // region styles

    get loadedStyles() {
        if (!this._loadedStyles) {
            throw new Error("Styles not loaded");
        }

        return this._loadedStyles;
    }

    get calculatedStyles() {
        if (!this._calculatedStyles && this.loadedStyles) {
            throw new Error("Styles are loaded, but calculated styles are missing");
        }

        return this._calculatedStyles;
    }

    /**
     * Gets current styles (calculated -> loaded -> Error)
     * !!!!DON'T ABUSE, USE ONLY WHEN THERE'S NO OTHER WAY, USE DEDICATED STYLES INSTEAD!!!!
     */
    get styles() {
        // NOTE: we use this.loadedStyles instead of this._loadedStyles because we want to throw
        // if there are no styles loaded
        return this._calculatedStyles ?? this.loadedStyles;
    }

    get alwaysRefreshStyles() {
        return this.parentElement.alwaysRefreshStyles;
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // loads styles for this element from the current theme
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_

    loadStyles() {
        if (DEBUG_STYLES) {
            /* eslint-disable no-console */
            console.groupCollapsed("LoadStyles", this.toString());
            console.log("path", this.toString(true));
            /* eslint-disable no-console */
        }

        const styles = new ElementStyles();

        if (this.isRootElement()) {
            // load root element styles
            _.merge(styles, this.canvas.styleSheet.RootElement);
        }

        // load style for class and inherited classes
        for (const classType of this.getInheritancePath()) {
            if (DEBUG_STYLES) {
                /* eslint-disable no-console */
                if (this.canvas.styleSheet.hasOwnProperty(classType)) {
                    console.log("merge class styles from ", classType);
                    console.log(this.canvas.styleSheet[classType]);
                }
                if (this.canvas.styleSheet.hasOwnProperty(classType + "." + this.id)) {
                    console.log("merge class styles from ", classType + "." + this.id);
                    console.log(this.canvas.styleSheet[classType + "." + this.id]);
                }
                /* eslint-disable no-console */
            }
            _.merge(styles, this.canvas.styleSheet[classType]);
            _.merge(styles, this.canvas.styleSheet[classType + "." + this.id]);
        }

        // load styles from parent styles
        if (this.parentElement) {
            const path = _.drop(_.reverse(this.getElementPath()), 1);
            const parentStyles = this.parentElement.loadedStyles;
            for (const element of path) {
                const stylesKey = this.getStylesKeysForElement(element).find(stylesKey => stylesKey in parentStyles);
                if (stylesKey) {
                    if (DEBUG_STYLES) {
                        /* eslint-disable no-console */
                        console.log("merge parent styles from path element", element.toString(), "selector=", stylesKey);
                        console.log(parentStyles[stylesKey]);
                        /* eslint-disable no-console */
                    }
                    _.merge(styles, parentStyles[stylesKey]);
                }
            }
        }

        // apply decoration variables to styles with $
        styles.applyDecorationStyles(this, this.decorationStyle);

        if (DEBUG_STYLES) {
            /* eslint-disable no-console */
            console.log("loaded styles", styles);
            console.groupEnd("LoadStyles", this.toString());
            /* eslint-disable no-console */
        }

        this._loadStyles(styles);

        return styles;
    }

    getStylesKeysForElement(element) {
        return [`${element.type}.${element.id}`, element.id, element.type];
    }

    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    // _loadStyles can be overridden to merge any special-case styling with this.calculatedProps.styles
    // ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------_
    _loadStyles(styles) {
        // should be overridden by extending class
    }

    updateStyles(styles, updateChildren = false) {
        if (!styles) return;

        this.styles.applyStyles(styles);

        if (updateChildren) {
            for (const element of Object.values(this.elements)) {
                this.getStylesKeysForElement(element)
                    .filter(stylesKey => stylesKey in styles)
                    .forEach(stylesKey => element.updateStyles(styles[stylesKey], updateChildren));
            }
        }
    }

    /**
     * Scales calculatedStyles values
     */
    scaleStyleValues(scale, additionalKeys = []) {
        const scaleKeys = [
            "fontSize",
            "spaceBelow",
            "spaceAbove",
            "marginTop",
            "marginBottom",
            "marginLeft",
            "marginRight",
            "paddingTop",
            "paddingBottom",
            "paddingLeft",
            "paddingRight",
            "width",
            "height",
            "hGap",
            "vGap",
            ...additionalKeys
        ];

        this.walkStyles(this.calculatedStyles, (key, value, path, parent) => {
            if (scaleKeys.includes(key)) {
                parent[key] = parent[key] * scale;
            }
        });

        _.each(this.elements, element => {
            if (element.calculatedProps) {
                element.scaleStyleValues(scale, additionalKeys);
            }
        });
    }

    get isDecoration() {
        return false;
    }

    /**
     * Removes resolved colors cache and enforces all used colors to be resolved again on next render
     */
    markStylesAsDirty() {
        this.resolvedColorsCache = {};

        _.each(this.elements, element => {
            element.markStylesAsDirty();
        });
    }

    walkStyles(stylesNode, handleStylesValue = (key, value, path, parent) => {
    }, currentKey = "root", currentPath = "root", currentParent = null) {
        if (_.isObjectLike(stylesNode)) {
            for (const [key, value] of Object.entries(stylesNode)) {
                this.walkStyles(value, handleStylesValue, key, `${currentPath}.${key}`, stylesNode);
            }
            return;
        }

        if (_.isFunction(stylesNode)) {
            return;
        }

        handleStylesValue(currentKey, stylesNode, currentPath, currentParent);
    }

    resolveColorStyles(useCache = true) {
        // Resolves a given color
        const resolveColor = (key, value, path, parent) => {
            if (!value) {
                return null;
            }

            const backgroundColor = this.getBackgroundColor(this);

            const slideColor = this.getSlideColor();
            const color = this.canvas.getTheme().palette.getForeColor(value, slideColor, backgroundColor, { userColor: this.model.color, isOnAuthoringCanvas: this.isOnAuthoringCanvas });

            if (DEBUG_STYLES) {
                // eslint-disable-next-line no-console
                console.log(`[${this.toString(true)}]\nresolveColorStyles() resolve ${path} from ${value} to ${color} %c`);
            }

            return color;
        };

        // Walking styles and resolving colors when needed
        this.walkStyles(this.calculatedStyles, (key, value, path, parent) => {
            // Not a color key, skipping
            if (!["fillColor", "strokeColor", "fill", "stroke", "color", "lineColor", "gridLineColor", "tickColor", "bulletColor", "forceBackgroundColor"].includes(key) && !key.endsWith("fontColor")) {
                return;
            }

            // Color key that's not resolved, resolving
            const resolvedColorKeyName = `resolved_${key}`;
            if (!(resolvedColorKeyName in parent)) {
                Object.defineProperty(parent, resolvedColorKeyName, {
                    // Dynamic getter, will only resolve the color when actually requested
                    // (i.e. on render) this will help us avoid resolving children colors
                    // that aren't used
                    get: () => {
                        if (!useCache) {
                            return resolveColor(key, value, path, parent);
                        }

                        const cachePath = `resolved_${path}`;
                        // Checking for cache, generating color if needed
                        if (!this.resolvedColorsCache[cachePath]) {
                            this.resolvedColorsCache[cachePath] = resolveColor(key, value, path, parent);
                        } else if (DEBUG_STYLES) {
                            // eslint-disable-next-line no-console
                            console.log(`[${this.toString(true)}]\nresolveColorStyles() resolve *from cache* ${path} from ${value} to ${this.resolvedColorsCache[cachePath]} %c`);
                        }

                        return this.resolvedColorsCache[cachePath];
                    },
                    enumerable: true
                });
            }
        });

        _.each(this.elements, element => {
            if (element.calculatedProps) {
                element.resolveColorStyles();
            }
        });
    }

    get allowDecorationStyles() {
        return true;
    }

    get decorationStyle() {
        return this.model.decorationStyle;
    }

    // endregion

    // region Colors

    /*
        DO NOT OVERRIDE, use _getSlideColor instead

        Get the slide color (also called Foreground Color in the UI) for this element.
        A canvas's slideColor can either be a color, COLORFUL, or AUTO (which means dark on light/light on dark).
        If the slideColor is COLORFUL, return a colorful color from the palette based on the item's index
        If the shading option is provided, return a shaded color from the actual SlideColor based on the item's index and total number of items
        Otherwise, return the actual slideColor.

        NOTE: GridElementItem will override getItemIndex() and getItemCount() to automatically return the itemIndex and count. For elements that aren't using GridElement but still want
        to support COLORFUL and shading, you can pass in the index and itemCount in options. (ie. chart series)
     */
    getSlideColor(options = {}) {
        const cacheKey = `slide_color_${getObjectHash(options, true)}`;
        if (!this.elementColorsCache[cacheKey]) {
            this.elementColorsCache[cacheKey] = this._getSlideColor(options);
        }

        return this.elementColorsCache[cacheKey];
    }

    _getSlideColor(options = {}) {
        // if a color property is defined on the model, it will be used for the slideColor instead
        if (options.ignoreUserDefinedColor !== true) {
            const userDefinedColor = this.getUserDefinedFillColor();
            if (userDefinedColor) {
                return userDefinedColor;
            }
        }

        let slideColor;
        if (this.styles.fillColor === "tray") {
            slideColor = "background_accent";
        } else {
            slideColor = this.canvas.getSlideColor();
        }

        if (slideColor == ForeColorType.COLORFUL) {
            // get a colorful color from the palette based on the item index
            return this.canvas.getTheme().palette.getColorfulColor(options.index || this.itemIndex);
        } else if (options.shading) {
            // get a shaded color based on the slideColor, itemIndex and itemCount
            return this.canvas.getTheme().palette.getShadedColor(this.canvas.getTheme().palette.getColor(slideColor), options.index !== undefined ? options.index : this.itemIndex, options.itemCount || this.itemCount, options.shading == "dark");
        } else {
            // just return the slideColor
            return this.canvas.getTheme().palette.getColor(slideColor);
        }
    }

    /**
     * DO NOT OVERRIDE, use _getUserDefinedFillColor instead
     */
    getUserDefinedFillColor() {
        const cacheKey = "user_defined_fill_color";
        if (!this.elementColorsCache[cacheKey]) {
            this.elementColorsCache[cacheKey] = this._getUserDefinedFillColor();
        }

        return this.elementColorsCache[cacheKey];
    }

    _getUserDefinedFillColor() {
        const foreColor = this.model.color ?? this.options.color;
        if (!foreColor) {
            return null;
        }

        switch (foreColor) {
            case "auto":
                return null;
            case "none":
            default:
                return this.canvas.getTheme().palette.getColor(foreColor);
        }
    }

    /**
     * DO NOT OVERRIDE, use _getBackgroundColor instead
     */
    getBackgroundColor(forElement) {
        const cacheKey = `background_color_${forElement?.uniqueId ?? "default"}`;
        if (!this.elementColorsCache[cacheKey]) {
            this.elementColorsCache[cacheKey] = this._getBackgroundColor(forElement);
        }

        return this.elementColorsCache[cacheKey];
    }

    _getBackgroundColor(forElement) {
        if (!this.decoration ||
            forElement === this.decoration ||
            (!this.decoration.styles.fillColor && !this.decoration.styles.forceBackgroundColor) ||
            this.decoration.styles.forceBackgroundColor === "none" ||
            (this.decoration.styles.forceBackgroundColor === "slide" && this.getSlideColor().name === "none") ||
            this.decoration.styles.type === "none" ||
            this.decoration.styles.shape === "none" ||
            this.decoration.styles.fillColor === "none" ||
            (this.decoration.styles.fillColor === "slide" && this.getSlideColor().name === "none")
        ) {
            // if there was no decoration fill color to return, walk up the parent's to find a background color or return the canvas background color
            return this.getParentBackgroundColor(forElement);
        } else {
            // if there is a decoration with a fillColor, return that as the background color
            return this.getDecorationFillColor();
        }
    }

    getParentBackgroundColor(forElement) {
        if (this.parentElement) {
            return this.parentElement.getBackgroundColor(forElement);
        } else {
            return this.getCanvasElement().elements.background.canvasBackgroundColor;
        }
    }

    getDecorationFillColor() {
        const colorProp = this.decoration.styles.forceBackgroundColor ? "forceBackgroundColor" : "fillColor";
        if (this.decoration.styles[colorProp] !== "none") {
            /// Special case to allow getting fill color before decoration is calculated
            let removeCalculatedStyles = false;
            if (!this.decoration.calculatedProps) {
                this.decoration._calculatedStyles = _.cloneDeep(this.decoration.loadedStyles);
                removeCalculatedStyles = true;
            }
            if (!this.decoration.calculatedStyles[`resolved_${colorProp}`]) {
                this.decoration.resolveColorStyles(false);
            }

            const color = this.decoration.calculatedStyles[`resolved_${colorProp}`];

            if (removeCalculatedStyles) {
                delete this.decoration._calculatedStyles;
            }

            if (color.getAlpha() < 1 && !this.decoration.styles.ignoreTransparency) {
                return blendColors(color, this.getParentBackgroundColor(this));
            } else {
                return color;
            }
        } else {
            return this.getParentBackgroundColor(this);
        }
    }

    /*
        Sometimes an element's backgroundColor can't be determined from the decoration fill because it uses as SVG component as it's background shape instead of a decoration (ArrowBars, SliceChart, UserTestimonial, etc.)
        Those elements can override getBackgroundColor to return the fillColor of that background SVG component.
        getFillColor() is a helper function that will return the fillColor based on a style or color property. If there is no fill color for the styles, it will return the parent's backgroundColor
    */
    getShapeFillColor(shapeElement) {
        if (shapeElement.styles.fillColor != "none") {
            /// Special case to allow getting fill color before shape is calculated
            let removeCalculatedStyles = false;
            if (!shapeElement.calculatedProps) {
                shapeElement._calculatedStyles = _.cloneDeep(shapeElement.loadedStyles);
                removeCalculatedStyles = true;
            }
            if (!shapeElement.calculatedStyles.resolved_fillColor) {
                shapeElement.resolveColorStyles(false);
            }
            ///

            const fillColor = shapeElement.calculatedStyles.resolved_fillColor;

            if (removeCalculatedStyles) {
                delete shapeElement._calculatedStyles;
            }

            if (fillColor.getAlpha() < 1) {
                return blendColors(fillColor, this.getParentBackgroundColor(this));
            } else {
                return fillColor;
            }
        } else {
            return this.getParentBackgroundColor(this);
        }
    }

    resetUserColors() {
        this._resetUserColors();
        _.each(this.elements, element => {
            element.resetUserColors();
        });
    }

    _resetUserColors() {
        this.model.color = null;
    }

    // endregion

    // region Animations

    onCanvasAnimationStart() {
        this._onCanvasAnimationStart();
        Object.values(this.elements).forEach(element => element.onCanvasAnimationStart());
    }

    _onCanvasAnimationStart() {

    }

    get animateChildren() {
        return true;
    }

    get animationElementName() {
        return this.name;
    }

    // Tells if the canvas should disable all animations for the slide by default,
    // used to mimic the old behavior where some elements (i.e. NodeDiagram) were disabling all animations
    get disableAllAnimationsByDefault() {
        return Object.values(this.elements)
            .reduce((disableAnimations, element) => disableAnimations || element.disableAllAnimationsByDefault, false);
    }

    // Tells if the element and its children animations are disabled by default
    get disableAnimationsByDefault() {
        return false;
    }

    getAnimations() {
        const animations = this._getAnimations();
        animations.forEach(animation => animation.element = this);

        if (this.animateChildren) {
            Object.values(this.elements).forEach(element => {
                animations.push(...element.getAnimations());
            });
        }

        if (this.disableAnimationsByDefault) {
            animations.forEach(animation => animation.disabledByDefault = true);
        }

        return animations;
    }

    /**
     * Should be overridden by extending class
     */
    _getAnimations() {
        return [];
    }

    // endregion

    removeDataSource() {
        if (this.currDataSourceManager) {
            this.currDataSourceManager.removeListeners(this.currDataSourceStateChangedCb);
            this.currDataSourceId = null;
            this.currDataSourceState = null;
            this.currDataSourceManager = null;
        }
    }

    remove() {
        this.removeDataSource();

        _.each(this.elements, element => {
            element.remove();
        });

        this.isDeleted = true;
    }

    animate() {
        this._animate();
        Object.values(this.elements)
            .forEach(element => element.animate());
    }

    _animate() {
        // Override when an element needs to animate once permitted
    }
}

class DecorativeElement extends BaseElement {
    get canSelect() {
        return false;
    }

    get canRollover() {
        return false;
    }

    get canEdit() {
        return false;
    }
}

export { BaseElement, DecorativeElement };
