import { CollectionElementSelection, CollectionItemElementSelection } from "../CollectionElementEditor";
import { controls } from "../../../../editor/ui";
import { FormatOptionsMenu } from "../FormatOptionsMenu";
import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";
import { $, numeral, SVG } from "../../../../vendor";
import * as geom from "../../../../core/utilities/geom";
import { ds } from "js/core/models/dataService";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import EditTextDialog from "js/react/components/Dialogs/EditTextDialog";
import { FormatType } from "common/constants";

const RadialBarChartSelection = CollectionElementSelection.extend({
    renderControls: function() {
        this.createAddItemButton();

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Format",
            menuContents: closeMenu => {
                let $menu = new FormatOptionsMenu({
                    element: this.element,
                    allowedFormats: [FormatType.NONE, FormatType.PERCENT, FormatType.NUMBER, FormatType.CURRENCY],
                    showTableFormatOptions: false
                }).render().$el;
                return $menu;
            }
        });
    }
});

const RadialBarChartOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function($container) {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Start Position",
            property: "startPosition",
            transitionModel: false,
            items: [{
                value: "top", label: "Top"
            }, {
                value: "bottom", label: "Bottom"
            }]
        });

        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Track Style",
            property: "trackStyle",
            transitionModel: false,
            markStylesAsDirty: true,
            items: [{
                value: "none", label: "None"
            }, {
                value: "bar", label: "Bar"
            }, {
                value: "line", label: "Line"
            }]
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Grid",
            property: "showGrid",
            transitionModel: false,
        });

        this.addControl({
            type: controls.NUMERIC,
            label: "Max Value",
            property: "maxValue",
            triggerEvent: "blur",
            min: 1
        });
    }
});

const RadialBarChartItemSelection = CollectionItemElementSelection.extend({

    showSelectionBox: false,

    canDrag: function() {
        return false;
    },

    getOffset() {
        return 30;
    },

    getDeleteButtonPosition() {
        if (this.element.parentElement.startPosition == "top") {
            return new geom.Point(this.element.radius - this.element.label.bounds.width - 20, -20).multiply(this.canvasScale);
            // return new geom.Point(this.element.radius - this.element.label.bounds.width - 10, this.element.label.bounds.height / 2 - 2).multiply(this.canvas.canvasScale);
        } else {
            return new geom.Point(this.element.radius + this.element.label.bounds.width, this.element.radius * 2 - this.element.label.bounds.height - 10).multiply(this.canvasScale);
        }
    },

    renderControls: function() {
        let $selectionPathContainer = this.$el.addEl($.div("selection-path control"));
        this.selectionPathSVG = SVG($selectionPathContainer[0]);

        let radialBarChartElement = this.element.getRootElement();
        this.addControl({
            type: controls.SLIDER,
            min: 0,
            max: radialBarChartElement.maxValue,
            label: "Value",
            property: "value"
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            property: "color",
            includeAuto: true
        });

        let selectionBounds = this.element.selectionBounds.zeroOffset();

        let $dragWidget = this.$el.addEl($.div("drag-widget control"));
        $dragWidget.makeDraggable({
            start: event => {
                this.selectionLayer.hideWidgets($dragWidget);
            },
            drag: (event, position) => {
                let mousePt = this.screenToElementCoordinates(position.screenPosition, radialBarChartElement);

                let angle;
                if (radialBarChartElement.startPosition == "top") {
                    angle = (radialBarChartElement.bounds.zeroOffset().getPoint("center").angleToPoint(mousePt) + 90) % 360;
                } else {
                    angle = (radialBarChartElement.bounds.zeroOffset().getPoint("center").angleToPoint(mousePt) - 90) % 360;
                }
                let percent = angle / 270;

                if (radialBarChartElement.startPosition == "top") {
                    if (angle > 270 && angle < 340) {
                        percent = 1;
                    } else if (angle >= 340 && angle < 360) {
                        percent = 0;
                    }
                } else {
                    if (angle < -20) {
                        percent = 1;
                    } else if (angle < 0) {
                        percent = 0;
                    }
                }

                this.element.model.value = Math.clamp(Math.round(percent * radialBarChartElement.maxValue), 0, radialBarChartElement.maxValue);

                this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true }).then(() => {
                    this._layout();
                });
            },
            stop: (event, position) => {
                this.selectionLayer.showWidgets($dragWidget);
                this.element.canvas.updateCanvasModel(false);
            }
        });
    },

    _layout() {
        let selectionBounds = this.getElementSelectionBounds();

        this.selectionPathSVG.clear();
        this.selectionPathSVG.path(this.element.getSelectionPath(this.canvas.canvasScale).toPathData());

        let r = this.element.radius * this.canvas.canvasScale;
        let arcEndPoint = geom.Point.PointFromAngle(r, this.element.endAngle, new geom.Point(selectionBounds.width / 2, selectionBounds.height / 2));
        const OFFSET = 5;

        this.$el.find(".drag-widget").center(arcEndPoint);
    },

});

const RadialBarChartItemValueSelection = ElementSelection.extend({
    showSelectionBox: false,

    setup: function() {
        ds.selection.selection = null;
        const selectionBounds = this.element.selectionBounds;
        const screenBounds = this.element.getScreenBounds().offset(0, -selectionBounds.height / 2);
        const targetPt = new geom.Point(screenBounds.centerH, screenBounds.centerV);

        ShowDialog(EditTextDialog, {
            targetPt,
            target: this.element,
            minWidth: 150,
            value: this.element.parentElement.model.value,
            callback: value => {
                if (numeral.validateEx(value)) {
                    this.element.parentElement.model.value = numeral(value).value();
                    this.element.parentElement.canvas.updateCanvasModel(false);
                }
                // Clear selection so we can re-select this element again if need be.
                ds.selection.element = null;
            },
        });
    },

});

export const editors = {
    RadialBarChartSelection,
    RadialBarChartOptionsMenu,
    RadialBarChartItemSelection,
    RadialBarChartItemValueSelection
};
