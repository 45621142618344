import React from "react";
import styled from "styled-components";
import { _ } from "js/vendor";

import { sanitizeHtmlText } from "js/core/utilities/htmlTextHelpers";
import { AuthoringBlockType } from "common/constants";
import { AuthoringBlock } from "./AuthoringBlock";

const OuterBlockStyles = styled.div`
  width: 100%;
  margin: 0px 0px;
  pointer-events: auto;
  break-inside: avoid-column;
`;

export const BlockStyles = styled.div`
  padding: 0px;
  background: white;
`;

export const CodeBlockStyles = styled.div`
   width: 100%;
   height: 100%;
   // https://beautifulai.atlassian.net/browse/BA-12268 - this is a hack to get the code block to always render on the left side  
   text-align: left;
   
   .code {
       font-size: 20px;
       line-height: 1.5em;
       letter-spacing: 0px;
       font-family: monospace;
       white-space: pre-wrap;
       
       &.small {
          font-size: 16px;
       }
       &.medium {
          font-size: 20px;
       }
       &.large {
          font-size: 30px;
       }
         
   }

   pre {
        margin: 0px;
        pointer-events: none;
        background: transparent;
        border: none;
   }
  
  .hljs {
    padding: 20px !important;
    background: transparent !important;
  }
  
  &.style-light {
    background: #f8f8f8;
     
    .hljs {
      color: #657b83;
    }
    
    .hljs-comment,
    .hljs-quote {
      color: #93a1a1;
    }
    
    /* Solarized Green */
    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-addition {
      color: #859900;
    }
    
    /* Solarized Cyan */
    .hljs-number,
    .hljs-string,
    .hljs-meta .hljs-meta-string,
    .hljs-literal,
    .hljs-doctag,
    .hljs-regexp {
      color: #2aa198;
    }
    
    /* Solarized Blue */
    .hljs-title,
    .hljs-section,
    .hljs-name,
    .hljs-selector-id,
    .hljs-selector-class {
      color: #268bd2;
    }
    
    /* Solarized Yellow */
    .hljs-attribute,
    .hljs-attr,
    .hljs-variable,
    .hljs-template-variable,
    .hljs-class .hljs-title,
    .hljs-type {
      color: #b58900;
    }
    
    /* Solarized Orange */
    .hljs-symbol,
    .hljs-bullet,
    .hljs-subst,
    .hljs-meta,
    .hljs-meta .hljs-keyword,
    .hljs-selector-attr,
    .hljs-selector-pseudo,
    .hljs-link {
      color: #cb4b16;
    }
    
    /* Solarized Red */
    .hljs-built_in,
    .hljs-deletion {
      color: #dc322f;
    }
    
    .hljs-formula {
      background: #eee8d5;
    }
    
    .hljs-emphasis {
      font-style: italic;
    }
    
    .hljs-strong {
      font-weight: bold;
    }
  }
  
  &.style-dark {
  
    background: #333;
    
    .hljs {
      color: #bababa;
    }
        
    .hljs-strong,
    .hljs-emphasis {
      color: #a8a8a2;
    }
    
    .hljs-bullet,
    .hljs-quote,
    .hljs-link,
    .hljs-number,
    .hljs-regexp,
    .hljs-literal {
      color: #6896ba;
    }
    
    .hljs-code,
    .hljs-selector-class {
      color: #a6e22e;
    }
    
    .hljs-emphasis {
      font-style: italic;
    }
    
    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-section,
    .hljs-attribute,
    .hljs-name,
    .hljs-variable {
      color: #cb7832;
    }
    
    .hljs-params {
      color: #b9b9b9;
    }
    
    .hljs-string {
      color: #6a8759;
    }
    
    .hljs-subst,
    .hljs-type,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-symbol,
    .hljs-selector-id,
    .hljs-selector-attr,
    .hljs-selector-pseudo,
    .hljs-template-tag,
    .hljs-template-variable,
    .hljs-addition {
      color: #e0c46c;
    }
    
    .hljs-comment,
    .hljs-deletion,
    .hljs-meta {
      color: #7f7f7f;
    }
  }
`;

export class CodeBlock extends AuthoringBlock {
    get type() {
        return AuthoringBlockType.CODE;
    }

    constructor() {
        super();

        this.codeRef = React.createRef();
    }

    get blockMargin() {
        return {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        };
    }

    get html() {
        const { model } = this.props;
        return (!_.isEmpty(model.html) ? model.html : "Type code");
    }

    get style() {
        const { model } = this.props;
        return model.style ?? "light";
    }

    get textSize() {
        const { model } = this.props;
        return model.textSize ?? "medium";
    }

    render() {
        const { isDragging } = this.state;

        return (
            <OuterBlockStyles onMouseDown={this.handleMouseDown} style={{ opacity: isDragging ? 0.3 : null }} ref={this.containerRef}>
                <BlockStyles ref={this.ref}>
                    <CodeBlockStyles className={`style-${this.style}`}>
                        <pre className={`code hljs ${this.textSize}`} ref={this.codeRef}>
                            <code dangerouslySetInnerHTML={{ __html: sanitizeHtmlText(this.html) }} />
                        </pre>
                    </CodeBlockStyles>
                </BlockStyles>
            </OuterBlockStyles>
        );
    }
}
