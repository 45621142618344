import { Component } from "react";
import { _ } from "js/vendor";

export class AuthoringBaseEditor extends Component {
    state = {}

    componentDidMount() {
        this.setSelectionState();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.selection !== this.props.selection) {
            this.setSelectionState();
        }
    }

    setSelectionState() {
        this.setState({
            x: this.getContainerValue("x"),
            y: this.getContainerValue("y"),
            width: this.getContainerValue("width"),
            height: this.getContainerValue("height"),
            rotation: this.getContainerValue("rotation"),
            opacity: this.getContainerValue("opacity"),
            shadow: this.getContainerValue("shadow")
        });
    }

    getValue = property => element => {
        if (typeof property === "function") {
            return property(element);
        }
        return element[property] ?? element.model[property];
    }

    getContainerValue = property => {
        const values = [...new Set(this.props.selection.map(this.getValue(property)))];
        if (values.length == 1) {
            return values[0];
        } else {
            return "mixed";
        }
    }

    getElementValue = property => {
        const values = [...new Set(this.props.selection.map(this.getValue(property)))];
        if (values.length == 1) {
            return values[0];
        } else {
            return "mixed";
        }
    }

    setContainerValues = (containerValues, saveChanges = true) => {
        const { selection, refreshCanvasAndSaveChanges, refreshElement } = this.props;

        for (const element of selection) {
            if (typeof containerValues === "function") {
                containerValues(element);
            } else {
                Object.keys(containerValues).forEach(key => element.model[key] = containerValues[key]);
            }
        }

        if (saveChanges) {
            refreshCanvasAndSaveChanges().then(() => this.setSelectionState());
        } else {
            refreshElement();
            this.setSelectionState();
        }
    }

    setModelValues = (modelValues, saveChanges = true) => {
        const { selection, refreshCanvasAndSaveChanges, refreshElement } = this.props;

        for (const element of selection) {
            if (typeof modelValues === "function") {
                modelValues(element.childElement);
            } else {
                Object.keys(modelValues).forEach(key => element.childElement.model[key] = modelValues[key]);
            }
        }

        if (saveChanges) {
            refreshCanvasAndSaveChanges().then(() => this.setSelectionState());
        } else {
            refreshElement();
            this.setSelectionState();
        }
    }
}
