import { _ } from "js/vendor";
import { AuthoringBlockType, HeaderPositionType, PositionType, TextStyleType, TrayType } from "common/constants";
import { controls } from "js/editor/ui";
import { getStaticUrl } from "js/config";
import { ShowDialogAsync, ShowWarningDialog } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import { ds } from "js/core/models/dataService";

import { createResizer } from "../EditorComponents/Resizer";
import { ElementSelection } from "../BaseElementEditor";

const HeaderSelection = ElementSelection.extend({
    showSelectionBox: true,

    getOffset() {
        if (this.element.position == HeaderPositionType.LEFT) {
            return "canvas";
        } else {
            return 20;
        }
    },

    renderContextControls() {
        let header = this.element;

        let labelBlock = header.text.getBlockByTextStyle(TextStyleType.LABEL);
        let descriptionBlock = header.text.getBlockByTextStyle(TextStyleType.BODY);

        if (labelBlock && descriptionBlock) return false;

        let $container = this.createContextControls(this.element.textAlign, this.element.text.offsetBounds.left, this.element.paddedBounds.top + this.element.text.bounds.bottom + 30);

        if (!labelBlock) {
            $container.append(controls.createButton(this, {
                label: "Add Label",
                callback: async () => {
                    ds.selection.element = null;

                    let block = this.element.text.addBlock({
                        type: AuthoringBlockType.TEXT,
                        index: 0,
                        textStyle: TextStyleType.LABEL
                    });

                    this.element.canvas.refreshCanvas().then(() => {
                        ds.selection.element = this.element.text;
                        this.element.text.overlay.focusBlock(block.id);
                    });
                }
            }));
        }

        if (!descriptionBlock) {
            $container.append(controls.createButton(this, {
                label: "Add SubHeader",
                callback: async () => {
                    ds.selection.element = null;

                    let block = this.element.text.addBlock({
                        type: AuthoringBlockType.TEXT,
                        textStyle: TextStyleType.BODY
                    });

                    this.element.canvas.refreshCanvas().then(() => {
                        ds.selection.element = this.element.text;
                        this.element.text.overlay.focusBlock(block.id);
                    });
                }
            }));
        }

        return $container;
    },

    renderControls: function() {
        if (this.element.parentElement?.model.isLocked) return false;

        let canvas = this.element.canvas;

        if (canvas.model.layout.headerPosition == HeaderPositionType.LEFT && !this.element.isOnAuthoringCanvas) {
            let lastFitValue;
            createResizer({
                view: this,
                element: this.element,
                position: PositionType.RIGHT,
                minSize: 200,
                maxSize: 1280 / 2,
                handleType: "tray",
                showSelectionBox: true,
                onResize: async value => {
                    canvas.model.layout.headerWidth = value;
                    await canvas.refreshCanvas()
                        .then(() => {
                            lastFitValue = value;
                        })
                        .catch(err => {
                            canvas.model.layout.headerWidth = lastFitValue;
                            canvas.refreshCanvas();
                        });
                }
            }, this.selectionLayer);
        }

        let headerOptions = [];
        if (canvas.slideTemplate.availableHeaderPositions.contains("top")) {
            headerOptions.push({
                value: HeaderPositionType.TOP,
                label: "Top",
                image: getStaticUrl(`/images/ui/trays/header_top.png`)
            });
        }
        if (
            canvas.slideTemplate.availableHeaderPositions.contains("left") &&
            (
                canvas.model.layout.trayLayout == TrayType.NONE ||
                canvas.model.layout.trayLayout == null
            )
        ) {
            headerOptions.push({
                value: HeaderPositionType.LEFT,
                label: "Left",
                image: getStaticUrl(`/images/ui/trays/header_left.png`)
            });
        }

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Position",
            menuClass: "icon-menu twocol",
            items: headerOptions,
            callback: value => {
                if (this.element.isOnAuthoringCanvas) {
                    this.element.setPosition(value);
                } else {
                    canvas.model.layout.headerPosition = value;
                }
                this.element.markStylesAsDirty();
                canvas.updateCanvasModel(false).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, the content on your slide won't fit with the header on the left."
                    });
                });
            }
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            label: "Background",
            model: this.element.model,
            property: "backgroundColor",
            includeNone: true,
            omitCurrentBackgroundColor: true,
            showBackgroundColors: true,
        });
    },

});

export const editors = {
    HeaderSelection
};

