import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Key } from "js/core/utilities/keys";
import { _ } from "js/vendor";
import { themeColors } from "js/react/sharedStyles";
import { IconButton } from "@material-ui/core";

export const TitleLabel = styled.label`
  color: #222;
  font-size: 20px;
  font-weight: 600;
`;

export const SectionLabel = styled.label`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  display: block;
  color: #333;
`;

export const SmallLabel = styled.label`
  font-size: 11px;
  text-transform: uppercase;
  color: #666;
  margin-right: 10px;
  font-weight: 600;
`;

export const StyledInput = styled.input`
  width: ${props => props.width ? props.width : 50}px;
  font-size: 14px;
  background: none;
  border: solid 1px #eee;
  padding: 3px 8px;
  text-align: left;
  //flex-grow: 2;
  //margin-right: 5px;
  &:focus {
    outline: none;
    border: solid 1px ${themeColors.ui_blue};
  }

  ${props => props.noArrows &&
    `
&::-webkit-outer-spin-button,
&::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
-webkit-appearance: none;
margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

&[type=number] {
-moz-appearance:textfield; /* Firefox */
} `
}
`;

export const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => props.labelWidth ?? "80"}px calc(100% - ${props => props.labelWidth ?? "80"}px);
  align-items: center;
`;

export const StyledIconButton = styled(IconButton)`
    && {
        display: inline-block;
        text-align: center;
        position: relative;
        padding: 6px;
        text-transform: uppercase;
        border-radius: 0;
        color: #676767;
        font-size: 11px;

        .MuiIcon-root {
            display: flex;
            align-items: center;
            justify-content: center;
            color: #11A9E2;
            margin-right: 3px;
        }
    }
`;

export class Input extends Component {
    state = {
        inputValue: ""
    };

    constructor(props) {
        super(props);

        this.inputRef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            inputValue: this.props.value
        });

        this.inputRef.current.onchange = event => {
            this.commitValue(event.target.value);
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ inputValue: this.props.value });
        }
    }

    handleChange = event => {
        this.setState({
            inputValue: event.target.value
        });
    };

    commitValue = value => {
        this.props.onChange(value);
    };

    render() {
        let { inputValue } = this.state;
        let { type = "text" } = this.props;

        return (
            <StyledInput
                ref={this.inputRef}
                {...this.props}
                type={type}
                value={inputValue}
                onChange={this.handleChange}
            />
        );
    }
}

export class NumericInput extends Input {
    constructor(props) {
        super(props);

        this.debouncedOnChange = _.debounce(value => this.props.onChange(value), props.debounce, {
            leading: false,
            trailing: true
        });
    }

    componentDidMount() {
        this.setState({
            inputValue: this.props.value * (this.props.multiplier ?? 1)
        });

        this.inputRef.current.onchange = event => {
            this.commitValue(event.target.value / (this.props.multiplier ?? 1));
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.value != this.props.value) {
            this.setState({ inputValue: this.props.value * (this.props.multiplier ?? 1) });
        }
    }

    handleKeyDown = event => {
        if (event.shiftKey) {
            switch (event.which) {
                case Key.UP_ARROW:
                    event.stopPropagation();
                    this.setState({ inputValue: Math.min(parseFloat(event.target.value) + 9, this.props.max) });
                    break;
                case Key.DOWN_ARROW:
                    event.stopPropagation();
                    this.setState({ inputValue: Math.max(parseFloat(event.target.value) - 9, this.props.min) });
                    break;
            }
        }
    };

    commitValue = value => {
        let val = 0;
        if (!isNaN(parseFloat(value))) {
            val = parseFloat(value);
        }

        if (this.props.debounce && this.props.debounce > 0) {
            this.debouncedOnChange(val);
        } else {
            this.props.onChange(val);
        }
    };

    render() {
        let { min = 0, max = 100, step = 1, noArrows, width } = this.props;
        let { inputValue } = this.state;

        if (!width) {
            width = noArrows ? 25 : 45;
        }

        return (
            <StyledInput
                noArrows={noArrows}
                ref={this.inputRef}
                width={width}
                {...this.props}
                type="number"
                value={inputValue}
                onChange={this.handleChange}
                onKeyDown={this.handleKeyDown}
            />
        );
    }
}

const StyledButton = styled.div`
  padding: 7px 12px;
  background: ${themeColors.ui_blue};
  color: white;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.04em;

  &:hover {
    background: darken(${themeColors.hoverBlue}, 10);
  }
`;

export class Button extends Component {
    render() {
        return (
            <StyledButton className="bbtn">
                {this.props.children}
            </StyledButton>
        );
    }
}
