import moment from "moment";
import { ds } from "js/core/models/dataService";
import { $, _ } from "js/vendor";
import { getStaticUrl } from "js/config";
import { app } from "js/namespaces";
import { controls } from "js/editor/ui";
import { Key } from "js/core/utilities/keys";
import { getDataSourceControl } from "js/editor/dataSourceMenu";
import { renderChartWidgets } from "./ChartEditor";
import { ConnectorType, WidgetPositionType } from "common/constants";
import { ShowWarningDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import { chartUtils } from "../../slideTemplates/slideTemplates";
import { DashboardItem } from "../../elements/elements/Dashboard";
import { CollectionElement } from "../../elements/base/CollectionElement";
import { ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";
import { CollectionItemElementSelection } from "../CollectionElementEditor";
import { EditChartDialog } from "./ChartDialog";
import { EditPieChartDialog } from "./PieChartEditor";
import {
    ConnectorItemLabelSelection,
    ConnectorItemSelection,
    renderConnectorStyleMenu
} from "./ConnectorsEditor";
import { handleDragChangeInValueAnchor } from "./ChartChangeInValueHelper";

const DashboardSelection = ElementSelection.extend({
    showSelectionBox: false,

    getAddItemLabel: function() {
        return "Add Chart";
    },

    renderControls: function() {
        const infographicElement = this.element.itemElements[0].infographic;

        if (this.element.itemCount == 1) {
            this.$controlBar.addEl(getDataSourceControl(this.element.itemElements[0], this));

            if (this.element.canvas.model.template_id != "chart_waterfall") {
                createChartTypeMenu(this, infographicElement);
            }

            this.addControl({
                type: controls.BUTTON,
                label: "Edit Chart",
                icon: "edit",
                callback: () => {
                    let dialog;
                    switch (infographicElement.type) {
                        case "PieChart":
                            dialog = new EditPieChartDialog({ element: infographicElement });
                            break;
                        default:
                            dialog = new EditChartDialog({ element: infographicElement });
                    }
                    PresentationEditorController.showElementPanel(dialog);
                }
            });

            if (this.element.canvas.model.template_id != "chart_waterfall" && !this.element.isOnAuthoringCanvas) {
                this.addGap(20);
            }
        }

        if (this.element.canvas.model.template_id != "chart_waterfall" && !this.element.isOnAuthoringCanvas) {
            this.addControl({
                type: controls.BUTTON,
                label: "Add Chart",
                icon: "add",
                callback: () => {
                    let lastElementModel = this.element.itemElements[this.element.itemCount - 1].model;

                    if (lastElementModel.elementType == "piechart") {
                        let model = _.cloneDeep(lastElementModel.piechart);
                        model.data = [...Array(5)].map((o, i) => ({
                            label: { text: `Slice${i + 1}` },
                            value: parseInt(Math.random() * 100),
                            color: "auto",
                            offset: 0,
                            id: `wedge${i + 1}`
                        }));
                        this.addItem({
                            model: {
                                elementType: "piechart",
                                piechart: model
                            }
                        });
                    } else {
                        const year = (new Date()).getFullYear();
                        let model = _.cloneDeep(lastElementModel.chart);
                        model.chartAnnotations = {};
                        model.chartData.series = [{
                            id: "series1",
                            name: "Data",
                            type: model.chartData.series[0].type,
                            showDataLabels: model.chartData.series[0].showDataLabels,
                            marker: model.chartData.series[0].marker,
                            colorName: model.chartData.series[0].colorName,
                            lineWidth: model.chartData.series[0].lineWidth || undefined,
                            data: [...Array(8)].map(o => ({ y: parseInt(Math.random() * 100), pt: true }))
                        }];
                        model.chartData.series[0].data[0] = { y: 10, pt: true };
                        model.chartData.xAxis.categories = [...Array(8)].map((o, i) => moment(`${i + 1}/1/${year}`).format("MMM D YYYY"));
                        this.addItem({
                            model: {
                                chart: model
                            }
                        });
                    }
                }
            });

            let layouts = [];

            let menuClass = "icon-menu ";

            switch (this.element.itemCount) {
                case 2:
                    layouts = [{
                        value: "2-charts-horizontal",
                        label: "",
                        image: getStaticUrl("/images/ui/charts/2-charts-horizontal.svg")
                    }, {
                        value: "2-charts-vertical",
                        label: "",
                        image: getStaticUrl("/images/ui/charts/2-charts-vertical.svg")
                    }];
                    menuClass += "twocol";
                    break;
                case 3:
                    layouts = [{
                        value: "3-charts-horizontal",
                        label: "",
                        image: getStaticUrl("/images/ui/charts/3-charts-horizontal.svg")
                    }, {
                        value: "3-charts-left", label: "", image: getStaticUrl("/images/ui/charts/3-charts-left.svg")
                    }, {
                        value: "3-charts-right", label: "", image: getStaticUrl("/images/ui/charts/3-charts-right.svg")
                    }, {
                        value: "3-charts-top", label: "", image: getStaticUrl("/images/ui/charts/3-charts-top.svg")
                    }, {
                        value: "3-charts-bottom",
                        label: "",
                        image: getStaticUrl("/images/ui/charts/3-charts-bottom.svg")
                    }];
                    menuClass += "threecol";
                    break;
            }

            if (layouts.length) {
                this.addControl({
                    id: "chartLayout",
                    type: controls.POPUP_BUTTON,
                    label: "Layout",
                    menuClass: menuClass,
                    items: layouts,
                    property: "layout",
                    transitionModel: false
                });
            }
        }
    },

    addItem: function(options = {}) {
        if (this.element.itemCount === this.element.maxItemCount) {
            ShowDialogAsync(BadFitDialog, {
                title: "Sorry, we aren't able to fit another item to this layout",
            });
            return;
        }

        options = _.defaults(options, { model: {}, transition: true });
        const newItem = this.element.addItem(options.model || {});

        const processCanvasUpdates = async () => {
            try {
                await this.element.canvas.updateCanvasModel(options.transition);
            } catch (err) {
                ShowDialogAsync(BadFitDialog, {
                    title: "Sorry, we aren't able to fit another item to this layout",
                });
            } finally {
                await this.element.canvas.refreshCanvas();
            }
        };

        // We have to keep this method sync, so we just run the async code and return the new item immediately
        processCanvasUpdates();

        return newItem;
    },

    _handleKeyboardShortcut(event) {
        switch (event.which) {
            case Key.KEY_D:
                this.addItem();
        }
    }
});

const DashboardOptionsMenu = ElementOptionsMenu.extend({
    renderControls: function() {
        this.addControl({
            type: controls.DROPDOWN_MENU,
            label: "Chart Title",
            property: "showTitle",
            items: [{
                value: false, label: "None"
            }, {
                value: true, label: "Above"
            }, {
                value: "below", label: "Below"
            }],
            callback: value => {
                ds.selection.element = null;
            }
        });
    }
});

const DashboardItemSelection = CollectionItemElementSelection.extend({
    captureMouseEvents: false,

    showDataSourceMenu: function() {
        return true;
    },

    renderControls: function() {
        let infographicElement = this.element.infographic;

        // try an update the parent container
        const dashboard = this.element.getParentOfType("Dashboard");
        if (dashboard && dashboard.itemCount != 1) {
            createChartTypeMenu(this, infographicElement);

            this.addControl({
                type: controls.BUTTON,
                label: "Edit Chart",
                icon: "edit",
                callback: () => {
                    let dialog;
                    switch (infographicElement.type) {
                        case "PieChart":
                            dialog = new EditPieChartDialog({ element: infographicElement });
                            break;
                        default:
                            dialog = new EditChartDialog({ element: infographicElement });
                    }
                    PresentationEditorController.showElementPanel(dialog);
                }
            });
        }

        switch (infographicElement.type) {
            case "PieChart":
                // renderPieChartWidgets(this, infographicElement);
                break;
            default:
                renderChartWidgets(this, infographicElement);
        }
    },

    onDeleteItem: function() {
        const containerElement = this.element.parentElement;
        if (containerElement instanceof CollectionElement && containerElement.itemCollection.length > containerElement.minItemCount) {
            containerElement.deleteItem(this.element.id);

            this.element.canvas.updateCanvasModel(false)
                .then(() => {
                    ds.selection.rolloverElement = null;
                    ds.selection.element = null;
                })
                .catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit another item to this layout",
                    });
                })
                .then(() => {
                    this.element.canvas.refreshCanvas();
                });
        } else {
            ShowWarningDialog({
                title: "Oops!",
                message: "Can't delete last item",
            });
        }
    },

    addDataSourceControl: function() {
        if (this.element.parentElement.itemElements.length > 1) {
            let dataSourceButton = getDataSourceControl(this.element, this);
            this.$controlBar.addEl(dataSourceButton);
        }
    }
});

function createChartTypeMenu(editor, element) {
    editor.addControl({
        type: controls.POPUP_BUTTON,
        label: "Chart Type",
        menuContents: closeMenu => {
            let $menu = $.div("styles_menu");

            $menu.append(controls.createIconGrid(this, {
                value: element.type,
                items: [{
                    value: "line",
                    label: "Line",
                    icon: "/images/ui/charts/chart_line.svg"
                }, {
                    value: "column",
                    label: "Column",
                    icon: "/images/ui/charts/chart_column.svg"
                }, {
                    value: "bar",
                    label: "Bar",
                    icon: "/images/ui/charts/chart_bar.svg"
                }, {
                    value: "area",
                    label: "Area",
                    icon: "/images/ui/charts/chart_area.svg"
                }, {
                    value: "spline",
                    label: "Spline",
                    icon: "/images/ui/charts/chart_spline.svg"
                }, {
                    value: "areaspline",
                    label: "Area Spline",
                    icon: "/images/ui/charts/chart_areaspline.svg"
                }, {
                    value: "pie",
                    label: "Pie",
                    icon: "/images/ui/charts/chart_pie.svg"
                }, {
                    value: "donut",
                    label: "Donut",
                    icon: "/images/ui/charts/chart_donut.svg"
                }],
                callback: type => {
                    let dashboardItem = element.findClosestOfType(DashboardItem);

                    // perform the conversion
                    const { elementType: startingType } = dashboardItem.model;
                    dashboardItem.model = chartUtils.convertChartType(dashboardItem.model, type);

                    // special scenarios
                    switch (type) {
                        case "pie":
                        case "donut":
                            // switch to pie chart
                            if (startingType === "chart") {
                                ds.selection.rolloverElement = null;
                                ds.selection.element = null;
                            }

                            break;
                    }
                    // This is a special case fix to deal with a highcharts
                    //   bug when changing legend to/from proximate
                    element.canvas.layouter.clear();
                    // Again, highcharts bug requires relayout, so we have to tell undoManager to do ths same
                    element.canvas.updateCanvasModel(false, false, { undoOptions: { clearLayout: true } });
                    closeMenu();
                }
            }));

            return $menu;
        }
    });
}

const ChartChangeInValueLabelSelection = ConnectorItemLabelSelection.extend({
    getOffset() {
        return 10;
    },

    renderControls() {
        let connector = this.element.findClosestOfType("ConnectorItem");

        this.createDeleteComponentWidget({
            target: this.element,
            action: () => {
                let connectors = this.element.findClosestOfType("ConnectorGroup");
                connectors.deleteItem(connector.id);
                this.element.canvas.updateCanvasModel(false);
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Line Type",
            items: [{
                value: "change", label: "Change in Value"
            }, {
                value: "growth", label: "Growth/Trend Line"
            }],
            value: connector.model.connectorType == ConnectorType.STEP ? "change" : "growth",
            callback: value => {
                const chartElement = this.element.findClosestOfType("Chart");

                switch (value) {
                    case "change":
                        connector.model.connectorType = ConnectorType.STEP;
                        connector.model.startDecoration = "circle";
                        connector.model.endDecoration = "circle";
                        connector.model.startAnchorOffsetY = 0;
                        connector.model.endAnchorOffsetY = 0;
                        connector.model.lineStyle = "dotted";
                        break;
                    case "growth":
                        connector.model.connectorType = ConnectorType.STRAIGHT;
                        connector.model.startDecoration = "none";
                        connector.model.endDecoration = "arrow";
                        if (chartElement.isHorizontalChart) {
                            // Removing the offset for horizontal charts if it exists
                            delete connector.model["startAnchorOffsetY"];
                            delete connector.model["endAnchorOffsetY"];
                            connector.model.startAnchorOffsetX = 10;
                            connector.model.endAnchorOffsetX = 10;
                        } else {
                            // Removing the offset for horizontal charts if it exists
                            delete connector.model["startAnchorOffsetX"];
                            delete connector.model["endAnchorOffsetX"];
                            connector.model.startAnchorOffsetY = -70;
                            connector.model.endAnchorOffsetY = -70;
                        }
                        connector.model.lineStyle = "solid";
                        connector.model.labels[0].offset = 0;
                        break;
                }

                this.element.canvas.updateCanvasModel(false);
            }
        });

        this.addControl({
            type: controls.POPUP_BUTTON,
            label: "Format",
            items: [{
                value: "percent", label: "Percent"
            }, {
                value: "absolute", label: "Absolute"
            }, {
                value: "cagr", label: "CAGR"
            }, {
                value: "multiple", label: "Multiple"
            }, {
                value: "text", label: "Custom Text..."
            }],
            value: this.element.model.dataSource.changeType ?? "percent",
            callback: value => {
                this.element.model.dataSource.changeType = value;

                this.element.canvas.updateCanvasModel(false)
                    .then(() => {
                        // Recycle selection to force text editor to re-render
                        // in case of switching to/from custom text
                        ds.selection.element = null;
                        ds.selection.element = this.element;
                    });
            }
        });

        renderConnectorStyleMenu(this, connector);
    }
});

const ChartChangeInValueConnectorSelection = ConnectorItemSelection.extend({
    renderControls() {
        this.renderConnectorWidget(true);
        this.renderConnectorWidget(false);
    },

    renderConnectorWidget: function(isStart) {
        let chartElement = this.element.findClosestOfType("Chart");
        let chart = chartElement.chart;
        let seriesId = this.element.model.sourceSnapOptions.seriesId;
        let series = chart.series.find(s => s.userOptions.id == seriesId);
        let pointIndex = 0;

        let $el = this.$el.addEl($.div("connector-dragger control"));
        if (isStart) {
            $el.addClass("start");
            pointIndex = Math.min(this.element.model.sourceSnapOptions.pointIndex, this.element.model.targetSnapOptions.pointIndex);
        } else {
            $el.addClass("end");
            pointIndex = Math.max(this.element.model.sourceSnapOptions.pointIndex, this.element.model.targetSnapOptions.pointIndex);
        }

        let point = series.points[pointIndex];

        $el.on("mousedown", event => {
            event.stopPropagation();
            app.isDraggingItem = true;

            this.selectionLayer.hideWidgets();

            handleDragChangeInValueAnchor(this, point, chartElement.annotations, this.element.model, chartElement, isStart);
        });
    }

});

const DataHiliteNodeElementSelection = ElementSelection.extend({

    renderControls() {
        this.createDeleteComponentWidget({
            target: this.element,
            action: () => {
                let containerElement = this.element.parentElement;
                containerElement.deleteItem(this.element.id);
                ds.selection.element = null;
                this.element.canvas.updateCanvasModel(false, true);
            },
            position: WidgetPositionType.DELETE_BUTTON
        });

        this.addControl({
            type: controls.COLOR_PALETTE_PICKER,
            showBackgroundColors: true,
            omitCurrentBackgroundColor: true,
            showAuto: true,
            autoLabel: "AUTO",
            getAutoColor: () => {
                if (this.element.model.dataSource) {
                    let chart = this.element.canvas.getElementByUniquePath(this.element.model.dataSource.elementId);
                    if (chart) {
                        let series = chart.chartConfig?.series.find(s => s.id == this.element.model.dataSource.seriesId);
                        if (series) {
                            return this.element.canvas.getTheme().palette.getColor(series.colorName).toRgbString();
                        }
                    }
                }
            },
            showDecorationStyles: () => true,
            showMutedDecorationStyles: false,
            property: "color",
        });

        this.addControl({
            type: controls.SLIDER,
            value: this.element.model.userSize,
            min: 40,
            max: 200,
            callback: value => {
                this.element.model.userSize = parseInt(value);
                this.element.refreshElement();
            },
            onEnd: () => {
                this.element.canvas.updateCanvasModel(false);
            },
        });
    }

});

export const editors = {
    DashboardSelection,
    DashboardOptionsMenu,
    DashboardItemSelection,
    ChartChangeInValueConnectorSelection,
    ChartChangeInValueLabelSelection,
    DataHiliteNodeElementSelection
};
