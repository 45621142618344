import { _ } from "js/vendor";
import * as geom from "js/core/utilities/geom";
import { TrayType, TrayElementType } from "common/constants";
import { getValueOrDefault } from "js/core/utilities/extensions";

import { BaseElement } from "./BaseElement";
import { PhotoCollage } from "../elements/PhotoCollage";

class TrayContainer extends BaseElement {
    static get schema() {
        return {
            trayWidth: 440
        };
    }

    get canSelect() {
        return true;
    }

    get canRollover() {
        return true;
    }

    get showDefaultOverlay() {
        return this.trayElementType == TrayElementType.NONE;
    }

    get trayElementType() {
        return TrayElementType.IMAGE;
    }

    get trayLayout() {
        let trayLayout = this.getCanvasElement().trayLayout;
        if (trayLayout === TrayType.BOTTOM_TRAY || trayLayout === TrayType.TOP_TRAY) {
            trayLayout = TrayType.RIGHT_TRAY;
            // TODO?
            // this.canvas.model.layout.trayLayout = TrayType.RIGHT_TRAY;
        }
        return trayLayout;
    }

    get trayWidth() {
        return getValueOrDefault(this.model.trayWidth, 440);
    }

    get gutterSize() {
        return 10;
    }

    get isTray() {
        return this.trayLayout.equalsAnyOf(TrayType.BACKGROUND, TrayType.LEFT_TRAY, TrayType.RIGHT_TRAY);
    }

    get isInline() {
        return this.trayLayout.equalsAnyOf(TrayType.LEFT_INLINE, TrayType.RIGHT_INLINE);
    }

    get trayPosition() {
        return this.trayLayout.equalsAnyOf(TrayType.LEFT_TRAY, TrayType.LEFT_INLINE) ? "left" : "right";
    }

    get selectionPadding() {
        return 0;
    }

    refreshElement(transition) {
        this.canvas.refreshElement(this, transition);
    }

    get canRefreshElement() {
        return true;
    }

    resetUserColors() {
        return false;
    }

    _build() {
        this.trayElement = this.addElement("trayElement", () => TrayPhotoCollage, {
            fullBleed: true
        });

        if (this.trayElement) {
            this.trayElement.options.showRolloverOverlay = false;
        }
    }

    _calcProps(props, options) {
        let { size } = props;

        let trayProps;
        let traySize;

        if (options.calcMaxFit) {
            traySize = size;
        } else {
            switch (this.trayLayout) {
                case TrayType.BACKGROUND:
                    traySize = size;
                    break;
                case TrayType.LEFT_TRAY:
                case TrayType.RIGHT_TRAY:
                case TrayType.LEFT_INLINE:
                case TrayType.RIGHT_INLINE:
                default:
                    traySize = new geom.Size(this.trayWidth, size.height);
            }
        }

        switch (this.trayElementType) {
            case TrayElementType.IMAGE:
                let fullBleed = this.isInline || this.trayElement.showGutter == false;
                //
                let availableSize = traySize.clone();
                if (!fullBleed) {
                    availableSize = new geom.Size(availableSize.width - this.gutterSize * 2, availableSize.height - this.gutterSize * 2);
                }
                trayProps = this.trayElement.calcProps(availableSize, {
                    aspectRatio: options.aspectRatio,
                    checkFit: this.isTray // checks to make sure content with fit aspect ratio actually fits in the tray - otherwise reverts to Fill
                });

                if (fullBleed) {
                    trayProps.bounds = new geom.Rect(0, 0, trayProps.size);
                } else {
                    trayProps.bounds = new geom.Rect(this.gutterSize, this.gutterSize, trayProps.size);
                }

                break;
            case TrayElementType.NONE:
                break;
            case TrayElementType.TEXT:
            default:
                trayProps = this.trayElement.calcProps(traySize, { aspectRatio: options.aspectRatio });
                trayProps.bounds = new geom.Rect(0, 0, trayProps.size);
                break;
        }

        return { size: traySize };
    }

    _getUserDefinedFillColor() {
        return this.canvas.getTheme().palette.getColor(this.model.trayColor);
    }

    get disableAnimationsByDefault() {
        return true;
    }

    _exportToSharedModel() {
        const model = this.trayElement._exportToSharedModel();
        model.trayContent.props = {
            trayWidth: this.trayWidth, trayLayout: this.trayLayout, trayPosition: this.trayPosition
        };
        return model;
    }

    _importFromSharedModel(model) {
        const { trayContent } = model;
        if (!trayContent) return;

        const elModel = this.trayElement._importFromSharedModel(model);
        if (trayContent.props) {
            const { trayLayout, trayPosition, trayWidth } = trayContent.props;
            elModel.trayWidth = trayWidth;
            elModel.trayLayout = trayLayout;
            elModel.trayPosition = trayPosition;
        }
        return elModel;
    }
}

class TrayPhotoCollage extends PhotoCollage {
    get minItemCount() {
        return 0;
    }

    get animateChildren() {
        return false;
    }

    get animationElementName() {
        return "Tray";
    }

    _getAnimations() {
        return [{
            name: "Fade in",
            prepare: () => this.animationState.fadeInProgress = 0,
            onBeforeAnimationFrame: progress => {
                this.animationState.fadeInProgress = progress;
            }
        }];
    }

    _exportToSharedModel() {
        return { trayContent: this.model };
    }

    _importFromSharedModel(model) {
        return model.trayContent;
    }
}

export { TrayContainer };

export const elements = {
    TrayContainer
};
