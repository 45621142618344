import { $, SVG, _ } from "js/vendor";
import { getStaticUrl } from "js/config";
import { v4 as uuid } from "uuid";

import { ds } from "js/core/models/dataService";
import { controls } from "js/editor/ui";
import { AssetType, TrayType, AuthoringBlockType, TextStyleType } from "common/constants";
import { ShowDialog, ShowWarningDialog, ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";
import BadFitDialog from "js/react/components/Dialogs/BadFitDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";

import { MediaElement } from "../../elements/base/MediaElements/MediaElement";
import { TrayContainer } from "../../elements/base/TrayContainer";
import { GridLayouts } from "../../elements/layouts/GridLayout";

import { CreateMediaMenu } from "./pictureEditor";
import { ElementDefaultOverlay, ElementOptionsMenu, ElementSelection } from "../BaseElementEditor";
import {
    CollectionElementSelection,
    CollectionItemElementSelection
} from "../CollectionElementEditor";
import { BuildTextFrameOptionsMenu } from "./TextFrameEditor";
import { TextElementSelection } from "./TextEditor";
import { mergeMediaElementModelDefaults } from "common/assetUtils";

export function CreatePhotoCollageControls(view, element, options = {}) {
    let isTrayContainer = view.element instanceof TrayContainer;

    let addItemLabel;

    if (options.addItemLabel) {
        addItemLabel = options.addItemLabel;
    } else if (element.allowText) {
        addItemLabel = "Add Cell";
    } else {
        addItemLabel = "Add Image";
    }

    view.addControl({
        type: controls.BUTTON,
        label: addItemLabel,
        icon: element.allowText ? "add_circle" : "add_a_photo",
        callback: () => {
            if (element.allowText == false) {
                ShowDialog(AddAssetsContainer, {
                    workspaceId: ds.selection.presentation.getWorkspaceId(),
                    callback: model => {
                        const newItem = element.addItem();
                        mergeMediaElementModelDefaults(
                            newItem,
                            model,
                        );
                        element.canvas.updateCanvasModel(false).catch(err => {
                            ShowDialogAsync(BadFitDialog, {
                                title: "Sorry, we aren't able to fit another item to this layout",
                            });
                        });
                    },
                });
            } else {
                element.addItem();
                element.canvas.updateCanvasModel(true).catch(err => {
                    ShowDialogAsync(BadFitDialog, {
                        title: "Sorry, we aren't able to fit another item to this layout",
                    });
                });
            }
        }
    });

    let createGridThumbnail = (index, layout, images) => {
        let $thumbnail = $.div("thumbnail").attr("data-layout-type", "grid").attr("data-grid-id", index).data("grid-layout", layout);

        let height = 50;

        if (layout.cols) {
            let width = 84;

            let svg = SVG($thumbnail[0]).size(width, height);

            let gap = 4;

            // gridLayout
            let colWidth = (width - (layout.cols - 1) * gap - gap * 2) / layout.cols;
            let rowHeight = (height - (layout.rows - 1) * gap - gap * 2) / layout.rows;

            for (let cell of layout.cells) {
                svg.rect(cell[2] * (colWidth + gap) - gap, cell[3] * (rowHeight + gap) - gap).move(gap + cell[0] * (colWidth + gap), gap + cell[1] * (rowHeight + gap)).fill("#ccc");
            }
        } else {
            // tileLayout
            let layoutWidth = 0;
            let layoutHeight = 0;

            let minX = 99999;
            let minY = 99999;

            for (let cell of layout) {
                layoutWidth = Math.max(cell.x + cell.w, layoutWidth);
                layoutHeight = Math.max(cell.y + cell.h, layoutHeight);
                minX = Math.min(minX, cell.x);
                minY = Math.min(minY, cell.y);
            }

            let scale = height / layoutHeight;

            let width = (layoutWidth - minX) * scale;

            if (width > 100) {
                scale = 100 / layoutWidth;
                width = 100;
                height = layoutHeight * scale;
            }

            let svg = SVG($thumbnail[0]).size(width, height);

            for (let cell of layout) {
                svg.rect(cell.w * scale, cell.h * scale).move((cell.x - minX) * scale, (cell.y - minY) * scale).fill("#ccc").stroke({
                    color: "white",
                    width: 2
                });
            }
        }

        return $thumbnail;
    };

    if (element.itemCollection.length > 1 && element.layoutType == "grid") {
        view.addControl({
            id: "photocollage_grid",
            type: controls.POPUP_BUTTON,
            label: "Grid",
            menuContents: closeMenu => {
                let $menu = $.div();

                let $gridLayouts = $menu.addEl($.div("layout_thumbnails"));
                let $thumbnails = $gridLayouts.addEl($.div("thumbnails"));

                let images = _.map(element.itemElements, item => {
                    if (item.content) {
                        return item.content.assetElement.image;
                    } else {
                        return {};
                    }
                });

                if (element.aspectRatio == "fit") {
                    for (let i = 0; i < Math.min(10, element.tileLayouts.length); i++) {
                        let $thumbnail = $thumbnails.addEl(createGridThumbnail(i, element.tileLayouts[i], images));
                        $thumbnail.toggleClass("selected", i == element.model.gridLayout);
                    }
                } else {
                    let gridLayout = GridLayouts[element.itemCount - 1];
                    gridLayout = gridLayout.sort((l, r) => (r.sortOrder || 0) - (l.sortOrder || 0));
                    for (let i = 0; i < gridLayout.length; i++) {
                        let $thumbnail = $thumbnails.addEl(createGridThumbnail(i, gridLayout[i], images));
                        $thumbnail.toggleClass("selected", i == element.model.gridLayout);
                    }
                }

                $gridLayouts.on("click", ".thumbnail", event => {
                    ds.selection.rolloverElement = null;
                    element.model.gridLayout = $(event.currentTarget).attr("data-grid-id");
                    // Should not be necessary because we should have only one selected at a time
                    // but just in case...
                    Array.from(document.getElementsByClassName("selected")).forEach(el => el.classList.remove("selected"));
                    element.canvas.updateCanvasModel(true).then(() => {
                        document.querySelector(`[data-grid-id="${element.model.gridLayout}"]`).classList.add("selected");
                    }).catch(err => {
                        ShowWarningDialog({
                            title: "Sorry, we can't make this change",
                            message: err.message,
                        });
                    });
                });
                return $menu;
            }
        });
    }

    view.addControl({
        id: "photocollage_layout",
        type: controls.POPUP_BUTTON,
        icon: "settings",
        showArrow: false,
        // label: "Image Options",
        menuContents: closeMenu => {
            const $menu = $.div();
            const $row = $menu.addEl($.div("xcontrol-row"));

            $row.append(controls.createMultiToggle(view, {
                type: controls.MULTI_TOGGLE,
                label: "Image Aspect Ratio",
                singleLine: true,
                options: [{
                    value: "fill", label: "Fill"
                }, {
                    value: "fit", label: "Fit"
                }],
                value: element.aspectRatio,
                enabled: _.some(element.itemElements, { showImage: true }),
                callback: value => {
                    element.model.aspectRatio = value;
                    if (value == "fit") {
                        if (element.id == "trayElement") {
                            element.canvas.model.layout.trayLayout = element.canvas.model.layout.trayLayout.replace("tray", "inline");
                        } else {
                            element.model.fullBleed = false;
                        }

                        // when switching to fit, reset the transform on all images
                        for (let cell of element.itemElements) {
                            const element = cell.content?.assetElement;
                            if (element instanceof MediaElement) {
                                element.resetMediaTransform();
                            }
                        }
                    }

                    element.canvas.updateCanvasModel(true);
                }

            }));

            $row.append($.hr());

            $row.append(controls.createToggle(view, {
                label: "Show Gutter",
                property: "showGutter",
                model: element.model,
                markStylesAsDirty: true,
                enabled: element.itemElements.length > 1 || (element.fullBleed && (!isTrayContainer || !element.canvas.model.layout.trayLayout.contains("inline")))
            }));

            if (isTrayContainer) {
                $row.append(controls.createToggle(view, {
                    label: "Full Bleed",
                    value: element.canvas.model.layout.trayLayout.contains("tray"),
                    markStylesAsDirty: true,
                    callback: value => {
                        if (value) {
                            element.model.aspectRatio = "fill";
                            element.canvas.model.layout.trayLayout = element.canvas.model.layout.trayLayout.replace("inline", "tray");
                        } else {
                            element.canvas.model.layout.trayLayout = element.canvas.model.layout.trayLayout.replace("tray", "inline");
                        }
                        element.canvas.updateCanvasModel(true);
                    }
                }));
            } else {
                $row.append(controls.createToggle(view, {
                    label: "Full Bleed",
                    property: "fullBleed",
                    model: element.model,
                    markStylesAsDirty: true,
                    enabled: element.model.aspectRatio != "fit"
                }));
            }

            $row.append($.hr());

            $menu.append(controls.createOptionsList(this, {
                property: "frame",
                label: "Frame",
                markStylesAsDirty: true,
                selectedValue: element.model.frame || "none",
                items: [{
                    value: "none",
                    label: "None",
                    img: getStaticUrl("/images/frames/frame_none.png")
                }, {
                    value: "light",
                    label: "White",
                    img: getStaticUrl("/images/frames/frame_white.png")
                }, {
                    value: "dark",
                    label: "Dark",
                    img: getStaticUrl("/images/frames/frame_dark.png")
                }],
                callback: value => {
                    element.model.frame = value;
                    element.markStylesAsDirty();
                    element.canvas.updateCanvasModel(false);
                }
            }));

            return $menu;
        }
    });
}

const PhotoCollageSelection = CollectionElementSelection.extend({
    captureMouseEvents: false,

    getAddItemLabel() {
        return "Add Image";
    },

    renderControls: function() {
        CreatePhotoCollageControls(this, this.element);
    }
});

const PhotoCollageItemSelection = CollectionItemElementSelection.extend({
    captureMouseEvents: false,

    getWidgetPosition() {
        return "inner";
    },

    getTitle: function() {
        if (this.element.itemCount > 1) {
            return `Cell #${this.element.itemIndex + 1}`;
        } else {
            return null;
        }
    },

    getOffset: function() {
        return -40;
    },

    showOptionsMenu: function() {
        if (this.element.getRootElement().isInstanceOf("Headline")) {
            // Headline photocollage doesnt show the options menu for adding text elements
            return false;
        } else {
            return true;
        }
    },

    getDragAxis: function() {
        switch (this.element.parentElement.layoutType) {
            case "vertical":
                return "y";
            case "horizontal":
                return "x";
            default:
                return "";
        }
    },

    onDeleteItem() {
        let isTrayContainer = this.element.parentElement.parentElement instanceof TrayContainer;

        if (isTrayContainer && this.element.itemCount == 1) {
            ds.selection.element = null;
            // this is a bit ugly but we need to remove a tray when the last cell is deleted and this is the best place i could figure out to do it...
            this.element.canvas.model.layout.trayLayout = TrayType.NONE;
            this.element.canvas.model.elements.tray = null;
            this.element.canvas.updateCanvasModel(true);
        } else {
            CollectionItemElementSelection.prototype.onDeleteItem.apply(this, arguments);
        }
    },

    renderControls: function() {
        if (this.element.showImage == false || (this.element.content && this.element.content.isBackgroundVisible)) {
            this.addControl({
                type: controls.COLOR_PALETTE_PICKER,
                showAuto: true,
                showNone: true,
                showBackgroundColors: true,
                model: this.element.model,
                property: "cellColor",
            });
        }

        if (this.element.showImage) {
            CreateMediaMenu(this, this.element.content, {
                onReplaceAsset: asset => {
                    if (this.element.showAttribution) {
                        this.element.model.attribution.blocks[0].html = asset.content_attribution;
                    }
                }
            });
        }

        if (this.element.showText) {
            BuildTextFrameOptionsMenu(this, this.element.textFrame);
        }
    },
});

const PhotoCollageItemOptionsMenu = ElementOptionsMenu.extend({
    renderControls() {
        let optionsMenu = this;

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Media",
            value: this.element.showImage,
            callback: value => {
                if (value) {
                    optionsMenu.closeMenu();
                    const assetType = this.element.model.content_type || this.element.options.defaultAssetType || AssetType.IMAGE;
                    ShowDialog(AddAssetsContainer, {
                        assetType,
                        workspaceId: ds.selection.presentation.getWorkspaceId(),
                        callback: model => {
                            if (!this.element.showImage) {
                                this.element.model.textStyle = "white_box";
                            }
                            mergeMediaElementModelDefaults(
                                this.element.model,
                                model,
                            );

                            this.element.canvas.updateCanvasModel(false);
                        },
                    });
                } else {
                    this.element.model.content_value = null;
                    this.element.model.content_type = null;
                    this.element.canvas.updateCanvasModel(false);
                }
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Text",
            value: this.element.showText,
            callback: value => {
                if (value) {
                    let blockId = uuid();
                    this.element.model.text = {
                        blocks: [{
                            id: blockId,
                            html: "",
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.HEADING
                        }]
                    };

                    this.element.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = this.element.textFrame.text;
                        ds.selection.element.overlay.focusBlock(blockId);
                    });
                } else {
                    this.element.model.text = null;
                    this.element.canvas.updateCanvasModel(false);
                }
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Caption",
            value: this.element.showCaption,
            enabled: this.element.showImage,
            callback: value => {
                if (value) {
                    let blockId = uuid();
                    this.element.model.caption = {
                        blocks: [{
                            id: blockId,
                            type: AuthoringBlockType.TEXT,
                            html: "",
                            textStyle: TextStyleType.CAPTION
                        }]
                    };
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = this.element.caption;
                        ds.selection.element.overlay.focusBlock(blockId);
                    });
                } else {
                    this.element.model.caption = null;
                    this.element.canvas.updateCanvasModel(false);
                }
            }
        });

        this.addControl({
            type: controls.TOGGLE,
            label: "Show Attribution",
            value: this.element.showAttribution,
            enabled: this.element.showImage,
            callback: value => {
                if (value) {
                    let blockId = uuid();
                    this.element.model.attribution = {
                        blocks: [{
                            id: blockId,
                            type: AuthoringBlockType.TEXT,
                            html: this.element.content.asset.get("attribution"),
                            textStyle: TextStyleType.BODY
                        }]
                    };
                    this.element.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = this.element.attribution;
                        ds.selection.element.overlay.focusBlock(blockId);
                    });
                } else {
                    this.element.model.attribution = null;
                    this.element.canvas.updateCanvasModel(false);
                }
            }
        });
    }
});

const PhotoCollageCaptionSelection = TextElementSelection.extend({

    getWidgetPosition() {
        return "inner";
    },

    renderControls() {
        this.createDeleteComponentWidget({
            action: () => {
                ds.selection.element = null;
                this.element.findClosestOfType("PhotoCollageItem").model.caption = null;
                this.element.canvas.updateCanvasModel(true);
            }
        });
    }
});

const PhotoCollageItemDefaultOverlay = ElementDefaultOverlay.extend({

    render() {
        let $add = this.$el.addEl(controls.createButton(this, {
            icon: "add_a_photo",
            callback: () => {
                ShowDialog(AddAssetsContainer, {
                    callback: model => {
                        mergeMediaElementModelDefaults(
                            this.element.model,
                            model,
                        );
                        this.element.canvas.updateCanvasModel(false);
                    },
                });
            }
        }));

        if (this.element.parentElement.allowText) {
            this.$el.addEl(controls.createButton(this, {
                icon: "text_increase",
                callback: () => {
                    let blockId = uuid();
                    this.element.model.text = {
                        blocks: [{
                            id: blockId,
                            html: "",
                            type: AuthoringBlockType.TEXT,
                            textStyle: TextStyleType.HEADING
                        }]
                    };

                    this.element.canvas.updateCanvasModel(false).then(() => {
                        ds.selection.element = this.element.textFrame.text;
                        ds.selection.element.overlay.focusBlock(blockId);
                    });
                }
            }));
        }

        return this;
    },
});

const PhotoCollageContentSelection = ElementSelection.extend({
    getOffset() {
        return -36;
    },

    renderControls: function() {
        if (this.element.contentType == AssetType.ICON) {
            if (this.element.assetElement.getBackgroundColor(this.element.assetElement).isColor == false) {
                this.addControl({
                    type: controls.COLOR_PALETTE_PICKER,
                    label: "Icon Color",
                    includeAuto: true,
                    includeAddImage: false,
                    showBackgroundColors: true,
                    property: "iconColor",
                    selectedColor: this.element.model.iconColor,
                });
            }
            this.addControl({
                type: controls.COLOR_PALETTE_PICKER,
                label: "Background",
                includeAuto: true,
                includeAddImage: false,
                showBackgroundColors: true,
                property: "backgroundColor",
                selectedColor: this.element.getSlideColor(),
            });
        }
    }

});

export const editors = {
    PhotoCollageSelection,
    PhotoCollageItemSelection,
    PhotoCollageItemDefaultOverlay,
    PhotoCollageItemOptionsMenu,
    PhotoCollageContentSelection,
    PhotoCollageCaptionSelection
};
