import { ElementSelection } from "../BaseElementEditor";
import { _ } from "js/vendor";
import { renderReactRootJSX } from "js/react/renderReactRoot";

import { ImageFrameMenu } from "../EditorComponents/ImageFrameMenu";
import { BlockTypePopupMenu } from "../authoring/Editors/BlockEditors/TextFormatBar";
import { themeColors } from "js/react/sharedStyles";
import { ControlBar, ControlBarGroup } from "../EditorComponents/ControlBar";
import React from "react";
import { PopupMenu } from "js/react/components/PopupMenu";
import { Icon, MenuItem, MenuList } from "@material-ui/core";
import { ColorPicker } from "js/react/components/ColorPicker";
import { ShowDialog } from "js/react/components/Dialogs/BaseDialog";
import { AddAssetsContainer } from "js/react/views/AddAssets";
import { AssetType } from "common/constants";
import { ds } from "js/core/models/dataService";
import { mergeMediaElementModelDefaults } from "common/assetUtils";

const MediaBlockSelection = ElementSelection.extend({

    showSelectionBox: false,
    getOffset() {
        return "top -40";
    },

    handleReplaceMedia() {
        ShowDialog(AddAssetsContainer, {
            assetType: this.element.model.content_type || this.element.options.defaultAssetType || AssetType.IMAGE,
            workspaceId: ds.selection.presentation.getWorkspaceId(),
            callback: model => {
                mergeMediaElementModelDefaults(
                    this.element.model,
                    model,
                );
                this.element.canvas.updateCanvasModel(false);
            },
        });
    },

    handleAdjustMedia() {
        ds.selection.element = this.element.media.assetElement;
    },

    handleChangeFrame(frameType, frameAspectRatio) {
        this.element.model.frameType = frameType;
        this.element.model.frameAspectRatio = frameAspectRatio;
        this.element.markStylesAsDirty();
        this.element.canvas.updateCanvasModel(false);
    },

    async handleSetColor(color) {
        this.element.model.color = color;
        this.element.markStylesAsDirty();
        await this.element.canvas.refreshCanvas({ suppressRefreshCanvasEvent: true });
        this.element.canvas.saveCanvasModel();
    },

    handleSetDecorationStyle(style) {
        this.element.model.decorationStyle = style;
        this.element.markStylesAsDirty();
        this.element.canvas.updateCanvasModel(false);
    },

    renderControls() {
        renderReactRootJSX(
            <ControlBar position="above" offset={-20}>
                <ControlBarGroup color={themeColors.darkBlue}>
                    <BlockTypePopupMenu
                        selectedBlocks={[this.element.parentElement.getBlockRef(this.element.options.blockId)]}
                        allowedBlockTypes={this.element.parentElement.options.allowedBlockTypes}
                        onChange={() => {
                            this.element.canvas.updateCanvasModel();
                        }}
                    />
                </ControlBarGroup>
                <ControlBarGroup>
                    <PopupMenu icon="photo_camera" childrenAreMenuItems position="above">
                        <MenuList>
                            <MenuItem onClick={() => this.handleReplaceMedia()}>
                                <Icon>add_a_photo</Icon>
                                Replace Media...
                            </MenuItem>
                            <MenuItem onClick={() => this.handleAdjustMedia()}>
                                <Icon>crop</Icon>
                                Adjust Media
                            </MenuItem>
                        </MenuList>
                    </PopupMenu>
                    <PopupMenu icon="filter_frames" position="above">
                        <ImageFrameMenu
                            selectedFrame={this.element.media.frameType}
                            onSelect={(frameType, aspectRatio) => this.handleChangeFrame(frameType, aspectRatio)}
                            onSelectStyle={style => this.handleSetDecorationStyle(style)}
                        />
                    </PopupMenu>
                    <ColorPicker
                        color={this.element.model.color}
                        showAuto
                        autoColor={this.element.getSlideColor()}
                        onChange={color => this.handleSetColor(color)}
                        position="above"
                    />
                </ControlBarGroup>
            </ControlBar>,
            this.$controlBar[0]
        );
    }
});

export const editors = {
    MediaBlockSelection,
};
